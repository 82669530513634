import { Calculator } from '@methodset/calculator-ts';
import { ReactElement, useContext } from 'react';
import { Applet, PanelWidgetConfiguration } from '@methodset/model-client-ts';
import { AppletViewer } from 'containers/Console/Dashboards/DashboardItem/AppletViewer/AppletViewer';
import { Configuration } from '@methodset/endpoint-client-ts';
import { Card } from 'antd';
import { ConfigurationCallback } from '../WidgetViewer/WidgetViewer';
import classNames from 'classnames';
import './PanelWidgetViewer.less';

export type PanelWidgetViewerProps = {
    calculator: Calculator,
    configuration: PanelWidgetConfiguration,
    // modelId: string,
    // version?: number,
    applet: Applet,
    appletConfiguration: Configuration,
    onUpdate: ConfigurationCallback
};

export const PanelWidgetViewer = (props: PanelWidgetViewerProps): ReactElement => {

    const buildAppletsView = (): ReactElement => {
        const panelId = props.configuration.panelId;
        const panel = props.applet.panels.find(panel => panel.id === panelId);
        if (!panel) {
            return (
                <div>Could not load panel.</div>
            )
        }
        // const appletRef = {
        //     appletId: props.applet.id,
        //     modelId: props.modelId,
        //     version: props.version
        // }
        const view = (
            <AppletViewer
                key={panel.id}
                // appletRef={appletRef}
                applet={props.applet}
                panel={panel}
                calculator={props.calculator}
                configuration={props.appletConfiguration}
                isEmbedded={true}
                onChange={props.onUpdate}
            />
        )
        if (props.configuration.title) {
            return (
                <Card
                    className={classNames({ "x-panelwidgetviewer-shadow": props.configuration.shadow })}
                    size="small"
                    bordered={true}
                    title={props.configuration.title}
                >
                    {view}
                </Card>
            )
        } else {
            return (
                <div className={classNames("x-panelwidgetviewer", { "x-panelwidgetviewer-shadow": props.configuration.shadow })}>
                    {view}
                </div>
            )
        }
    }

    return buildAppletsView();

}
