import { ChangeEvent, KeyboardEvent, ReactElement, useRef } from 'react';
import { Input } from 'antd';
import { Alignment, Spacer } from 'components/Spacer/Spacer';
import './FilterInput.less';

export type Justification = "left" | "center" | "right";

export type TestFunction = (value: string) => boolean;
export type ChangeCallback = (value: string | undefined) => void;
export type FocusCallback = () => void;
export type BlurCallback = () => void;
export type KeyCallback = (e: KeyboardEvent<HTMLInputElement>) => void;

export type FilterInputProps = {
    className?: string,
    value?: any,
    defaultValue?: any,
    disabled?: boolean,
    alignment?: Alignment,
    justification?: Justification,
    placeholder?: string,
    prefix?: ReactElement | boolean | string,
    postfix?: ReactElement | boolean | string,
    tester: TestFunction,
    onKey?: KeyCallback,
    onBlur?: BlurCallback,
    onFocus?: FocusCallback,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    alignment: "middle",
    justification: "left",
    onKey: (e: KeyboardEvent<HTMLInputElement>) => { },
    onBlur: () => { },
    onFocus: () => { }
}

/**
 * An input field that filters its input to restrict
 * values to a filter test.
 */
export const FilterInput = (props: FilterInputProps): ReactElement => {

    const inputRef = useRef<Input>(null);

    const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        let value: any = e.target.value;
        if (value) {
            const pass = props.tester(value);
            if (!pass) {
                value = props.value;
                // Change the value in the native control since notifying
                // with the previous value may not trigger a render event.
                inputRef.current?.setValue(value);
            }
        }
        props.onChange(value);
    }

    const { prefix, postfix, alignment, justification, tester, onKey, onBlur, onFocus, onChange, ...rest } = props;

    return (
        <Spacer
            fill
            className={props.className}
            alignment={alignment}
        >
            {prefix}
            <Input
                {...rest}
                className={`x-filterinput-${justification}`}
                ref={inputRef}
                onBlur={() => props.onBlur()}
                onFocus={() => props.onFocus()}
                onKeyDown={(e) => props.onKey(e)}
                onChange={handleChange}
            />
            {postfix}
        </Spacer>
    );

}

FilterInput.defaultProps = defaultProps;
