import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { AppstoreOutlined, BankOutlined, BarChartOutlined, DeleteOutlined, ExclamationCircleOutlined, LockOutlined, LogoutOutlined, MailOutlined, MenuOutlined, SwapOutlined, TeamOutlined, UsergroupAddOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Menu, message, Modal } from 'antd';
import { Image } from 'components/Image/Image';
import { RouteBuilder } from 'utils/RouteBuilder';
import { RouteComponentProps } from 'react-router-dom';
import { EntityContext } from 'context/EntityContext';
import { Spacer } from 'components/Spacer/Spacer';
import { ApplicationContext } from 'context/ApplicationContext';
import { Board, CategoryType } from '@methodset/model-client-ts';
import { CoreUtils } from 'utils/CoreUtils';
import { RestUtils } from 'utils/RestUtils';
import authService from 'services/AuthService';
import entityService from 'services/EntityService';
import logo from 'images/methodset_blue_logo.png';
import update from 'immutability-helper';
import './NavBar.less';

export type ModalData = {
    confirm?: string,
    ref?: any
}

export type NavBarProps = RouteComponentProps;

export type NavBarState = {
    modalData: ModalData,
    showInvite: boolean,
    errorMessage: string | undefined
}

export const NavBar = (props: NavBarProps): ReactElement => {

    const entityContext = useContext(EntityContext);
    const applicationContext = useContext(ApplicationContext);

    const [modalData, setModalData] = useState<ModalData>({} as ModalData);

    useEffect(() => {
        if (!entityContext.user) {
            // If the page got refreshed, may need to reset the user.
            authService.readUser().then(user => {
                entityContext.saveUser(user);
                readGroupsRequest();
            }).catch(e => {
                entityContext.clearUser();
                //props.history.push(RouteBuilder.user(props, RouteBuilder.USER_LOGIN));
                props.history.push(RouteBuilder.USER_LOGIN);
            });
        } else {
            readGroupsRequest();
        }
    }, []);

    const handleHomeClick = (): void => {
        const isLoggedIn = entityContext.user ? true : false;
        if (isLoggedIn) {
            props.history.push(RouteBuilder.APPLICATION);
        } else {
            props.history.push(RouteBuilder.HOME);
        }
    }

    const handleDevelopmentClick = (): void => {
        props.history.push(RouteBuilder.CONSOLE);
    }

    const handleStoreClick = (): void => {
        props.history.push(RouteBuilder.APPLICATION_STORE);
    }

    const handleAccountsClick = (): void => {
        props.history.push(RouteBuilder.APPLICATION_ACCOUNTS);
    }

    const handleFamilyClick = (): void => {
        props.history.push(RouteBuilder.APPLICATION_FAMILY);
    }

    const handleAppletsClick = (): void => {
        props.history.push(RouteBuilder.APPLICATION_BOARDS);
    }

    const handleSwitchClick = (): void => {
        props.history.push(RouteBuilder.APPLICATION_SWITCH_GROUP);
        //props.history.push(RouteBuilder.user(props, RouteBuilder.USER_SWITCH_GROUP));
    }

    const handleProfileClick = (): void => {
        props.history.push(RouteBuilder.APPLICATION_EDIT_PROFILE);
        //props.history.push(RouteBuilder.user(props, RouteBuilder.USER_EDIT_PROFILE));
    }

    const handlePasswordClick = (): void => {
        props.history.push(RouteBuilder.APPLICATION_CHANGE_PASSWORD);
        //props.history.push(RouteBuilder.user(props, RouteBuilder.USER_CHANGE_PASSWORD));
    }

    const handleEmailClick = (): void => {
        props.history.push(RouteBuilder.APPLICATION_CHANGE_EMAIL);
        //props.history.push(RouteBuilder.user(props, RouteBuilder.USER_CHANGE_EMAIL));
    }

    const handleDeleteClick = (): void => {
        const config = {
            title: "Delete Your Account",
            okText: "Delete Account",
            okButtonProps: {
                disabled: true
            },
            cancelText: "Cancel",
            closable: true,
            icon: (
                <ExclamationCircleOutlined className="x-navbar-confirm-icon" />
            ),
            content: (
                <div>
                    <div>
                        Are you sure you want to delete
                        your account? You will lose access
                        to all your data.
                    </div>
                    <div className="x-navbar-confirm-info">
                        Please enter the word "confirm" to
                        acknowledge that you want to delete
                        your account.
                    </div>
                    <Input
                        className="x-navbar-confirm-input"
                        value={modalData.confirm}
                        onChange={handleConfirmChange}
                    />
                </div>
            ),
            onOk: handleOkClick,
            onCancel: handleCancelClick
        };
        const ref = Modal.confirm(config);
        const data = update(modalData, {
            ref: { $set: ref }
        });
        setModalData(data);
    }

    const handleLogoutClick = (): void => {
        authService.logoutUser(
            () => handleLogout(),
            () => handleLogout()
        );
    }

    const handleLogout = (): void => {
        authService.clearUser();
        entityContext.clearUser();
        //props.history.push(RouteBuilder.user(props, RouteBuilder.USER_LOGIN));
        props.history.push(RouteBuilder.USER_LOGIN);
    }

    const handleOkClick = (): Promise<any> => {
        if (modalData.confirm !== 'confirm') {
            return Promise.reject();
        }
        const request = {};
        return entityService.deleteUser(request,
            (response: any) => deleteUserResponse(response),
            (response: any) => deleteUserException(response),
            true
        );
    }

    const handleCancelClick = (): void => {
        setModalData({} as ModalData);
    }

    const handleConfirmChange = (e: any): void => {
        const confirm = e.target.value;
        const disabled = confirm !== 'confirm';
        modalData.ref.update({
            okButtonProps: {
                disabled: disabled
            }
        });
        const data = update(modalData, {
            confirm: { $set: confirm }
        });
        setModalData(data);
    }

    const handleBoardClick = (board: Board): void => {
        props.history.push(RouteBuilder.applicationBoard(board.id));
    }

    const readGroupsRequest = (): Promise<any> => {
        const request = {};
        return entityService.readGroups(request,
            (response: any) => readGroupsResponse(response),
            (error: any) => readGroupsException(error),
            true
        );
    }

    const readGroupsResponse = (response: any): void => {
        const groups = response.data.groups;
        //entityContext.saveGroups(groups);
    }

    const readGroupsException = (error: any): void => {
        message.error("Failed to load groups.");
    }

    const deleteUserResponse = (response: any): void => {
        message.success("Your account has been deleted.");
        setModalData({} as ModalData);
        authService.clearUser();
        entityContext.clearUser();
        props.history.push(RouteBuilder.HOME);
    }

    const deleteUserException = (response: any): void => {
        Modal.error({
            title: 'Delete Error',
            content: RestUtils.getErrorMessage(response),
        });
    }

    const buildSubMenu = (key: CategoryType, boards: Board[]): ReactElement[] => {
        return boards.map((board, index) => (
            <Menu.Item key={`${key}-${index}`} onClick={() => handleBoardClick(board)}>
                {board.name}
            </Menu.Item>
        ));
    }

    const buildAppletMenu = (): ReactElement[] => {
        const boards = applicationContext.boards!;
        const map = new Map<CategoryType, Board[]>();
        // Group the applets by category.
        for (const board of boards) {
            let list = map.get(board.category!);
            if (!list) {
                list = [];
                map.set(board.category!, list);
            }
            list.push(board);
        }
        const menu: ReactElement[] = [];
        // Iterate over each category.
        map.forEach((list, key) => {
            menu.push(
                <Menu.ItemGroup key={key} title={CoreUtils.toProper(key)}>
                    {buildSubMenu(key, list)}
                </Menu.ItemGroup>
            );
        });
        return menu;
    }

    const buildMainMenu = (): ReactElement => {
        const appletMenu = buildAppletMenu();
        return (
            <Menu>
                <Menu.Item
                    key="profile"
                    icon={<UserOutlined />}
                    onClick={handleProfileClick}
                >
                    Profile
                </Menu.Item>
                <Menu.Item
                    key="accounts"
                    icon={<BankOutlined />}
                    onClick={handleAccountsClick}
                >
                    Accounts
                </Menu.Item>
                <Menu.Item
                    key="family"
                    icon={<TeamOutlined />}
                    onClick={handleFamilyClick}
                >
                    Family
                </Menu.Item>
                <Menu.Item
                    key="applets"
                    icon={<BarChartOutlined />}
                    onClick={handleAppletsClick}
                >
                    Applets
                </Menu.Item>
                <Menu.Divider />
                {appletMenu}
            </Menu>
        )
    }

    const buildUserMenu = (): ReactElement => {
        //const environment = entityContext.environment;
        return (
            <Menu>
                {entityContext.user &&
                    <>
                        <div className="x-navbar-section x-navbar-label">
                            Welcome {entityContext.user.firstName} {entityContext.user.lastName}
                        </div>
                        <Menu.Divider />
                    </>
                }
                {/* {environment === TenantType.DEVELOPMENT &&
                    <Menu.Item
                        key="view"
                        icon={<SwapOutlined />}
                        onClick={handleDevelopmentClick}
                    >
                        Development View
                    </Menu.Item>
                } */}
                <Menu.Item
                    key="switch"
                    icon={<UserSwitchOutlined />}
                    onClick={handleSwitchClick}
                >
                    Switch Accounts
                </Menu.Item>
                <Menu.Item
                    key="password"
                    icon={<LockOutlined />}
                    onClick={handlePasswordClick}
                >
                    Change Password
                </Menu.Item>
                <Menu.Item
                    key="email"
                    icon={<MailOutlined />}
                    onClick={handleEmailClick}
                >
                    Change Email
                </Menu.Item>
                <Menu.Item
                    key="delete"
                    icon={<DeleteOutlined />}
                    onClick={handleDeleteClick}
                >
                    Delete Account
                </Menu.Item>
                {user &&
                    <React.Fragment>
                        <Menu.Divider />
                        <Menu.Item
                            key="logout"
                            icon={<LogoutOutlined />}
                            onClick={handleLogoutClick}
                        >
                            Logout
                        </Menu.Item>
                    </React.Fragment>
                }
            </Menu>
        )
    }

    const user = entityContext.user;
    const mainMenu = buildMainMenu();
    const userMenu = buildUserMenu();

    return (
        <Spacer justification="between">
            <div className="x-navbar-main">
                <Dropdown overlay={mainMenu} trigger={['click']}>
                    <Button shape="circle" size="large">
                        <MenuOutlined />
                    </Button>
                </Dropdown>
            </div>
            {user &&
                <Spacer size="none">
                    {/* <Image src={gears} className="x-navbar-gears" alt="gears" /> */}
                    <Image src={logo} className="x-navbar-logo" alt="logo" onClick={() => handleHomeClick()} />
                </Spacer>
            }
            {user &&
                <Spacer>
                    <Dropdown overlay={userMenu} trigger={['click']}>
                        <Button shape="circle" size="large">
                            <UserOutlined />
                        </Button>
                    </Dropdown>
                    <Button shape="circle" size="large" onClick={() => handleStoreClick()}>
                        <AppstoreOutlined />
                    </Button>
                </Spacer>
            }
        </Spacer>
    );

}
