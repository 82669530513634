import { ChangeEvent, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { ModelContext } from 'context/ModelContext';
import { Alert, Applet, AppletPanel, Application, ApplicationType, CategoryType, Condition, Interval, LogicalType, UnitType } from '@methodset/model-client-ts';
import { RouteComponentProps } from 'react-router-dom';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Button, Col, Divider, Form, FormInstance, Input, Row, Select } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { FormItem } from 'components/FormItem/FormItem';
import { VariablesSelector } from './AlertEditor/VariablesSelector/VariablesSelector';
import { IntervalEditor } from './AlertEditor/IntervalEditor/IntervalEditor';
import { FormValue } from 'components/FormValue/FormValue';
import { ConditionEditor } from './AlertTester/AlertSetup/ConditionEditor/ConditionEditor';
import { PanelEditor } from './AlertEditor/PanelEditor/PanelEditor';
import { ItemSpec, MenuButton } from 'components/MenuButton/MenuButton';
import { ScheduleEditor } from 'containers/Console/Workflows/WorkflowItem/WorkflowEditor/TriggerEditor/ScheduleEditor/ScheduleEditor';
import { DownOutlined } from '@ant-design/icons';
import { AlertTester } from './AlertTester/AlertTester';
import { CoreUtils } from 'utils/CoreUtils';
import { Cron } from '@methodset/schedule-client-ts';
import { Globals } from 'constants/Globals';
import { v4 as uuid } from "uuid";
import update from 'immutability-helper';
import './ModelAlert.less';

type MatchParams = {
    modelId: string,
    applicationId: string
}

type EditMode = "none" | "test" | "applet";

const NullAlert = {} as Alert;

export type ModelAlertProps = RouteComponentProps<MatchParams> & {
}

export const ModelAlert = (props: ModelAlertProps): ReactElement => {

    // The model context.
    const context = useContext(ModelContext);
    // The form reference.
    const formRef = useRef<FormInstance>(null);
    // The alert being edited.
    const [alert, setAlert] = useState<Alert | undefined>();
    // The applet index.
    const [index, setIndex] = useState<number>(-1);
    // Edit dialog.
    const [editMode, setEditMode] = useState<EditMode>("none");

    useEffect(() => {
        const applicationId = props.match.params.applicationId;
        const applications: Application[] = context.model!.applications;
        const index = applications.findIndex(application => application.id === applicationId && application.type === ApplicationType.ALERT);
        let alert: Alert;
        if (context.application && context.application.id === applicationId) {
            alert = context.application as Alert;
        } else if (index > 0) {
            alert = applications[index] as Alert;
        } else {
            alert = {
                type: ApplicationType.ALERT,
                id: applicationId,
                name: "New Alert",
                inputIds: [],
                conditions: [{
                    variableId: undefined as any,
                    opType: undefined as any,
                    value: undefined as any
                }],
                schedule: {
                    //cron: {} as Cron
                    cron: undefined as any
                },
                interval: {
                    duration: 1,
                    unitType: UnitType.DAY
                },
                testInputs: {
                    configuration: {},
                    conditions: []
                },
                panel: {
                    id: uuid(),
                    name: "Alert Panel",
                    span: Globals.LAYOUT_COLUMNS,
                    widgets: []
                }
            }
        }
        setAlert(alert);
        setIndex(index);
    }, []);

    const handleAlertCancel = (): void => {
        const modelId = props.match.params.modelId;
        context.saveApplication(undefined);
        props.history.push(RouteBuilder.applications(modelId));
    }

    const handleAlertSave = (): void => {
        formRef.current?.submit();
    }

    const handleFormFinish = (): void => {
        let model;
        if (index === -1) {
            model = update(context.model!, {
                applications: {
                    $push: [alert!]
                }
            });
        } else {
            model = update(context.model!, {
                applications: {
                    [index]: { $set: alert! }
                }
            });
        }
        context.saveModel(model);
        context.saveApplication(undefined);
        props.history.push(RouteBuilder.applications(model.id));
    }

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const name = e.target.value;
        const updated = update(alert, {
            name: { $set: name }
        });
        setAlert(updated);
        context.saveApplication(updated);
    }

    const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        const description = e.target.value;
        const updated = update(alert, {
            description: { $set: description }
        });
        setAlert(updated);
        context.saveApplication(updated);
    }

    const handleCategoryChange = (categoryType: CategoryType): void => {
        const updated = update(alert, {
            categoryType: { $set: categoryType }
        });
        setAlert(updated);
        context.saveApplication(updated);
    }

    const handleCronChange = (cron: Cron): void => {
        const updated = update(alert, {
            schedule: {
                cron: { $set: cron }
            }
        });
        setAlert(updated);
        context.saveApplication(updated);
    }

    const handleIntervalChange = (interval: Interval): void => {
        const updated = update(alert, {
            interval: { $set: interval }
        });
        setAlert(updated);
        context.saveApplication(updated);
    }

    const handleInputsChange = (inputIds: string[]): void => {
        const updated = update(alert, {
            inputIds: { $set: inputIds },
            testInputs: {
                configuration: { $set: {} }
            }
        });
        setAlert(updated);
        context.saveApplication(updated);
    }

    const handleConditionChange = (condition: Condition, index: number): void => {
        const updated = update(alert, {
            conditions: {
                [index]: { $set: condition }
            },
            testInputs: {
                conditions: { $set: [] }
            }
        });
        setAlert(updated);
        context.saveApplication(updated);
    }

    const handleConditionRemove = (index: number): void => {
        const updated = update(alert, {
            conditions: {
                $splice: [[index, 1]]
            },
            testInputs: {
                conditions: { $set: [] }
            }
        });
        setAlert(updated);
        context.saveApplication(updated);
    }

    const handleConditionAdd = (logicalType: LogicalType): void => {
        const condition: Condition = {
            variableId: undefined as any,
            opType: undefined as any,
            value: undefined as any,
            logicalType: logicalType
        }
        const updated = update(alert, {
            conditions: {
                $push: [condition]
            }
        });
        setAlert(updated);
        context.saveApplication(updated);
    }

    const handleEditChange = (isEdit: boolean): void => {
        const modelId = props.match.params.modelId;
        const applicationId = props.match.params.applicationId;
        const path = `${applicationId}?edit=${isEdit ? "true" : "false"}`;
        props.history.push(RouteBuilder.alert(modelId, path));
    }

    const handlePanelUpdate = (panel: AppletPanel): void => {
        const updated = update(alert, {
            panel: { $set: panel }
        });
        setAlert(updated);
        context.saveApplication(updated);
    }

    const handleAlertTest = (): void => {
        setEditMode("test");
    }

    const handleTestClose = (alert: Alert): void => {
        setEditMode("none");
        setAlert(alert);
    }

    const items: ItemSpec[] = [{
        label: LogicalType.name(LogicalType.AND),
        onSelect: () => handleConditionAdd(LogicalType.AND)
    }, {
        label: LogicalType.name(LogicalType.OR),
        onSelect: () => handleConditionAdd(LogicalType.OR)
    }];

    const actions = (
        <Spacer className="x-modelalert-actions" justification="right" fill>
            <Button onClick={handleAlertCancel}>Cancel</Button>
            <Button type="primary" onClick={handleAlertSave}>Done</Button>
        </Spacer>
    );

    const buildLoadView = (): ReactElement => {
        return <></>;
    }

    const buildAlertView = (alert: Alert): ReactElement => {
        return (
            <Spacer size="md" alignment="top">
                <Form
                    className="x-modelalert-form"
                    ref={formRef}
                    onFinish={handleFormFinish}
                >
                    <Row className="x-modelalert-col" gutter={[Globals.FORM_GUTTER_COL, 0]}>
                        <Col span={12}>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={formRef}
                                label="Name"
                                name="name"
                                info="The name of the alert."
                                rules={[{
                                    required: true,
                                    message: 'Please enter a name.'
                                }]}
                            >
                                <Input
                                    placeholder="Enter a name."
                                    value={alert.name}
                                    onChange={handleNameChange}
                                />
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={formRef}
                                label="Description"
                                name="description"
                                info="The description of the alert."
                                rules={[{
                                    required: true,
                                    message: 'Please enter a description.'
                                }]}
                            >
                                <Input.TextArea
                                    placeholder="Enter a description."
                                    rows={3}
                                    value={alert.description}
                                    onChange={handleDescriptionChange}
                                />
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={formRef}
                                label="Category"
                                name="category"
                                info="The category that describes the applet's purpose."
                                rules={[{
                                    required: true,
                                    message: 'Please select a category.'
                                }]}
                            >
                                <Select
                                    allowClear={true}
                                    value={alert.categoryType}
                                    onChange={handleCategoryChange}
                                >
                                    {CoreUtils.enumToKeys(CategoryType).map(key => (
                                        <Select.Option value={key}>{CoreUtils.toProper(key, "_")}</Select.Option>
                                    ))}
                                </Select>
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={formRef}
                                label="Input Variables"
                                name="inputs"
                                info="The input variables to the model that can be changed."
                                valuePropName="variableIds"
                            >
                                <VariablesSelector
                                    label="Input Variable"
                                    calculator={context.calculator!}
                                    variableIds={alert.inputIds}
                                    onChange={handleInputsChange}
                                />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={formRef}
                                label="Minimum Alerting Interval"
                                name="interval"
                                info="The minimum interval between alerts. This limits the number of alerts that are sent if the conditions trigger the alert and the alert is scheduled to check frequently."
                                valuePropName="interval"
                                rules={[{
                                    required: true,
                                    message: 'Please fill in the minimum interval.'
                                }]}
                            >
                                <IntervalEditor
                                    interval={alert?.interval}
                                    onChange={handleIntervalChange}
                                />
                            </FormItem>
                            <ScheduleEditor
                                formRef={formRef}
                                label="Conditions Check Frequency"
                                cron={alert.schedule.cron}
                                excludes={["time_zone", "start_time", "end_time"]}
                                onChange={handleCronChange}
                            />
                        </Col>
                        <Col span={24}>
                            {alert.conditions &&
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={formRef}
                                    label="Trigger Conditions"
                                    name="conditions"
                                    info="The conditions that will decide if the alert should be triggered. Leave fields open that the user should customize and fill in."
                                    required={true}
                                    // rules={[{
                                    //     validator: () => {
                                    //         return Promise.resolve();
                                    //     }
                                    // }]}
                                >
                                    <FormValue value={alert.conditions}>
                                        <Spacer direction="vertical" fill>
                                            {alert.conditions.map((condition: Condition, index: number) => (
                                                <ConditionEditor
                                                    key={index}
                                                    index={index}
                                                    condition={condition}
                                                    calculator={context.calculator!}
                                                    onChange={handleConditionChange}
                                                    onRemove={handleConditionRemove}
                                                />
                                            ))}
                                        </Spacer>
                                    </FormValue>
                                </FormItem>
                            }
                            <Spacer className="x-modelalert-add">
                                <MenuButton
                                    label="Add Condition"
                                    items={items}
                                    icon={<DownOutlined />}
                                />
                                <Button onClick={handleAlertTest}>
                                    Test Alert
                                </Button>
                            </Spacer>
                            {actions}
                        </Col>
                    </Row>
                </Form>
                <Divider className="x-modelalert-div" type="vertical" />
                {alert.panel &&
                    <PanelEditor
                        {...props}
                        className="x-modelalert-col"
                        key={alert.id}
                        model={context.model!}
                        applet={alert.panel as Applet}
                        index={0}
                        panel={alert.panel}
                        calculator={context.calculator!}
                        onEdit={handleEditChange}
                        onUpdate={handlePanelUpdate}
                    />
                }
            </Spacer>
        )
    }

    return (
        <div id="modelalert" className="x-modelalert">
            {!alert && buildLoadView()}
            {alert && buildAlertView(alert)}
            {alert && editMode === "test" &&
                <AlertTester
                    alert={alert}
                    calculator={context.calculator!}
                    onClose={handleTestClose}
                />
            }
        </div>
    )

}
