import { ReferenceParser } from "@methodset/calculator-ts";
import { ChangeListWidgetConfiguration, Widget } from '@methodset/model-client-ts';

export class ChangeListWidgetSync {

    public static parser = (widget: Widget): string[] => {
        const configuration = widget.configuration as ChangeListWidgetConfiguration;
        const refs: string[] = [];
        refs.push(...ReferenceParser.extract(configuration.hideId, true));
        refs.push(...ReferenceParser.extract(configuration.labelRangeId, true));
        refs.push(...ReferenceParser.extract(configuration.changeRangeId, true));
        return refs;
    }

    public static updater = (widget: Widget, target: string, replacement: string): void => {
        const configuration = widget.configuration as ChangeListWidgetConfiguration;
        configuration.hideId = ReferenceParser.update(configuration.hideId, target, replacement, true);
        configuration.labelRangeId = ReferenceParser.update(configuration.labelRangeId, target, replacement, true);
        configuration.changeRangeId = ReferenceParser.update(configuration.changeRangeId, target, replacement, true);
    }

}
