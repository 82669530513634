import React, { Component, ReactElement } from 'react';
import { Checkbox, FormInstance } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Calculator } from '@methodset/calculator-ts';
import { Info } from 'components/Info/Info';
import { BarChartWidgetConfiguration } from '@methodset/model-client-ts';
import update from 'immutability-helper';
import './BarChartEditor.less';

export type ChangeCallback = (configuration: BarChartWidgetConfiguration) => void;

export type BarChartEditorProps = {
    formRef: React.RefObject<FormInstance>,
    configuration: BarChartWidgetConfiguration,
    calculator: Calculator,
    onChange: ChangeCallback
}

export class BarChartEditor extends Component<BarChartEditorProps> {

    constructor(props: BarChartEditorProps) {
        super(props);
        this.handleStackedChange = this.handleStackedChange.bind(this);
    }

    private handleStackedChange(e: CheckboxChangeEvent): void {
        const isStacked = e.target.checked;
        const configuration = update(this.props.configuration, {
            isStacked: { $set: isStacked }
        });
        this.props.onChange(configuration);
    }

    public render(): ReactElement {
        return (
            <Checkbox
                checked={this.props.configuration.isStacked}
                onChange={this.handleStackedChange}
            >
                <Info
                    label="Stack Datasets"
                    info="True to stack datasets and accumulate values."
                    bold={true}
                />
            </Checkbox>
        );
    }

}
