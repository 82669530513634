import { ReactElement } from 'react';
import { ModelVersionPicker } from 'containers/Console/ConfigurationEditor/RangeSelector/ModelVersionPicker/ModelVersionPicker';
import { QueryVersionPicker } from 'containers/Console/ConfigurationEditor/QuerySelector/QueryVersionPicker/QueryVersionPicker';
import { WorkflowVersionPicker } from 'containers/Console/Workflows/WorkflowVersionPicker/WorkflowVersionPicker';
import { PackType } from '@methodset/library-client-ts';
import './VersionPicker.less';

//export type ItemType = "applet" | "alert" | "model" | "query" | "workflow";

export type FilterFunction = (version: number, count: number) => boolean;
export type ChangeCallback = (version: number) => void;

export type VersionPickerProps = {
    id: string,
    type: PackType,
    version?: number,
    filter?: FilterFunction,
    onChange: ChangeCallback
};

export const VersionPicker = (props: VersionPickerProps): ReactElement => {

    // TODO: add applet version picker
    return (
        <>
            {props.type === PackType.MODEL &&
                <ModelVersionPicker
                    modelId={props.id}
                    version={props.version}
                    filter={props.filter}
                    onChange={(version) => props.onChange(version)}
                />
            }
            {props.type === PackType.QUERY &&
                <QueryVersionPicker
                    queryId={props.id}
                    version={props.version}
                    filter={props.filter}
                    onChange={(version) => props.onChange(version)}
                />
            }
            {props.type === PackType.WORKFLOW &&
                <WorkflowVersionPicker
                    workflowId={props.id}
                    version={props.version}
                    filter={props.filter}
                    onChange={(version) => props.onChange(version)}
                />
            }
        </>
    );
}
