import { ReactElement, Ref, useContext, useRef, useState } from 'react';
import { Button, Form, FormInstance, Modal, Result } from 'antd';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { PackHeader, PackType } from '@methodset/library-client-ts';
import { CoreUtils } from 'utils/CoreUtils';
import { AccessType, EnvironmentType } from '@methodset/commons-shared-ts';
import { PackInfo } from 'containers/Console/Dashboards/DashboardItem/AppletPacks/PackInfo/PackInfo';
import { PackReleaser } from './PackReleaser/PackReleaser';
import { PackPublisher } from './PackPublisher/PackPublisher';
import libraryService from 'services/LibraryService';
import './PackDeployer.less';
import { EntityContext } from 'context/EntityContext';

export type DeployType = "environment" | "access";

export type DeployCallback = (header: PackHeader) => void;
export type CancelCallback = () => void;

export type PackDeployerProps = {
    deployType: DeployType,
    packHeader: PackHeader,
    onRelease: DeployCallback,
    onCancel: CancelCallback
}

export const PackDeployer = (props: PackDeployerProps): ReactElement => {

    // True if in process of releasing pack.
    const [isDeploying, setIsDeploying] = useState<boolean>(false);
    // Pack header after successful release.
    const [packHeader, setPackHeader] = useState<PackHeader | undefined>();
    // User selected environment type.
    const [environmentType, setEnvironmentType] = useState<EnvironmentType | undefined>();
    // User selected access type.
    const [accessType, setAccessType] = useState<AccessType | undefined>();
    // Error when releasing.
    const [error, setError] = useState<Error | undefined>();

    const formRef: Ref<FormInstance> = useRef(null);
    const context = useContext(EntityContext);

    const handleRelease = (): void => {
        formRef.current!.validateFields().then(values => {
            deployPack();
        });
    }

    const handleCancel = (): void => {
        props.onCancel();
    }

    const handleEnvironmentChange = (environmentType: EnvironmentType): void => {
        setEnvironmentType(environmentType);
        setError(undefined);
    }

    const handleAccessChange = (accessType: AccessType): void => {
        setAccessType(accessType);
        setError(undefined);
    }

    const deployPack = (): Promise<any> => {
        if (props.deployType === "environment") {
            return releasePackRequest();
        } else {
            return publishPackRequest();
        }
    }

    const releasePackRequest = (): Promise<any> => {
        setIsDeploying(true);
        setError(undefined);
        const request = {
            packId: props.packHeader.id,
            environmentType: environmentType
        }
        return libraryService.releasePack(request,
            (response: any) => deployPackResponse(response),
            (response: any) => deployPackException(response),
            true
        );
    }

    const publishPackRequest = (): Promise<any> => {
        setIsDeploying(true);
        setError(undefined);
        const request = {
            packId: props.packHeader.id,
            accessType: accessType
        }
        return libraryService.publishPack(request,
            (response: any) => deployPackResponse(response),
            (response: any) => deployPackException(response),
            true
        );
    }

    const deployPackResponse = (response: any): void => {
        const header = response.data.header;
        setPackHeader(header);
        setIsDeploying(false);
    }

    const deployPackException = (response: any): void => {
        const error = RestUtils.getError(response);
        setIsDeploying(false);
        setError(error);
    }

    const buildSuccessView = (): ReactElement => {
        let message;
        if (props.deployType === "environment") {
            message = `Version ${props.packHeader.version} of '${props.packHeader.name}' has been released to the ${CoreUtils.toLower(environmentType)} ${environmentType !== EnvironmentType.PUBLIC ? "environment" : ""}.`
        } else {
            message = `Version ${props.packHeader.version} of '${props.packHeader.name}' has been released to the ${CoreUtils.toLower(accessType)}.`
        }
        return (
            <Result
                status="success"
                title="Successfully released package!"
                subTitle={message}
            />
        )
    }

    const buildReleaseView = (): ReactElement => {
        return (
            <Form ref={formRef}>
                <PackInfo
                    className="x-packdeployer-info"
                    packHeader={props.packHeader}
                />
                <PackReleaser
                    formRef={formRef}
                    packHeader={props.packHeader}
                    onChange={handleEnvironmentChange}
                />
            </Form>
        )
    }

    const buildPublishView = (): ReactElement => {
        return (
            <Form ref={formRef}>
                <PackInfo
                    className="x-packdeployer-info"
                    packHeader={props.packHeader}
                />
                <PackPublisher
                    formRef={formRef}
                    packHeader={props.packHeader}
                    onChange={handleAccessChange}
                />
            </Form>
        )
    }

    const view = !packHeader ? (props.deployType === "environment" ? buildReleaseView() : buildPublishView()) : buildSuccessView();
    return (
        <Modal
            className="x-packdeployer"
            centered
            width={Globals.DIALOG_WIDTH_STANDARD}
            title={`${props.deployType === "environment" ? "Release" : "Publish"} ${CoreUtils.toProper(props.packHeader.packType)}`}
            onCancel={handleCancel}
            visible={true}
            footer={(
                <>
                    {!packHeader &&
                        <>
                            <Button disabled={isDeploying} onClick={handleCancel}>Cancel</Button>
                            <Button type="primary" loading={isDeploying} onClick={handleRelease}>Release</Button>
                        </>
                    }
                    {!!packHeader &&
                        <Button type="primary" onClick={() => props.onRelease(packHeader)}>Close</Button>
                    }
                </>
            )}
        >
            {view}
            {!!error &&
                <div className="x-packdeployer-error">{error.message}</div>
            }
        </Modal>
    );

}
