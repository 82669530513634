import { ReactElement } from 'react';
import { AlertState } from '@methodset/dashboard-client-ts';
import './AlertItem.less';

export type AlertItemProps = {
    alertState: AlertState
}

export const AlertItem = (props: AlertItemProps): ReactElement => {

    return (
        <div className="x-alertitem">
            <div>{props.alertState.packInfo.name}</div>
        </div>
    );

}
