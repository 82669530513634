import React, { Component as ReactComponent, ReactElement } from 'react';
import { FormInstance, Radio, RadioChangeEvent, Space } from 'antd';
import { Globals } from 'constants/Globals';
import { FileEditor } from './FileEditor/FileEditor';
import { FormItem } from 'components/FormItem/FormItem';
import { ScheduleEditor } from './ScheduleEditor/ScheduleEditor';
import { Component, Flow } from '@methodset/endpoint-client-ts';
import { EmailTrigger, FileTrigger, ScheduleTrigger, Trigger, TriggerType } from '@methodset/workflow-client-ts';
import { EmailEditor } from './EmailEditor/EmailEditor';
import { Cron } from '@methodset/schedule-client-ts';
import update from 'immutability-helper';
import './TriggerEditor.less';

export type ChangeCallback = (trigger: Trigger) => void;

export type TriggerEditorProps = {
    formRef: React.RefObject<FormInstance>,
    className?: string,
    trigger?: Trigger,
    components: Component[],
    transitions: Flow[],
    onChange: ChangeCallback,
}

export type TriggerEditorState = {
}

export class TriggerEditor extends ReactComponent<TriggerEditorProps, TriggerEditorState> {

    constructor(props: TriggerEditorProps) {
        super(props);
        this.state = {
        }
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleCronChange = this.handleCronChange.bind(this);
    }

    private handleTypeChange(e: RadioChangeEvent): void {
        const triggerType = e.target.value;
        let trigger;
        switch (triggerType) {
            case TriggerType.EMAIL:
                trigger = {
                    type: TriggerType.EMAIL,
                    whitelist: [] as string[],
                } as EmailTrigger;
                break;
            case TriggerType.FILE:
                trigger = {
                    type: TriggerType.FILE
                } as FileTrigger;
                break;
            case TriggerType.SCHEDULE:
                trigger = {
                    type: TriggerType.SCHEDULE
                } as ScheduleTrigger;
                break;
            default:
                return;
        }
        this.props.onChange(trigger);
    }

    // private handleTriggerChange(trigger: Trigger): void {
    //     // TODO: hardcoded to SCHEDULE trigger only - remove when other options supported
    //     trigger.type = TriggerType.SCHEDULE;
    //     this.props.onChange(trigger);
    // }

    private handleCronChange(cron: Cron): void {
        const trigger = update(this.props.trigger as ScheduleTrigger, {
            type: { $set: TriggerType.SCHEDULE },
            cron: { $set: cron }
        });
        this.props.onChange(trigger);
    }

    public render(): ReactElement {
        // TODO: support other trigger types
        return (
            <div>
                <ScheduleEditor
                    formRef={this.props.formRef}
                    cron={(this.props.trigger as ScheduleTrigger).cron}
                    excludes={["time_zone"]}
                    onChange={this.handleCronChange}
                />
                {/* <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Trigger"
                    name="trigger"
                    info="Select how the workflow will start."
                    rules={[{
                        required: true,
                        message: 'Please enter a trigger type.'
                    }]}
                >
                    <Radio.Group
                        className="x-triggereditor-types"
                        value={this.props.trigger?.type}
                        onChange={this.handleTypeChange}
                    >
                        <Space direction="vertical">
                            <Radio value={TriggerType.SCHEDULE}>
                                Schedule
                            </Radio>
                            <Radio value={TriggerType.EMAIL}>
                                Email
                            </Radio>
                            <Radio value={TriggerType.FILE}>
                                File Upload
                            </Radio>
                        </Space>
                    </Radio.Group>
                </FormItem>
                {this.props.trigger?.type === TriggerType.SCHEDULE &&
                    <ScheduleEditor
                        formRef={this.props.formRef}
                        trigger={this.props.trigger as ScheduleTrigger}
                        onChange={this.handleTriggerChange}
                    />
                }
                {this.props.trigger?.type === TriggerType.EMAIL &&
                    <EmailEditor
                        formRef={this.props.formRef}
                        trigger={this.props.trigger as EmailTrigger}
                        onChange={this.handleTriggerChange}
                    />
                }
                {this.props.trigger?.type === TriggerType.FILE &&
                    <FileEditor
                        formRef={this.props.formRef}
                        trigger={this.props.trigger as FileTrigger}
                        onChange={this.handleTriggerChange}
                    />
                } */}
            </div>
        );
    }

}
