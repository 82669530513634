import { ReactElement, useEffect } from 'react';
import { Widget, Applet, WidgetType, AppletPanel } from '@methodset/model-client-ts';
import { WidgetViewer } from 'containers/Components/Widgets/WidgetViewer/WidgetViewer';
import { Calculator } from '@methodset/calculator-ts';
import { WidgetUtils } from 'utils/WidgetUtils';
import { ViewItem } from '../../../ModelApplet/AppletEditor/ItemLayout/ItemLayout';
import './PanelViewer.less';

export type PanelViewerProps = {
    calculator: Calculator,
    panel: AppletPanel,
}

export const PanelViewer = (props: PanelViewerProps): ReactElement => {

    const buildItems = (): ViewItem[] => {
        const widgets = findRootWidgets();
        const viewItems: ViewItem[] = [];
        widgets.forEach(widget => {
            // Check if the hide condition is satisfied.
            if (WidgetUtils.isHidden(props.calculator, widget)) {
                return;
            }
            const viewItem = {
                row: widget.row,
                col: widget.col,
                span: widget.span,
                element: (
                    <WidgetViewer
                        key={widget.id}
                        applet={props.panel as Applet}
                        panel={props.panel}
                        widget={widget}
                        calculator={props.calculator}
                        configuration={{}}
                        showHeader={false}
                        onUpdate={() => { }}
                    />
                )
            }
            viewItems.push(viewItem);
        });
        return viewItems;
    }

    const findRootWidgets = (): Widget[] => {
        const childIds = new Set<string>();
        const widgets = props.panel.widgets;
        for (const widget of widgets) {
            const type = widget.configuration.type;
            // Find child widgets of row and columns.
            if (type === WidgetType.ROW || type === WidgetType.COLUMN) {
                const configuration = widget.configuration as any;
                const widgetIds = configuration.widgetIds;
                for (const widgetId of widgetIds) {
                    childIds.add(widgetId);
                }
            }
        }
        const rootWidgets = [];
        for (const widget of widgets) {
            if (!childIds.has(widget.id)) {
                rootWidgets.push(widget);
            }
        }
        return rootWidgets;
    }

    const items = buildItems();

    return (
        <div className="x-panelviewer">
            {items.map(item => item.element)}
        </div>
    )

}
