import { ReactElement, Ref, useRef, useState } from 'react';
import { FormInstance } from 'antd';
import { Person, PersonType } from '@methodset/entity-client-ts';
import { ListEditor } from '../ListEditor/ListEditor';
import { PersonEditor } from '../PersonEditor/PersonEditor';
import './ChildrenEditor.less';

export type ChangeCallback = (children: Person[]) => void;

export type ChildrenEditorProps = {
    id: string,
    children?: Person[],
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    children: [] as Person[]
}

export const ChildrenEditor = (props: ChildrenEditorProps): ReactElement => {

    const [editChild, setEditChild] = useState<Person | undefined>();

    const formRef: Ref<FormInstance> = useRef(null);

    const handleChildChange = (child: Person): void => {
        setEditChild(child);
    }

    const handleChildEdit = (child: Person): void => {
        setEditChild(child);
    }

    const handleChildrenSave = (children: Person[]): void => {
        setEditChild(undefined);
        children.sort((a, b) => a.dateOfBirth < b.dateOfBirth ? 1 : -1);
        props.onChange(children);
    }

    const renderItem = (child: Person): ReactElement => {
        return (
            <div className="x-childreneditor-item">
                <div>{child.firstName} {child.lastName}</div>
                <div>{child.dateOfBirth}</div>
            </div>
        )
    }

    return (
        <ListEditor
            ref={formRef}
            id={props.id}
            name="Child"
            items={props.children}
            editItem={editChild}
            renderItem={renderItem}
            onEdit={(address) => handleChildEdit(address)}
            onSave={(addresses) => handleChildrenSave(addresses)}
        >
            <PersonEditor
                formRef={formRef}
                type={PersonType.CHILD}
                id="child"
                person={editChild}
                onChange={(child) => handleChildChange(child)}
            />
        </ListEditor>
    )

}

ChildrenEditor.defaultProps = defaultProps;