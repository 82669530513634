import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Button, Col, Form, FormInstance, Input, Row, Select, Switch } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { Options } from './Options/Options';
import { Variable } from '@methodset/calculator-ts';
import { Boolean, Date, Number, String, Time } from '@methodset/commons-shared-ts';
import { ConfigurationSpec, ConfigurationType, CredentialsConfigurationSpec, CredentialsType, IoType, Option, OptionConfigurationSpec, Query, RequirementType, SourceType, Stringer, StringerType } from '@methodset/endpoint-client-ts';
import { EditorHeader } from '../../EditorHeader/EditorHeader';
import { FilterInput } from 'components/FilterInput/FilterInput';
import { ConfigurationUtils } from 'utils/ConfigurationUtils';
import { Spacer } from 'components/Spacer/Spacer';
import { QuerySelector } from 'containers/Console/Models/ModelItem/ModelCalculator/Queries/QueryDialog/QueryBuilder/QuerySelector/QuerySelector';
import { Justify } from 'components/Justify/Justify';
import { FormatDialog } from './FormatDialog/FormatDialog';
import update from 'immutability-helper';
import './SpecEditor.less';
import { CoreUtils } from 'utils/CoreUtils';

// TODO: put into endpoint-client-ts
export const CREDENTIALS_MAP: { [key: string]: string[] } = {
    API: ['key'],
    FTP: ['host', 'port', 'username', 'password'],
    WEB: ['username', 'password']
};

export type CancelCallback = () => void;
export type DoneCallback = (variableSpec: ConfigurationSpec, index: number) => void;

export type SpecEditorProps = typeof SpecEditor.defaultProps & {
    className?: string,
    variables?: Variable[],
    spec?: ConfigurationSpec,
    specs: ConfigurationSpec[],
    index: number,
    excludes: ConfigurationType[],
    onCancel: CancelCallback,
    onDone: DoneCallback
}

export type SpecEditorState = {
    spec: ConfigurationSpec,
    query?: Query,
    doSync: boolean,
    queryId?: string,
    version?: number,
    specKey?: string,
    editStringer?: boolean
}

export class SpecEditor extends PureComponent<SpecEditorProps, SpecEditorState> {

    static defaultProps = {
        editStringer: false
    }

    private formRef = React.createRef<FormInstance>();

    constructor(props: SpecEditorProps) {
        super(props);
        this.state = {
            spec: props.spec ? props.spec : {
                type: undefined as any,
                ioType: undefined as any,
                key: undefined as any,
                name: undefined as any,
                description: undefined as any,
                requirementType: RequirementType.REQUIRED,
                defaultData: {}
            } as ConfigurationSpec,
            doSync: false
        }
        this.handleSpecKeyChange = this.handleSpecKeyChange.bind(this);
        this.handleSourceTypeChange = this.handleSourceTypeChange.bind(this);
        this.handleCredentialsTypeChange = this.handleCredentialsTypeChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleOptionTypeChange = this.handleOptionTypeChange.bind(this);
        this.handleVariableChange = this.handleVariableChange.bind(this);
        this.handleVariableFormat = this.handleVariableFormat.bind(this);
        this.handleFormatChange = this.handleFormatChange.bind(this);
        this.handleFormatCancel = this.handleFormatCancel.bind(this);
        this.handleRequiredChange = this.handleRequiredChange.bind(this);
        this.handleOptionsChange = this.handleOptionsChange.bind(this);
        this.handleSyncToggle = this.handleSyncToggle.bind(this);
        this.handleEditDone = this.handleEditDone.bind(this);
        this.handleEditError = this.handleEditError.bind(this);
        this.handleEditCancel = this.handleEditCancel.bind(this);
        this.handleQueryChange = this.handleQueryChange.bind(this);
        //this.handleVersionChange = this.handleVersionChange.bind(this);
        this.handleQueryLoaded = this.handleQueryLoaded.bind(this);
    }

    private handleSpecKeyChange(specKey: string): void {
        const query = this.state.query!;
        const config = query.configurationSpecs.find(spec => spec.key === specKey) as OptionConfigurationSpec;
        if (!config) {
            return;
        }
        const optionMap = new Map([
            [IoType.TEXT, IoType.TEXT_ARRAY],
            [IoType.NUMBER, IoType.NUMBER_ARRAY]
        ]);
        const multiMap = new Map([
            [IoType.TEXT_ARRAY, IoType.TEXT],
            [IoType.NUMBER_ARRAY, IoType.NUMBER]
        ]);
        let spec = this.state.spec;
        let ioType;
        if (spec.type === config.type) {
            ioType = config.ioType;
        } else if (config.type === ConfigurationType.OPTION) {
            ioType = optionMap.get(config.ioType);
        } else {
            ioType = multiMap.get(config.ioType);
        }
        spec = update(this.state.spec as OptionConfigurationSpec, {
            options: { $set: config.options },
            ioType: { $set: ioType! }
        });
        this.setState({
            spec: spec,
            specKey: specKey,
        });
    }

    private handleSourceTypeChange(sourceType: SourceType): void {
        const spec = update(this.state.spec as CredentialsConfigurationSpec, {
            sourceType: { $set: sourceType }
        });
        this.setState({ spec: spec });
    }

    private handleCredentialsTypeChange(credentialsType: CredentialsType): void {
        const spec = update(this.state.spec as CredentialsConfigurationSpec, {
            credentialsType: { $set: credentialsType }
        });
        this.setState({ spec: spec });
    }

    private handleVariableChange(variableId: string | undefined): void {
        let spec;
        if (variableId && this.props.variables) {
            const variable = this.props.variables.find(variable => variable.id === variableId);
            if (variable) {
                const type = this.guessType(variable);
                spec = ConfigurationUtils.createConfigurationSpec(
                    type,
                    variable.id,
                    variable.name,
                    variable.description
                )!;
            } else {
                spec = update(this.state.spec, {
                    key: { $set: variableId as any }
                });
            }
        } else {
            spec = update(this.state.spec, {
                key: { $set: variableId as any }
            });
        }
        this.setState({ spec: spec });
    }

    private handleVariableFormat(): void {
        this.setState({ editStringer: true });
    }

    private handleFormatChange(stringer: Stringer | undefined): void {
        const spec = update(this.state.spec, {
            stringer: { $set: stringer }
        });
        this.setState({
            spec: spec,
            editStringer: false
        });
    }

    private handleFormatCancel(): void {
        this.setState({ editStringer: false });
    }

    private guessType(variable: Variable): ConfigurationType {
        const value = variable.cell?.value;
        if (String.isString(value)) {
            return ConfigurationType.TEXT;
        } else if (Number.isNumber(value)) {
            return ConfigurationType.NUMBER;
        } else if (Boolean.isBoolean(value)) {
            return ConfigurationType.BOOLEAN;
        } else if (Date.isDate(value)) {
            return ConfigurationType.DATE;
        } else if (Time.isTime(value)) {
            return ConfigurationType.TIME;
        } else {
            return ConfigurationType.TEXT;
        }
    }

    private handleRequiredChange(isRequired: boolean): void {
        const requirementType = isRequired ? RequirementType.REQUIRED : RequirementType.OPTIONAL;
        const spec = update(this.state.spec, {
            requirementType: { $set: requirementType }
        });
        this.setState({ spec: spec });
    }

    private handleTypeChange(type: ConfigurationType): void {
        const spec = ConfigurationUtils.createConfigurationSpec(
            type,
            this.state.spec.key,
            this.state.spec.name,
            this.state.spec.description
        )!;
        this.setState({ spec: spec });
    }

    private handleOptionTypeChange(type: IoType): void {
        const spec = update(this.state.spec, {
            ioType: { $set: type }
        });
        this.setState({ spec: spec });
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const label = e.target.value;
        const spec = update(this.state.spec, {
            name: { $set: label }
        });
        this.setState({ spec: spec });
    }

    private handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const description = e.target.value;
        const spec = update(this.state.spec, {
            description: { $set: description }
        });
        this.setState({ spec: spec });
    }

    private handleEditDone(): void {
        this.props.onDone(this.state.spec, this.props.index);
    }

    private handleEditError(e: any): void {
    }

    private handleEditCancel(): void {
        this.props.onCancel();
    }

    private handleOptionsChange(options: Option[]): void {
        const spec = update(this.state.spec as OptionConfigurationSpec, {
            options: { $set: options }
        });
        this.setState({ spec: spec });
    }

    private handleSyncToggle(doSync: boolean): void {
        if (doSync) {
            this.setState({ doSync: doSync });
        } else {
            const spec = update(this.state.spec as OptionConfigurationSpec, {
                options: { $set: [] }
            });
            this.setState({
                spec: spec,
                doSync: doSync,
                queryId: undefined,
                version: undefined,
                query: undefined,
                specKey: undefined
            });
        }
    }

    private handleQueryChange(queryId: string | undefined, version: number | undefined): void {
        this.setState({
            queryId: queryId,
            version: version
        });
    }

    // private handleVersionChange(version: number): void {
    //     this.setState({ version: version });
    // }

    private handleQueryLoaded(query: Query): void {
        this.setState({ query: query });
    }

    private isVariableUsed(variable: Variable): boolean {
        return this.props.specs.findIndex(spec => spec.key === variable.id) !== -1;
    }

    public render(): ReactElement {
        return (
            <Form
                ref={this.formRef}
                onFinish={this.handleEditDone}
                onFinishFailed={this.handleEditError}
            >
                <EditorHeader
                    title="Variable Editor"
                    onCancel={this.handleEditCancel}
                />
                <Row gutter={[Globals.FORM_GUTTER_COL, Globals.FORM_GUTTER_ROW]}>
                    <Col span={12}>
                        {!this.props.variables &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                name={`spec-variable-${this.props.index}`}
                                label="Variable"
                                info="The variable to be used in expressions, e.g., x_value. The name can include numbers, letters, underscores and dashes."
                                rules={[{
                                    required: true,
                                    message: `Enter a variable.`
                                }, {
                                    validator: (rule: any, value: string) => {
                                        // Make sure variable names are unique and don't dup another.
                                        const idx = this.props.specs.findIndex(spec => spec.key === value);
                                        return (idx !== -1 && idx !== this.props.index) ?
                                            Promise.reject("Variable already exists.") :
                                            Promise.resolve();
                                    }
                                }]}
                            >
                                <FilterInput
                                    placeholder="Variable."
                                    tester={(value: string) => /^[a-zA-Z]+[a-zA-Z0-9_]*$/.test(value)}
                                    value={this.state.spec.key}
                                    onChange={this.handleVariableChange}
                                />
                            </FormItem>
                        }
                        {this.props.variables &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                name={`spec-variable-${this.props.index}`}
                                label="Variable"
                                info="The variable to be used in expressions, e.g., x_value. The name can include numbers, letters, underscores and dashes."
                                rules={[{
                                    required: true,
                                    message: `Enter a variable.`
                                }, {
                                    validator: (rule: any, value: string) => {
                                        // Make sure variable names are unique and don't dup another.
                                        const idx = this.props.specs.findIndex(spec => spec.key === value);
                                        return (idx !== -1 && idx !== this.props.index) ?
                                            Promise.reject("Variable already exists.") :
                                            Promise.resolve();
                                    }
                                }]}
                            >
                                <Select
                                    placeholder="Variable."
                                    value={this.state.spec.key}
                                    onChange={this.handleVariableChange}
                                >
                                    {this.props.variables.map(variable => (
                                        <Select.Option
                                            key={variable.id}
                                            value={variable.id}
                                            disabled={this.isVariableUsed(variable)}
                                        >
                                            {variable.id}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </FormItem>
                        }
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Name"
                            info={`The friendly name for the variable used for identification.`}
                            name={`spec-name-${this.props.index}`}
                            rules={[{
                                required: true,
                                message: "Select a variable name."
                            }]}
                        >
                            <Input
                                placeholder="Variable name."
                                value={this.state.spec.name}
                                onChange={this.handleNameChange}
                            />
                        </FormItem>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Description"
                            info="The description of the variable."
                            name={`spec-description-${this.props.index}`}
                        >
                            <Input.TextArea
                                placeholder="Variable description."
                                rows={3}
                                value={this.state.spec.description}
                                onChange={this.handleDescriptionChange}
                            />
                        </FormItem>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            name={`spec-type-${this.props.index}`}
                            label="Type"
                            info="The type of the variable."
                            rules={[{
                                required: true,
                                message: "Select a variable type."
                            }]}
                        >
                            <Select
                                placeholder="Variable type."
                                value={this.state.spec.type}
                                onChange={this.handleTypeChange}>
                                {!this.props.excludes.includes(ConfigurationType.TEXT) &&
                                    <Select.Option value={ConfigurationType.TEXT}>Text</Select.Option>
                                }
                                {!this.props.excludes.includes(ConfigurationType.NUMBER) &&
                                    <Select.Option value={ConfigurationType.NUMBER}>Number</Select.Option>
                                }
                                {!this.props.excludes.includes(ConfigurationType.BOOLEAN) &&
                                    <Select.Option value={ConfigurationType.BOOLEAN}>Boolean</Select.Option>
                                }
                                {!this.props.excludes.includes(ConfigurationType.DATE) &&
                                    <Select.Option value={ConfigurationType.DATE}>Date</Select.Option>
                                }
                                {!this.props.excludes.includes(ConfigurationType.TIME) &&
                                    <Select.Option value={ConfigurationType.TIME}>Time</Select.Option>
                                }
                                {!this.props.excludes.includes(ConfigurationType.TIME_ZONE) &&
                                    <Select.Option value={ConfigurationType.TIME_ZONE}>Time Zone</Select.Option>
                                }
                                {!this.props.excludes.includes(ConfigurationType.OPTION) &&
                                    <Select.Option value={ConfigurationType.OPTION}>Option</Select.Option>
                                }
                                {!this.props.excludes.includes(ConfigurationType.MULTI_OPTION) &&
                                    <Select.Option value={ConfigurationType.MULTI_OPTION}>Multi-Option</Select.Option>
                                }
                                {!this.props.excludes.includes(ConfigurationType.CREDENTIALS) &&
                                    <Select.Option value={ConfigurationType.CREDENTIALS}>Credentials</Select.Option>
                                }
                            </Select>
                        </FormItem>
                        <Justify className="x-speceditor-format" justification="left">
                            <Spacer>
                                <Button onClick={this.handleVariableFormat}>
                                    Format Variable
                                </Button>
                                <span className="x-speceditor-label">{this.state.spec.stringer ? StringerType.map().get(this.state.spec.stringer.type)!.name : "No formatting."}</span>
                            </Spacer>
                        </Justify>
                        {this.state.editStringer &&
                            <FormatDialog
                                stringer={this.state.spec.stringer}
                                onChange={this.handleFormatChange}
                                onCancel={this.handleFormatCancel}
                            />
                        }
                        {this.state.spec.type === ConfigurationType.CREDENTIALS &&
                            <>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.formRef}
                                    label="Data Source"
                                    info="The source from which the data will be fetched."
                                    name={`spec-source-${this.props.index}`}
                                    rules={[{
                                        required: true,
                                        message: 'Select a data source.'
                                    }]}
                                >
                                    <Select
                                        placeholder="Data source."
                                        value={(this.state.spec as CredentialsConfigurationSpec).sourceType}
                                        onChange={this.handleSourceTypeChange}
                                    >
                                        {SourceType.entries().map(([key, value]) =>
                                            <Select.Option key={key} value={key}>{value.name}</Select.Option>
                                        )}
                                    </Select>
                                </FormItem>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.formRef}
                                    label="Credentials Type"
                                    info="The credentials type for which to select a field for the variable."
                                    name={`spec-credentials-${this.props.index}`}
                                    rules={[{
                                        required: true,
                                        message: 'Select a credentials type.'
                                    }]}
                                >
                                    <Select
                                        placeholder="Credentials type."
                                        value={(this.state.spec as CredentialsConfigurationSpec).credentialsType}
                                        onChange={this.handleCredentialsTypeChange}
                                    >
                                        {CredentialsType.entries().map(([key, value]) =>
                                            <Select.Option key={key} value={key}>{value}</Select.Option>
                                        )}
                                    </Select>
                                </FormItem>
                            </>
                        }
                        <Spacer>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                label="Required"
                                name="required"
                                info="Tells if the variable requires a value."
                                valuePropName="checked"
                            >
                                <Switch
                                    checked={this.state.spec.requirementType === RequirementType.REQUIRED}
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    onChange={this.handleRequiredChange}
                                />
                            </FormItem>
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                label="Sync Options With Query"
                                name="sync"
                                info="Sync with the options of an existing dataset query."
                                valuePropName="checked"
                            >
                                <Switch
                                    checked={!!this.state.doSync}
                                    disabled={this.state.spec.type !== ConfigurationType.OPTION && this.state.spec.type !== ConfigurationType.MULTI_OPTION}
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    onChange={this.handleSyncToggle}
                                />
                            </FormItem>
                        </Spacer>
                    </Col>
                    <Col span={12}>
                        {this.state.spec.type === ConfigurationType.OPTION &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                name={`option-type-${this.props.index}`}
                                label="Option Type"
                                info="The type of the option values."
                                rules={[{
                                    required: true,
                                    message: "Select an option type."
                                }]}
                            >
                                <Select
                                    placeholder="Option type."
                                    value={this.state.spec.ioType}
                                    onChange={this.handleOptionTypeChange}
                                >
                                    <Select.Option value={IoType.TEXT}>Text</Select.Option>
                                    <Select.Option value={IoType.NUMBER}>Number</Select.Option>
                                </Select>
                            </FormItem>
                        }
                        {this.state.spec.type === ConfigurationType.MULTI_OPTION &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                name={`multi-option-type-${this.props.index}`}
                                label="Option Type"
                                info="The type of the option values."
                                rules={[{
                                    required: true,
                                    message: "Select an option type."
                                }]}
                            >
                                <Select
                                    placeholder="Option type."
                                    value={this.state.spec.ioType}
                                    onChange={this.handleOptionTypeChange}
                                >
                                    <Select.Option value={IoType.TEXT_ARRAY}>Text</Select.Option>
                                    <Select.Option value={IoType.NUMBER_ARRAY}>Number</Select.Option>
                                </Select>
                            </FormItem>
                        }
                        {this.state.doSync &&
                            <>
                                <QuerySelector
                                    formRef={this.formRef}
                                    columns={2}
                                    queryId={this.state.queryId}
                                    version={this.state.version}
                                    onSelect={this.handleQueryChange}
                                    onLoad={this.handleQueryLoaded}
                                />
                                {this.state.query &&
                                    <>
                                        <FormItem
                                            {...Globals.FORM_LAYOUT}
                                            formRef={this.formRef}
                                            label="Option Field"
                                            name="field"
                                            info="The dataset's options field to use as the template for its values."
                                            rules={[{
                                                required: true,
                                                message: `Please select an options field.`
                                            }]}
                                        >
                                            <Select
                                                placeholder="Reference field."
                                                value={this.state.specKey}
                                                onChange={this.handleSpecKeyChange}
                                            >
                                                {this.state.query.configurationSpecs
                                                    .filter(spec => spec.type === ConfigurationType.OPTION || spec.type === ConfigurationType.MULTI_OPTION)
                                                    .map(spec => (
                                                        <Select.Option key={spec.key} value={spec.key}>{spec.name}</Select.Option>
                                                    ))}
                                            </Select>
                                        </FormItem>
                                        {this.state.specKey &&
                                            <Options
                                                options={(this.state.spec as OptionConfigurationSpec).options}
                                                onChange={this.handleOptionsChange}
                                            />
                                        }
                                    </>
                                }
                            </>
                        }
                        {!this.state.doSync && (this.state.spec as OptionConfigurationSpec).options &&
                            <FormItem
                                {...Globals.FORM_LAYOUT}
                                formRef={this.formRef}
                                label="Options"
                                info="The options for the value."
                                name="options"
                                valuePropName="options"
                                rules={[{
                                    required: true,
                                    message: ''
                                }, {
                                    validator: (rule: any, options: Option[]) => {
                                        if (options.length === 0) {
                                            return Promise.reject('Please enter at least one option field.');
                                        }
                                        for (let i = 0; i < options.length; i++) {
                                            const option = options[i];
                                            if (!option.value) {
                                                return Promise.reject('Please enter all option fields.');
                                            }
                                        }
                                        return Promise.resolve();
                                    }
                                }]}
                            >
                                <Options
                                    options={(this.state.spec as OptionConfigurationSpec).options}
                                    onChange={this.handleOptionsChange}
                                />
                            </FormItem>
                        }
                    </Col>
                </Row>
            </Form >
        );
    }
}
