import { ReactElement } from 'react';
import { SizeType } from '@methodset/model-client-ts';
import { Button, Dropdown, Menu } from 'antd';
import './SizeStyle.less';

export type ChangeCallback = (size: SizeType) => void;

export type SizeStyleProps = {
    size?: SizeType,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    size: SizeType.MED,
}

export const SizeStyle = (props: SizeStyleProps): ReactElement => {

    const handleSelect = (info: any) => {
        props.onChange(info.key);
    }

    const menu = (
        <Menu onClick={handleSelect}>
            {Object.entries(SizeType).map(([key, value]) =>
                <Menu.Item key={key}>
                    {value}
                </Menu.Item>
            )}
        </Menu>
    );

    return (
        <div className="x-sizestyle">
            <Dropdown
                overlay={menu}
                trigger={['click']}
            >
                <Button>
                    {props.size}
                </Button>
            </Dropdown>
        </div>

    )
}

SizeStyle.defaultProps = defaultProps;
