import { ReactElement, useEffect, useReducer, useRef, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Location } from 'history';
import { Button, Card, Col, Divider, Empty, Form, FormInstance, Input, Row, Select, Tag } from 'antd';
import { Widget, Applet, Model, WidgetType, RowWidgetConfiguration, ColumnWidgetConfiguration, Configuration, AppletPanel, MenuItem } from '@methodset/model-client-ts';
import { Globals } from 'constants/Globals';
import { ViewItem } from './ItemLayout/ItemLayout';
import { Spacer } from 'components/Spacer/Spacer';
import { ArrowsAltOutlined, DeleteOutlined, DownOutlined, EditOutlined, MenuOutlined, PlusOutlined, ShrinkOutlined, SyncOutlined } from '@ant-design/icons';
import { WidgetViewer } from 'containers/Components/Widgets/WidgetViewer/WidgetViewer';
import { WidgetEditor } from 'containers/Components/Widgets/WidgetEditor/WidgetEditor';
import { ItemSpec, MenuButton } from 'components/MenuButton/MenuButton';
import { Calculator } from '@methodset/calculator-ts';
import { WidgetUtils } from 'utils/WidgetUtils';
import { WidgetSyncFactory } from 'sync/WidgetSyncFactory';
import { ItemMenu } from './ItemMenu/ItemMenu';
import { PanelProperties } from '../PanelProperties/PanelProperties';
import { MenuEditor } from './MenuEditor/MenuEditor';
import { PanelDialog } from 'containers/Console/Dashboards/DashboardItem/AppletViewer/PanelDialog/PanelDialog';
import { AppletTitle } from 'containers/Console/Dashboards/DashboardItem/AppletViewer/AppletTitle/AppletTitle';
import { CoreUtils } from 'utils/CoreUtils';
import { v4 as uuid } from "uuid";
import update from 'immutability-helper';
import './AppletEditor.less';
import { NoData } from 'components/NoData/NoData';

type EditMode = "none" | "properties" | "widget" | "menu";

export type EditCallback = (isEdit: boolean) => void;
export type ChangeCallback = (applet: Applet) => void;

export type AppletEditorProps = RouteComponentProps & {
    model: Model,
    applet: Applet,
    calculator: Calculator,
    onEdit?: EditCallback,
    onChange: ChangeCallback,
}

export const AppletEditor = (props: AppletEditorProps): ReactElement => {

    // Access to history.
    const history = useHistory();
    // Add ability to force update on change in cells.
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    // Widget being edited.
    const [widget, setWidget] = useState<Widget | undefined>();
    // Menu being edited.
    const [menuItem, setMenuItem] = useState<MenuItem | undefined>();
    // Test configuration.
    const [configuration, setConfiguration] = useState<Configuration>({});
    // Model is executing.
    const [isExecuting, setIsExecuting] = useState<boolean>(false);
    // Applet is in preview mode.
    const [isPreviewing, setIsPreviewing] = useState<boolean>(true);
    // Tells which editor is active.
    const [editMode, setEditMode] = useState<EditMode>("none");
    // The panel being edited.
    const [panelId, setPanelId] = useState<string>(props.applet.id);

    const checkEdit = (location: Location<any>): false | void => {
        const params = new URLSearchParams(location.search);
        const edit = params.get("edit");
        const isPreviewing = !edit || edit === "false";
        setIsPreviewing(isPreviewing);
    }

    useEffect(() => {
        const unregister = history.listen(checkEdit);
        props.calculator.addCallback("ExecutionInitiate", handleExecutionInitiate);
        props.calculator.addCallback("ExecutionComplete", handleExecutionComplete);
        checkEdit(props.location);
        return () => {
            unregister();
            props.calculator.removeCallback("ExecutionInitiate", handleExecutionInitiate);
            props.calculator.removeCallback("ExecutionComplete", handleExecutionComplete);
        }
    }, []);

    const handleWidgetAdd = (): void => {
        setWidget(undefined);
        setIsPreviewing(false);
        setEditMode("widget");
    }

    const handleWidgetEdit = (widget: Widget): void => {
        setWidget(widget);
        setEditMode("widget");
    }

    const handleWidgetChange = (widget: Widget): void => {
        const panel = findPanel();
        const widgets = panel.widgets;
        let index = widgets.findIndex(w => w.id === widget.id);
        let updated;
        if (index !== -1) {
            updated = update(panel, {
                widgets: {
                    [index]: { $set: widget }
                }
            });
        } else {
            updated = update(panel, {
                widgets: {
                    $push: [widget]
                }
            });
            const widgetSync = WidgetSyncFactory.createSync(widget.configuration);
            if (widgetSync) {
                const registry = props.calculator.registry;
                registry.register(widget.id, widget, widgetSync.parser, widgetSync.updater);
            }
        }
        let applet;
        if (isPanel()) {
            let index = props.applet.panels.findIndex(p => p.id === panel.id);
            applet = update(props.applet, {
                panels: {
                    [index]: { $set: updated }
                }
            });
        } else {
            applet = updated as Applet;
        }
        setWidget(undefined);
        setEditMode("none");
        props.onChange(applet);
    }

    const handleWidgetCancel = (): void => {
        setWidget(undefined);
        setEditMode("none");
    }

    const handleWidgetRemove = (widget: Widget): void => {
        const panel = findPanel();
        const widgets = panel.widgets;
        const index = widgets.findIndex(w => w.id === widget.id);
        if (index === -1) {
            return;
        }
        const updated = update(panel, {
            widgets: {
                $splice: [[index, 1]]
            }
        });
        // Remove widget references from row and column widgets.
        // TODO: remove from other widget types that contain references
        const widgetId = widget.id;
        for (const widget of widgets) {
            if (widget.configuration.type === WidgetType.ROW) {
                const configuration = widget.configuration as RowWidgetConfiguration;
                let index;
                do {
                    index = configuration.widgetIds.findIndex(id => id === widgetId);
                    if (index !== -1) {
                        configuration.widgetIds.splice(index, 1);
                    }
                } while (index !== -1);
            } else if (widget.configuration.type === WidgetType.COLUMN) {
                const configuration = widget.configuration as ColumnWidgetConfiguration;
                let index;
                do {
                    index = configuration.widgetIds.findIndex(id => id === widgetId);
                    if (index !== -1) {
                        configuration.widgetIds.splice(index, 1);
                    }
                } while (index !== -1);
            }
        }
        let applet;
        if (isPanel()) {
            let index = props.applet.panels.findIndex(p => p.id === panel.id);
            applet = update(props.applet, {
                panels: {
                    [index]: { $set: updated }
                }
            });
        } else {
            applet = updated as Applet;
        }
        props.onChange(applet);
        const registry = props.calculator.registry;
        registry.unregister(widget.id);
    }

    const handleConfigurationChange = (configuration: Configuration): void => {
        setConfiguration(configuration);
    }

    const handleExpandToggle = (): void => {
        if (props.onEdit) {
            props.onEdit(isPreviewing);
        }
    }

    const handlePropertiesEdit = (): void => {
        setEditMode("properties");
    }

    const handleMenuEdit = (): void => {
        setEditMode("menu");
    }

    const handleMenuChange = (menu: MenuItem[]): void => {
        const applet = update(props.applet, {
            menu: { $set: menu }
        });
        setEditMode("none");
        props.onChange(applet);
    }

    const handleMenuCancel = (): void => {
        setEditMode("none");
    }

    const handleMenuSelect = (menuItem: MenuItem): void => {
        setMenuItem(menuItem);
    }

    const handleMenuClose = (): void => {
        setMenuItem(undefined);
    }

    const handlePanelPropertiesChange = (panel: AppletPanel): void => {
        const index = props.applet.panels.findIndex(p => p.id === panel.id);
        if (index === -1) {
            return;
        }
        const applet = update(props.applet, {
            panels: {
                [index]: { $set: panel }
            }
        });
        setEditMode("none");
        props.onChange(applet);
    }

    const handlePropertiesCancel = (): void => {
        setEditMode("none");
    }

    const handleExecutionInitiate = (): void => {
        setIsExecuting(true);
    }

    const handleExecutionComplete = (): void => {
        setIsExecuting(false);
        forceUpdate();
    }

    const handlePanelSelect = (panelId: string): void => {
        setPanelId(panelId);
    }

    const handlePanelCreate = (): void => {
        const panels = props.applet.panels;
        const name = CoreUtils.createName("New Panel", panels, (item: any) => item.name);
        const panel = {
            id: uuid(),
            name: name,
            span: Globals.APPLET_DEFAULT_SPAN,
            widgets: []
        }
        const applet = update(props.applet, {
            panels: {
                $push: [panel]
            }
        });
        props.onChange(applet);
    }

    const handlePanelRemove = (panel: AppletPanel): void => {
        let applet;
        const index = props.applet.panels.findIndex(p => p.id === panel.id);
        if (index !== -1) {
            applet = update(props.applet, {
                panels: {
                    $splice: [[index, 1]]
                }
            });
        } else {
            applet = panel as Applet;
        }
        const registry = props.calculator.registry;
        registry.unregister(panel.id);
        props.onChange(applet);
    }

    const findPanel = (): AppletPanel => {
        if (panelId === props.applet.id) {
            return props.applet;
        } else {
            return props.applet.panels.find(panel => panel.id === panelId)!;
        }
    }

    const findRootWidgets = (): Widget[] => {
        const childIds = new Set<string>();
        const panel = findPanel();
        const widgets = panel.widgets;
        for (const widget of widgets) {
            const type = widget.configuration.type;
            // Find child widgets of row and columns.
            if (type === WidgetType.ROW || type === WidgetType.COLUMN) {
                const configuration = widget.configuration as any;
                const widgetIds = configuration.widgetIds;
                for (const widgetId of widgetIds) {
                    childIds.add(widgetId);
                }
            }
        }
        const rootWidgets = [];
        for (const widget of widgets) {
            if (!childIds.has(widget.id)) {
                rootWidgets.push(widget);
            }
        }
        return rootWidgets;
    }

    const buildItems = (): ViewItem[] => {
        const calculator = props.calculator;
        const panel = findPanel();
        const widgets = findRootWidgets();
        const viewItems: ViewItem[] = [];
        widgets.forEach(widget => {
            // Check if the hide condition is satisfied.
            if (isPreviewing && WidgetUtils.isHidden(calculator, widget)) {
                return;
            }
            const menu = (
                <ItemMenu
                    widget={widget}
                    onEdit={handleWidgetEdit}
                    onRemove={handleWidgetRemove}
                />
            )
            const viewItem = {
                row: widget.row,
                col: widget.col,
                span: widget.span,
                element: (
                    <WidgetViewer
                        key={widget.id}
                        applet={props.applet}
                        panel={panel}
                        widget={widget}
                        calculator={calculator}
                        configuration={configuration}
                        extra={menu}
                        showHeader={!isPreviewing}
                        onEdit={handleWidgetEdit}
                        onRemove={handleWidgetRemove}
                        onChange={handleWidgetChange}
                        onUpdate={handleConfigurationChange}
                    />
                )
            }
            viewItems.push(viewItem);
        });
        return viewItems;
    }

    const buildWidgetsView = (): ReactElement => {
        const items = buildItems();
        return (
            <>
                {items.map(item => item.element)}
            </>
        )
    }

    const isPanel = (): boolean => {
        return props.applet.id !== panelId;
    }

    const calculator = props.calculator;
    const items: ItemSpec[] = [{
        icon: <EditOutlined />,
        label: "Edit properties...",
        hidden: () => !isPanel(),
        onSelect: handlePropertiesEdit
    }, {
        icon: <PlusOutlined />,
        label: "Add widget...",
        onSelect: handleWidgetAdd
    }, {
        icon: <MenuOutlined />,
        label: "Edit menu...",
        hidden: () => isPanel(),
        onSelect: handleMenuEdit
    }, {
        icon: !isPreviewing ? <ShrinkOutlined /> : <ArrowsAltOutlined />,
        label: !isPreviewing ? "Preview applet" : "Edit widgets",
        onSelect: handleExpandToggle
    }, {
        icon: <DeleteOutlined />,
        label: "Delete panel",
        confirm: "Are you sure you want to delete the panel?",
        hidden: () => !isPanel(),
        onSelect: handlePanelRemove
    }];
    const extra = (
        <Spacer>
            {isExecuting &&
                <SyncOutlined spin={true} />
            }
            {props.onEdit &&
                <MenuButton
                    items={items}
                    size={Globals.APPLET_MENU_SIZE}
                    label="Edit"
                    icon={<DownOutlined />}
                />
            }
        </Spacer>
    );
    const panel = findPanel();
    const title = isPanel() ? panel.name : (
        <AppletTitle
            applet={props.applet}
            calculator={calculator}
            onSelect={handleMenuSelect}
        />
    )

    const labels = ["Applet", "Panel"];
    const applet = props.applet;
    const panels = [applet as AppletPanel].concat(applet.panels);
    const tag = (index: number): ReactElement => {
        const i = index === 0 ? 0 : 1;
        const label = labels[i];
        return (
            <Tag color={Globals.color(Globals.TAG_COLORS, i)}>
                {label}
            </Tag>
        )
    }

    const actions = (
        <Spacer className="x-appleteditor-actions">
            <Select
                className="x-appleteditor-actions-select"
                value={panel.id}
                placeholder="Select an applet to edit."
                onChange={handlePanelSelect}
            >
                {panels.map((panel: AppletPanel, index: number) => (
                    <Select.Option key={panel.id} value={panel.id}>
                        <Spacer justification="between">
                            <span>{panel.name}</span>
                            <span>{tag(index)}</span>
                        </Spacer>
                    </Select.Option>
                ))}
            </Select>
            <Button onClick={handlePanelCreate}>New Panel</Button>
        </Spacer>
    );

    return (
        <>
            <Row className="x-appleteditor-applet">
                <Col span={24}>
                    {actions}
                </Col>
                <Col span={panel.span * Globals.LAYOUT_SCALE}>
                    <Card className="x-appleteditor" size="small" bordered={true} title={title} extra={extra}>
                        {panel.widgets.length === 0 &&
                            <NoData text="No widgets added." />
                        }
                        {panel.widgets.length > 0 &&
                            buildWidgetsView()
                        }
                    </Card>
                </Col>
            </Row>
            {editMode === "properties" && isPanel() &&
                <PanelProperties
                    panel={panel}
                    onChange={handlePanelPropertiesChange}
                    onCancel={handlePropertiesCancel}
                />
            }
            {editMode === "widget" &&
                <WidgetEditor
                    applet={props.applet}
                    panel={panel}
                    widget={widget}
                    calculator={calculator}
                    onChange={handleWidgetChange}
                    onCancel={handleWidgetCancel}
                />
            }
            {editMode === "menu" &&
                <MenuEditor
                    applet={props.applet}
                    menu={props.applet.menu}
                    onChange={handleMenuChange}
                    onCancel={handleMenuCancel}
                />
            }
            {menuItem &&
                <PanelDialog
                    viewId="modelapplet"
                    applet={props.applet}
                    title={menuItem.label}
                    panelId={menuItem.panelId}
                    calculator={calculator}
                    configuration={configuration}
                    onChange={handleConfigurationChange}
                    onClose={handleMenuClose}
                />
            }
        </>
    );

}
