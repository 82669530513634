import { ReactElement } from 'react';
import { ExecutionError } from '@methodset/workflow-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { Label } from 'components/Label/Label';
import { Collapse } from 'antd';
import './ErrorView.less';

export type ErrorViewProps = {
    className?: string,
    error: ExecutionError,
    recurse?: boolean
} & typeof defaultProps;

const defaultProps = {
    recurse: true
}

export const ErrorView = (props: ErrorViewProps): ReactElement => {

    const buildPanels = (error: ExecutionError | undefined, index: number, panels: ReactElement[] = []): ReactElement[] => {
        if (error) {
            const panel = (
                <Collapse.Panel header="Cause Details" key={index}>
                    <ErrorView error={error} recurse={false} />
                </Collapse.Panel>
            );
            panels.push(panel);
            buildPanels(error.cause, index + 1, panels);
        }
        return panels;
    }

    return (
        <Spacer className={props.className} direction="vertical" size="lg">
            <Label
                label="Message"
                placement="top"
            >
                {props.error.message}
            </Label>
            <Label
                label="Type"
                placement="top"
            >
                {props.error.type}
            </Label>
            <Label
                label="Stack Trace"
                placement="top"
            >
                {props.error.stackTrace && props.error.stackTrace.map(message => (
                    <span>{message}</span>
                ))}
            </Label>
            {props.error.cause && props.recurse && 
                <Collapse className="x-errorview-causes">
                    {buildPanels(props.error.cause, 1)}
                </Collapse>
            }
        </Spacer>
    );

}

ErrorView.defaultProps = defaultProps;
