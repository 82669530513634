import { FC, ReactElement, Ref, useRef, useState } from 'react';
import { Form, FormInstance, Modal, Switch } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { AppletState } from '@methodset/dashboard-client-ts';
import update from 'immutability-helper';
import './AutoUpdater.less';
import { Spacer } from 'components/Spacer/Spacer';

export type ChangeCallback = (appletState: AppletState) => void;
export type CancelCallback = () => void;

export type AutoUpdaterProps = {
    appletState: AppletState,
    onChange: (appletState: AppletState) => void,
    onCancel: () => void
}

export const AutoUpdater: FC<AutoUpdaterProps> = (props: AutoUpdaterProps): ReactElement => {

    const [appletState, setAppletState] = useState<AppletState>(props.appletState);

    const formRef: Ref<FormInstance> = useRef(null);

    const handleAutoSaveChange = (autoUpdate: boolean): void => {
        const updated = update(appletState, {
            autoUpdate: { $set: autoUpdate }
        });
        setAppletState(updated)
    }

    const handleOk = (): void => {
        formRef.current?.submit();
    }

    const handleCancel = (): void => {
        props.onCancel();
    }

    const handleFormFinish = (): void => {
        props.onChange(appletState);
    }

    return (
        <Modal
            className="x-autoupdater"
            centered
            width={Globals.DIALOG_WIDTH}
            title="Auto Update Editor"
            onOk={handleOk}
            onCancel={handleCancel}
            visible={true}
        >
            <Form
                ref={formRef}
                onFinish={handleFormFinish}
            >
                <Spacer direction="vertical" size="lg">
                    <div>
                        Enabling this option will automatically install new applet versions when they become available.
                        Disabling this option will require a manual update to install a new version.
                    </div>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={formRef}
                        label="Automatic Updates"
                        name="update"
                        info="Automatically updates the applet when a new version is available."
                        valuePropName="checked"
                    >
                        <Switch
                            checkedChildren="On"
                            unCheckedChildren="Off"
                            checked={appletState.autoUpdate}
                            onChange={handleAutoSaveChange}
                        />
                    </FormItem>
                </Spacer>
            </Form>
        </Modal>
    );

}
