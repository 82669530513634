import { ReactElement } from 'react';
import { Modal } from 'antd';
import { Globals } from 'constants/Globals';
import { CoreUtils } from 'utils/CoreUtils';
import { PackType } from '@methodset/library-client-ts';
import './VersionResetter.less';

export type ResetCallback = () => void;
export type CancelCallback = () => void;

export type VersionResetterProps = {
    id: string,
    type: PackType,
    version: number,
    error?: Error,
    isProcessing: boolean,
    onReset: ResetCallback,
    onCancel: CancelCallback
}

export const VersionResetter = (props: VersionResetterProps): ReactElement => {

    return (
        <Modal
            className="x-versionresetter"
            centered
            title="Discard Changes"
            width={Globals.DIALOG_WIDTH}
            visible={true}
            okText="Discard"
            okButtonProps={{ loading: props.isProcessing }}
            onOk={() => props.onReset()}
            onCancel={() => props.onCancel()}
        >
            <div>
                This action will discard any changes that have been made
                {props.version > 0 &&
                    <> since the publication of version <span className="x-editversion-version">{CoreUtils.toVersion(props.version)}</span>.</>
                }
                {props.version === 0 &&
                    <> since the start of editing.</>
                }
            </div>
            {props.error &&
                <div className="x-versionresetter-error">{props.error.message}</div>
            }
        </Modal>
    );
}
