import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Checkbox, Form, FormInstance, Input, Modal, Radio, RadioChangeEvent } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FileTextOutlined } from '@ant-design/icons';
import { EntityContext } from 'context/EntityContext';
import { FormItem } from 'components/FormItem/FormItem';
import { FormFrame } from 'components/FormFrame/FormFrame';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { RouteBuilder } from 'utils/RouteBuilder';
import { PlanType } from '@methodset/entity-client-ts';
import { CoreUtils } from 'utils/CoreUtils';
import entityService from 'services/EntityService';
import authService from 'services/AuthService';
import update from 'immutability-helper';
import './Register.less';

interface FormData {
    inviteCode?: string,
    firstName?: string,
    lastName?: string,
    emailAddress?: string,
    password?: string,
    confirmPassword?: string,
    organizationId?: string,
    planType?: PlanType,
    acceptTerms?: boolean
}

export type RegisterProps = RouteComponentProps & {}

export type RegisterState = {
    error?: Error,
    isSubmitting: boolean,
    formData: FormData,
    doJoin: boolean
}

export class Register extends PureComponent<RegisterProps, RegisterState> {

    static contextType = EntityContext;

    private formRef = React.createRef<FormInstance>();

    constructor(props: RegisterProps) {
        super(props);
        this.state = {
            error: undefined,
            isSubmitting: false,
            formData: {
                planType: PlanType.INDIVIDUAL
            },
            doJoin: false
        };
        this.handleInviteCodeChange = this.handleInviteCodeChange.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
        this.handleJoinChange = this.handleJoinChange.bind(this);
        this.handleOrganizationChange = this.handleOrganizationChange.bind(this);
        this.handlePlanChange = this.handlePlanChange.bind(this);
        this.handleAcceptChange = this.handleAcceptChange.bind(this);
        this.handleTermsClick = this.handleTermsClick.bind(this);
        this.handleFormFinish = this.handleFormFinish.bind(this);
    }

    private handleInviteCodeChange(e: ChangeEvent<HTMLInputElement>): void {
        const inviteCode = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                inviteCode: { $set: inviteCode }
            })
        });
    }

    private handleFirstNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const firstName = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                firstName: { $set: firstName }
            })
        });
    }

    private handleLastNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const lastName = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                lastName: { $set: lastName }
            })
        });
    }

    private handleEmailChange(e: ChangeEvent<HTMLInputElement>): void {
        const emailAddress = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                emailAddress: { $set: emailAddress }
            })
        });
    }

    private handlePasswordChange(e: ChangeEvent<HTMLInputElement>): void {
        const password = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                password: { $set: password }
            })
        });
    }

    private handleConfirmPasswordChange(e: ChangeEvent<HTMLInputElement>): void {
        const confirmPassword = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                confirmPassword: { $set: confirmPassword }
            })
        });
    }

    private handleJoinChange(e: CheckboxChangeEvent): void {
        const doJoin = e.target.checked;
        this.setState({ doJoin: doJoin });
    }

    private handleOrganizationChange(e: any): void {
        const organizationId = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                organizationId: { $set: organizationId }
            })
        });
    }

    private handlePlanChange(e: RadioChangeEvent): void {
        const planType = e.target.value;
        this.setState({
            formData: update(this.state.formData, {
                planType: { $set: planType }
            })
        });
    }

    private handleAcceptChange(e: CheckboxChangeEvent): void {
        const acceptTerms = e.target.checked;
        this.setState({
            formData: update(this.state.formData, {
                acceptTerms: { $set: acceptTerms }
            })
        });
    }

    private handleTermsClick(): void {
        Modal.info({
            title: 'Terms of Service',
            content: (
                <div>Terms of service here...</div>
            ),
            icon: <FileTextOutlined />,
            width: 600
        });
    }

    private handleFormFinish(): void {
        if (!this.formRef.current) {
            const error = new Error('Form reference not set.');
            this.setState({ error: error });
            return;
        }
        this.formRef.current.validateFields().then(() => {
            if (!this.state.formData.acceptTerms) {
                const error = new Error('Please accept the terms of service.');
                this.setState({ error: error });
            } else {
                const request = {
                    inviteCode: this.state.formData.inviteCode,
                    organizationId: this.state.formData.organizationId,
                    firstName: this.state.formData.firstName,
                    lastName: this.state.formData.lastName,
                    emailAddress: this.state.formData.emailAddress,
                    password: this.state.formData.password,
                    acceptTerms: this.state.formData.acceptTerms
                };
                this.createUserRequest(request);
            }
        });
    }

    private createUserRequest(request: any): Promise<any> {
        this.setState({
            error: undefined,
            isSubmitting: true
        });
        return entityService.createUser(request,
            (response: any) => this.createUserResponse(response),
            (response: any) => this.createException(response),
            true
        );
    }

    private createUserResponse(response: any): void {
        this.setState({
            error: undefined,
            isSubmitting: false
        });
        const user = response.data.user;
        this.context.saveUser(user);
        this.props.history.push(RouteBuilder.registerConfirm(user.id));
    }

    private createException(response: any): void {
        this.setState({
            error: new Error(RestUtils.getErrorMessage(response)),
            isSubmitting: false
        });
    }

    private parseParameters(): [PlanType | undefined, string | undefined, string | undefined] {
        const query = this.props.location.search;
        const params = new URLSearchParams(query);
        const plan = params.get("plan");
        const organization = params.get("organization");
        const code = params.get("code");
        return [
            plan ? plan.toUpperCase() as PlanType : undefined,
            organization ? organization : undefined,
            code ? code : undefined
        ]
    }

    private buildRegisterForm(): ReactElement {
        return (
            <FormFrame
                ref={this.formRef}
                title="Register"
                error={this.state.error}
                hideFooter={true}
                onOk={this.handleFormFinish}
            >
                {!this.state.formData.inviteCode &&
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Invite Code"
                        name="invite-code"
                        rules={[{
                            required: true,
                            message: 'Please enter your invite code.'
                        }]}
                    >
                        <Input
                            id="code"
                            placeholder="Invite code."
                            size="large"
                            value={this.state.formData.inviteCode}
                            onChange={this.handleInviteCodeChange}
                        />
                    </FormItem>
                }
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="First Name"
                    name="first-name"
                    rules={[{
                        required: true,
                        message: 'Please enter your first name.'
                    }]}
                >
                    <Input
                        id="first"
                        placeholder="First name."
                        size="large"
                        value={this.state.formData.firstName}
                        onChange={this.handleFirstNameChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Last Name"
                    name="last-name"
                    rules={[{
                        required: true,
                        message: 'Please enter your last name.'
                    }]}
                >
                    <Input
                        id="last"
                        placeholder="Last name."
                        size="large"
                        value={this.state.formData.lastName}
                        onChange={this.handleLastNameChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Email"
                    name="email"
                    rules={[{
                        required: true,
                        message: 'Please enter your email address.'
                    }, {
                        type: 'email',
                        message: 'The email address is invalid.'
                    }]}
                >
                    <Input
                        id="email"
                        placeholder="Email address."
                        size="large"
                        value={this.state.formData.emailAddress}
                        onChange={this.handleEmailChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Password"
                    name="password"
                    rules={[{
                        required: true,
                        message: 'Please enter a password.'
                    }, {
                        validator: (rule: any, value: any) => {
                            const message = authService.validatePassword(this.state.formData.password);
                            return message ? Promise.reject(message) : Promise.resolve();
                        }
                    }]}
                >
                    <Input.Password
                        id="password"
                        placeholder="Password."
                        size="large"
                        value={this.state.formData.password}
                        onChange={this.handlePasswordChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Confirm"
                    name="confirm"
                    rules={[{
                        required: true,
                        message: 'Please enter the password again.'
                    },
                    ({ getFieldValue }: any) => ({
                        validator(rule: any, value: any) {
                            if (value && value !== getFieldValue('password')) {
                                return Promise.reject('The confirm password does not match.');
                            } else {
                                return Promise.resolve();
                            }
                        }
                    })
                    ]}
                >
                    <Input.Password
                        id="confirm"
                        placeholder="Confirm password."
                        size="large"
                        value={this.state.formData.confirmPassword}
                        onChange={this.handleConfirmPasswordChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Plan"
                    name="plan"
                    rules={[{
                        required: true,
                        message: 'Please select a plan.'
                    }]}
                >
                    <Radio.Group
                        value={this.state.formData.planType}
                        onChange={this.handlePlanChange}
                    >
                        {CoreUtils.enumToKeys(PlanType).map(type => (
                            <Radio key={type} value={type}>{CoreUtils.toProper(type)}</Radio>
                        ))}
                    </Radio.Group>
                </FormItem>
                {!this.state.formData.organizationId &&
                    <Checkbox onChange={this.handleJoinChange}>
                        Join an existing organization.
                    </Checkbox>
                }
                {this.state.doJoin &&
                    <>
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={this.formRef}
                            label="Organization Id"
                            name="organization"
                            info="Join an existing organization by entering the id that has been given to you by a member of your organization."
                            rules={[{
                                required: true,
                                message: 'Please enter your organization id.'
                            }]}
                        >
                            <Input
                                id="organization"
                                placeholder="Organization id."
                                size="large"
                                value={this.state.formData.organizationId}
                                onChange={this.handleOrganizationChange}
                            />
                        </FormItem>
                    </>
                }
                <div className="x-register-footer">
                    <Checkbox onChange={this.handleAcceptChange}>
                        I agree to the <Link to="#" onClick={this.handleTermsClick}>terms of service.</Link>
                    </Checkbox>
                    <Button
                        className="x-register-submit"
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={this.state.isSubmitting}
                    >
                        Register
                    </Button>
                    <span className="x-register-question">Didn't get your confirmation code?</span>
                    <Link to={RouteBuilder.USER_REGISTER_RESEND}>Resend here.</Link>
                </div>
            </FormFrame>
        );
    }

    public componentDidMount(): void {
        const [planType, organizationId, inviteCode] = this.parseParameters()
        this.setState({
            formData: update(this.state.formData, {
                planType: { $set: planType },
                inviteCode: { $set: inviteCode },
                organizationId: { $set: organizationId }
            })
        });
    }

    public render(): ReactElement {
        return this.buildRegisterForm();
    }

}
