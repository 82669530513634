import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal, Radio, RadioChangeEvent } from 'antd';
import { RestUtils } from 'utils/RestUtils';
import { Globals } from 'constants/Globals';
import { EnvironmentType } from '@methodset/commons-shared-ts';
import { StatusType } from 'constants/StatusType';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { Spacer } from 'components/Spacer/Spacer';
import { EntityContext } from 'context/EntityContext';
import { CoreUtils } from 'utils/CoreUtils';
import { RouteBuilder } from 'utils/RouteBuilder';
import axios from 'axios';
import authService from 'services/AuthService';
import entityService from 'services/EntityService';
import './EnvironmentDialog.less';

export type CloseCallback = () => void;

export type EnvironmentDialogProps = {
    onClose: CloseCallback
}

export const EnvironmentDialog = (props: EnvironmentDialogProps): ReactElement => {

    const context = useContext(EntityContext);
    const history = useHistory();

    // Status of loading data.
    const [status, setStatus] = useState<string>(StatusType.INIT);
    // True if switching.
    const [isSwitching, setIsSwitching] = useState<boolean>(false);
    // The environment types that the organization has setup.
    const [environmentTypes, setEnvironmentTypes] = useState<EnvironmentType[]>([]);
    // The selected environment type.
    const [environmentType, setEnvironmentType] = useState<EnvironmentType>(context.user!.environmentType);
    // An error if one has occurred.
    const [error, setError] = useState<Error | undefined>();
    // The history callback does not have the scoped environment type - store a reference.
    const environmentRef = useRef<EnvironmentType>(environmentType);

    useEffect(() => {
        const unregister = history.listen(() => switchEnvironmentRequest());
        if (status !== StatusType.READY) {
            loadData();
        }
        return () => {
            unregister();
        }
    }, []);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const handleCancel = (): void => {
        props.onClose();
    }

    const handleSwitch = (): void => {
        setIsSwitching(true);
        setError(undefined);
        // Switch to the console and allow any screens to save 
        // changes before continuing. The history listener will 
        // be triggered when the path change is complete (after 
        // any save), which will perform the environment switch.
        history.push(RouteBuilder.CONSOLE);
    }

    const handleEnvironmentChange = (e: RadioChangeEvent): void => {
        const environment = e.target.value;
        setEnvironmentType(environment);
        // Store the current environment type in a ref so that
        // it can be used in the history callback. The state
        // variable is the original when the listener was created.
        environmentRef.current = environment;
    }

    const readEnvironmentTypesRequest = (): Promise<any> => {
        const request = {}
        return entityService.readEnvironmentTypes(request,
            (response: any) => readEnvironmentTypesResponse(response),
            undefined, true
        );
    }

    const readEnvironmentTypesResponse = (response: any): void => {
        const types = response.data.types;
        setEnvironmentTypes(types);
    }

    const switchEnvironmentRequest = (): Promise<any> => {
        const request = {
            //environment: EnvironmentType.abbreviation(environmentType)
            environment: EnvironmentType.abbreviation(environmentRef.current)
        }
        return entityService.switchEnvironment(request,
            (response: any) => switchEnvironmentResponse(response),
            (response: any) => switchEnvironmentException(response),
            true
        );
    }

    const switchEnvironmentResponse = (response: any): void => {
        const group = response.data.group;
        authService.refreshUser().then((user) => {
            context.saveUser(user);
            context.saveGroup(group);
            setIsSwitching(false);
            props.onClose();
        }).catch((err) => {
            context.clearUser();
        });
    }

    const switchEnvironmentException = (response: any): void => {
        const error = RestUtils.getError(response);
        setError(error);
        setIsSwitching(false);
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <LoadSkeleton
                count={3}
                status={isLoading ? "loading" : "failed"}
                onRetry={() => handleRetryLoad()}
            >
                <LoadSkeleton.Checkbox />
                <LoadSkeleton.Input length="fill" />
            </LoadSkeleton>
        );
    }

    const loadData = (): void => {
        const requests = [];
        requests.push(readEnvironmentTypesRequest())
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    const buildSwitchView = (): ReactElement => {
        return (
            <div>
                <div className="x-environmentdialog-info">Chose an environment:</div>
                <Radio.Group
                    className="x-environmentdialog-type"
                    value={environmentType}
                    onChange={(e) => handleEnvironmentChange(e)}
                >
                    <Spacer direction="vertical">
                        {environmentTypes.map(env => (
                            <Radio key={env} value={env}>{CoreUtils.toProper(env)}</Radio>
                        ))}
                    </Spacer>
                </Radio.Group>
                {!!error &&
                    <div className="x-environmentdialog-error">{error.message}</div>
                }
            </div>
        );
    }

    let view;
    if (status === StatusType.LOADING) {
        view = buildLoadingView(true);
    } else if (status === StatusType.FAILED) {
        view = buildLoadingView(false);
    } else if (status === StatusType.READY) {
        view = buildSwitchView();
    }
    return (
        <Modal
            className="x-environmentdialog"
            centered
            width={Globals.DIALOG_WIDTH}
            title="Switch Environment"
            onCancel={handleCancel}
            visible={true}
            footer={(
                <>
                    <Button disabled={isSwitching} onClick={handleCancel}>Cancel</Button>
                    <Button type="primary" loading={isSwitching} onClick={handleSwitch}>Switch</Button>
                </>
            )}
        >
            {view}
        </Modal>
    );

}
