import { ReactElement } from 'react';
import { Button, ButtonProps } from 'antd';
import classNames from 'classnames';
import './ButtonLink.less';

/**
 * A button that behaves like a link with a click handler.
 * 
 * @param props Standard button props.
 * @returns The link button instance.
 */
export const ButtonLink = (props: ButtonProps): ReactElement => {
    return (
        <div className={classNames("x-buttonlink", props.className)}>
            <Button
                type="link"
                {...props}
            >
                {props.children}
            </Button>
        </div>
    )
}
