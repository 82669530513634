import { ReactElement, Ref, useEffect, useRef, useState } from 'react';
import { Button, Form, FormInstance, Modal, Result, Tag } from 'antd';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { FormItem } from 'components/FormItem/FormItem';
import { Spacer } from 'components/Spacer/Spacer';
import { ChangeEditor } from './ChangeEditor/ChangeEditor';
import { CoreUtils } from 'utils/CoreUtils';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { Alert } from 'components/Alert/Alert';
import { AppletDetails, ApplicationDetails, PackDetails, PackHeader, PackType } from '@methodset/library-client-ts';
import { StatusType } from 'constants/StatusType';
import axios from 'axios';
import update from 'immutability-helper';
import libraryService from 'services/LibraryService';
import './VersionPackager.less';

export type BeforeCallback = () => Promise<any>;
export type PackageCallback = (header: PackHeader) => void;
export type CancelCallback = () => void;

export type VersionPackagerProps = {
    type: PackType,
    packId: string,
    name?: string,
    description?: string,
    details: PackDetails,
    onBefore?: BeforeCallback,
    onPackage: PackageCallback,
    onCancel: CancelCallback
}

export const VersionPackager = (props: VersionPackagerProps): ReactElement => {

    const [status, setStatus] = useState<StatusType>(StatusType.INIT);
    const [changes, setChanges] = useState<string[]>([]);
    const [version, setVersion] = useState<number>(0);
    const [header, setHeader] = useState<PackHeader | undefined>();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>();

    const formRef: Ref<FormInstance> = useRef(null);

    useEffect(() => {
        if (status !== StatusType.READY) {
            loadData();
        }
    }, []);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const handlePackagePack = (): void => {
        if (!props.name || !props.description) {
            setError(new Error(`Information about the ${CoreUtils.toLower(props.type)} is missing, please fill.`));
            return;
        } else if (PackType.isApplication(props.type)) {
            const details = props.details as AppletDetails;
            if (!details.categoryType) {
                setError(new Error(`Information about the ${CoreUtils.toLower(props.type)} is missing, please fill.`));
                return;
            }
        }
        formRef.current!.validateFields().then(() => {
            packagePackRequest();
        }).catch(error => {
            // noop - form item will display error
        });
    }

    const handleTextChange = (text: string, index: number): void => {
        const updated = update(changes, {
            [index]: { $set: text }
        });
        setChanges(updated);
    }

    const handleTextRemove = (index: number): void => {
        const updated = update(changes, {
            $splice: [[index, 1]]
        });
        setChanges(updated);
    }

    const handleTextAdd = (): void => {
        const updated = update(changes, {
            $push: [null as any]
        });
        setChanges(updated);
    }

    const executeBeforeCallback = (): Promise<any> => {
        return props.onBefore ? props.onBefore() : Promise.resolve();
    }

    const packagePackRequest = (): Promise<any> => {
        setIsProcessing(true);
        setError(null);
        const request = {
            name: props.name,
            description: props.description,
            changes: changes,
            details: props.details
        }
        return libraryService.packagePack(request,
            (response: any) => packagePackResponse(response),
            (response: any) => packagePackException(response),
            true
        );
    }

    const packagePackResponse = (response: any): void => {
        const header = response.data.header;
        setVersion(header.version);
        setHeader(header);
        setIsProcessing(false);
    }

    const packagePackException = (response: any): void => {
        const error = RestUtils.getError(response)
        setIsProcessing(false);
        setError(error);
    }

    const readPackVersionRequest = (): Promise<any> => {
        const request = {
            packId: props.packId
        }
        return libraryService.readPackVersion(request,
            (response: any) => readPackVersionResponse(response),
            undefined, true
        );
    }

    const readPackVersionResponse = (response: any): void => {
        const version = response.data.version;
        setVersion(version);
    }

    const loadData = (): void => {
        const requests = [];
        requests.push(readPackVersionRequest());
        requests.push(executeBeforeCallback())
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((r1, r2) => {
            if (RestUtils.isOk(r1, r2)) {
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <LoadSkeleton
                direction="vertical"
                status={isLoading ? "loading" : "failed"}
                onRetry={() => handleRetryLoad()}
            >
                <LoadSkeleton.Input length="medium" />
                <LoadSkeleton.Input length="fill" />
                <LoadSkeleton.Input length="short" />
                <LoadSkeleton.Input length="short" />
                <LoadSkeleton.Input length="medium" />
            </LoadSkeleton>
        )
    }

    const buildPackageView = (): ReactElement => {
        return (
            <Form ref={formRef}>
                <Spacer className="x-versionpackager-info" direction="vertical" size="sm">
                    <div>
                        <div>
                            {props.name ?
                                <span className="x-versionpackager-name">{props.name}</span> :
                                <span className="x-versionpackager-error">Please add a name in the properties.</span>
                            }
                        </div>
                        <div>
                            {props.description ?
                                <span className="x-versionpackager-description">{props.description}</span> :
                                <span className="x-versionpackager-error">Please add a description in the properties.</span>
                            }
                        </div>
                    </div>
                    <div>
                        {PackType.isApplication(props.type) &&
                            <>
                                {(props.details as ApplicationDetails).categoryType ?
                                    <Tag color="blue">{CoreUtils.toProper((props.details as ApplicationDetails).categoryType, "_")}</Tag> :
                                    <span className="x-versionpackager-error">Please add a category in the properties.</span>
                                }
                            </>
                        }
                    </div>
                </Spacer>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={formRef}
                    label="Change Messages"
                    name="changes"
                    info="A description of the changes that have been made since the last packaging."
                    rules={[{
                        required: false,
                        validator: () => {
                            if (!changes || changes.length === 0) {
                                //return Promise.reject('Please add at least one change message.');
                                return Promise.resolve();
                            } else {
                                for (let change of changes) {
                                    if (!change) {
                                        return Promise.reject('Please fill empty messages.');
                                    }
                                }
                                return Promise.resolve();
                            }
                        }
                    }]}
                >
                    <Spacer
                        fill
                        direction="vertical"
                        value={changes}
                    >
                        {changes.map((change, index) => (
                            <ChangeEditor
                                key={index}
                                index={index}
                                text={change}
                                onChange={(text, index) => handleTextChange(text, index)}
                                onRemove={(index) => handleTextRemove(index)}
                            />
                        ))}
                        <div className="x-versionpackager-add">
                            <Button onClick={() => handleTextAdd()}>
                                Add Change Message
                            </Button>
                        </div>
                    </Spacer>
                </FormItem>
                <Alert className="x-versionpackager-alert" type="info">
                    Packaging {CoreUtils.toLower(props.type)} version {version + 1}.
                </Alert>
                {!!error &&
                    <div className="x-versionpackager-error">{error.message}</div>
                }
            </Form>
        )
    }

    const buildSuccessView = (): ReactElement => {
        return (
            <Result
                status="success"
                title={`Successfully packaged ${CoreUtils.toLower(props.type)}!`}
                subTitle={`Version ${version} of '${props.name}' has been packaged and can now be released to other environments.`}
            />
        )
    }

    let view;
    if (status === StatusType.LOADING) {
        view = buildLoadingView(true);
    } else if (status === StatusType.FAILED) {
        view = buildLoadingView(false);
    } else if (status === StatusType.READY) {
        view = !header ? buildPackageView() : buildSuccessView();
    }

    return (
        <Modal
            className="x-versionpackager"
            centered
            width={Globals.DIALOG_WIDTH_STANDARD}
            title={`Package ${CoreUtils.toProper(props.type)}`}
            visible={true}
            closable={false}
            footer={(
                <>
                    {!header &&
                        <>
                            <Button
                                disabled={isProcessing}
                                onClick={() => props.onCancel()}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                loading={isProcessing}
                                onClick={() => handlePackagePack()}
                            >
                                Package
                            </Button>
                        </>
                    }
                    {header &&
                        <Button type="primary" onClick={() => props.onPackage(header)}>Close</Button>
                    }
                </>
            )}
        >
            {view}
        </Modal>
    );

}
