import { ReactElement } from 'react';
import classNames from 'classnames';
import './Spacer.less';

export type Size = "none" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
export type Direction = "horizontal" | "vertical";
export type Alignment = "top" | "middle" | "bottom";
export type Justification = "left" | "center" | "right" | "between" | "around";

export type SpacerProps = {
    id?: string,
    className?: string,
    children?: any,
    fill?: boolean,
    size?: Size,
    direction?: Direction,
    alignment?: Alignment,
    justification?: Justification,
    // Optional to allow as child to set a form item.
    // Set the value to the same value as the child.
    value?: any
} & typeof defaultProps;

const defaultProps = {
    fill: false,
    size: "md",
    direction: "horizontal",
    //alignment: "top",
    //alignment: "middle",
    justification: "left"
}

export const Spacer = (props: SpacerProps): ReactElement => {
    // For vertical direction, default to aligning items to the left (i.e., top when flipped).
    const alignment = props.alignment ?? (props.direction === "vertical" ? "top" : "middle");
    return (
        <div
            id={props.id}
            className={classNames(
                "x-spacer",
                `x-spacer-${props.size}`,
                `x-spacer-${props.direction}`,
                `x-spacer-${alignment}`,
                `x-spacer-${props.justification}`,
                {"x-spacer-fill": props.fill},
                props.className
            )}
        >
            {props.children}
        </div>
    )
}

Spacer.defaultProps = defaultProps;
