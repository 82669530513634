import React, { Component, ReactElement } from 'react';
import { Checkbox, Col, FormInstance, Row, Select, Switch } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { Calculator, RefType } from '@methodset/calculator-ts';
import { ChartDataset, PolarWidgetConfiguration, WidgetType } from '@methodset/model-client-ts';
import { RefEditor } from 'containers/Components/Widgets/RefEditor/RefEditor';
import { WidgetUtils } from 'utils/WidgetUtils';
import { Spacer } from 'components/Spacer/Spacer';
import { Info } from 'components/Info/Info';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import update from 'immutability-helper';
import './PolarEditor.less';

export type ChangeCallback = (configuration: PolarWidgetConfiguration) => void;

export type PolarEditorProps = typeof PolarEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    type?: WidgetType,
    defaultConfigurations: ReactElement,
    configuration?: PolarWidgetConfiguration,
    calculator: Calculator,
    onChange: ChangeCallback
}

export class PolarEditor extends Component<PolarEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.PIE_CHART,
        height: 300,
        dataset: {} as ChartDataset
    } as PolarWidgetConfiguration;

    static defaultProps = {
        configuration: PolarEditor.DefaultConfiguration
    }

    private customConfiguration: PolarWidgetConfiguration | undefined;

    constructor(props: PolarEditorProps) {
        super(props);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleHeadersChange = this.handleHeadersChange.bind(this);
        this.handleLayoutChange = this.handleLayoutChange.bind(this);
        this.handleLabelChange = this.handleLabelChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
    }

    private handleRangeChange(rangeId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            dataset: {
                rangeId: { $set: rangeId as any }
            }
        });
        this.props.onChange(configuration);
    }

    private handleHeadersChange(e: CheckboxChangeEvent): void {
        const hasHeaders = e.target.checked;
        const configuration = update(this.props.configuration, {
            dataset: {
                hasHeaders: { $set: hasHeaders }
            }
        });
        this.props.onChange(configuration);
    }

    private handleLayoutChange(e: CheckboxChangeEvent): void {
        const rowLayout = e.target.checked;
        const configuration = update(this.props.configuration, {
            dataset: {
                rowLayout: { $set: rowLayout }
            }
        });
        this.props.onChange(configuration);
    }

    private handleLabelChange(labelRangeId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            dataset: {
                labelRangeId: { $set: labelRangeId! }
            }
        });
        this.props.onChange(configuration);
    }

    private handleValueChange(valueRangeId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            dataset: {
                valueRangeId: { $set: valueRangeId! }
            }
        });
        this.props.onChange(configuration);
    }

    private handleConfigurationDefault(configuration: PolarWidgetConfiguration): void {
        this.customConfiguration = configuration;
    }

    private buildCustomConfigurations(type: WidgetType | undefined): ReactElement | undefined {
        switch (type) {
            default:
                return undefined;
        }
    }

    public componentDidMount(): void {
        if (this.props.configuration === PolarEditor.DefaultConfiguration) {
            let configuration = this.props.configuration;
            if (this.customConfiguration) {
                configuration = update(configuration, {
                    $merge: this.customConfiguration
                });
            }
            if (this.props.type) {
                configuration = update(configuration, {
                    type: { $set: this.props.type }
                });
            }
            this.props.onChange(configuration);
        }
    }

    public shouldComponentUpdate(nextProps: Readonly<PolarEditorProps>): boolean {
        if (nextProps.type && nextProps.type !== this.props.type) {
            const configuration = update(nextProps.configuration, {
                type: { $set: nextProps.type }
            });
            this.props.onChange(configuration);
            return false;
        }
        return true;
    }

    public render(): ReactElement {
        const vectors = WidgetUtils.rangeVectors(this.props.calculator, this.props.configuration.dataset.rangeId, this.props.configuration.dataset.hasHeaders, this.props.configuration.dataset.rowLayout);
        const customConfigurations = this.buildCustomConfigurations(this.props.type);
        return (
            <Row gutter={Globals.FORM_GUTTER_ROW}>
                <Col span={12}>
                    {this.props.defaultConfigurations}
                </Col>
                <Col span={12}>
                    {customConfigurations}
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        required={true}
                        label="Data Range"
                        name="range"
                        info="The range that includes the data that can be added to the dataset."
                    >
                        <RefEditor
                            formRef={this.props.formRef}
                            index={0}
                            calculator={this.props.calculator}
                            refTypes={[RefType.RANGE]}
                            refId={this.props.configuration.dataset.rangeId}
                            onChange={this.handleRangeChange}
                        />
                    </FormItem>
                    {/* <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Includes Headers"
                        name="headers"
                        info="True if the data range has header values in the first row or column. These will be used to help identify the range contents."
                        valuePropName="checked"
                        required={true}
                    >
                        <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={this.props.configuration.dataset.valueHeaders}
                            onChange={this.handleHeadersChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Row Layout"
                        name="layout"
                        info="True if the data is layed out in rows, false if the data is layed out in columns."
                        valuePropName="checked"
                        required={true}
                    >
                        <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={this.props.configuration.dataset.rowLayout}
                            onChange={this.handleLayoutChange}
                        />
                    </FormItem> */}
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        required={true}
                        //noError={true}
                        label="Labels Range"
                        name="labels"
                        info="The range for the labels. Must be vector, i.e., a column or row."
                    >
                        <Select
                            value={this.props.configuration.dataset.labelRangeId}
                            allowClear={true}
                            onChange={this.handleLabelChange}
                        >
                            {vectors.map(vector => (
                                <Select.Option key={vector.id} value={vector.id}>{vector.label}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        required={true}
                        //noError={true}
                        label="Values Range"
                        name="values"
                        info="The range for the values. Must be vector, i.e., a column or row."
                    >
                        <Select
                            value={this.props.configuration.dataset.valueRangeId}
                            allowClear={true}
                            onChange={this.handleValueChange}
                        >
                            {vectors.map(vector => (
                                <Select.Option key={vector.id} value={vector.id}>{vector.label}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <Spacer className="x-polareditor-checkbox" direction="vertical">
                        <Checkbox
                            checked={this.props.configuration.dataset.hasHeaders}
                            onChange={this.handleHeadersChange}
                        >
                            <Info
                                label="Range Includes Headers"
                                info="True if the data range has header values in the first row or column."
                                bold={true}
                            />
                        </Checkbox>
                        <Checkbox
                            checked={this.props.configuration.dataset.rowLayout}
                            onChange={this.handleLayoutChange}
                        >
                            <Info
                                label="Range Layout as Rows"
                                info="True if the data is layed out in rows, false if the data is layed out in columns."
                                bold={true}
                            />
                        </Checkbox>
                    </Spacer>
                </Col>
            </Row>
        );
    }

}
