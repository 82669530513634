import React, { ChangeEvent, KeyboardEvent, Component, ReactElement } from 'react';
import { Input } from 'antd';
import classNames from 'classnames';
import './TextInput.less';

export type ChangeCallback = (value: string | undefined) => void;
export type FilterFunction = (value: any) => any;

export type TextInputState = {
    isEditing: boolean,
    value: any
}

export type TextInputProps = {
    className?: string,
    bodyClassName?: string,
    defaultValue?: string | number,
    value?: string | number,
    formattedValue?: string,
    textClassName?: string,
    placeholder?: string,
    disabled?: boolean,
    rows?: number,
    fill?: boolean,
    filter?: FilterFunction,
    onChange: ChangeCallback
}

export class TextInput extends Component<TextInputProps, TextInputState> {

    static defaultProps = {
        disabled: false
    }

    private inputRef = React.createRef<Input>();

    constructor(props: TextInputProps) {
        super(props);
        this.state = {
            isEditing: false,
            value: this.props.value
        }
        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPush = this.handleKeyPush.bind(this);
    }

    private handleBlur(): void {
        this.props.onChange(this.state.value);
        this.setState({ isEditing: false });
    }

    private handleFocus(): void {
        this.setState({ isEditing: true });
    }

    private handleChange(e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>): void {
        let value = e.target.value;
        if (this.props.filter) {
            value = this.props.filter(value);
        }
        if (value.length === 0) {
            value = undefined as any;
        }
        this.setState({ value: value });
    }

    private handleKeyPush(e: KeyboardEvent<HTMLInputElement> | KeyboardEvent<HTMLTextAreaElement>): void {
        if (e.key === 'Escape') {
            // Reset to the original value.
            this.setState({ value: this.props.value });
            this.inputRef.current!.blur();
        } else if (e.key === 'Enter' || e.key === 'Tab') {
            // Value is accepted, save for reset.
            const isChanged = this.props.value !== this.state.value;
            if (isChanged) {
                this.props.onChange(this.state.value);
            }
            this.inputRef.current!.blur();
        }
    }

    public shouldComponentUpdate(nextProps: TextInputProps): boolean {
        if (nextProps.value !== this.props.value) {
            this.setState({value: nextProps.value});
            return false;
        } else {
            return true;
        }
    }

    public render(): ReactElement {
        const value = this.state.isEditing ? this.state.value : (this.props.formattedValue ? this.props.formattedValue : this.state.value);
        //console.log(`Render text input: ${value}`);
        return (
            <div className={classNames("x-textinput", this.props.className)}>
                {!this.props.rows &&
                    <Input
                        ref={this.inputRef}
                        className={this.props.bodyClassName}
                        disabled={this.props.disabled}
                        defaultValue={this.props.defaultValue}
                        value={value}
                        placeholder={this.props.placeholder}
                        onKeyDown={this.handleKeyPush}
                        onBlur={this.handleBlur}
                        onFocus={this.handleFocus}
                        onChange={this.handleChange}
                    />
                }
                {this.props.rows &&
                    <Input.TextArea
                        ref={this.inputRef}
                        className={this.props.className}
                        disabled={this.props.disabled}
                        rows={this.props.rows}
                        defaultValue={this.props.defaultValue}
                        value={value}
                        placeholder={this.props.placeholder}
                        onKeyDown={this.handleKeyPush}
                        onBlur={this.handleBlur}
                        onFocus={this.handleFocus}
                        onChange={this.handleChange}
                    />
                }
            </div>
        );
    }

}
