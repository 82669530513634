import { Calculator, DataType, Variable } from '@methodset/calculator-ts';
import { ReactElement, useEffect, useState } from 'react';
import { Justify } from 'components/Justify/Justify';
import { Button, Popover, Select, Switch } from 'antd';
import { DecimalInput } from 'components/DecimalInput/DecimalInput';
import { DateTimeSelector, DateTimeSelectorUtils } from 'components/DateTimeSelector/DateTimeSelector';
import { CoreUtils } from '@methodset/commons-shared-ts';
import { TextInput } from 'components/TextInput/TextInput';
import { Spacer } from 'components/Spacer/Spacer';
import { CloseCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import './InputEditor.less';

export type ChangeCallback = (value: any, variable: Variable) => void;

export type InputEditorProps = {
    className?: string,
    calculator: Calculator,
    value: any,
    variableId: string,
    doEmbed?: boolean,
    useDefault?: boolean,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    useDefault: true
}

export const InputEditor = (props: InputEditorProps): ReactElement => {

    const [isEditing, setIsEditing] = useState<boolean>(false);

    const handleChange = (variable: Variable, value: any): void => {
        // Need to differentiate between the user clearing a value,
        // which will override any default value, or the user accepting
        // the default cell value. In both cases, the value in the 
        // configuration will be undefined. If the user explicitly 
        // cleared a value, it should overwrite the default value.
        if (value === undefined) {
            // The value was cleared. Set it to null to 
            // indicate as such, as opposed to undefined
            // which means that the default is being used.
            value = null;
        }
        props.onChange(value, variable);
    }

    const buildView = (): ReactElement => {
        const variable = props.calculator.variables.get(props.variableId, false);
        if (!variable) {
            return (
                <Spacer size="sm">
                    <CloseCircleOutlined className="x-inputeditor-error" />
                    {`Variable ${props.variableId} does not exist.`}
                </Spacer>
            );
        }
        if (variable.options) {
            // Option values are stored as strings.
            // TODO: enable typed inputs for option values
            const value = !CoreUtils.isEmpty(props.value) ? props.value.toString() : props.value;
            return (
                <Select
                    className={classNames("x-inputeditor-fill", props.className)}
                    allowClear={true}
                    value={value}
                    onChange={(value) => handleChange(variable, value)}
                >
                    {variable.options.map(option => (
                        <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                    ))}
                </Select>
            )
        }
        switch (variable.type) {
            case DataType.TEXT: {
                return (
                    <TextInput
                        className={props.className}
                        value={props.value}
                        onChange={(value) => handleChange(variable, value)}
                    />
                )
            }
            case DataType.NUMBER: {
                return (
                    <DecimalInput
                        className={props.className}
                        fill={true}
                        value={props.value}
                        onChange={(value) => handleChange(variable, value)}
                    />
                )
            }
            case DataType.BOOLEAN: {
                return (
                    <Switch
                        className={props.className}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        checked={props.value}
                        onChange={(value) => handleChange(variable, value)}
                    />
                )
            }
            case DataType.TIME:
            case DataType.DATE: {
                if (props.doEmbed) {
                    return (
                        <DateTimeSelector
                            className={props.className}
                            value={props.value}
                            syntax="formula"
                            showTime={variable.type === DataType.TIME}
                            onChange={(value) => handleChange(variable, value)}
                        />
                    )
                } else {
                    const selector = (
                        <Spacer direction="vertical" size="lg" alignment="top">
                            <DateTimeSelector
                                className={classNames("x-inputeditor-popup", props.className)}
                                value={props.value}
                                syntax="formula"
                                showTime={variable.type === DataType.TIME}
                                onChange={(value) => handleChange(variable, value)}
                            />
                            <Justify justification="right">
                                <Button type="primary" onClick={() => setIsEditing(false)}>Close</Button>
                            </Justify>
                        </Spacer>
                    )
                    return (
                        <Popover
                            content={selector}
                            trigger="click"
                            placement="bottomLeft"
                            visible={isEditing}
                            onVisibleChange={(visible) => setIsEditing(visible)}
                        >
                            <span className="x-inputeditor-date">
                                {DateTimeSelectorUtils.toString(props.value, "formula")}
                            </span>
                        </Popover>
                    )
                }
            }
            default: {
                return (
                    <TextInput
                        className={props.className}
                        value={props.value}
                        onChange={(value) => handleChange(variable, value)}
                    />
                )
            }
        }
    }

    return buildView();

}

InputEditor.defaultProps = defaultProps;
