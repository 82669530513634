import { MouseEvent, ReactElement, useEffect } from 'react';
import { List, Tag } from 'antd';
import { Calculator, Range } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { Configuration, WatchlistWidgetConfiguration } from '@methodset/model-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { ValueDisplay, ValueType } from 'components/ValueDisplay/ValueDisplay';
import { ConfigurationCallback } from '../WidgetViewer/WidgetViewer';
import { CoreUtils } from 'utils/CoreUtils';
import update from 'immutability-helper';
import './WatchlistWidgetViewer.less';

export type WatchlistWidgetViewerProps = {
    calculator: Calculator,
    configuration: WatchlistWidgetConfiguration,
    appletConfiguration: Configuration,
    onUpdate: ConfigurationCallback
} & typeof defaultProps;

const defaultProps = {
    appletConfiguration: {} as Configuration
}

export const WatchlistWidgetViewer = (props: WatchlistWidgetViewerProps): ReactElement => {

    useEffect(() => {
        const values = getLinkValues();
        if (values && values.length > 0) {
            handleLinkClicked(values[0][0]);
        }
    }, []);

    const handleLinkClicked = (value: any): void => {
        const variableId = props.configuration.rowLink?.variableId;
        if (CoreUtils.isEmpty(variableId)) {
            return;
        }
        const variables = props.calculator.variables;
        const variable = variables.get(variableId!, false);
        if (variable && variable.cell) {
            variable.cell.value = value;
        }
        let configuration;
        if (CoreUtils.isEmpty(value)) {
            configuration = update(props.appletConfiguration, {
                $unset: [variableId!]
            });
        } else {
            configuration = update(props.appletConfiguration, {
                [variableId!]: { $set: value }
            });
        }
        props.onUpdate(configuration);
    }

    const handleRowClick = (linkValues: any[][], index: number): void => {
        if (index < 0 || index >= linkValues.length) {
            return;
        }
        const value = linkValues[index][0];
        handleLinkClicked(value);
    }

    const buildRow = (item: any[], index: number, linkValues?: any[][]): ReactElement => {
        let col = 0;
        const name = !CoreUtils.isEmpty(props.configuration.nameRangeId) ? item[col++] : undefined;
        const symbol = !CoreUtils.isEmpty(props.configuration.symbolRangeId) ? item[col++] : undefined;
        const value = !CoreUtils.isEmpty(props.configuration.valueRangeId) ? item[col++] : undefined;
        const change = !CoreUtils.isEmpty(props.configuration.changeRangeId) ? item[col++] : undefined;
        const time = !CoreUtils.isEmpty(props.configuration.timeRangeId) ? item[col++] : undefined;

        // Add a row click handler if there is a row link.
        let clickProps;
        if (props.configuration.rowLink) {
            clickProps = {
                style: { cursor: "pointer" },
                onClick: (e: MouseEvent<HTMLDivElement>): void => {
                    handleRowClick(linkValues!, index!);
                }
            }
        }
        const displayType = CoreUtils.toLower(props.configuration.changeType) as ValueType;
        return (
            <List.Item className="x-watchlistwidgetviewer" {...clickProps}>
                <div>
                    <div className="x-watchlistwidgetviewer-name">
                        {name}
                    </div>
                    {symbol &&
                        <div className="x-watchlistwidgetviewer-symbol">
                            <Tag color={Globals.TAG_COLORS[index % Globals.TAG_COLORS.length]}>
                                {symbol}
                            </Tag>
                        </div>
                    }
                </div>
                <div>
                    <div>
                        <Spacer>
                            {!CoreUtils.isEmpty(value) &&
                                <div className="x-watchlistwidgetviewer-value">
                                    {value}
                                </div>
                            }
                            {!CoreUtils.isEmpty(change) &&
                                <ValueDisplay type={displayType} value={change} />
                            }
                        </Spacer>
                        {time &&
                            <div className="x-watchlistwidgetviewer-time">
                                {time}
                            </div>
                        }
                    </div>
                </div>
            </List.Item>
        )
    }

    const findMaxSize = (...ranges: (Range | undefined)[]): number => {
        return ranges.reduce((max: number, range: Range | undefined) => {
            return !range ? max : Math.max(max, range.size);
        }, 0);
    }

    const buildRange = (rangeId: string | undefined): Range | undefined => {
        try {
            return rangeId ? Range.fromUid(props.calculator, rangeId) : undefined;
        } catch (e) {
            return undefined;
        }
    }

    const getDataValues = (): any[] => {
        const matrix: any[] = [];
        const nameRange = buildRange(props.configuration.nameRangeId);
        const symbolRange = buildRange(props.configuration.symbolRangeId);
        const valueRange = buildRange(props.configuration.valueRangeId);
        const changeRange = buildRange(props.configuration.changeRangeId);
        const timeRange = buildRange(props.configuration.timeRangeId);
        const columns = [];
        if (nameRange) {
            columns.push(nameRange.values(true));
        }
        if (symbolRange) {
            columns.push(symbolRange.values(true));
        }
        if (valueRange) {
            columns.push(valueRange.values(true));
        }
        if (changeRange) {
            columns.push(changeRange.values(true));
        }
        if (timeRange) {
            columns.push(timeRange.values(true));
        }
        const length = findMaxSize(nameRange, symbolRange, valueRange, changeRange, timeRange);
        for (let r = 0; r < length; r++) {
            const row: any[] = [];
            for (let c = 0; c < columns.length; c++) {
                let column = columns[c];
                const subrow = column[r];
                const value = subrow ? subrow[0] : undefined;
                row.push(value);
            }
            matrix.push(row);
        }
        return matrix;
    }

    const getLinkValues = (): any[][] | undefined => {
        const rowLink = props.configuration.rowLink;
        if (rowLink && rowLink.rangeId) {
            const range = Range.fromUid(props.calculator, rowLink.rangeId);
            return range ? range.values(false) : undefined;
        } else {
            return undefined;
        }
    }

    const buildView = (): ReactElement => {
        let dataValues: any[][] | undefined;
        let linkValues: any[][] | undefined;
        try {
            dataValues = getDataValues();
            linkValues = getLinkValues();
        } catch (e) {
            // Invalid data sheet or range - skip.
            return <>Error</>;
        }
        return (
            <List
                className="x-watchlistwidgetviewer-list"
                size="default"
                dataSource={dataValues}
                renderItem={(row, index) => buildRow(row, index, linkValues)}
            />
        );
    }

    return (
        <div className="x-watchlistwidgetviewer">
            {buildView()}
        </div>
    );
}

WatchlistWidgetViewer.defaultProps = defaultProps;
