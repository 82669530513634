import { ReactElement, useState } from 'react';
import { Button, Checkbox, Modal, Space, Table } from 'antd';
import { Data, Dataset, IoType, Records } from '@methodset/endpoint-client-ts';
import { ColumnsType } from 'antd/lib/table';
import { CoreUtils } from 'utils/CoreUtils';
import { Globals } from 'constants/Globals';
import { Spacer } from 'components/Spacer/Spacer';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import './DataPreview.less';

export type CloseCallback = () => void;

export type DataPreviewProps = {
    data: Data,
    onClose: CloseCallback
}

export const DataPreview = (props: DataPreviewProps): ReactElement => {

    const [showNull, setShowNull] = useState<any>(true);

    const buildRecords = (data: Data): ReactElement => {
        const records = data.value as Records;
        try {
            return buildTable(buildRecordsColumns(records), buildRecordsData(records));
        } catch (e) {
            return buildGeneric(data);
        }
    }

    const handleNullChange = (e: CheckboxChangeEvent): void => {
        setShowNull(e.target.checked);
    }

    const buildRecordsColumns = (records: Records): ColumnsType<any> => {
        const columns = [];
        const entries = Object.entries(records.keys);
        let c = 0;
        for (const [key, value] of entries) {
            const column = {
                key: c,
                title: key,
                dataIndex: c,
                ellipsis: true,
                width: 200
            };
            columns.push(column);
            c++;
        }
        return columns;
    }

    const buildRecordsData = (records: Records): any[] => {
        const data: any[] = [];
        const entries = Object.entries(records.keys);
        const rows = records.rows;
        let numRows = rows.length;
        for (let r = 0; r < numRows; r++) {
            const row: any = { key: r };
            let c = 0;
            for (const [key, index] of entries) {
                let value = rows[r][index];
                if (isBoolean(value)) {
                    value = value ? "true" : "false";
                } else if (!isNative(value)) {
                    value = JSON.stringify(value);
                }
                row[c] = value === null && showNull ? "null" : value;
                c++;
            }
            data.push(row);
        }
        return data;
    }

    const buildDataset = (data: Data): ReactElement => {
        const dataset = data.value as Dataset;
        try {
            return buildTable(buildDatasetColumns(dataset), buildDatasetData(dataset));
        } catch (e) {
            return buildGeneric(data);
        }
    }

    const buildDatasetColumns = (dataset: Dataset): ColumnsType<any> => {
        const columns = [];
        for (let c = 0; c < dataset.headers.length; c++) {
            const header = dataset.headers[c];
            const key = header.key;
            const column = {
                key: c,
                title: header.name,
                dataIndex: c,
                ellipsis: true,
                width: 200
            };
            columns.push(column);
        }
        return columns;
    }

    const buildDatasetData = (dataset: Dataset): any[] => {
        const rows: any[] = [];
        const matrix = dataset.matrix;
        const headers = dataset.headers;
        let numRows = matrix.length;
        let numCols = headers.length;
        for (let r = 0; r < numRows; r++) {
            const row: any = { key: r };
            for (let c = 0; c < numCols; c++) {
                let value = matrix[r][c];
                if (isBoolean(value)) {
                    value = value ? "true" : "false";
                } else if (!isNative(value)) {
                    value = JSON.stringify(value);
                }
                row[c] = value === null && showNull ? "null" : value;
            }
            rows.push(row);
        }
        return rows;
    }

    const buildTable = (columns: ColumnsType<any>, rows: any[]): ReactElement => {
        return (
            <Table
                bordered
                pagination={false}
                scroll={{ x: "max-content", y: Globals.DIALOG_HEIGHT_MAX }}
                size="small"
                columns={columns}
                dataSource={rows}
            />
        );
    }

    const buildGeneric = (data: Data): ReactElement => {
        let content = data.value
        if (data.type === IoType.TEXT && content && (content.startsWith("{") || content.startsWith("["))) {
            content = JSON.stringify(content);
        }
        return (
            <div><pre>{content}</pre></div>
        );
    }

    const isNative = (value: any): boolean => {
        return CoreUtils.isEmpty(value) || CoreUtils.isString(value) || CoreUtils.isNumber(value) || CoreUtils.isBoolean(value);
    }

    const isBoolean = (value: any): boolean => {
        return CoreUtils.isBoolean(value);
    }

    const isGrid = (): boolean => {
        return props.data.type === IoType.RECORDS || props.data.type === IoType.DATASET;
    }

    return (
        <Modal
            className="x-datapreview"
            centered
            title="Data Preview"
            visible={true}
            onCancel={props.onClose}
            footer={(
                <Spacer justification={isGrid() ? "between" : "right"}>
                    {isGrid() &&
                        <Checkbox
                            checked={showNull}
                            onChange={handleNullChange}
                        >
                            Show null values
                        </Checkbox>
                    }
                    <Button type="primary" onClick={props.onClose}>Close</Button>
                </Spacer>
            )}
        >
            <div>
                {props.data.type === IoType.RECORDS &&
                    buildRecords(props.data)
                }
                {props.data.type === IoType.DATASET &&
                    buildDataset(props.data)
                }
                {props.data.type !== IoType.RECORDS && props.data.type !== IoType.DATASET &&
                    buildGeneric(props.data)
                }
            </div>
        </Modal>
    );

}
