import { Calculator } from '@methodset/calculator-ts';
import { ReactElement, useEffect } from 'react';
import { TextWidgetConfiguration } from '@methodset/model-client-ts';
import { WidgetUtils } from 'utils/WidgetUtils';
import { Justification, Justify } from 'components/Justify/Justify';
import { CoreUtils } from 'utils/CoreUtils';
import classNames from 'classnames';
import './TextWidgetViewer.less';
import { Link } from 'react-router-dom';

export type TextWidgetViewerProps = {
    calculator: Calculator,
    configuration: TextWidgetConfiguration
};

export const TextWidgetViewer = (props: TextWidgetViewerProps): ReactElement => {

    useEffect(() => {
        // Set the variable to the value on startup.
        if (props.configuration.valueLink) {
            handleLinkClicked();
        }
    }, []);

    const handleLinkClicked = (): void => {
        const value = WidgetUtils.findValue(props.calculator, props.configuration.valueLink!.valueId);
        const variableId = props.configuration.valueLink!.variableId;
        const variables = props.calculator.variables;
        const variable = variables.get(variableId, false);
        if (variable && variable.cell) {
            variable.cell.value = value;
            return;
        }
    }

    const textView = (text: string): ReactElement => {
        return <div dangerouslySetInnerHTML={{ __html: text }} />;
    }

    const text = WidgetUtils.replaceCellRefs(props.calculator, props.configuration.text);
    return (
        <Justify
            className={classNames(
                "x-textwidgetviewer",
                { [`x-widgetviewer-size-${CoreUtils.toLower(props.configuration.size)}`]: !!props.configuration.size },
                { [`x-widgetviewer-color-${CoreUtils.toLower(props.configuration.color)}`]: !!props.configuration.color },
                { [`x-widgetviewer-weight-${CoreUtils.toLower(props.configuration.weight)}`]: !!props.configuration.weight }
            )}
            justification={
                props.configuration.justification ?
                    props.configuration.justification.toLowerCase() as Justification :
                    "left"
            }
        >
            {props.configuration.valueLink &&
                <Link to="#" onClick={() => handleLinkClicked()}>
                    {textView(text)}
                </Link>
            }
            {!props.configuration.valueLink &&
                textView(text)
            }
        </Justify>
    );
}
