import { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Button, Input } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { DeleteOutlined } from '@ant-design/icons';
import { Applet, MenuItem } from '@methodset/model-client-ts';
import { PanelSelector } from 'containers/Components/Widgets/Selectors/AppletSelector';
import update from 'immutability-helper';
import './MenuItemEditor.less';

export type ChangeCallback = (item: MenuItem, index: number) => void;
export type RemoveCallback = (index: number) => void;

export type MenuItemProps = {
    index: number,
    applet: Applet,
    item: MenuItem,
    onChange: ChangeCallback,
    onRemove: RemoveCallback
}

export class MenuItemEditor extends PureComponent<MenuItemProps> {

    constructor(props: MenuItemProps) {
        super(props);
        this.handleLabelChange = this.handleLabelChange.bind(this);
        this.handlePanelChange = this.handlePanelChange.bind(this);
        this.handleItemRemove = this.handleItemRemove.bind(this);
    }

    private handleLabelChange(e: ChangeEvent<HTMLInputElement>): void {
        const label = e.target.value;
        const item = update(this.props.item, {
            label: { $set: label }
        });
        this.props.onChange(item, this.props.index);
    }

    private handlePanelChange(panelId: string): void {
        const item = update(this.props.item, {
            panelId: { $set: panelId }
        });
        this.props.onChange(item, this.props.index);
    }

    private handleItemRemove(): void {
        this.props.onRemove(this.props.index);
    }

    public render(): ReactElement {
        return (
            <Spacer className="x-menuitemeditor" fill>
                <Input
                    className="x-menuitemeditor-label"
                    placeholder="Item label."
                    value={this.props.item.label}
                    onChange={this.handleLabelChange}
                />
                <PanelSelector
                    className="x-menuitemeditor-panel"
                    applet={this.props.applet}
                    panelId={this.props.item.panelId}
                    onChange={this.handlePanelChange}
                />
                <Button
                    className="x-menuitemeditor-delete"
                    icon={<DeleteOutlined />}
                    onClick={this.handleItemRemove}
                >
                </Button>
            </Spacer>
        );
    }

}
