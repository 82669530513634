import { ReactElement } from 'react';
import { Select } from 'antd';
import { SpacingType } from '@methodset/model-client-ts';
import classNames from 'classnames';

export type ChangeCallback = (value: SpacingType) => void;

export type SpacingSelectorProps = {
    className?: string,
    value?: SpacingType,
    onChange: ChangeCallback
};

export const SpacingSelector = (props: SpacingSelectorProps): ReactElement => {
    return (
        <Select className={classNames("x-spacingselector", props.className)}
            allowClear={true}
            placeholder="Select a spacing."
            value={props.value}
            onChange={(value) => props.onChange(value)}
        >
            <Select.Option key="none" value={SpacingType.NONE}>None</Select.Option>
            <Select.Option key="xxs" value={SpacingType.XXS}>XX-Small</Select.Option>
            <Select.Option key="xs" value={SpacingType.XS}>X-Small</Select.Option>
            <Select.Option key="sm" value={SpacingType.SM}>Small</Select.Option>
            <Select.Option key="md" value={SpacingType.MED}>Medium</Select.Option>
            <Select.Option key="lg" value={SpacingType.LG}>Large</Select.Option>
            <Select.Option key="xl" value={SpacingType.XL}>X-Large</Select.Option>
            <Select.Option key="xxl" value={SpacingType.XXL}>XX-Large</Select.Option>
        </Select>
    )
}
