import { ReactElement, useState } from 'react';
import { Calculator, DataConverter, DataType, Range, Vector } from '@methodset/calculator-ts';
import { FilterWidgetConfiguration } from '@methodset/model-client-ts';
import { ConfigurationCallback } from '../WidgetViewer/WidgetViewer';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import update from 'immutability-helper';
import './FilterWidgetViewer.less';

type Item = {
    label: string,
    value: any,
    checked: boolean
}

export type FilterWidgetViewerProps = {
    calculator: Calculator,
    configuration: FilterWidgetConfiguration,
    onUpdate: ConfigurationCallback
}

export const FilterWidgetViewer = (props: FilterWidgetViewerProps): ReactElement => {

    const buildItems = (): Item[] => {
        const set = new Set<string>();
        const param = props.calculator.parameters.get(props.configuration.variableId);
        if (param && param.value) {
            const value = DataConverter.convert(DataType.TEXT, param.value) as string;
            const values = value.split(props.configuration.delimiter);
            values.forEach(value => set.add(value));
        }
        const labelRange = Range.fromUid(props.calculator, props.configuration.labelRangeId);
        const valueRange = Range.fromUid(props.calculator, props.configuration.valueRangeId);
        const labelVector = Vector.fromRange(labelRange, true);
        const valueVector = Vector.fromRange(valueRange, true);
        const labels = labelVector.values();
        const values = valueVector.values();
        const items = [];
        const start = props.configuration.hasHeaders ? 1 : 0;
        for (let i = start; i < values.length; i++) {
            const value = DataConverter.convert(DataType.TEXT, values[i]);
            items.push({
                label: labels[i],
                value: value,
                checked: set.has(value)
            });
        }
        return items;
    }

    const [items, setItems] = useState<Item[]>(() => buildItems());

    const handleSelectChange = (e: CheckboxChangeEvent, index: number): void => {
        const isChecked = e.target.checked;
        const updated = update(items, {
            [index]: {
                checked: { $set: isChecked }
            }
        });
        setItems(updated);
        updateParameter(updated);
    }

    const updateParameter = (items: Item[]): void => {
        const param = props.calculator.parameters.get(props.configuration.variableId);
        if (param) {
            const list = [];
            for (let i = 0; i < items.length; i++) {
                if (items[i].checked) {
                    list.push(items[i].value);
                }
            }
            param.value = list.join(";");
        }
    }

    const buildView = (): ReactElement => {
        return (
            <div className="x-filterwidgetviewer-list" style={{height: props.configuration.height}}>
                {items.map((item, index) => (
                    <div className="x-filterwidgetviewer-item">
                        <Checkbox
                            checked={item.checked}
                            onChange={(e) => handleSelectChange(e, index)}
                        >
                            {item.label}
                        </Checkbox>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className="x-filterwidgetviewer">
            {buildView()}
        </div>
    );
}
