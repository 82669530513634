import { ReferenceParser } from "@methodset/calculator-ts";
import { InputFlowWidgetConfiguration, Widget } from '@methodset/model-client-ts';

export class InputFlowWidgetSync {

    public static parser = (widget: Widget): string[] => {
        const configuration = widget.configuration as InputFlowWidgetConfiguration;
        const refs: string[] = [];
        refs.push(...ReferenceParser.extract(configuration.hideId, true));
        if (configuration.variableIds) {
            for (let i = 0; i < configuration.variableIds.length; i++) {
                refs.push(...ReferenceParser.extract(configuration.variableIds[i], true));
            }
        }
        return refs;
    }

    public static updater = (widget: Widget, target: string, replacement: string): void => {
        const configuration = widget.configuration as InputFlowWidgetConfiguration;
        configuration.hideId = ReferenceParser.update(configuration.hideId, target, replacement, true);
        if (configuration.variableIds) {
            for (let i = 0; i < configuration.variableIds.length; i++) {
                configuration.variableIds[i] = ReferenceParser.update(configuration.variableIds[i], target, replacement, true);
            }
        }
    }

}
