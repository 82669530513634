import { Calculator } from '@methodset/calculator-ts';
import { ReactElement } from 'react';
import { Applet, AppletPanel, SwitchOption, SwitchWidgetConfiguration } from '@methodset/model-client-ts';
import { CoreUtils } from 'utils/CoreUtils';
import { AppletViewer } from 'containers/Console/Dashboards/DashboardItem/AppletViewer/AppletViewer';
import { Configuration } from '@methodset/endpoint-client-ts';
import { ConfigurationCallback } from '../WidgetViewer/WidgetViewer';
import './SwitchWidgetViewer.less';

export type SwitchWidgetViewerProps = {
    calculator: Calculator,
    configuration: SwitchWidgetConfiguration,
    // modelId: string,
    // version?: number,
    applet: Applet,
    appletConfiguration: Configuration,
    onUpdate: ConfigurationCallback
};

export const SwitchWidgetViewer = (props: SwitchWidgetViewerProps): ReactElement => {

    const variableValue = (): any => {
        const variables = props.calculator.variables;
        const variable = variables.get(props.configuration.variableId, false);
        if (!variable) {
            return undefined;
        }
        const value = variable.cell?.value;
        return !CoreUtils.isEmpty(value) ? value.toString() : undefined;
    }

    const activeOption = (): SwitchOption | undefined => {
        let value = variableValue();
        if (CoreUtils.isEmpty(value)) {
            return undefined;
        }
        return props.configuration.options.find(option => option.value === value);
    }

    const findPanel = (panelId: string | undefined): AppletPanel | undefined => {
        if (!panelId) {
            return undefined;
        }
        return props.applet.panels.find(panel => panel.id === panelId);
    }

    const buildAppletsView = (): ReactElement => {
        const option = activeOption();
        const panel = findPanel(option?.panelId);
        if (option && panel) {
            // const appletRef = {
            //     appletId: props.applet.id,
            //     modelId: props.modelId,
            //     version: props.version
            // }
            return (
                <AppletViewer
                    key={props.applet.id}
                    // appletRef={appletRef}
                    applet={props.applet}
                    panel={panel}
                    calculator={props.calculator}
                    configuration={props.appletConfiguration}
                    isEmbedded={true}
                    onChange={props.onUpdate}
                />
            )
        } else {
            const value = variableValue();
            if (CoreUtils.isEmpty(value)) {
                return (
                    <div>{`ERROR: Applet variable '${props.configuration.variableId}' does not exist.`}</div>
                )
            } else {
                return (
                    <div>{`ERROR: Panel mapping does not exist for variable value '${value}'.`}</div>
                )
            }
        }
    }

    return buildAppletsView();

}
