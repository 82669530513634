import { ReactElement, useContext, useEffect, useState } from 'react';
import { Button, Col, Empty, Modal, Row, Select } from 'antd';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { CategoryType } from '@methodset/model-client-ts';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { PackItem } from './PackItem/PackItem';
import { CoreUtils } from 'utils/CoreUtils';
import { AppletDetails, PackHeader, PackType } from '@methodset/library-client-ts';
import { Dashboard } from '@methodset/dashboard-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { Label } from 'components/Label/Label';
import { StatusType } from 'constants/StatusType';
import { EntityContext } from 'context/EntityContext';
import { AccessType } from '@methodset/commons-shared-ts';
import libraryService from 'services/LibraryService';
import axios from 'axios';
import './AppletPacks.less';
import { NoData } from 'components/NoData/NoData';

export type InstallCallback = (header: PackHeader) => void;
export type CloseCallback = () => void;

const ALL = "ALL" as CategoryType;
const COLUMN_COUNT = 2;

export type AppletPacksProps = {
    dashboard: Dashboard,
    // Allow to keep installing if modal is dismissed.
    //isVisible: boolean,
    onInstall: InstallCallback,
    onClose: CloseCallback
}

export const AppletPacks = (props: AppletPacksProps): ReactElement => {

    //const context = useContext(EntityContext);

    const [status, setStatus] = useState<string>(StatusType.INIT);
    const [count, setCount] = useState<number>(0);
    const [categoryType, setCategoryType] = useState<CategoryType>(ALL);
    const [accessType, setAccessType] = useState<AccessType>(AccessType.GROUP);
    const [packHeaders, setPackHeaders] = useState<PackHeader[]>([]);

    useEffect(() => {
        if (status !== StatusType.READY) {
            loadData(accessType);
        }
    }, []);

    const handleRetryLoad = (): void => {
        loadData(accessType);
    }

    const handleCategoryChange = (categoryType: CategoryType): void => {
        setCategoryType(categoryType);
    }

    const handleCatalogChange = (accessType: AccessType): void => {
        setAccessType(accessType);
        loadData(accessType);
    }

    const handleInstalling = (): void => {
        setCount(count + 1);
    }

    const handleInstalled = (packHeader: PackHeader): void => {
        props.onInstall(packHeader);
        setCount(count - 1);
    }

    const handleError = (): void => {
        setCount(count - 1);
    }

    const readPackHeadersRequest = (accessType: AccessType): Promise<any> => {
        const request = {
            packType: PackType.APPLET,
            accessType: accessType
        }
        return libraryService.readPackHeaders(request,
            (response: any) => readPackHeadersResponse(response),
            undefined, true
        );
    }

    const readPackHeadersResponse = (response: any): void => {
        const headers = response.data.headers;
        setPackHeaders(headers);
    }

    const loadData = (accessType: AccessType): void => {
        const requests = [];
        requests.push(readPackHeadersRequest(accessType));
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        const indices = Array.from(Array(COLUMN_COUNT * 2).keys());
        return (
            <Row gutter={[Globals.APPLET_GUTTER_COL, Globals.APPLET_GUTTER_ROW]}>
                {indices.map(index => (
                    <Col
                        key={index}
                        md={{ span: 24 / COLUMN_COUNT }}
                    >
                        <div className="x-appletpacks-item">
                            <LoadSkeleton
                                direction="vertical"
                                status={isLoading ? "loading" : "failed"}
                                onRetry={() => handleRetryLoad()}
                            >
                                <LoadSkeleton.Input length="medium" />
                                <LoadSkeleton.Input length="fill" />
                                <LoadSkeleton.Input length="short" />
                                <LoadSkeleton.Input length="short" />
                            </LoadSkeleton>
                        </div>
                    </Col>
                ))}
            </Row>
        );
    }

    const buildAppletsView = (): ReactElement => {
        const headers = packHeaders.filter(header => {
            const details = header.details as AppletDetails;
            return categoryType === ALL || categoryType === details.categoryType;
        });
        return (
            <>
                <Spacer className="x-appletpacks-select" fill>
                    <Label className="x-appletpacks-category" placement="top" label="Category">
                        <Select
                            className="x-appletpacks-fill"
                            value={categoryType}
                            onChange={(categoryType) => handleCategoryChange(categoryType)}
                        >
                            <Select.Option value={ALL}>All</Select.Option>
                            {CoreUtils.enumToKeys(CategoryType).map(type => (
                                <Select.Option key={type} value={type}>{CoreUtils.toProper(type, "_")}</Select.Option>
                            ))}
                        </Select>
                    </Label>
                    <Label className="x-appletpacks-access" placement="top" label="Catalog">
                        <Select
                            className="x-appletpacks-fill"
                            value={accessType}
                            onChange={(accessType) => handleCatalogChange(accessType)}
                        >
                            {AccessType.array().map(type => (
                                <Select.Option key={type} value={type}>{CoreUtils.toProper(type, "_")}</Select.Option>
                            ))}
                        </Select>
                    </Label>
                </Spacer>
                {headers.length === 0 &&
                    <div className="x-appletpacks-empty">
                        <NoData text="No applets available." />
                    </div>
                }
                {headers.length > 0 &&
                    <Row className="x-appletpacks-applets" gutter={[Globals.APPLET_GUTTER_COL, Globals.APPLET_GUTTER_ROW]}>
                        {headers.map((header, index) => (
                            <Col
                                key={header.id}
                                lg={{ span: 24 / COLUMN_COUNT }}
                            >
                                <PackItem
                                    index={index}
                                    dashboard={props.dashboard}
                                    packHeader={header}
                                    accessType={accessType}
                                    onInstalling={() => handleInstalling()}
                                    onInstalled={(header) => handleInstalled(header)}
                                    onError={() => handleError()}
                                />
                            </Col>
                        ))}
                    </Row>
                }
            </>
        )
    }

    let view;
    if (status === StatusType.LOADING) {
        view = buildLoadingView(true);
    } else if (status === StatusType.FAILED) {
        view = buildLoadingView(false);
    } else if (status === StatusType.READY) {
        view = buildAppletsView();
    }
    return (
        <Modal
            className="x-appletpacks"
            centered
            width={Globals.DIALOG_WIDTH * COLUMN_COUNT}
            title="Applets"
            closable={false}
            visible={true}
            footer={(
                <Button type="primary" disabled={count > 0} onClick={() => props.onClose()}>Close</Button>
            )}
        >
            {view}
        </Modal>
    );

}
