import { ReactElement, useEffect, useState } from 'react';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Globals } from 'constants/Globals';
import { YodleeContext } from '@methodset/endpoint-client-ts';
import { RouteComponentProps } from 'react-router-dom';
import { RestUtils } from 'utils/RestUtils';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { FastLink } from './FastLink/FastLink';
import { ResponsiveBox } from 'components/ResponsiveBox/ResponsiveBox';
import { Justify } from 'components/Justify/Justify';
import endpointService from 'services/EndpointService';
import axios from 'axios';
import './AccountItem.less';

type MatchParams = {
    accountId: string
}

export type AccountItemProps = RouteComponentProps<MatchParams>;

export const AccountItem = (props: AccountItemProps): ReactElement => {

    const [status, setStatus] = useState<string>(Globals.STATUS_INIT);
    const [yodleeContext, setYodleeContext] = useState<YodleeContext | undefined>();
    const [accessToken, setAccessToken] = useState<string | undefined>();

    useEffect(() => {
        loadData();
    }, []);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const handleClose = (data: any): void => {
        props.history.push(RouteBuilder.APPLICATION_ACCOUNTS);
    }

    const handleSuccess = (data: any): void => {
    }

    const handleError = (data: any): void => {
    }

    const readYodleeContextRequest = (): Promise<any> => {
        const request = {};
        return endpointService.readYodleeContext(request,
            (response: any) => readYodleeContextResponse(response),
            undefined, true
        );
    }

    const readYodleeContextResponse = (response: any): void => {
        setYodleeContext(response.data.yodleeContext);
    }

    const createYodleeAccessTokenRequest = (): Promise<any> => {
        const request = {};
        return endpointService.createYodleeAccessToken(request,
            (response: any) => createYodleeAccessTokenResponse(response),
            undefined, true
        );
    }

    const createYodleeAccessTokenResponse = (response: any): void => {
        setAccessToken(response.data.accessToken);
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <LoadSpinner
                className="x-accountitem-loading"
                status={isLoading ? "loading" : "failed"}
                onRetry={handleRetryLoad}
            />
        );
    }

    const buildLinkView = (): ReactElement => {
        const providerId = props.match.params.accountId;
        const providerAccountId = providerId && providerId !== "create" ? parseInt(providerId) : undefined;
        return (
            <FastLink
                fastLinkURL={yodleeContext!.fastLinkUrl}
                accessToken={accessToken!}
                providerAccountId={providerAccountId}
                onSuccess={handleSuccess}
                onError={handleError}
                onClose={handleClose}
            />
        );
    }

    const loadData = (): void => {
        const requests = [];
        requests.push(createYodleeAccessTokenRequest());
        requests.push(readYodleeContextRequest());
        setStatus(Globals.STATUS_LOADING);
        axios.all(requests).then(axios.spread((r1, r2) => {
            if (RestUtils.isOk(r1, r2)) {
                setStatus(Globals.STATUS_READY);
            } else {
                setStatus(Globals.STATUS_FAILED);
            }
        }));
    }

    let view;
    if (status === Globals.STATUS_LOADING) {
        view = buildLoadingView(true);
    } else if (status === Globals.STATUS_FAILED) {
        view = buildLoadingView(false);
    } else if (status === Globals.STATUS_READY) {
        view = buildLinkView();
    }
    return (
        <Justify justification="center">
            <ResponsiveBox
                className="x-accountitem"
                md={{ className: 'x-accountitem-md' }}
                xs={{ className: 'x-accountitem-xs' }}
            >
                <div className="x-accontitem-top">
                    <div className="x-accountitem-title">Link Provider</div>
                    {view}
                </div>
            </ResponsiveBox>
        </Justify>
    );

}
