import React, { PureComponent, ReactElement } from 'react';
import { Button, Checkbox, Collapse, Form, FormInstance, Radio, RadioChangeEvent, Space } from 'antd';
import { FormFrame } from 'components/FormFrame/FormFrame';
import { RouteComponentProps } from 'react-router-dom';
import { EntityContext } from 'context/EntityContext';
import { Address, Contact, Income, Pension, Person, PersonType, PersonaType, PersonalPersona, Profile, Retirement } from '@methodset/entity-client-ts';
import { PersonEditor } from './PersonEditor/PersonEditor';
import { ChildrenEditor } from './ChildrenEditor/ChildrenEditor';
import { RealEstateEditor } from './RealEstateEditor/RealEstateEditor';
import { IncomesEditor } from './IncomesEditor/IncomesEditor';
import { RetirementEditor } from './RetirementEditor/RetirementEditor';
import { ContactEditor } from './ContactEditor/ContactEditor';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { PensionsEditor } from './PensionsEditor/PensionsEditor';
import { SpouseEditor } from './SpouseEditor/SpouseEditor';
import { Spacer } from 'components/Spacer/Spacer';
import { CoreUtils } from 'utils/CoreUtils';
import { Label } from 'components/Label/Label';
import axios from 'axios';
import entityService from 'services/EntityService';
import update from 'immutability-helper';
import './EditProfile.less';

export type EditProfileProps = RouteComponentProps & {
}

export type EditProfileState = {
    status: string,
    error?: Error,
    isSubmitting: boolean,
    hasSpouse: boolean,
    profile: Profile
    personaType: PersonaType,
    profileKey?: string,
    personaKey?: string
}

export class EditProfile extends PureComponent<EditProfileProps, EditProfileState> {

    static contextType = EntityContext;

    private formRef = React.createRef<FormInstance>();

    constructor(props: EditProfileProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            error: undefined,
            isSubmitting: false,
            hasSpouse: false,
            profile: {} as Profile,
            personaType: PersonaType.NONE
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleProfileChange = this.handleProfileChange.bind(this);
        this.handlePersonaChange = this.handlePersonaChange.bind(this);
        this.handleSelfChange = this.handleSelfChange.bind(this);
        this.handleContactChange = this.handleContactChange.bind(this);
        this.handleSpouseToggle = this.handleSpouseToggle.bind(this);
        this.handleSpouseChange = this.handleSpouseChange.bind(this);
        this.handleChildrenChange = this.handleChildrenChange.bind(this);
        this.handleIncomesChange = this.handleIncomesChange.bind(this);
        this.handlePensionsChange = this.handlePensionsChange.bind(this);
        this.handleRealEstateChange = this.handleRealEstateChange.bind(this);
        this.handleRetirementChange = this.handleRetirementChange.bind(this);
        this.handleFormFinish = this.handleFormFinish.bind(this);
        this.handleFormError = this.handleFormError.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleTypeChange(e: RadioChangeEvent): void {
        const personaType = e.target.value;
        this.setState({ 
            personaType: personaType,
            profileKey: personaType === PersonaType.NONE ? "general" : undefined,
            personaKey: undefined
        });
    }

    private handleProfileChange(profileKey: string | string[]): void {
        this.setState({profileKey: Array.isArray(profileKey) ? profileKey[0] : profileKey});
    }

    private handlePersonaChange(personaKey: string | string[]): void {
        this.setState({personaKey: Array.isArray(personaKey) ? personaKey[0] : personaKey});
    }

    private handleSelfChange(self: Person): void {
        const profile = update(this.state.profile, {
            self: { $set: self }
        });
        this.setState({ profile: profile });
    }

    private handleContactChange(contact: Contact): void {
        const profile = update(this.state.profile, {
            contact: { $set: contact }
        });
        this.setState({ profile: profile });
    }

    private handleSpouseToggle(e: CheckboxChangeEvent): void {
        const hasSpouse = e.target.checked;
        this.setState({ hasSpouse: hasSpouse });
        if (!hasSpouse) {
            let persona: PersonalPersona = this.state.profile.personas["PERSONAL"] ?? { type: "PERSONAL" };
            persona = update(persona, {
                spouse: { $set: undefined }
            });
            const profile = update(this.state.profile, {
                personas: {
                    PERSONAL: { $set: persona }
                }
            });
            this.setState({ profile: profile });
        }
    }

    private handleSpouseChange(spouse: Person): void {
        let persona: PersonalPersona = this.state.profile.personas["PERSONAL"] ?? { type: "PERSONAL" };
        persona = update(persona, {
            spouse: { $set: spouse }
        });
        const profile = update(this.state.profile, {
            personas: {
                PERSONAL: { $set: persona }
            }
        });
        this.setState({ profile: profile });
    }

    private handleChildrenChange(children: Person[]): void {
        let persona: PersonalPersona = this.state.profile.personas["PERSONAL"] ?? { type: "PERSONAL" };
        persona = update(persona, {
            children: { $set: children }
        });
        const profile = update(this.state.profile, {
            personas: {
                PERSONAL: { $set: persona }
            }
        });
        this.setState({ profile: profile });
    }

    private handleIncomesChange(incomes: Income[]): void {
        let persona: PersonalPersona = this.state.profile.personas["PERSONAL"] ?? { type: "PERSONAL" };
        persona = update(persona, {
            incomes: { $set: incomes }
        });
        const profile = update(this.state.profile, {
            personas: {
                PERSONAL: { $set: persona }
            }
        });
        this.setState({ profile: profile });
    }

    private handlePensionsChange(pensions: Pension[]): void {
        let persona: PersonalPersona = this.state.profile.personas["PERSONAL"] ?? { type: "PERSONAL" };
        persona = update(persona, {
            pensions: { $set: pensions }
        });
        const profile = update(this.state.profile, {
            personas: {
                PERSONAL: { $set: persona }
            }
        });
        this.setState({ profile: profile });
    }

    private handleRealEstateChange(realEstate: Address[]): void {
        let persona: PersonalPersona = this.state.profile.personas["PERSONAL"] ?? { type: "PERSONAL" };
        persona = update(persona, {
            realEstate: { $set: realEstate }
        });
        const profile = update(this.state.profile, {
            personas: {
                PERSONAL: { $set: persona }
            }
        });
        this.setState({ profile: profile });
    }

    private handleRetirementChange(retirement: Retirement): void {
        let persona: PersonalPersona = this.state.profile.personas["PERSONAL"] ?? { type: "PERSONAL" };
        persona = update(persona, {
            retirement: { $set: retirement }
        });
        const profile = update(this.state.profile, {
            personas: {
                PERSONAL: { $set: persona }
            }
        });
        this.setState({ profile: profile });
    }

    private handleFormFinish(): void {
        this.updateProfileRequest();
    }

    private handleFormError(e: any): void {
        const error = new Error("There are errors in the form, please fix.");
        this.setState({ error: error });
    }

    private handleCancelClick(): void {
        this.props.history.goBack();
    }

    private readProfileRequest(): Promise<any> {
        const request = {
            asOwner: true
        };
        return entityService.readProfile(request,
            (response: any) => this.readProfileResponse(response),
            undefined, true
        );
    }

    private readProfileResponse(response: any): void {
        const profile = response.data.profile;
        const hasSpouse = !!profile.personas.PERSONAL.spouse;
        this.setState({
            profile: profile,
            hasSpouse: hasSpouse
        });
    }

    private updateProfileRequest(): Promise<any> {
        this.setState({
            isSubmitting: true,
            error: undefined
        });
        const request = {
            profile: this.state.profile
        };
        return entityService.updateProfile(request,
            (response: any) => this.updateProfileResponse(response),
            (response: any) => this.updateProfileException(response),
            true
        );
    }

    private updateProfileResponse(response: any): void {
        this.setState({ isSubmitting: false });
        this.props.history.goBack();
    }

    private updateProfileException(response: any): void {
        const error = new Error(RestUtils.getErrorMessage(response));
        this.setState({
            isSubmitting: false,
            error: error
        });
    }

    private loadData() {
        const requests = [];
        requests.push(this.readProfileRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSkeleton
                count={8}
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load profile."
                onRetry={this.handleRetryLoad}
            >
                <LoadSkeleton.Input length="fill" />
            </LoadSkeleton>
        );
    }

    private buildFormView(): ReactElement {
        const profileKey = this.state.profileKey ? [this.state.profileKey] : [];
        const personaKey = this.state.personaKey ? [this.state.personaKey] : [];
        return (
            <Form
                ref={this.formRef}
                onFinish={this.handleFormFinish}
                onFinishFailed={this.handleFormError}
            >
                <Collapse activeKey={profileKey} accordion={true} onChange={this.handleProfileChange}>
                    <Collapse.Panel key="general" header="General" forceRender={true}>
                        <div className="x-editprofile-info">
                            Enter your personal information.
                        </div>
                        <PersonEditor
                            formRef={this.formRef}
                            id="self"
                            type={PersonType.SELF}
                            person={this.state.profile.self}
                            onChange={this.handleSelfChange}
                        />
                    </Collapse.Panel>
                    <Collapse.Panel key="contact" header="Contact" forceRender={true}>
                        <div className="x-editprofile-info">
                            Enter your address and phone number.
                        </div>
                        <ContactEditor
                            formRef={this.formRef}
                            id="self"
                            contact={this.state.profile.contact}
                            onChange={this.handleContactChange}
                        />
                    </Collapse.Panel>
                </Collapse>
                <Label className="x-editprofile-persona" label="Profile Type" placement="top">
                    <Radio.Group
                        value={this.state.personaType}
                        onChange={this.handleTypeChange}
                    >
                        <Spacer fill={true}>
                            {CoreUtils.enumToKeys(PersonaType).map(type => (
                                <Radio key={type} value={type}>{CoreUtils.toProper(type)}</Radio>
                            ))}
                        </Spacer>
                    </Radio.Group>
                </Label>
                {this.state.personaType === PersonaType.PERSONAL &&
                    <Collapse activeKey={personaKey} accordion={true} onChange={this.handlePersonaChange}>
                        <Collapse.Panel key="spouse" header="Spouse" forceRender={true}>
                            <div className="x-editprofile-info">
                                Enter information about your spouse.
                            </div>
                            <Checkbox
                                className="x-editprofile-spouse"
                                checked={this.state.hasSpouse}
                                onChange={this.handleSpouseToggle}
                            >
                                I have a spouse.
                            </Checkbox>
                            {this.state.hasSpouse &&
                                <SpouseEditor
                                    formRef={this.formRef}
                                    id="spouse"
                                    spouse={(this.state.profile.personas.PERSONAL as PersonalPersona).spouse}
                                    onChange={this.handleSpouseChange}
                                />
                            }
                        </Collapse.Panel>
                        <Collapse.Panel key="children" header="Children" forceRender={true}>
                            <div className="x-editprofile-info">
                                Enter information about your children.
                            </div>
                            <ChildrenEditor
                                id="children"
                                children={(this.state.profile.personas.PERSONAL as PersonalPersona).children}
                                onChange={this.handleChildrenChange}
                            />
                        </Collapse.Panel>
                        <Collapse.Panel key="income" header="Income" forceRender={true}>
                            <div className="x-editprofile-info">
                                Enter information about your income. Keep your history as a record.
                            </div>
                            <IncomesEditor
                                id="income"
                                incomes={(this.state.profile.personas.PERSONAL as PersonalPersona).incomes}
                                onChange={this.handleIncomesChange}
                            />
                        </Collapse.Panel>
                        <Collapse.Panel key="pensions" header="Pensions" forceRender={true}>
                            <div className="x-editprofile-info">
                                Enter information about your pensions.
                            </div>
                            <PensionsEditor
                                id="pensions"
                                pensions={(this.state.profile.personas.PERSONAL as PersonalPersona).pensions}
                                onChange={this.handlePensionsChange}
                            />
                        </Collapse.Panel>
                        <Collapse.Panel key="real-estate" header="Real Estate" forceRender={true}>
                            <div className="x-editprofile-info">
                                Enter the addresses of the real read estate you own.
                            </div>
                            <RealEstateEditor
                                id="real-estate"
                                addresses={(this.state.profile.personas.PERSONAL as PersonalPersona).realEstate}
                                onChange={this.handleRealEstateChange}
                            />
                        </Collapse.Panel>
                        <Collapse.Panel key="retirement" header="Retirement" forceRender={true}>
                            <div className="x-editprofile-info">
                                Enter information about your retirement.
                            </div>
                            <RetirementEditor
                                formRef={this.formRef}
                                id="retirement"
                                retirement={(this.state.profile.personas.PERSONAL as PersonalPersona).retirement}
                                onChange={this.handleRetirementChange}
                            />
                        </Collapse.Panel>
                    </Collapse>
                }
                <Space
                    className="x-editprofile-footer"
                >
                    <Button
                        block
                        onClick={this.handleCancelClick}
                    >
                        Cancel
                    </Button>
                    <Button
                        block
                        type="primary"
                        htmlType="submit"
                        loading={this.state.isSubmitting}
                    >
                        Update
                    </Button>
                </Space>
            </Form>
        );
    }

    public componentDidMount(): void {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildFormView();
        }
        return (
            <FormFrame
                className="x-editprofile"
                title="Update Profile"
                error={this.state.error}
                removeForm={true}
            >
                {view}
            </FormFrame>
        );
    }

}
