import { useState, createContext, FC, useContext, ReactElement } from 'react';
import { Board } from '@methodset/model-client-ts';

interface ApplicationState {
    boards: Board[];
    saveBoards: (board: Board[]) => void;
}

const defaultState: ApplicationState = {
    boards: [],
    saveBoards: () => {}
};

export const ApplicationContext = createContext<ApplicationState>(defaultState);

export const ApplicationProvider: FC = ({ children }): ReactElement => {

    const [boards, setBoards] = useState(defaultState.boards);

    const saveBoards = (boards: Board[]) => {
        setBoards(boards);
    }

    return (
        <ApplicationContext.Provider
            value={{
                boards,
                saveBoards
            }}
        >
            {children}
        </ApplicationContext.Provider>
    );

};

export const useApplicationContext = () => useContext(ApplicationContext)