import { PureComponent, ReactElement } from 'react';
import { DeleteOutlined, EditOutlined, LayoutOutlined, DownOutlined, AlertOutlined } from '@ant-design/icons';
import { ModelContext } from 'context/ModelContext';
import { Application, ApplicationHeader, ApplicationType, CategoryType, Model } from '@methodset/model-client-ts';
import { ColumnsType } from 'antd/lib/table';
import { Globals } from 'constants/Globals';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CoreUtils } from 'utils/CoreUtils';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Tag } from 'antd';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { ItemSpec, MenuButton } from 'components/MenuButton/MenuButton';
import { v4 as uuid } from "uuid";
import update from 'immutability-helper';
import './ApplicationList.less';

type MatchParams = {
    modelId: string,
    applicationId: string
}

export type ApplicationListProps = RouteComponentProps<MatchParams> & {
}

export class ApplicationList extends PureComponent<ApplicationListProps> {

    static contextType = ModelContext;

    private colorMap = CoreUtils.toColorMap(CoreUtils.enumToKeys(ApplicationType));

    constructor(props: ApplicationListProps) {
        super(props);
        this.state = {
        };
        this.handleApplicationEdit = this.handleApplicationEdit.bind(this);
        this.handleApplicationDelete = this.handleApplicationDelete.bind(this);
        this.handleAppletCreate = this.handleAppletCreate.bind(this);
        this.handleAlertCreate = this.handleAlertCreate.bind(this);
    }

    private handleApplicationEdit(application: Application): void {
        const model: Model = this.context.model;
        const url = RouteBuilder.application(model.id, application);
        this.props.history.push(url);
    }

    private handleApplicationDelete(application: Application): void {
        const model: Model = this.context.model;
        const applications = model.applications;
        const index = applications.findIndex(app => app.id === application.id);
        if (index !== -1) {
            const model = update(this.context.model, {
                applications: {
                    $splice: [[index, 1]]
                }
            });
            this.context.saveModel(model);
        }
    }

    private handleAppletCreate(): void {
        const model: Model = this.context.model;
        this.props.history.push(RouteBuilder.applet(model.id, uuid()));
    }

    private handleAlertCreate(): void {
        const model: Model = this.context.model;
        this.props.history.push(RouteBuilder.alert(model.id, uuid()));
    }

    private buildColumns(): ColumnsType<any> {
        let i = 0;
        const model: Model = this.context.model;
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            ellipsis: true,
            width: Globals.TABLE_WIDTH_LARGE,
            render: (value: string, application: Application) => (
                <Link to={RouteBuilder.application(model.id, application)}>{application.name}</Link>
            ),
            sorter: (a: any, b: any) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'description',
            title: 'Description',
            ellipsis: true,
            dataIndex: 'description',
            render: (value: string, application: Application) => {
                return (
                    <span>{application.description ?? Globals.EMPTY_FIELD}</span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'type',
            title: 'Type',
            align: 'center',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (value: string, application: Application) => {
                return (
                    <Tag color={this.colorMap[application.type]}>{CoreUtils.toProper(application.type)}</Tag>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.type, b.type),
            sortDirections: ['ascend', 'descend']
        }];
        return columns;
    }

    public render(): ReactElement {
        const items: ItemSpec[] = [{
            icon: <LayoutOutlined />,
            label: "New applet",
            onSelect: () => this.handleAppletCreate()
        }, {
            icon: <AlertOutlined />,
            label: "New alert",
            onSelect: () => this.handleAlertCreate()
        }];
        const extra = (
            <MenuButton
                label="New Application"
                items={items}
                icon={<DownOutlined />}
            />
        );
        const actions = [{
            icon: <EditOutlined />,
            label: (header: ApplicationHeader) => `Edit ${header.type.toLowerCase()}`,
            callback: this.handleApplicationEdit
        }, {
            icon: <DeleteOutlined />,
            label: (header: ApplicationHeader) => `Delete ${header.type.toLowerCase()}`,
            confirm: (header: ApplicationHeader) => `Are you sure you want to delete the ${header.type.toLowerCase()}?`,
            callback: this.handleApplicationDelete
        }];
        const columns = this.buildColumns();
        const data = this.context.model.applications;
        return (
            <ItemTable
                columns={columns}
                items={data}
                actions={actions}
                extra={extra}
            />
        );
    }

}
