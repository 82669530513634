import { ReactElement } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Calculator, ErrorUtils, FormulaError, Range, RangeComponents, ReferenceParser } from '@methodset/calculator-ts';
import { TableWidgetConfiguration } from '@methodset/model-client-ts';
import { CoreUtils } from 'utils/CoreUtils';
import './TableWidgetViewer.less';

export type TableWidgetViewerProps = {
    calculator: Calculator,
    configuration: TableWidgetConfiguration
}

export const TableWidgetViewer = (props: TableWidgetViewerProps): ReactElement => {

    const buildColumns = (values: any[][]): ColumnsType<any> => {
        const columns: ColumnsType<any> = [];
        if (values.length === 0) {
            return columns;
        }
        const row = values[0];
        for (let i = 0; i < row.length; i++) {
            const header = row[i];
            const column = {
                key: i,
                title: header,
                dataIndex: i,
                className: "x-tablewidgetviewer-cell"
            }
            columns.push(column);
        }
        return columns;
    }

    const buildData = (values: any[][]): any[] | undefined => {
        if (hasError(values)) {
            return undefined;
        }
        const start = props.configuration.hasHeaders ? 1 : 0;
        const matrix = [];
        for (let r = start; r < values.length; r++) {
            const row = values[r];
            const data = { key: r, row: r } as any;
            for (let c = 0; c < row.length; c++) {
                const index = c;
                let value = row[c];
                if (!CoreUtils.isEmpty(value)) {
                    if (ErrorUtils.isError(value)) {
                        data[index] = value.type;
                    } else {
                        value = value.toString();
                        data[index] = value;
                    }
                }
            }
            matrix.push(data);
        }
        return matrix;
    }

    const hasError = (values: any[][] | undefined): boolean => {
        return values && values.length === 1 && FormulaError.isError(values[0][0]) ? true : false;
    }

    const rangeValues = (rangeId: string | undefined, formatted: boolean): any[][] | undefined => {
        if (!rangeId) {
            return undefined;
        }
        const ref = ReferenceParser.parse(rangeId, true);
        const components = ref!.components as RangeComponents;
        const sheet = props.calculator.sheets.get(components.sheetId);
        const range = Range.fromId(sheet, components.rangeId);
        return range.values(formatted);
    }

    const buildError = (): ReactElement => {
        return (
            <div className="x-tablewidgetviewer-error">Error generating table.</div>
        )
    }

    const buildView = (): ReactElement => {
        let values: any[][] | undefined;
        try {
            values = rangeValues(props.configuration.rangeId, true);
        } catch (e) {
            return buildError();
        }
        return (
            <Table
                className="x-tablewidgetviewer-table"
                pagination={false}
                bordered={true}
                size="middle"
                columns={buildColumns(values!)}
                dataSource={buildData(values!)}
                showHeader={!!props.configuration.hasHeaders}
            />
        );
    }

    return (
        <div className="x-tablewidgetviewer">
            {buildView()}
        </div>
    );
}
