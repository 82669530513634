import { ReferenceParser } from "@methodset/calculator-ts";
import { SwitchWidgetConfiguration, Widget } from '@methodset/model-client-ts';

export class SwitchWidgetSync {

    public static parser = (widget: Widget): string[] => {
        const configuration = widget.configuration as SwitchWidgetConfiguration;
        const refs: string[] = [];
        refs.push(...ReferenceParser.extract(configuration.hideId, true));
        refs.push(...ReferenceParser.extract(configuration.variableId, true));
        return refs;
    }

    public static updater = (widget: Widget, target: string, replacement: string): void => {
        const configuration = widget.configuration as SwitchWidgetConfiguration;
        configuration.hideId = ReferenceParser.update(configuration.hideId, target, replacement, true);
        configuration.variableId = ReferenceParser.update(configuration.variableId, target, replacement, true);
    }

}
