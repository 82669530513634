import { useState, createContext, FC, useContext, ReactElement } from 'react';
import { Group, Permissions, User } from '@methodset/entity-client-ts';

export interface EntityState {
    // The current user.
    user?: User;
    // The active group.
    group?: Group;
    // The user's permissions.
    permissions?: Permissions;
    clearUser: () => void;
    saveUser: (user: User | undefined) => void;
    saveGroup: (group: Group | undefined) => void;
    savePermissions: (permissions: Permissions | undefined) => void;
}

const defaultState: EntityState = {
    user: undefined,
    group: undefined,
    permissions: undefined,
    clearUser: () => {},
    saveUser: () => {},
    saveGroup: () => {},
    savePermissions: () => {}
};

// Static version of entity information to access in non-components.
export class CurrentEntity {
    public static user: User | undefined;
    public static group: Group | undefined;
    public static permissions: Permissions | undefined;
}

export const EntityContext = createContext<EntityState>(defaultState);

export const EntityProvider: FC = ({ children }): ReactElement => {

    const [user, setUser] = useState(defaultState.user);
    const [group, setGroup] = useState(defaultState.group);
    const [permissions, setPermissions] = useState(defaultState.permissions);

    const clearUser = () => {
        CurrentEntity.user = undefined;
        CurrentEntity.group = undefined;
        CurrentEntity.permissions = undefined;
        setUser(CurrentEntity.user);
        setGroup(CurrentEntity.group);
        setPermissions(CurrentEntity.permissions);
    }

    const saveUser = (user: User | undefined) => {
        CurrentEntity.user = user;
        setUser(CurrentEntity.user);
    }

    const saveGroup = (group: Group | undefined) => {
        CurrentEntity.group = group;
        setGroup(CurrentEntity.group);
    }

    const savePermissions = (permissions: Permissions | undefined) => {
        CurrentEntity.permissions = permissions;
        setPermissions(CurrentEntity.permissions);
    }

    return (
        <EntityContext.Provider
            value={{
                user,
                group,
                permissions,
                clearUser,
                saveUser,
                saveGroup,
                savePermissions
            }}
        >
            {children}
        </EntityContext.Provider>
    );

};

export const useEntityContext = () => useContext(EntityContext)