import { ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import { FormInstance, Select, Switch } from 'antd';
import { Retirement } from '@methodset/entity-client-ts';
import { FormItem } from 'components/FormItem/FormItem';
import update from 'immutability-helper';
import './RetirementEditor.less';

export type ChangeCallback = (retirement: Retirement) => void;

export type RetirementProps = {
    formRef: React.RefObject<FormInstance>,
    id: string,
    retirement?: Retirement,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    retirement: {}
}

export const RetirementEditor = (props: RetirementProps): ReactElement => {

    const buildAges = (): number[] => {
        const ages = [];
        for (let i = 20; i < 100; i++) {
            ages.push(i);
        }
        return ages;
    }

    const handleAgeChange = (age: number): void => {
        const retirement = update(props.retirement, {
            age: { $set: age }
        });
        props.onChange(retirement);
    }

    return (
        <>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Retirement Age"
                name={`${props.id}-age`}
            >
                <Select
                    allowClear={true}
                    value={props.retirement.age}
                    onChange={(type) => handleAgeChange(type)}
                >
                    {buildAges().map(age => (
                        <Select.Option key={age} value={age}>{age}</Select.Option>
                    ))}
                </Select>
            </FormItem>
        </>
    )
}

RetirementEditor.defaultProps = defaultProps;
