import { ReferenceParser } from "@methodset/calculator-ts";
import { ListWidgetConfiguration, Widget } from '@methodset/model-client-ts';

export class ListWidgetSync {

    public static parser = (widget: Widget): string[] => {
        const configuration = widget.configuration as ListWidgetConfiguration;
        const columnSets = configuration.columnSets;
        const refs: string[] = [];
        refs.push(...ReferenceParser.extract(configuration.hideId, true));
        refs.push(...ReferenceParser.extract(configuration.rangeId, true));
        const rowLink = configuration.rowLink;
        if (rowLink) {
            refs.push(...ReferenceParser.extract(rowLink.variableId, true));
            refs.push(...ReferenceParser.extract(rowLink.rangeId, true));
        }
        for (const columnSet of columnSets) {
            refs.push(...ReferenceParser.extract(columnSet.name));
            const columnDefs = columnSet.columnDefs;
            if (columnDefs) {
                for (const columnDef of columnDefs) {
                    refs.push(...ReferenceParser.extract(columnDef.rangeId, true));
                }
            }
        }
        return refs;
    }

    public static updater = (widget: Widget, target: string, replacement: string): void => {
        const configuration = widget.configuration as ListWidgetConfiguration;
        const columnSets = configuration.columnSets;
        configuration.hideId = ReferenceParser.update(configuration.hideId, target, replacement, true);
        configuration.rangeId = ReferenceParser.update(configuration.rangeId, target, replacement, true);
        const rowLink = configuration.rowLink;
        if (rowLink) {
            rowLink.variableId = ReferenceParser.update(rowLink.variableId, target, replacement, true);
            rowLink.rangeId = ReferenceParser.update(rowLink.rangeId, target, replacement, true);
        }
        for (const columnSet of columnSets) {
            columnSet.name = ReferenceParser.update(columnSet.name, target, replacement);
            const columnDefs = columnSet.columnDefs;
            if (columnDefs) {
                for (const columnDef of columnDefs) {
                    columnDef.rangeId = ReferenceParser.update(columnDef.rangeId, target, replacement, true);
                }
            }
        }
    }

}
