import { ReactElement } from 'react';
import { FormInstance } from 'antd';
import { Address, Contact, Email, Phone } from '@methodset/entity-client-ts';
import { AddressEditor } from '../AddressEditor/AddressEditor';
import { PhonesEditor } from '../PhonesEditor/PhonesEditor';
import update from 'immutability-helper';
import './ContactEditor.less';
import { PhoneEditor } from '../PhonesEditor/PhoneEditor/PhoneEditor';

export type ChangeCallback = (contact: Contact) => void;

export type ContactEditorProps = {
    formRef: React.RefObject<FormInstance>,
    id: string,
    contact?: Contact,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    contact: {} as Contact
}

export const ContactEditor = (props: ContactEditorProps): ReactElement => {

    const handleAddressChange = (address: Address): void => {
        const contact = update(props.contact, {
            address: { $set: address }
        });
        props.onChange(contact);
    }

    const handlePhoneChange = (phone: Phone): void => {
        const contact = update(props.contact, {
            phone: { $set: phone }
        });
        props.onChange(contact);
    }

    return (
        <>
            <AddressEditor
                formRef={props.formRef}
                id={props.id}
                address={props.contact.address}
                onChange={(address) => handleAddressChange(address)}
            />
            <PhoneEditor
                formRef={props.formRef}
                id={props.id}
                phone={props.contact.phone}
                onChange={(phone) => handlePhoneChange(phone)}
            />
        </>
    )
}

ContactEditor.defaultProps = defaultProps;
