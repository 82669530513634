import { PureComponent, ReactElement } from 'react';
import { BookOutlined, DeleteOutlined, ExportOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Radio, RadioChangeEvent, Space, Tag } from 'antd';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { ColumnsType } from 'antd/lib/table';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { StatusType } from 'constants/StatusType';
import { PackHeader, PackType } from '@methodset/library-client-ts';
import { Globals } from 'constants/Globals';
import { PackDeployer, DeployType } from './PackDeployer/PackDeployer';
import { AccessType } from '@methodset/commons-shared-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { EntityContext } from 'context/EntityContext';
import { PermissionsHelper } from '@methodset/entity-client-ts';
import axios from 'axios';
import update from 'immutability-helper';
import libraryService from 'services/LibraryService';
import './Packs.less';

export type PacksProps = {
}

export type PacksState = {
    status: StatusType,
    headers: PackHeader[],
    releaseType?: DeployType,
    packHeader?: PackHeader,
    accessType: AccessType
}

export class Packs extends PureComponent<PacksProps, PacksState> {

    private colorMap = CoreUtils.toColorMap(CoreUtils.enumToKeys(PackType));
    static contextType = EntityContext;

    constructor(props: PacksProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            headers: [],
            accessType: AccessType.GROUP
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handlePackRelease = this.handlePackRelease.bind(this);
        this.handlePackPublish = this.handlePackPublish.bind(this);
        this.handlePackDelete = this.handlePackDelete.bind(this);
        this.handleReleaseDone = this.handleReleaseDone.bind(this);
        this.handleReleaseCancel = this.handleReleaseCancel.bind(this);
        this.handleAccessChange = this.handleAccessChange.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleRefreshClick(): void {
        this.loadData();
    }

    private handlePackRelease(packHeader: PackHeader): void {
        this.setState({
            packHeader: packHeader,
            releaseType: "environment"
        });
    }

    private handlePackPublish(packHeader: PackHeader): void {
        this.setState({
            packHeader: packHeader,
            releaseType: "access"
        });
    }

    private handleReleaseDone(packHeader: PackHeader): void {
        this.setState({
            packHeader: undefined,
            releaseType: undefined
        });
    }

    private handleReleaseCancel(): void {
        this.setState({
            packHeader: undefined,
            releaseType: undefined
        });
    }

    private handlePackDelete(header: PackHeader): Promise<any> {
        const request = {
            packId: header.id,
            accessType: AccessType.GROUP
        }
        return libraryService.deletePack(request,
            (response: any) => this.deletePackResponse(response),
            undefined, false
        );
    }

    private handleAccessChange(e: RadioChangeEvent): void {
        const accessType = e.target.value;
        this.setState({ accessType: accessType }, () => this.loadData());
    }

    private deletePackResponse(response: any): void {
        const packId = response.data.packId;
        const index = this.state.headers.findIndex(header => header.id === packId);
        if (index !== -1) {
            const headers = update(this.state.headers, {
                $splice: [[index, 1]]
            });
            this.setState({ headers: headers });
        }
    }

    private readPackHeadersRequest(): Promise<any> {
        const request = {
            accessType: this.state.accessType
        }
        return libraryService.readPackHeaders(request,
            (response: any) => this.readPackHeadersResponse(response),
            undefined, true
        );
    }

    private readPackHeadersResponse(response: any): void {
        const headers = response.data.headers;
        this.setState({ headers: headers });
    }

    private buildColumns(): ColumnsType<any> {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            render: (header: PackHeader) => {
                return <span>{header.name}</span>
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'type',
            title: 'Type',
            align: 'center',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (header: PackHeader) => {
                return (
                    <Tag color={this.colorMap![header.packType]}>
                        {CoreUtils.toProper(header.packType)}
                    </Tag>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.packType, b.packType),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'version',
            title: 'Version',
            align: 'center',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (header: PackHeader) => {
                return (
                    <span>
                        {CoreUtils.toVersion(header.version)}
                    </span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareNumbers(a.version, b.version),
            sortDirections: ['descend', 'ascend']
        }, {
            key: 'access',
            title: 'Access',
            align: 'center',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (header: PackHeader) => {
                return (
                    <span>
                        {CoreUtils.toProper(header.accessType)}
                    </span>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.accessType, b.accessType),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'utime',
            title: 'Last Updated',
            align: 'center',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (header: PackHeader) => {
                return (
                    <span>
                        {CoreUtils.toUpdateTime(header.updateTime)}
                    </span>
                );
            },
            sorter: (a, b) => CoreUtils.compareNumbers(a.updateTime, b.updateTime),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readPackHeadersRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const columns = this.buildColumns();
        const data = this.state.headers;
        const perms = PermissionsHelper.to(this.context.permissions);
        const actions = [{
            icon: <ExportOutlined />,
            label: "Release package",
            disabled: (header: PackHeader) => !PackType.isReleasable(header.packType) || this.state.accessType !== AccessType.GROUP,
            callback: this.handlePackRelease
        }, {
            icon: <BookOutlined />,
            label: "Publish package",
            disabled: (header: PackHeader) => !PackType.isPublishable(header.packType) || this.state.accessType === AccessType.PUBLIC,
            callback: this.handlePackPublish
        }, {
            icon: <DeleteOutlined />,
            label: "Delete package",
            confirm: "Are you sure you want to delete the package?",
            callback: this.handlePackDelete
        }];
        let extra;
        // Allow user to switch to organization or public if they have access.
        if (perms.get("access.organization") || perms.get("access.public")) {
            extra = (
                <Spacer>
                    <Radio.Group
                        buttonStyle="solid"
                        value={this.state.accessType}
                        onChange={this.handleAccessChange}
                    >
                        {AccessType.array().filter(type => perms.get("access", type)).map(type => (
                            <Radio.Button value={type}>
                                {CoreUtils.toProper(type)}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                    <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick}></Button>
                </Spacer>
            );
        }
        return (
            <>
                <ItemTable
                    className="x-packs"
                    title="Packages"
                    status={this.state.status}
                    columns={columns}
                    items={data}
                    extra={extra}
                    actions={actions}
                    onLoad={this.handleRetryLoad}
                />
                {this.state.packHeader && this.state.releaseType &&
                    <PackDeployer
                        deployType={this.state.releaseType}
                        packHeader={this.state.packHeader}
                        onCancel={this.handleReleaseCancel}
                        onRelease={this.handleReleaseDone}
                    />
                }
            </>
        );
    }

}
