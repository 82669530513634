import { ReactElement, useState } from 'react';
import { Avatar, Tag } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { CoreUtils } from 'utils/CoreUtils';
import { CategoryType } from '@methodset/model-client-ts';
import { AppletDetails, PackHeader } from '@methodset/library-client-ts';
import { Link } from 'react-router-dom';
import { Globals } from 'constants/Globals';
import classNames from 'classnames';
import './PackInfo.less';

export type PackInfoProps = {
    index?: number,
    className?: string,
    packHeader: PackHeader
} & typeof defaultProps;

const defaultProps = {
    index: 0
}

export const PackInfo = (props: PackInfoProps): ReactElement => {

    const [show, setShow] = useState<boolean>(false);

    const handleMoreToggle = () => {
        setShow(!show);
    }

    const buildSummary = (): string => {
        const description = props.packHeader.description;
        if (!description) {
            return "<no description>";
        }
        const index = description.indexOf(".");
        return index == -1 ? description : description.substring(0, index + 1);
    }

    const buildLogo = (): string => {
        return props.packHeader.name[0];
    }

    const color = Globals.BOLD_COLORS[props.index % Globals.BOLD_COLORS.length];
    const details = props.packHeader.details as AppletDetails;
    const category = details.categoryType ? CategoryType[details.categoryType] : CategoryType[CategoryType.GENERAL];
    const summary = buildSummary();
    const logo = buildLogo();
    return (
        <div className={classNames("x-packinfo", props.className)}>
            <Spacer direction="vertical" size="sm">
                <div className="x-packinfo-wrap">
                    <Spacer alignment="top">
                        <div>
                            <Avatar
                                size={40}
                                shape="square"
                                style={{ color: "white", backgroundColor: color }}
                            >
                                {logo}
                            </Avatar>
                        </div>
                        <div>
                            <div className="x-packinfo-name">{props.packHeader.name}</div>
                            <div className="x-packinfo-publisher">{props.packHeader.publisher}</div>
                        </div>
                    </Spacer>
                    <div className="x-packinfo-description">
                        {!show && <div className="x-packinfo-summary">{summary}</div>}
                        {show && <div>{props.packHeader.description}</div>}
                    </div>
                    <Link to="#" onClick={() => handleMoreToggle()}>{show ? "Show less" : "Show more"}</Link>
                </div>
                <Tag color="blue">{CoreUtils.toProper(category, "_")}</Tag>
            </Spacer>
        </div>
    )
}

PackInfo.defaultProps = defaultProps;
