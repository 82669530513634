import { ChangeEvent, PureComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Divider, Empty, Form, FormInstance, Input, Row, Select, Tag } from 'antd';
import { Globals } from 'constants/Globals';
import { ModelContext } from 'context/ModelContext';
import { Applet, AppletPanel, Application, ApplicationType, CategoryType } from '@methodset/model-client-ts';
import { AppletEditor } from './AppletEditor/AppletEditor';
import { RouteComponentProps } from 'react-router-dom';
import { RouteBuilder } from 'utils/RouteBuilder';
import { AppletSync } from 'sync/AppletSync';
import { FormItem } from 'components/FormItem/FormItem';
import { Spacer } from 'components/Spacer/Spacer';
import { CoreUtils } from 'utils/CoreUtils';
import update from 'immutability-helper';
import './ModelApplet.less';

type MatchParams = {
    modelId: string,
    applicationId: string
}

const NullApplet = {} as Applet;

export type ModelAppletProps = RouteComponentProps<MatchParams> & {
}

export const ModelApplet = (props: ModelAppletProps): ReactElement => {

    // The model context.
    const context = useContext(ModelContext);
    // The form reference.
    const formRef = useRef<FormInstance>(null);
    // The applet being edited.
    const [applet, setApplet] = useState<Applet>(NullApplet);
    // The applet index.
    const [index, setIndex] = useState<number>(-1);

    useEffect(() => {
        const applicationId = props.match.params.applicationId;
        const applications: Application[] = context.model!.applications;
        const index = applications.findIndex(application => application.id === applicationId && application.type === ApplicationType.APPLET);
        let applet: Applet;
        if (context.application && context.application.id === applicationId) {
            applet = context.application as Applet;
        } else if (index === -1) {
            applet = {
                type: ApplicationType.APPLET,
                id: applicationId,
                name: "New Applet",
                title: undefined as any,
                span: Globals.APPLET_DEFAULT_SPAN,
                widgets: [],
                panels: [],
                categoryType: CategoryType.GENERAL,
                menu: []
            }
        } else {
            applet = applications[index] as Applet;
        }
        setApplet(applet);
        setIndex(index);
    }, []);

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const name = e.target.value;
        const updated = update(applet, {
            name: { $set: name }
        });
        setApplet(updated);
        context.saveApplication(updated);
    }

    const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        const description = e.target.value;
        const updated = update(applet, {
            description: { $set: description }
        });
        setApplet(updated);
        context.saveApplication(updated);
    }

    const handleCategoryChange = (categoryType: CategoryType): void => {
        const updated = update(applet, {
            categoryType: { $set: categoryType }
        });
        setApplet(updated);
        context.saveApplication(updated);
    }

    const handleTitleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const title = e.target.value;
        const updated = update(applet, {
            title: { $set: title }
        });
        setApplet(updated);
        context.saveApplication(updated);
        const registry = context.calculator!.registry;
        registry.register(applet.id, applet, AppletSync.parser, AppletSync.updater);
    }

    const handleSpanChange = (span: number): void => {
        const updated = update(applet, {
            span: { $set: span }
        });
        setApplet(updated);
        context.saveApplication(updated);
    }

    const handleEditChange = (isEdit: boolean): void => {
        const modelId = props.match.params.modelId;
        const applicationId = props.match.params.applicationId;
        const path = `${applicationId}?edit=${isEdit ? "true" : "false"}`;
        props.history.push(RouteBuilder.applet(modelId, path));
    }

    const handleAppletChange = (applet: Applet): void => {
        setApplet(applet);
        context.saveApplication(applet);
    }

    const handleAppletCancel = (): void => {
        const modelId = props.match.params.modelId;
        context.saveApplication(undefined);
        props.history.push(RouteBuilder.applications(modelId));
    }

    const handleAppletSave = (): void => {
        formRef.current?.submit();
    }

    const handleFormFinish = (): void => {
        let updated;
        if (!applet.title) {
            // Copy the name into the title if it is not set.
            updated = update(applet, {
                title: { $set: applet.name }
            });
        } else {
            updated = applet;
        }
        let model;
        if (index === -1) {
            model = update(context.model!, {
                applications: {
                    $push: [updated]
                }
            })
        } else {
            model = update(context.model!, {
                applications: {
                    [index]: { $set: updated }
                }
            });
        }
        context.saveModel(model);
        context.saveApplication(undefined);
        props.history.push(RouteBuilder.applications(model.id));
    }

    const actions = (
        <Spacer className="x-modelapplet-actions" justification="between">
            <Spacer justification="right" fill>
                <Button onClick={handleAppletCancel}>Cancel</Button>
                <Button type="primary" onClick={handleAppletSave}>Done</Button>
            </Spacer>
        </Spacer>
    );
    return (
        <div id="modelapplet" className="x-modelapplet">
            <Spacer size="md" alignment="top">
                <Form
                    className="x-modelapplet-form"
                    ref={formRef}
                    onFinish={handleFormFinish}
                >
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={formRef}
                        label="Name"
                        name="name"
                        info="The name of the applet."
                        rules={[{
                            required: true,
                            message: 'Please enter a name.'
                        }]}
                    >
                        <Input
                            placeholder="Enter a name."
                            value={applet.name}
                            onChange={handleNameChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={formRef}
                        label="Description"
                        name="description"
                        info="The description of the applet."
                        rules={[{
                            required: true,
                            message: 'Please enter a description.'
                        }]}
                    >
                        <Input.TextArea
                            placeholder="Enter a description."
                            rows={3}
                            value={applet.description}
                            onChange={handleDescriptionChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={formRef}
                        label="Category"
                        name="category"
                        info="The category that describes the applet's purpose."
                        rules={[{
                            required: true,
                            message: 'Please select a category.'
                        }]}
                    >
                        <Select
                            allowClear={true}
                            value={applet.categoryType}
                            onChange={handleCategoryChange}
                        >
                            {CoreUtils.enumToKeys(CategoryType).map(key => (
                                <Select.Option value={key}>{CoreUtils.toProper(key, "_")}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={formRef}
                        label="Title"
                        name="title"
                        info="The title displayed on the applet header section and can contain variables. If not specified, the applet name will be used."
                    >
                        <Input
                            placeholder="Enter a title."
                            value={applet.title}
                            onChange={handleTitleChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={formRef}
                        label="Width"
                        name="span"
                        info={`The default number of columns the applet should span (1-${Globals.LAYOUT_COLUMNS}).`}
                        rules={[{
                            required: true,
                            message: "Please select a default column width."
                        }]}
                    >
                        <Select
                            value={applet.span}
                            onChange={handleSpanChange}
                        >
                            {Array.from(Array(Globals.LAYOUT_COLUMNS).keys()).map(index => (
                                <Select.Option key={index} value={index + 1}>{index + 1}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    {actions}
                </Form>
                <Divider className="x-appleteditor-div" type="vertical" />
                {applet !== NullApplet && context.model && context.calculator &&
                    <AppletEditor
                        {...props}
                        key={applet.id}
                        model={context.model}
                        applet={applet}
                        calculator={context.calculator}
                        onEdit={handleEditChange}
                        onChange={handleAppletChange}
                    />
                }
            </Spacer>
        </div>
    )

}
