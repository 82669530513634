import { ReactElement } from 'react';
import { Button, Modal, Space, Tabs } from 'antd';
import { ExecutionState } from '@methodset/workflow-client-ts';
import { Align } from 'components/Align/Align';
import { Justify } from 'components/Justify/Justify';
import { ErrorView } from './ErrorView/ErrorView';
import './StateView.less';

export type CloseCallback = () => void;

export type StateViewProps = {
    state: ExecutionState,
    onClose: CloseCallback
}

export const StateView = (props: StateViewProps): ReactElement => {

    const buildContent = (data: any): ReactElement => {
        const content = JSON.stringify(data, undefined, 4);
        return (
            <div><pre>{content}</pre></div>
        );
    }

    return (
        <Modal
            className="x-stateview"
            centered
            title="Execution State"
            visible={true}
            onCancel={props.onClose}
            footer={(
                <Space>
                    <Button type="primary" onClick={props.onClose}>Close</Button>
                </Space>
            )}
        >
            <Tabs
                defaultActiveKey="inputs"
            >
                <Tabs.TabPane
                    tab="Inputs"
                    key="inputs"
                >
                    <div className="x-stateview-body">
                        {buildContent(props.state.inputs)}
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab="Outputs"
                    key="outputs"
                >
                    <div className="x-stateview-body">
                        {buildContent(props.state.outputs)}
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab="Error"
                    key="error"
                >
                    {props.state.error &&
                        <ErrorView
                            className="x-stateview-body"
                            error={props.state.error}
                        />
                    }
                    {!props.state.error &&
                        <div className="x-stateview-body">
                            <Justify justification="center" fill>
                                <Align alignment="center">
                                    <div>No Error</div>
                                </Align>
                            </Justify>
                        </div>
                    }
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    );

}
