import { ReactElement } from 'react';
import { Calculator } from '@methodset/calculator-ts';
import { ValueWidgetConfiguration, ValueWidgetLayoutType } from '@methodset/model-client-ts';
import { ValueDisplay } from 'components/ValueDisplay/ValueDisplay';
import { WidgetUtils } from 'utils/WidgetUtils';
import { Spacer } from 'components/Spacer/Spacer';
import { CoreUtils } from 'utils/CoreUtils';
import { Justification, Justify } from 'components/Justify/Justify';
import './ValueWidgetViewer.less';

export type ValueWidgetViewerProps = {
    calculator: Calculator,
    configuration: ValueWidgetConfiguration
}

export const ValueWidgetViewer = (props: ValueWidgetViewerProps): ReactElement => {

    const buildView = (): ReactElement => {
        const value = WidgetUtils.findValue(props.calculator, props.configuration.valueId);
        const change = WidgetUtils.findValue(props.calculator, props.configuration.changeId);
        const label = WidgetUtils.replaceCellRefs(props.calculator, props.configuration.label);
        const description = WidgetUtils.replaceCellRefs(props.calculator, props.configuration.description);
        if (props.configuration.layout === ValueWidgetLayoutType.HORIZONTAL) {
            return (
                <div>
                    <Spacer>
                        {label &&
                            <div className="x-valuewidgetviewer-label">
                                {label}:
                            </div>
                        }
                        {!CoreUtils.isEmpty(value) &&
                            <span className="x-valuewidgetviewer-value">
                                {value}
                            </span>
                        }
                        {!CoreUtils.isEmpty(change) &&
                            <ValueDisplay type="direction" value={change} />
                        }
                    </Spacer>
                    <div className="x-valuewidgetviewer-description">
                        {description}
                    </div>
                </div>
            );
        } else if (props.configuration.layout === ValueWidgetLayoutType.SPLIT) {
            return (
                <>
                    <div className="x-valuewidgetviewer-label x-valuewidgetviewer-split">
                        {label &&
                            <div className="x-valuewidgetviewer-label">
                                {label}
                            </div>
                        }
                        <Spacer>
                            {!CoreUtils.isEmpty(value) &&
                                <span className="x-valuewidgetviewer-value">
                                    {value}
                                </span>
                            }
                            {!CoreUtils.isEmpty(change) &&
                                <ValueDisplay type="direction" value={change} />
                            }
                        </Spacer>
                    </div>
                    <div className="x-valuewidgetviewer-description">
                        {description}
                    </div>
                </>
            );
        } else {
            return (
                <Spacer direction="vertical" alignment="top">
                    <div>
                        {label &&
                            <div className="x-valuewidgetviewer-label">
                                {label}
                            </div>
                        }
                        {description &&
                            <div className="x-valuewidgetviewer-description">
                                {description}
                            </div>
                        }
                    </div>
                    <Spacer>
                        {!CoreUtils.isEmpty(value) &&
                            <span className="x-valuewidgetviewer-value">
                                {value}
                            </span>
                        }
                        {!CoreUtils.isEmpty(change) &&
                            <ValueDisplay type="direction" value={change} />
                        }
                    </Spacer>
                </Spacer>
            );
        }
    }

    return (
        <Justify
            className="x-valuewidgetviewer"
            justification={
                props.configuration.justification ?
                    props.configuration.justification.toLowerCase() as Justification :
                    "left"
            }
        >
            {buildView()}
        </Justify>
    );
}

