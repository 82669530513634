import { PureComponent, ReactElement } from 'react';
import { Select } from 'antd';
import { Globals } from 'constants/Globals';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { RestUtils } from 'utils/RestUtils';
import { WorkflowHeader } from '@methodset/workflow-client-ts';
import axios from 'axios';
import classNames from 'classnames';
import workflowService from 'services/WorkflowService';
import './WorkflowPicker.less';

export type ChangeCallback = (queryId: string) => void;

export type WorkflowPickerState = {
    status: string,
    headers: WorkflowHeader[]
}

export type WorkflowPickerProps = {
    className?: string,
    workflowId?: string,
    onChange: ChangeCallback
}

export class WorkflowPicker extends PureComponent<WorkflowPickerProps, WorkflowPickerState> {

    constructor(props: WorkflowPickerProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            headers: []
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleWorkflowChange = this.handleWorkflowChange.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleWorkflowChange(workflowId: string): void {
        this.props.onChange(workflowId);
    }

    private readWorkflowHeadersRequest(): Promise<any> {
        const request = {};
        return workflowService.readWorkflowHeaders(request,
            (response: any) => this.readWorkflowHeadersResponse(response),
            undefined, true
        );
    }

    private readWorkflowHeadersResponse(response: any): void {
        const headers = response.data.headers;
        this.setState({ headers: headers });
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readWorkflowHeadersRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSpinner
                className="x-workflowpicker-loading"
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load workflows."
                onRetry={this.handleRetryLoad}
            />
        );
    }

    private buildWorkflowView(): ReactElement {
        return (
            <Select
                placeholder="Select a workflow."
                value={this.props.workflowId}
                onChange={this.handleWorkflowChange}
            >
                {this.state.headers.map(header => (
                    <Select.Option
                        key={header.id}
                        value={header.id}
                    >
                        {header.name}
                    </Select.Option>
                ))}
            </Select>
        );
    }

    public componentDidMount(): void {
        this.loadData();
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildWorkflowView();
        }
        return (
            <div className={classNames('x-workflowpicker', this.props.className)}>
                {view}
            </div>
        );
    }

}
