import { FC, ReactElement, useContext, useRef, useState } from 'react';
import { Form, FormInstance, Modal, Result } from 'antd';
import { Alert, Configuration, Inputs, LogicalType, OpType } from '@methodset/model-client-ts';
import { Date, String, Time } from '@methodset/commons-shared-ts';
import { ModelContext } from 'context/ModelContext';
import { AlertSetup } from './AlertSetup/AlertSetup';
import { Calculator, DataConverter, DataType, DateFormatType, Formatter, FormulaError, TimeFormatType } from '@methodset/calculator-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { CoreUtils } from 'utils/CoreUtils';
import { Panel } from 'components/Panel/Panel';
import { PanelViewer } from '../AlertEditor/PanelViewer/PanelViewer';
import update from 'immutability-helper';
import './AlertTester.less';

export type CloseCallback = (alert: Alert) => void;

export type AlertTesterProps = {
    alert: Alert,
    //model: Model,
    calculator: Calculator,
    onClose: CloseCallback
}

export const AlertTester: FC<AlertTesterProps> = (props: AlertTesterProps): ReactElement => {

    // The form reference.
    const formRef = useRef<FormInstance>(null);
    // The stored configuration during testing.
    const configuration = useRef<Configuration | null>(null);
    // The model context.
    const context = useContext(ModelContext);
    // True when the alert is being executed.
    const [isExecuting, setIsExecuting] = useState<boolean>(false);
    // The result of the alert conditions.
    const [result, setResult] = useState<any>(null);
    // The snapshot of the calculator data after running a test.
    const [snapshot, setSnapshot] = useState<Calculator | null>(null);
    // The alert being edited.
    const [alert, setAlert] = useState<Alert>(props.alert);

    const handleInputsChange = (testInputs: Inputs): void => {
        const updated = update(alert, {
            testInputs: { $set: testInputs }
        });
        setAlert(updated);
        context.saveApplication(updated);
    }

    const handleRun = (): void => {
        if (CoreUtils.isEmpty(result)) {
            formRef.current?.submit();
        } else {
            setResult(null);
            setSnapshot(null);
        }
    }

    const handleClose = (): void => {
        props.onClose(alert);
    }

    const handleFormFinish = (): void => {
        testAlert();
    }

    const handleExecutionComplete = (): void => {
        const snapshot = props.calculator.snapshot();
        setSnapshot(snapshot);
        const formula = createFormula();
        const value = props.calculator.evaluateFormula(formula);
        setResult(value);
        restoreVariables();
        props.calculator.removeCallback("ExecutionComplete", handleExecutionComplete);
        setIsExecuting(false);
    }

    const executeFormula = (): void => {
        const snapshot = props.calculator.snapshot();
        setSnapshot(snapshot);
        const formula = createFormula();
        const value = props.calculator.evaluateFormula(formula);
        setResult(value);
    }

    const testAlert = (): void => {
        const originals = saveVariables();
        if (!originals) {
            executeFormula();
        } else {
            setIsExecuting(true);
            props.calculator.addCallback("ExecutionComplete", handleExecutionComplete);
            installInputs();
        }
    }

    const saveVariables = (): boolean => {
        const entries = Object.entries(alert.testInputs.configuration);
        if (entries.length === 0) {
            return false;
        }
        let count = 0;
        const originals: Configuration = {};
        const variables = props.calculator.variables;
        for (const [key, value] of entries) {
            const variable = variables.get(key, false);
            if (variable && variable.cell) {
                const cell = variable.cell;
                if (cell.value !== value) {
                    if (cell.hasFormula()) {
                        originals[key] = cell.formula;
                    } else {
                        originals[key] = cell.value;
                    }
                    count++;
                }
            }
        }
        // If there are configurations, but none of the values are 
        // different, the calculator will not execute anything and
        // there will no completion callback.
        if (count > 0) {
            configuration.current = originals;
            return true;
        } else {
            return false;
        }
    }

    const installInputs = (): void => {
        props.calculator.suspend();
        const entries = Object.entries(alert.testInputs.configuration);
        const variables = props.calculator.variables;
        for (const [key, value] of entries) {
            const variable = variables.get(key, false);
            if (variable && variable.cell) {
                const cell = variable.cell;
                cell.value = value;
            }
        }
        props.calculator.execute();
    }

    const restoreVariables = (): void => {
        props.calculator.suspend();
        const entries = Object.entries(configuration.current!);
        const variables = props.calculator.variables;
        for (const [key, value] of entries) {
            const variable = variables.get(key, false);
            if (variable && variable.cell) {
                const cell = variable.cell;
                if (CoreUtils.isFormula(value)) {
                    cell.formula = value;
                } else {
                    cell.value = value;
                }
            }
        }
        props.calculator.execute();
        configuration.current = null;
    }

    const createFormula = (): string => {
        const parts = ["="];
        const conditions = alert.testInputs.conditions;
        for (let i = 0; i < conditions.length; i++) {
            const condition = conditions[i];
            const logical = i > 0 ? (condition.logicalType === LogicalType.AND ? " && " : " || ") : "";
            const value = String.isString(condition.value!) ? `'${condition.value}'` : condition.value;
            const part = `${logical}${condition.variableId} ${OpType.name(condition.opType!)} ${value}`;
            parts.push(part);
        }
        return parts.join("");
    }

    const variableName = (variableId: string): string => {
        const variable = props.calculator.variables.get(variableId, false);
        return variable ? variable.name : "<Variable not found>";
    }

    const buildResultView = (): ReactElement => {
        if (FormulaError.isError(result)) {
            return (
                <Result
                    status="error"
                    title="Alert did not execute successfully."
                    subTitle={`The conditions resulted in an error of type ${result.type}: ${result.message}`}
                />
            )
        } else {
            try {
                //const applet = props.model.applications.filter(application => application.type === ApplicationType.APPLET).find(applet => applet.id === alert.appletId) as Applet;
                const value = DataConverter.convert(DataType.BOOLEAN, result);
                const content = (
                    <Spacer direction="vertical" size="xxl">
                        <div className="x-alerttester-conditions">
                            {alert.testInputs.conditions.map((condition, index) => (
                                <span>
                                    {index > 0 &&
                                        <span>{LogicalType.name(condition.logicalType!).toLowerCase()}&nbsp;</span>
                                    }
                                    <span>
                                        {variableName(condition.variableId)}&nbsp;
                                        {OpType.name(condition.opType!)}&nbsp;
                                        {condition.value}&nbsp;
                                    </span>
                                </span>
                            ))}
                            <span>
                                <span>evaluated to&nbsp;</span>
                                <span className={`x-alerttester-eval-${value ? "true" : "false"}`}>
                                    {`${value ? "true" : "false"}`}&nbsp;
                                </span>
                                <span>on {Formatter.format(Date.today(), DateFormatType.MEDIUM)} at {Formatter.format(Time.now(), TimeFormatType.MEDIUM)}.</span>
                            </span>
                        </div>
                        {value && alert.panel.widgets.length > 0 &&
                            <Panel fill>
                                <PanelViewer
                                    calculator={snapshot!}
                                    panel={props.alert.panel}
                                />
                            </Panel>
                        }
                    </Spacer>
                )
                return (
                    <Result
                        status="success"
                        title={`Alert executed successfully!`}
                        extra={content}
                    />
                )
            } catch (e) {
                return (
                    <Result
                        status="error"
                        title="Alert did not execute successfully."
                        subTitle="The conditions did not result in a boolean expression."
                    />
                )
            }
        }
    }

    const buildSetupView = (): ReactElement => {
        return (
            <AlertSetup
                formRef={formRef}
                alert={alert}
                inputs={alert.testInputs}
                calculator={context.calculator!}
                onChange={handleInputsChange}
            />
        )
    }

    return (
        <Modal
            className="x-alerttester"
            centered
            title="Alert Test"
            width={650}
            visible={true}
            okText={CoreUtils.isEmpty(result) ? "Run" : "Done"}
            cancelText="Close"
            onOk={handleRun}
            onCancel={handleClose}
            okButtonProps={{
                loading: isExecuting
            }}
            cancelButtonProps={{
                style: { display: CoreUtils.isEmpty(result) ? "inline-block" : "none" }
            }}
        >
            <Form ref={formRef} onFinish={handleFormFinish}>
                {CoreUtils.isEmpty(result) ? buildSetupView() : buildResultView()}
            </Form>
        </Modal >
    )

}
