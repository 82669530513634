import { PureComponent, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { CheckOutlined, SwapOutlined, SyncOutlined, UsergroupDeleteOutlined } from '@ant-design/icons';
import { Button, Space, Tag, message } from 'antd';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RouteBuilder } from 'utils/RouteBuilder';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { Group } from '@methodset/entity-client-ts';
import { ColumnsType } from 'antd/lib/table';
import { EntityContext } from 'context/EntityContext';
import { GroupEditor } from './GroupItem/GroupEditor/GroupEditor';
import { StatusType } from 'constants/StatusType';
import { Spacer } from 'components/Spacer/Spacer';
import axios from 'axios';
import update from 'immutability-helper';
import authService from 'services/AuthService';
import entityService from 'services/EntityService';
import './Groups.less';

export type GroupsProps = {
}

export type GroupsState = {
    status: StatusType,
    isCreating: boolean,
    groups: Group[]
}

export class Groups extends PureComponent<GroupsProps, GroupsState> {

    static contextType = EntityContext;

    constructor(props: GroupsProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            isCreating: false,
            groups: []
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleCreateClick = this.handleCreateClick.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleSwitchClick = this.handleSwitchClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleCreateDone = this.handleCreateDone.bind(this);
        this.handleCreateCancel = this.handleCreateCancel.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleCreateClick(): void {
        this.setState({ isCreating: true });
    }

    private handleRefreshClick(): void {
        this.loadData();
    }

    private handleSwitchClick(group: Group): void {
        this.handleGroupChange(group.id);
    }

    private handleDeleteClick(group: Group): void {
        const groupId = group.id;
        this.deleteGroupRequest(groupId);
    }

    private handleCreateCancel(): void {
        this.setState({ isCreating: false });
    }

    private handleCreateDone(group: Group): void {
        const groups = update(this.state.groups, {
            $push: [group]
        });
        this.setState({
            groups: groups,
            isCreating: false
        });
    }

    private handleGroupChange(groupId: string): Promise<any> {
        const request = {
            groupId: groupId
        };
        return entityService.switchGroup(request,
            (response: any) => this.switchGroupResponse(response),
            undefined, false
        );
    }

    private switchGroupResponse(response: any): void {
        const group = response.data.group;
        authService.refreshUser().then((user) => {
            this.context.saveUser(user);
            this.context.saveGroup(group);
        }).catch((err) => {
            this.context.clearUser();
        });
    }

    private readGroupsRequest(): Promise<any> {
        const request = {};
        return entityService.readGroups(request,
            (response: any) => this.readGroupsResponse(response),
            undefined, true
        );
    }

    private readGroupsResponse(response: any): void {
        const groups = response.data.groups;
        this.setState({ groups: groups });
    }

    private deleteGroupRequest(groupId: string): Promise<any> {
        const request = {
            groupId: groupId
        };
        return entityService.deleteGroup(request,
            (response: any) => this.deleteGroupResponse(response),
            undefined, false
        );
    }

    private deleteGroupResponse(response: any): void {
        const groupId = response.data.groupId;
        const index = this.state.groups.findIndex(group => group.id === groupId);
        const groups = update(this.state.groups, {
            $splice: [[index, 1]]
        });
        this.setState({ groups: groups });
    }

    private buildData(): Group[] {
        return this.state.groups;
    }

    private buildColumns(): ColumnsType<any> {
        const user = this.context.user;
        const group = this.context.group;
        // User or group can be empty on logout.
        if (!user || !group) {
            return [] as ColumnsType<any>;
        }
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            render: (group) => {
                return (
                    <Spacer size="lg">
                        <Link to={RouteBuilder.group(group.id)}>{group.name}</Link>
                        {group.id.endsWith(user.id) &&
                            <Tag color="green">Default</Tag>
                        }
                    </Spacer>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'active',
            title: 'Active',
            align: 'center',
            render: (group) => {
                if (this.context.group?.id === group.id) {
                    return <CheckOutlined className="x-groups-active" />;
                } else {
                    return <></>;
                }
            }
        }, {
            key: 'ctime',
            title: 'Create Time',
            align: 'center',
            render: (group) => {
                return (
                    <span>
                        {CoreUtils.toUpdateTime(group.createTime)}
                    </span>
                );
            }
        }, {
            key: 'utime',
            title: 'Update Time',
            align: 'center',
            render: (group) => {
                return (
                    <span>
                        {CoreUtils.toUpdateTime(group.updateTime)}
                    </span>
                );
            }
        }];
        return columns;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readGroupsRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const actions = [{
            icon: <CheckOutlined />,
            label: "Activate group",
            disabled: (group: Group) => {
                // Can't switch to active group.
                return this.context.group?.id === group.id;
            },
            callback: this.handleSwitchClick
        },{
            icon: <UsergroupDeleteOutlined />,
            label: "Delete group",
            confirm: "Are you sure you want to delete the group?",
            disabled: (group: Group) => {
                // Don't allow default group to be deleted.
                return group.id.endsWith(this.context.user?.id);
            },
            callback: this.handleDeleteClick
        }];
        const columns = this.buildColumns();
        const data = this.buildData();
        return (
            <>
                <ItemTable
                    className="x-groups"
                    title="Groups"
                    status={this.state.status}
                    columns={columns}
                    items={data}
                    extra={
                        <Space>
                            <Button onClick={this.handleCreateClick}>New Group</Button>
                            <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick}></Button>
                        </Space>
                    }
                    actions={actions}
                    onLoad={this.handleRetryLoad}
                />
                {this.state.isCreating &&
                    <GroupEditor
                        onDone={this.handleCreateDone}
                        onCancel={this.handleCreateCancel}
                    />
                }
            </>
        );
    }

}
