import { PureComponent, ReactElement } from 'react';
import { Select } from 'antd';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import classNames from 'classnames';
import axios from 'axios';
import modelService from 'services/ModelService';
import './ModelVersionPicker.less';
import { StatusType } from 'constants/StatusType';

export type FilterFunction = (version: number, count: number) => boolean;
export type ChangeCallback = (version: number) => void;

export type SortOrder = "asc" | "desc";

export type ModelVersionPickerState = {
    status: StatusType,
    versions: number[]
}

export type ModelVersionPickerProps = typeof ModelVersionPicker.defaultProps & {
    className?: string,
    modelId?: string,
    order?: SortOrder,
    version?: number,
    filter?: FilterFunction,
    onChange: ChangeCallback
}

export class ModelVersionPicker extends PureComponent<ModelVersionPickerProps, ModelVersionPickerState> {

    static defaultProps = {
        order: "desc"
    }

    constructor(props: ModelVersionPickerProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            versions: []
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleVersionChange = this.handleVersionChange.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleVersionChange(version: number): void {
        this.props.onChange(version);
    }

    private readModelVersionsRequest(): Promise<any> {
        if (!this.props.modelId) {
            return Promise.resolve(true);
        }
        const request = {
            modelId: this.props.modelId
        };
        return modelService.readModelVersions(request,
            (response: any) => this.readModelVersionsResponse(response),
            undefined, true
        );
    }

    private readModelVersionsResponse(response: any): void {
        let versions = response.data.versions;
        if (!versions || versions.length === 0) {
            versions = [0];
        } else if (this.props.order === "asc") {
            versions.sort((a: number, b: number) => a - b);
        } else {
            versions.sort((a: number, b: number) => b - a);
        }
        this.setState({ versions: versions });
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readModelVersionsRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSpinner
                className="x-modelversionpicker-loading"
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load versions."
                onRetry={this.handleRetryLoad}
            />
        );
    }

    private buildModelView(): ReactElement {
        return (
            <Select
                placeholder="Select a version."
                value={this.props.version}
                onChange={this.handleVersionChange}
            >
                {this.state.versions.filter((version) => this.props.filter ? this.props.filter(version, this.state.versions.length) : true).map(version => (
                    <Select.Option key={version} value={version}>{CoreUtils.toVersion(version, "latest")}</Select.Option>
                ))}
            </Select>
        );
    }

    public componentDidMount(): void {
        if (this.props.modelId) {
            this.loadData();
        } else {
            this.setState({ status: StatusType.READY });
        }
    }

    public componentDidUpdate(prevProps: ModelVersionPickerProps): void {
        if (this.props.modelId && prevProps.modelId !== this.props.modelId) {
            this.setState({ status: StatusType.INIT });
            this.loadData();
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === StatusType.LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === StatusType.FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === StatusType.READY) {
            view = this.buildModelView();
        }
        return (
            <div className={classNames('x-modelversionpicker', this.props.className)}>
                {view}
            </div>
        );
    }

}
