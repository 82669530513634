import React, { PureComponent, ReactElement } from 'react';
import { Modal } from 'antd';
import { ModelContext } from 'context/ModelContext';
import { QueryBuilder } from './QueryBuilder/QueryBuilder';
import { Cell, Formula, RefUtils, Variable } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { Configuration, ConfigurationSpec, ConfigurationType, Data, IoType } from '@methodset/endpoint-client-ts';
import { QueryParams } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { ConfigurationUtils } from 'utils/ConfigurationUtils';
import './QueryDialog.less';

export type ChangeCallback = (formula: string) => void;
export type CancelCallback = () => void;

export type QueryDialogProps = {
    className?: string,
    cell: Cell,
    formula?: string,
    onChange: ChangeCallback,
    onCancel: CancelCallback
}

export type QueryDialogState = {
    errorMessage?: string
}

export class QueryDialog extends PureComponent<QueryDialogProps, QueryDialogState> {

    static contextType = ModelContext;

    private builderRef = React.createRef<QueryBuilder>();
    private queryId: string | undefined;
    private configuration: Configuration | undefined;
    private queryParams: QueryParams | undefined;

    constructor(props: QueryDialogProps) {
        super(props);
        this.state = {};
        this.handleQueryTouch = this.handleQueryTouch.bind(this);
        this.handleQueryChange = this.handleQueryChange.bind(this);
        this.handleOkClick = this.handleOkClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    private handleQueryTouch(): void {
        this.setState({ errorMessage: undefined });
    }

    private handleQueryChange(queryId: string, configuration: Configuration, queryParams: QueryParams): void {
        const calculator = this.context.calculator;
        const formula = this.generateFormula(queryId, configuration, queryParams);
        // Check if formula can be parsed. Since an overall parse error 
        // would prevent it from being edited, check here so that it can
        // be fixed before the edit dialog is dismissed.
        if (!calculator.executor.formulaEvaluator.isSyntaxValid(formula)) {
            this.setState({ errorMessage: "Syntax error in an expression." });
            return;
        }
        this.props.onChange(formula);
    }

    private handleOkClick(): void {
        this.builderRef.current!.submit();
    }

    private handleCancelClick(): void {
        this.props.onCancel();
    }

    private parseQueryFormula(): void {
        this.configuration = {};
        this.queryParams = {};
        if (this.queryId || !this.props.formula) {
            return;
        }
        let formula: Formula;
        const calculator = this.context.calculator;
        try {
            formula = calculator.executor.formulaDecomposer.parseFormula(this.props.cell, this.props.formula);
            if (formula.name !== "QUERY") {
                // Invalid query function, skip.
                return;
            }
        } catch (e) {
            // Could not parse, skip
            return;
        }

        let args = formula.args;
        this.queryId = args[0];

        let i = 1;
        while (i < args.length) {
            let arg = args[i];
            if (CoreUtils.isString(arg)) {
                this.addToQueryParams(arg, this.queryParams);
            } else if (arg instanceof Formula && arg.name === "CONCATENATE") {
                this.addToConfiguration(arg, this.configuration);
            }
            i++;
        }
    }

    private addToQueryParams(arg: string, queryParams: QueryParams): void {
        const tuple = arg.split("=");
        if (tuple.length === 2) {
            queryParams[tuple[0]] = tuple[1];
        }
    }

    private addToConfiguration(formula: Formula, configuration: Configuration): void {
        const args = formula.args;
        let i = 0;
        while (i < args.length) {
            let arg = args[i];
            if (arg === "params=") {
                // Skip and is will be re-added when editing is complete.
                i += 1;
            } else if (arg.endsWith(":")) {
                // This is a key/value pair, strip off the tailing ":".
                if (arg.startsWith(",")) {
                    arg = arg.substring(1, arg.length - 1);
                } else {
                    arg = arg.substring(0, arg.length - 1);
                }
                // Decompose the value (i.e., formula) to get the inner value.
                let result = this.findQueryValue(args[i + 1]);
                if (result) {
                    let value = result[0];
                    let isFormula = result[1];
                    let data: Data;
                    if (isFormula) {
                        // If the value is a formula, set the data as an expression type.
                        data = {
                            type: IoType.EXPRESSION,
                            value: `\${${value}}`
                        }
                    } else if (value.startsWith("[") && value.endsWith("]")) {
                        // Parse if items if the value is an array.
                        value = value.substring(1, value.length - 1);
                        if (value.length === 0) {
                            i += 2;
                            continue;
                        }
                        const items = value.split(/[, ]+/);
                        data = {
                            type: IoType.LIST,
                            value: items
                        }
                    } else {
                        // Add the value as text (default).
                        data = {
                            type: IoType.TEXT,
                            value: value
                        }
                    }
                    configuration[arg] = data;
                }
                i += 2;
            } else {
                // Invalid arg, abort.
                return;
            }
        }
    }

    private findQueryValue(arg: Formula | string | any): [string, boolean] | undefined {
        if (typeof arg === "string") {
            return [arg, false];
        } else if (arg instanceof Formula) {
            let formula = arg as Formula;
            // Check if the formula is wrapped with encode(<formula>). Unwrap
            // to get to the target formula.
            if (formula.name === "ENCODE") {
                let args = formula.args;
                if (args.length !== 1) {
                    // Invalid syntax, encode takes a single argument.
                    return undefined;
                }
                arg = args[0];
                if (CoreUtils.isString(arg)) {
                    return [arg, false];
                } else if (arg instanceof Variable) {
                    const variable = arg as Variable;
                    return [variable.id, true];
                } else if (arg instanceof Formula) {
                    formula = arg as Formula;
                    return [formula.fn, true];
                } else if (RefUtils.isRef(arg)) {
                    return [arg.toString(), true];
                } else {
                    // Invalid syntax.
                    return [arg.toString(), false];
                }

            } else {
                return [formula.fn, true];
            }
        } else {
            // Invalid syntax.
            return undefined;
        }
    }

    private buildVariableSpecs(): ConfigurationSpec[] {
        const calculator = this.context.calculator;
        const variables = calculator.variables;
        const configurationSpecs: ConfigurationSpec[] = [];
        variables.forEach((variable: Variable) => {
            const configurationSpec = ConfigurationUtils.createConfigurationSpec(
                ConfigurationType.TEXT,
                variable.id,
                variable.name,
                variable.description
            );
            configurationSpecs.push(configurationSpec!);
        });
        return configurationSpecs;
    }

    private generateFormula(queryId: string | undefined, configuration: Configuration, queryParams: QueryParams): string {
        if (!queryId) {
            return "=QUERY('', CONCATENATE('', ''))";
        } else {
            const configPart = this.generateConfigPart(configuration);
            const queryPart = this.generateQueryPart(queryParams);
            return `=QUERY('${queryId}', CONCATENATE(${configPart})${queryPart})`;
        }
    }

    private generateConfigPart(configuration: Configuration): string {
        let query: string = "'params='";
        const values = Object.entries(configuration);
        let index = 0;
        for (const [key, data] of values) {
            let parameter;
            const value = data.value;
            //if (value.length === 0) {
            if (value === undefined) {
                continue;
            }
            if (data.type === IoType.EXPRESSION && CoreUtils.isString(value)) {
                if (value.startsWith("${") && value.endsWith("}")) {
                    // Strip "=" and get reference.
                    const variable = value.substring(2, value.length - 1);
                    parameter = `'${index === 0 ? "" : ","}${key}:', ENCODE(${variable})`;
                } else {
                    // Encode string value.
                    parameter = `'${index === 0 ? "" : ","}${key}:', ENCODE('${value}')`;
                }
            } else {
                // Force value to string.
                let param;
                if (Array.isArray(value)) {
                    param = `'[${value.toString()}]'`;
                } else {
                    param = `'${value.toString()}'`;
                }
                parameter = `'${index === 0 ? "" : ","}${key}:', ENCODE(${param})`;
            }
            query += `, ${parameter}`;
            index++;
        }
        return query;
    }

    private generateQueryPart(queryParams: QueryParams): string {
        let query: string = "";
        const values = Object.entries(queryParams);
        for (const [key, value] of values) {
            query += `, '${key}=${value}'`;
        }
        return query;
    }

    public render(): ReactElement {
        // Need to parse here because context is not available in constructor.
        // Could put in componentDidMount(), but would require maintaining state.
        // TODO: bind and call in ctor
        this.parseQueryFormula();
        return (
            <Modal
                className="x-querydialog"
                centered
                title="Dataset Query Editor"
                visible={true}
                width={Globals.DIALOG_WIDTH_DOUBLE}
                okText="Run"
                onOk={this.handleOkClick}
                onCancel={this.props.onCancel}
            >
                <QueryBuilder
                    ref={this.builderRef}
                    result="formula"
                    queryId={this.queryId}
                    configuration={this.configuration}
                    queryParams={this.queryParams}
                    variableSpecs={this.buildVariableSpecs()}
                    excludes={["format", "api_key"]}
                    wideLayout={true}
                    onTouch={this.handleQueryTouch}
                    onChange={this.handleQueryChange}
                />
                <div className="x-querydialog-error">{this.state.errorMessage}</div>
            </Modal>
        );
    }

}
