import { ReactElement } from 'react';
import { Empty } from 'antd';
import classNames from 'classnames';
import './NoData.less';

export type NoDataProps = {
    className?: string,
    text: string
};

export const NoData = (props: NoDataProps): ReactElement => {

    return (
        <Empty
        className={classNames("x-nodata", props.className)}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={props.text}
        />
    )
}
