import { ParseCallback, UpdateCallback } from "@methodset/calculator-ts"
import { WidgetConfiguration, WidgetType } from '@methodset/model-client-ts';
import { CartesianWidgetSync } from "./CartesianWidgetSync";
import { ChangeListWidgetSync } from "./ChangeListWidgetSync";
import { DividerWidgetSync } from "./DividerWidgetSync";
import { InputWidgetSync } from "./InputWidgetSync";
import { ListWidgetSync } from "./ListWidgetSync";
import { PolarWidgetSync } from "./PolarWidgetSync";
import { SelectWidgetSync } from "./SelectWidgetSync";
import { SwitchWidgetSync } from "./SwitchWidgetSync";
import { TableWidgetSync } from "./TableWidgetSync";
import { TextWidgetSync } from "./TextWidgetSync";
import { ValueWidgetSync } from "./ValueWidgetSync";
import { WatchlistWidgetSync } from "./WatchlistWidgetSync";
import { InputFlowWidgetSync } from "./InputFlowWidgetSync";
import { FilterWidgetSync } from "./FilterWidgetSync";

export interface WidgetSync {
    parser: ParseCallback;
    updater: UpdateCallback;
}

export class WidgetSyncFactory {

    public static createSync(configuration: WidgetConfiguration): WidgetSync | null {
        switch (configuration.type) {
            case WidgetType.AREA_CHART:
            case WidgetType.BAR_CHART:
            case WidgetType.COLUMN_CHART:
            case WidgetType.LINE_CHART:
                return {
                    parser: CartesianWidgetSync.parser,
                    updater: CartesianWidgetSync.updater
                };
            case WidgetType.PIE_CHART:
                return {
                    parser: PolarWidgetSync.parser,
                    updater: PolarWidgetSync.updater
                };
            case WidgetType.TABLE:
                return {
                    parser: TableWidgetSync.parser,
                    updater: TableWidgetSync.updater
                };
            case WidgetType.LIST:
                return {
                    parser: ListWidgetSync.parser,
                    updater: ListWidgetSync.updater
                };
            case WidgetType.TEXT:
                return {
                    parser: TextWidgetSync.parser,
                    updater: TextWidgetSync.updater
                };
            case WidgetType.VALUE:
                return {
                    parser: ValueWidgetSync.parser,
                    updater: ValueWidgetSync.updater
                };
            case WidgetType.CHANGE_LIST:
                return {
                    parser: ChangeListWidgetSync.parser,
                    updater: ChangeListWidgetSync.updater
                };
            case WidgetType.INPUT:
                return {
                    parser: InputWidgetSync.parser,
                    updater: InputWidgetSync.updater
                };
            case WidgetType.INPUT_FLOW:
                return {
                    parser: InputFlowWidgetSync.parser,
                    updater: InputFlowWidgetSync.updater
                };
            case WidgetType.FILTER:
                return {
                    parser: FilterWidgetSync.parser,
                    updater: FilterWidgetSync.updater
                };
            case WidgetType.SELECT:
                return {
                    parser: SelectWidgetSync.parser,
                    updater: SelectWidgetSync.updater
                };
            case WidgetType.SWITCH:
                return {
                    parser: SwitchWidgetSync.parser,
                    updater: SwitchWidgetSync.updater
                };
            case WidgetType.DIVIDER:
                return {
                    parser: DividerWidgetSync.parser,
                    updater: DividerWidgetSync.updater
                };
            case WidgetType.WATCHLIST:
                return {
                    parser: WatchlistWidgetSync.parser,
                    updater: WatchlistWidgetSync.updater
                };
            default:
                return null;
        }
    }

}