import { ReactElement } from 'react';
import { Col, FormInstance, Row } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { Alert, Condition, Inputs } from '@methodset/model-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { ConditionEditor } from './ConditionEditor/ConditionEditor';
import { Calculator, Variable } from '@methodset/calculator-ts';
import { InputEditor } from 'containers/Components/Widgets/InputWidgetViewer/InputEditor/InputEditor';
import { CoreUtils } from 'utils/CoreUtils';
import { FormValue } from 'components/FormValue/FormValue';
import update from 'immutability-helper';
import './AlertSetup.less';

export type ChangeCallback = (inputs: Inputs) => void;

export type AlertSetupProps = {
    formRef: React.RefObject<FormInstance>,
    alert: Alert,
    inputs?: Inputs,
    calculator: Calculator,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    inputs: {
        configuration: {},
        conditions: [{
            variableId: undefined as any,
            opType: undefined as any,
            value: undefined as any
        }]
    } as Inputs
}

export const AlertSetup = (props: AlertSetupProps): ReactElement => {

    const handleConditionChange = (condition: Condition, index: number): void => {
        const inputs = update(props.inputs, {
            conditions: {
                [index]: { $set: condition }
            }
        });
        props.onChange(inputs);
    }

    const handleInputChange = (value: any, variable: Variable): void => {
        const inputs = update(props.inputs, {
            configuration: {
                [variable.id]: { $set: value }
            }
        });
        props.onChange(inputs);
    }

    const inputVariables = (): Variable[] => {
        return props.calculator.variables.filter(variable => props.alert.inputIds.includes(variable.id));
    }

    const inputConditions = (): Condition[] => {
        if (props.inputs.conditions && props.inputs.conditions.length === props.alert.conditions.length) {
            return props.inputs.conditions;
        } else {
            const conditions = [];
            for (const condition of props.alert.conditions) {
                conditions.push({
                    variableId: condition.variableId,
                    opType: condition.opType,
                    value: condition.value,
                    logicalType: condition.logicalType
                });
            }
            return conditions;
        }
    }

    const variables = inputVariables();
    const conditions = inputConditions();

    return (
        <div>
            <div className="x-alertsetup-header">
                <div className="x-alertsetup-name">{props.alert.name}</div>
                <div className="x-alertsetup-desc">{props.alert.description}</div>
            </div>
            <Spacer direction="vertical">
                {variables.length > 0 &&
                    <>
                        <div className="x-alertsetup-instruct">This alert requires the following inputs:</div>
                        <div className="x-alertsetup-input">
                            <Row gutter={Globals.FORM_GUTTER_COL}>
                                {variables.map(variable => (
                                    <Col span={12}>
                                        <FormItem
                                            {...Globals.FORM_LAYOUT}
                                            key={variable.id}
                                            formRef={props.formRef}
                                            label={variable.name}
                                            name={variable.id}
                                            info={variable.description}
                                            rules={[{
                                                required: variable.isRequired,
                                                message: "Please enter a value."
                                            }]}
                                        >
                                            <InputEditor
                                                calculator={props.calculator}
                                                value={props.inputs.configuration[variable.id]}
                                                variableId={variable.id}
                                                useDefault={false}
                                                doEmbed={true}
                                                onChange={(value, variable) => handleInputChange(value, variable)}
                                            />
                                        </FormItem>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                        <div className="x-alertsetup-instruct">And will be triggered:</div>
                    </>
                }
                {variables.length === 0 &&
                    <div className="x-alertsetup-instruct">This alert will be triggered:</div>
                }
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={props.formRef}
                    name="conditions"
                    info="The conditions that will decide if the alert should be triggered."
                    required={true}
                    rules={[{
                        validator: () => {
                            for (const condition of conditions) {
                                if (!condition.variableId || !condition.opType || CoreUtils.isEmpty(condition.value)) {
                                    return Promise.reject(`Please fill all conditions.`);
                                }
                            }
                            return Promise.resolve();
                        }
                    }]}
                >
                    <FormValue value={conditions}>
                        <Spacer direction="vertical" fill>
                            {conditions.map((condition: Condition, index: number) => (
                                <ConditionEditor
                                    key={index}
                                    index={index}
                                    condition={condition}
                                    template={props.alert.conditions[index]}
                                    calculator={props.calculator}
                                    onChange={handleConditionChange}
                                />
                            ))}
                        </Spacer>
                    </FormValue>
                </FormItem>
            </Spacer>
        </div>
    )

}

AlertSetup.defaultProps = defaultProps;