import { ReactElement } from 'react';
import { List } from 'antd';
import { Calculator, Range } from '@methodset/calculator-ts';
import { ValueDisplay } from 'components/ValueDisplay/ValueDisplay';
import { ChangeListWidgetConfiguration } from '@methodset/model-client-ts';
import { WidgetUtils } from 'utils/WidgetUtils';
import './ChangeListWidgetViewer.less';

export type ChangeListWidgetViewerProps = {
    calculator: Calculator,
    configuration: ChangeListWidgetConfiguration
}

export const ChangeListWidgetViewer = (props: ChangeListWidgetViewerProps): ReactElement => {

    const buildRow = (row: any[]): ReactElement => {
        return (
            <>
                <List.Item className="x-changelistwidgetviewer-row">
                    <div className="x-changelistwidgetviewer-label">
                        {row[0]}
                    </div>
                    <div>
                        <ValueDisplay type="direction" value={row[1]} />
                    </div>
                </List.Item>
            </>
        )
    }

    const findMaxSize = (...ranges: Range[]): number => {
        return ranges.reduce((max: number, range: Range) => {
            return Math.max(max, range.size);
        }, 0);
    }

    const buildData = (): any[] => {
        const matrix: any[] = [];
        const labelRange = WidgetUtils.buildRange(props.calculator, props.configuration.labelRangeId);
        const changeRange = WidgetUtils.buildRange(props.calculator, props.configuration.changeRangeId);
        if (!labelRange || !changeRange) {
            return matrix;
        }
        const columns = [labelRange.values(true), changeRange.values(true)];
        const length = findMaxSize(labelRange, changeRange);
        for (let r = 0; r < length; r++) {
            const row: any[] = [];
            for (let c = 0; c < columns.length; c++) {
                let column = columns[c];
                const subrow = column[r];
                const value = subrow ? subrow[0] : undefined;
                row.push(value);
            }
            matrix.push(row);
        }
        return matrix;
    }

    const buildView = (): ReactElement => {
        const data = buildData();
        return (
            <div>
                <List
                    className="x-changelistwidgetviewer-list"
                    size="default"
                    dataSource={data}
                    renderItem={(item) => buildRow(item)}
                />
            </div>
        );
    }

    return (
        <div className="x-changelistwidgetviewer">
            {buildView()}
        </div>
    );
}

