import { ReactElement } from 'react';
import { AlignmentType, ColorType, DisplayType, JustificationType, SizeType, WeightType } from '@methodset/model-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { SizeStyle } from './SizeStyle/SizeStyle';
import { WeightStyle } from './WeightStyle/WeightStyle';
import { ColorStyle } from './ColorStyle/ColorStyle';
import { JustificationStyle } from './JustificationStyle/JustificationStyle';
import { DisplayStyle } from './DisplayStyle/DisplayStyle';
import { AlignmentStyle } from './AlignmentStyle/AlignmentStyle';
import update from 'immutability-helper';
import './WidgetStyle.less';

export type StyleType = "size" | "weight" | "color" | "justification" | "alignment" | "display";

export interface StyleData {
    weight?: WeightType;
    size?: SizeType;
    color?: ColorType;
    justification?: JustificationType;
    alignment?: AlignmentType;
    display?: DisplayType;
}

export type ChangeCallback = (value: StyleData) => void;

export type WidgetStyleProps = {
    value?: StyleData,
    styles?: StyleType[],
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    value: {},
    styles: ["size", "weight", "color", "justification" , "alignment", "display"]
}

export const WidgetStyle = (props: WidgetStyleProps): ReactElement => {

    const handleSizeChange = (size: SizeType): void => {
        const value = update(props.value, {
            size: { $set: size }
        });
        props.onChange(value);
    }

    const handleWeightChange = (weight: WeightType): void => {
        const value = update(props.value, {
            weight: { $set: weight }
        });
        props.onChange(value);
    }

    const handleColorChange = (color: ColorType): void => {
        const value = update(props.value, {
            color: { $set: color }
        });
        props.onChange(value);
    }

    const handleJustificationChange = (justification: JustificationType): void => {
        const value = update(props.value, {
            justification: { $set: justification }
        });
        props.onChange(value);
    }

    const handleAlignmentChange = (alignment: AlignmentType): void => {
        const value = update(props.value, {
            alignment: { $set: alignment }
        });
        props.onChange(value);
    }

    const handleDisplayChange = (display: DisplayType): void => {
        const value = update(props.value, {
            display: { $set: display }
        });
        props.onChange(value);
    }

    return (
        <div className="x-widgetstyle">
            <Spacer>
                {props.styles.includes("size") &&
                    <SizeStyle
                        size={props.value.size}
                        onChange={(size) => handleSizeChange(size)}
                    />
                }
                {props.styles.includes("weight") &&
                    <WeightStyle
                        weight={props.value.weight}
                        onChange={(weight) => handleWeightChange(weight)}
                    />
                }
                {props.styles.includes("color") &&
                    <ColorStyle
                        color={props.value.color}
                        onChange={(color) => handleColorChange(color)}
                    />
                }
                {props.styles.includes("justification") &&
                    <JustificationStyle
                        justification={props.value.justification}
                        onChange={(justification) => handleJustificationChange(justification)}
                    />
                }
                {props.styles.includes("alignment") &&
                    <AlignmentStyle
                        alignment={props.value.alignment}
                        onChange={(alignment) => handleAlignmentChange(alignment)}
                    />
                }
                {props.styles.includes("display") &&
                    <DisplayStyle
                        display={props.value.display}
                        onChange={(display) => handleDisplayChange(display)}
                    />
                }
            </Spacer>
        </div>

    )
}

WidgetStyle.defaultProps = defaultProps;
