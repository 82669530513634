import { PureComponent, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, List, Row } from 'antd';
import { blue, green, purple } from '@ant-design/colors';
import { CheckSquareFilled } from '@ant-design/icons';
import { ResponsiveBox } from 'components/ResponsiveBox/ResponsiveBox';
import { Spacer } from 'components/Spacer/Spacer';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Pricing } from '../Pricing/Pricing';
import platform_overview_image from 'images/platform_overview.png';
import screenshot_fund_overview_image from 'images/screenshot_fund_overview-2023-06-22.png';
import screenshot_model_image from 'images/screenshot_model-2022-12-19.png';
import screenshot_dataset_image from 'images/screenshot_dataset-2022-10-14.png';
import screenshot_workflow_image from 'images/screenshot_workflow-2022-10-14.png';
import computer_gear_image from 'images/computer_gear.png';
import data_monitoring_image from 'images/data_monitoring.png';
import data_share_image from 'images/data_share.png';
import './Business.less';

export class Business extends PureComponent {

    public render(): ReactElement {
        return (
            <div className="x-business">
                <ResponsiveBox
                    className="x-business-overview"
                    md={{ className: 'x-business-overview-md' }}
                    xs={{ className: 'x-business-overview-xs' }}
                >
                    <Row gutter={8}>
                        <Col
                            lg={{ span: 10 }}
                            xs={{ span: 24 }}
                        >
                            <Row>
                                <Col span={22} offset={1}>
                                    <div className="x-business-overview-title">
                                        Financial Data Management Platform
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={22} offset={1}>
                                    <div className="x-business-overview-subtitle">
                                        Customized data processing, analytics, and monitoring.
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            lg={{ span: 14 }}
                            xs={{ span: 24 }}
                        >
                            <div>
                                <img className="x-business-overview-graphic" src={platform_overview_image} alt="platform_overview" />
                            </div>
                        </Col>
                    </Row>
                </ResponsiveBox>
                <div id="introduction" className="x-business-description">
                    <Row>
                        <Col
                            xs={{ span: 24, offset: 0 }}
                        >
                            <p>
                                MethodSet is a no-code data management platform that can be used to easily build
                                processes to integrate, analyze, and monitor financial data. Building blocks
                                can be assembled to aggregate data sources, build custom calculators, and trigger workflows
                                to store historical datasets. The resulting computations can be used to create real-time
                                dashboards or can be accessed via secure API. The platform lives 100% in the cloud, so there
                                is no hardware to provision and no code to write, allowing you to get up and running quickly.
                                Manage, automate, and track all your financial data as easily as using a spreadsheet.
                            </p>
                        </Col>
                    </Row>
                </div>
                <div id="summary" className="x-business-section">
                    <div className="x-business-title">Process, Analyze, and Monitor</div>
                    <Row gutter={[32, 20]}>
                        <Col
                            md={{ span: 8, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                        >
                            <Card
                                className="x-business-blurb"
                                cover={
                                    <img
                                        className="x-business-usecase-image"
                                        src={computer_gear_image}
                                        alt="computer_gear"
                                    />
                                }
                            >
                                <Card.Meta
                                    title="Process: Aggregate Data"
                                    description={
                                        <span>
                                            Combine and clean data from provider APIs, bank accounts, emails, files, databases,
                                            or scraped from web pages. Create real-time or stored datasets.
                                        </span>
                                    }
                                />
                            </Card>
                        </Col>
                        <Col
                            md={{ span: 8, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                        >
                            <Card
                                className="x-business-blurb"
                                cover={
                                    <img
                                        className="x-business-usecase-image"
                                        src={data_share_image}
                                        alt="data_share"
                                    />
                                }
                            >
                                <Card.Meta
                                    title="Analyze: Perform Calculations"
                                    description={
                                        <span>
                                            Model and analyze financial data with the integrated calculator. Use functions and
                                            formulas to create computed results, just like a spreadsheet.
                                        </span>
                                    }
                                />
                            </Card>
                        </Col>
                        <Col
                            md={{ span: 8, offset: 0 }}
                            xs={{ span: 24, offset: 0 }}
                        >
                            <Card
                                className="x-business-blurb"
                                cover={
                                    <img
                                        className="x-business-usecase-image"
                                        src={data_monitoring_image}
                                        alt="data_monitoring"
                                    />
                                }
                            >
                                <Card.Meta
                                    title="Monitor: Display Results"
                                    description={
                                        <span>
                                            Stay on top of the latest financial data with dashboards and API access. Continually
                                            track changes with real-time updates and notifications.
                                        </span>
                                    }
                                />
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div id="checklist" className="x-business-section">
                    <Row>
                        <Col
                            md={{ span: 18, offset: 3 }}
                            xs={{ span: 24, offset: 0 }}
                        >
                            <div>
                                <Spacer className="x-business-checkbox">
                                    <CheckSquareFilled />
                                    <span>Automate data workflows and replace existing Excel-based analysis and reporting.</span>
                                </Spacer>
                                <Spacer className="x-business-checkbox">
                                    <CheckSquareFilled />
                                    <span>Bring data together into comprehensive real-time dashboards with no code to write.</span>
                                </Spacer>
                                <Spacer className="x-business-checkbox">
                                    <CheckSquareFilled />
                                    <span>Integrate Bloomberg data with other data sources to create unique insights.</span>
                                </Spacer>
                                <Spacer className="x-business-checkbox">
                                    <CheckSquareFilled />
                                    <span>Auto-refresh data to continuously update calculations and views.</span>
                                </Spacer>
                                <Spacer className="x-business-checkbox">
                                    <CheckSquareFilled />
                                    <span>Build custom models to perform forecasting and scenario analysis.</span>
                                </Spacer>
                                <Spacer className="x-business-checkbox">
                                    <CheckSquareFilled />
                                    <span>Snapshot data over time and store as a timeseries to track trends and history.</span>
                                </Spacer>
                                <Spacer className="x-business-checkbox">
                                    <CheckSquareFilled />
                                    <span>Monitor important data points and computed values, and get alerted when conditions are met.</span>
                                </Spacer>
                                <Spacer className="x-business-checkbox">
                                    <CheckSquareFilled />
                                    <span>Combine business bank account information with user metrics to analyze performance indicators.</span>
                                </Spacer>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div id="features" className="x-business-section">
                    <div className="x-business-title">Features</div>
                    <Row className="x-business-feature-section" gutter={[20, 20]}>
                        <Col
                            md={{ span: 6, offset: 0, order: 2 }}
                            sm={{ span: 24, offset: 0, order: 1 }}
                        >
                            <List
                                className="x-business-feature-list"
                                size="large"
                                header={(
                                    <div className="x-business-feature-title">Views</div>
                                )}
                                dataSource={[
                                    "Use numerical and textual data based on datasets and computed values to build views.",
                                    "Combine widgets to construct more complex applets for composite views.",
                                    "Configure applet inputs to affect calculations for linked and interactive views.",
                                    // "Use applets in dashboards or embed into existing web pages."
                                ]}
                                renderItem={item => (
                                    <List.Item className="x-business-feature-item">
                                        {item}
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col
                            md={{ span: 18, offset: 0, order: 1 }}
                            sm={{ span: 24, offset: 0, order: 2 }}
                        >
                            <img className="x-business-feature-image" src={screenshot_fund_overview_image} alt="dashboard" />
                        </Col>
                    </Row>
                    <Row className="x-business-feature-section" gutter={[20, 20]}>
                        <Col
                            md={{ span: 6, offset: 0, order: 1 }}
                            sm={{ span: 24, offset: 0, order: 1 }}
                        >
                            <List
                                className="x-business-feature-list"
                                size="large"
                                header={(
                                    <div className="x-business-feature-title">Datasets</div>
                                )}
                                dataSource={[
                                    "Clean and prepare data through a comprehensive set of processors, transformers, and filters.",
                                    "Built-in support for REST APIs, web page scraping, financial accounts, and databases.",
                                    "Import datasets and use in models, workflows, and visualizations."
                                ]}
                                renderItem={item => (
                                    <List.Item className="x-business-feature-item">
                                        {item}
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col
                            md={{ span: 18, offset: 0, order: 2 }}
                            sm={{ span: 24, offset: 0, order: 2 }}
                        >
                            <img className="x-business-feature-image" src={screenshot_dataset_image} alt="dataset" />
                        </Col>
                    </Row>
                    <Row className="x-business-feature-section" gutter={[20, 20]}>
                        <Col
                            md={{ span: 6, offset: 0, order: 2 }}
                            sm={{ span: 24, offset: 0, order: 1 }}
                        >
                            <List
                                className="x-business-feature-list"
                                size="large"
                                header={(
                                    <div className="x-business-feature-title">Models</div>
                                )}
                                dataSource={[
                                    "Use the powerful integrated calculator to perform custom computations on data.",
                                    "Easily import data into models by connecting to external data sources and providers.",
                                    "Define variables and use in formulas and queries to affect computations."
                                    // "Link dashboard inputs to variables to build interactive views."
                                ]}
                                renderItem={item => (
                                    <List.Item className="x-business-feature-item">
                                        {item}
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col
                            md={{ span: 18, offset: 0, order: 1 }}
                            sm={{ span: 24, offset: 0, order: 2 }}
                        >
                            <img className="x-business-feature-image" src={screenshot_model_image} alt="model" />
                        </Col>
                    </Row>
                    <Row className="x-business-feature-section" gutter={[20, 20]}>
                        <Col
                            md={{ span: 6, offset: 0, order: 1 }}
                            sm={{ span: 24, offset: 0, order: 1 }}
                        >
                            <List
                                className="x-business-feature-list"
                                size="large"
                                header={(
                                    <div className="x-business-feature-title">Workflows</div>
                                )}
                                dataSource={[
                                    "Build and trigger workflows to automate data pipelines.",
                                    "Store data in our managed databases, with no infrastructure to setup.",
                                    "Create timeseries to track trends, history, and data changes."
                                ]}
                                renderItem={item => (
                                    <List.Item className="x-business-feature-item">
                                        {item}
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col
                            md={{ span: 18, offset: 0, order: 2 }}
                            sm={{ span: 24, offset: 0, order: 2 }}
                        >
                            <img className="x-business-feature-image" src={screenshot_workflow_image} alt="workflow" />
                        </Col>
                    </Row>
                </div>

                <div id="pricing" className="x-business-section">
                    <div className="x-business-title">Plans and Pricing</div>
                    <div className="x-business-try">
                        <div>Try our digital tools FREE for one month!</div>
                    </div>
                    <Row justify="center" gutter={[64, 20]}>
                        <Col>
                            <Pricing
                                color={blue}
                                title="Individual"
                                price="$10/month"
                                description="Track personal finances with advanced models."
                                bullets={[
                                    "Access for a single user.",
                                    "Choose from a collection of pre-built apps to track your personal finances.",
                                    "Link personal financial accounts for use in models and apps.",
                                    "Build your own apps with real-time updates and visualizations."
                                ]}

                            >
                                <div className="x-business-register">
                                    <Link to={RouteBuilder.register("individual")}>Register Now</Link>
                                </div>
                            </Pricing>
                        </Col>
                        <Col>
                            <Pricing
                                color={green}
                                title="Business"
                                price="$50/month"
                                description="Build models and collaborate with team members."
                                bullets={[
                                    "Access for three users.",
                                    "Collaborate with other team members within your organization.",
                                    "Replace existing spreadsheets with automated processes.",
                                    "Track and store historical data for forecasting and trend analysis."
                                ]}
                            >
                                <div className="x-business-register">
                                    <Link to={RouteBuilder.register("business")}>Register Now</Link>
                                </div>
                            </Pricing>
                        </Col>
                        <Col>
                            <Pricing
                                color={purple}
                                title="Enterprise"
                                price="Contact us for a quote"
                                description="Implement back-office processing solutions."
                                bullets={[
                                    "Access for any number of users.",
                                    "Connect your own data sources to develop customized models.",
                                    "Process and store feeds from data vendors.",
                                    "Bring all your data together in custom real-time dashboards."
                                ]}
                            >
                                <div className="x-business-register">
                                    <Link to={RouteBuilder.register("enterprise")}>Register Now</Link>
                                </div>
                            </Pricing>
                        </Col>
                    </Row>
                </div>
                {/* 
                <div className="x-business-section">
                    <div className="x-business-title">Overview Demo</div>
                    <div>
                        Check out the demo below to see the main features in action.
                    </div>
                    <div className="x-business-video">
                        <iframe src="https://www.youtube.com/embed/OVYlyhyT8BU" title="Overview Demo" allow="fullscreen"></iframe>
                    </div>
                </div> */}
            </div>
        )
    }

}
