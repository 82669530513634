import React, { PureComponent, ReactElement } from 'react';
import { Col, FormInstance, Row, Switch } from 'antd';
import { Calculator, RefType } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { InputWidgetConfiguration, JustificationType, LocationType, WidgetType } from '@methodset/model-client-ts';
import { LocationSelector } from 'containers/Components/Widgets/Selectors/LocationSelector';
import { JustificationSelector } from 'containers/Components/Widgets/Selectors/JustificationSelector';
import { RefEditor } from '../../RefEditor/RefEditor';
import update from 'immutability-helper';
import './InputWidgetEditor.less';

export type ChangeCallback = (configuration: InputWidgetConfiguration) => void;

export type InputWidgetEditorProps = typeof InputWidgetEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    defaults: ReactElement,
    calculator: Calculator,
    configuration?: InputWidgetConfiguration,
    onChange: ChangeCallback
}

export class InputWidgetEditor extends PureComponent<InputWidgetEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.INPUT,
        variableId: undefined as any,
        labelLocation: undefined as any,
        justification: JustificationType.LEFT
    } as InputWidgetConfiguration;

    static defaultProps = {
        configuration: InputWidgetEditor.DefaultConfiguration
    }

    constructor(props: InputWidgetEditorProps) {
        super(props);
        this.handleVariableChange = this.handleVariableChange.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleJustificationChange = this.handleJustificationChange.bind(this);
        this.handleDefaultChange = this.handleDefaultChange.bind(this);
    }

    private handleVariableChange(variableId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            variableId: { $set: variableId! }
        });
        this.props.onChange(configuration);
    }

    private handleLocationChange(labelLocation: LocationType): void {
        const configuration = update(this.props.configuration, {
            labelLocation: { $set: labelLocation }
        });
        this.props.onChange(configuration);
    }

    private handleJustificationChange(justification: JustificationType): void {
        const configuration = update(this.props.configuration, {
            justification: { $set: justification }
        });
        this.props.onChange(configuration);
    }

    private handleDefaultChange(useDefault: boolean): void {
        const configuration = update(this.props.configuration, {
            useDefault: { $set: useDefault }
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        if (this.props.configuration === InputWidgetEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        return (
            <Row gutter={Globals.FORM_GUTTER_ROW}>
                <Col span={12}>
                    {this.props.defaults}
                </Col>
                <Col span={12}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Variable"
                        name="variable"
                        info="The variable to change when a selection is chosen."
                        required={true}
                        valuePropName="variableId"
                    >
                        <RefEditor
                            formRef={this.props.formRef}
                            allowClear={false}
                            internalOnly={true}
                            calculator={this.props.calculator}
                            refTypes={[RefType.VARIABLE]}
                            refId={this.props.configuration.variableId}
                            onChange={this.handleVariableChange}
                        />
                        {/* <VariableSelector
                            calculator={this.props.calculator}
                            variableId={this.props.configuration.variableId}
                            onChange={this.handleVariableChange}
                        /> */}
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Add Label"
                        name="label"
                        info="Adds a label to the given location, unselect to remove the label."
                    >
                        <LocationSelector
                            includes={[LocationType.LEFT, LocationType.TOP]}
                            value={this.props.configuration.labelLocation}
                            onChange={this.handleLocationChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Justification"
                        name="justification"
                        info="Justification of the widget."
                    >
                        <JustificationSelector
                            value={this.props.configuration.justification}
                            onChange={this.handleJustificationChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Use Default"
                        name="use-default"
                        info="If the input value is not set, use the value already in the variable cell."
                        valuePropName="checked"
                    >
                        <Switch
                            checked={!!this.props.configuration.useDefault}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            onChange={this.handleDefaultChange}
                        />
                    </FormItem>
                </Col>
            </Row>
        );
    }

}
