import { PureComponent, ReactElement } from 'react';
import { Button } from 'antd';
import { SelectOption } from '@methodset/model-client-ts';
import { SelectOptionEditor } from './SelectOptionEditor/SelectOptionEditor';
import update from 'immutability-helper';
import './SelectOptions.less';
import { Spacer } from 'components/Spacer/Spacer';

export type ChangeCallback = (options: SelectOption[]) => void;

export type SelectOptionsProps = typeof SelectOptions.defaultProps & {
    options?: SelectOption[],
    onChange: ChangeCallback
}

export class SelectOptions extends PureComponent<SelectOptionsProps> {

    static defaultProps = {
        options: [] as SelectOption[]
    }

    constructor(props: SelectOptionsProps) {
        super(props);
        this.handleOptionAdd = this.handleOptionAdd.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleOptionRemove = this.handleOptionRemove.bind(this);
    }

    private handleOptionAdd(): void {
        const option = {} as SelectOption;
        const options = update(this.props.options, {
            $push: [option]
        });
        this.props.onChange(options);
    }

    private handleOptionRemove(index: number): void {
        const options = update(this.props.options, {
            $splice: [[index, 1]]
        });
        this.props.onChange(options);
    }

    private handleOptionChange(option: SelectOption, index: number): void {
        const options = update(this.props.options, {
            [index]: { $set: option }
        });
        this.props.onChange(options);
    }

    public render(): ReactElement {
        return (
            <Spacer className="x-selectoptions" direction="vertical" alignment="top" fill>
                {this.props.options.map((option, index) => (
                    <SelectOptionEditor
                        key={index}
                        index={index}
                        option={option}
                        onChange={this.handleOptionChange}
                        onRemove={this.handleOptionRemove}
                    />
                ))}
                <div className="x-selectoptions-add">
                    <Button onClick={this.handleOptionAdd}>Add Option</Button>
                </div>
            </Spacer>
        );
    }
}
