import { ReactElement, useEffect, useState } from 'react';
import { Button, Col, Empty, FormInstance, Row, Select } from 'antd';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { CoreUtils } from 'utils/CoreUtils';
import { Label } from 'components/Label/Label';
import { StatusType } from 'constants/StatusType';
import { AccessType, IdUtils } from '@methodset/commons-shared-ts';
import { QueryHeader } from '@methodset/endpoint-client-ts';
import { QueryInfo } from './QueryInfo/QueryInfo';
import endpointService from 'services/EndpointService';
import axios from 'axios';
import './QueryList.less';
import { NoData } from 'components/NoData/NoData';

export type SelectCallback = (header: QueryHeader) => void;

export type QueryListProps = {
    formRef: React.RefObject<FormInstance>,
    queryId?: string,
    columns?: number,
    onSelect: SelectCallback
} & typeof defaultProps;

const defaultProps = {
    columns: 2
}

export const QueryList = (props: QueryListProps): ReactElement => {

    const [status, setStatus] = useState<string>(StatusType.INIT);
    const [header, setHeader] = useState<QueryHeader | undefined>();
    const [headers, setHeaders] = useState<QueryHeader[]>([]);
    const [accessType, setAccessType] = useState<AccessType>(props.queryId ? IdUtils.toAccessType(props.queryId, AccessType.PUBLIC) : AccessType.GROUP);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        loadData(accessType);
    }, []);

    const handleRetryLoad = (): void => {
        loadData(accessType);
    }

    const handleAccessChange = (accessType: AccessType): void => {
        setAccessType(accessType);
        loadData(accessType);
    }

    const handleVisibleToggle = (visible: boolean): void => {
        setIsVisible(true);
    }

    const handleHeaderChange = (header: QueryHeader): void => {
        props.onSelect(header);
        setIsVisible(false);
    }

    const handleHeaderCancel = (): void => {
        setIsVisible(false);
    }

    const readQueryHeadersRequest = (accessType: AccessType): Promise<any> => {
        const request = {
            accessType: accessType
        };
        return endpointService.readQueryHeaders(request,
            (response: any) => readQueryHeadersResponse(response),
            undefined, true
        );
    }

    const readQueryHeadersResponse = (response: any): void => {
        setHeaders(response.data.headers);
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        const rows = 2;
        const indices = Array.from(Array(props.columns * rows).keys());
        return (
            <div className="x-querylist-wrap">
                <Row className="x-querylist-panel" gutter={[Globals.GUTTER_SIZE, Globals.GUTTER_SIZE]}>
                    {indices.map(index => (
                        <Col
                            key={index}
                            md={{ span: 24 / props.columns }}
                        >
                            <div className="x-querylist-item">
                                <LoadSkeleton
                                    direction="vertical"
                                    status={isLoading ? "loading" : "failed"}
                                    onRetry={() => handleRetryLoad()}
                                >
                                    <LoadSkeleton.Input length="medium" />
                                    <LoadSkeleton.Input length="fill" />
                                    <LoadSkeleton.Input length="short" />
                                    <LoadSkeleton.Input length="short" />
                                </LoadSkeleton>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        );
    }

    const loadData = (accessType: AccessType): void => {
        const requests = [];
        requests.push(readQueryHeadersRequest(accessType));
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    const buildQueriesView = (): ReactElement => {
        return (
            <div className="x-querylist-wrap">
                <Row>
                    {headers.length === 0 &&
                        <div className="x-querylist-empty">
                            <NoData text="No datasets available." />
                        </div>
                    }
                </Row>
                {headers.length > 0 &&
                    <Row className="x-querylist-panel" gutter={[Globals.GUTTER_SIZE, Globals.GUTTER_SIZE]}>
                        {headers.map((header, index) => (
                            <Col md={{ span: 24 / props.columns }}>
                                <QueryInfo
                                    key={header.id}
                                    index={index}
                                    header={header}
                                    onSelect={handleHeaderChange}
                                />
                            </Col>
                        ))}
                    </Row>
                }
            </div>
        )
    }

    const buildDropdownView = (): ReactElement => {
        let queriesView;
        if (status === StatusType.LOADING) {
            queriesView = buildLoadingView(true);
        } else if (status === StatusType.FAILED) {
            queriesView = buildLoadingView(false);
        } else if (status === StatusType.READY) {
            queriesView = buildQueriesView();
        } else {
            queriesView = <></>;
        }
        return (
            <div className="x-querylist-dropdown">
                <Row className="x-querylist-header">
                    <Label className="x-querylist-access" label="Catalog">
                        <Select
                            className="x-querylist-select"
                            value={accessType}
                            onChange={(accessType) => handleAccessChange(accessType)}
                        >
                            {AccessType.array().map(type => (
                                <Select.Option key={type} value={type}>{CoreUtils.toProper(type, "_")}</Select.Option>
                            ))}
                        </Select>
                    </Label>
                </Row>
                {queriesView}
                <Row className="x-querylist-footer" justify="end">
                    <Button type="primary" onClick={() => handleHeaderCancel()}>Cancel</Button>
                </Row>
            </div>
        )
    }

    const loading = status === StatusType.LOADING;
    const query = headers.find(header => header.id === props.queryId);
    if (query && query !== header) {
        setHeader(query);
    }

    return (
        <div className="x-querylist">
            <Select
                placeholder="Select a dataset."
                allowClear={true}
                value={loading ? undefined : props.queryId}
                loading={loading}
                dropdownStyle={{ visibility: "hidden" }}
                onDropdownVisibleChange={handleVisibleToggle}
            >
                {header && <Select.Option key={header.id} value={header.id}>{header.name}</Select.Option>}
            </Select>
            {isVisible && buildDropdownView()}
        </div>
    );

}

QueryList.defaultProps = defaultProps;
