import { ReactElement, useContext } from 'react';
import { Select } from 'antd';
import { ModelContext } from 'context/ModelContext';
import { Applet } from '@methodset/model-client-ts';

export type ChangeCallback = (appletId: string) => void;

export type PanelSelectorProps = {
    className?: string,
    applet: Applet,
    panelId?: string,
    onChange: ChangeCallback
};

export const PanelSelector = (props: PanelSelectorProps): ReactElement => {

    return (
        <Select
            className={props.className}
            allowClear={true}
            placeholder="Select a panel."
            value={props.panelId}
            onChange={(panelId) => props.onChange(panelId)}
        >
            {props.applet.panels.map(panel => (
                <Select.Option key={panel.id} value={panel.id}>{panel.name}</Select.Option>
            ))}
        </Select>
    )
}
