import React, { ForwardedRef, MutableRefObject, ReactElement, useState } from 'react';
import { Button, Form, FormInstance, List, Modal } from 'antd';
import { ItemSpec, MenuButton } from 'components/MenuButton/MenuButton';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Globals } from 'constants/Globals';
import update from 'immutability-helper';
import './ListEditor.less';

export type EditHandler = (item: any) => void;
export type DoneHandler = (items: any[]) => void;
export type RenderFunction = (item: any) => ReactElement;

export type ListEditorProps = {
    id: string,
    name: string,
    items?: any[],
    editItem?: any;
    children: ReactElement | ReactElement[],
    renderItem: RenderFunction,
    onEdit: EditHandler,
    onSave: DoneHandler
} & typeof defaultProps;

const defaultProps = {
    items: [] as any[]
}

const NONE_INDEX = -1;
const ADD_INDEX = -2;

export const ListEditor = React.forwardRef<FormInstance, ListEditorProps>((props: ListEditorProps, ref?: ForwardedRef<FormInstance>) => {

    const [editIndex, setEditIndex] = useState<number>(NONE_INDEX);

    const handleItemAdd = (): void => {
        setEditIndex(ADD_INDEX);
    }

    const handleItemEdit = (index: number): void => {
        const item = props.items[index];
        setEditIndex(index);
        props.onEdit(item);
    }

    const handleItemRemove = (index: number): void => {
        const items = update(props.items, {
            $splice: [[index, 1]]
        });
        props.onSave(items);
    }

    const handleItemCancel = (): void => {
        props.onEdit(undefined);
        setEditIndex(NONE_INDEX);
    }

    const handleItemOk = (): void => {
        if (!ref) {
            return;
        }
        const obj = ref as MutableRefObject<FormInstance>;
        obj.current?.submit();
    }

    const handleItemSave = (): void => {
        let items;
        if (editIndex === ADD_INDEX) {
            items = update(props.items, {
                $push: [props.editItem!]
            });
        } else {
            items = update(props.items, {
                [editIndex]: { $set: props.editItem }
            });
        }
        setEditIndex(NONE_INDEX);
        props.onSave(items);
    }

    const editView = (): ReactElement => {
        return (
            <Modal
                centered
                width={Globals.DIALOG_WIDTH}
                title={`Edit ${props.name}`}
                onOk={handleItemOk}
                onCancel={handleItemCancel}
                visible={true}
            >
                <Form ref={ref} onFinish={handleItemSave}>
                    {props.children}
                </Form>
            </Modal>
        )
    }

    const listView = (): ReactElement => {
        const items = (index: number): ItemSpec[] => {
            return [{
                icon: <EditOutlined />,
                label: "Edit",
                onSelect: () => handleItemEdit(index)
            }, {
                icon: <DeleteOutlined />,
                label: "Remove",
                onSelect: () => handleItemRemove(index)
            }];
        }
        return (
            <List
                dataSource={props.items}
                renderItem={(item, index) => (
                    <List.Item key={index}>
                        <div className="x-listeditor-item">
                            {props.renderItem(item)}
                            <MenuButton
                                className="x-listeditor-menu"
                                items={items(index)}
                                index={index}
                            />
                        </div>
                    </List.Item>
                )}
            />
        )
    }

    return (
        <>
            {(props.editItem || editIndex === ADD_INDEX) &&
                editView()
            }
            <div>
                {props.items.length > 0 &&
                    listView()
                }
                <Button
                    className="x-listeditor-add"
                    onClick={() => handleItemAdd()}
                >
                    {`Add ${props.name}`}
                </Button>
            </div>
        </>
    )
})

ListEditor.defaultProps = defaultProps;
