import { PureComponent, ReactElement } from 'react';
import { Select } from 'antd';
import classNames from 'classnames';
import './TimezoneSelector.less';

export type ChangeCallback = (value: any) => void;

export type TimezoneSelectorProps = {
    className?: string,
    value?: string,
    onChange: ChangeCallback
}

export class TimezoneSelector extends PureComponent<TimezoneSelectorProps> {

    public render(): ReactElement {
        const value = this.props.value === 'Z' ? 'UTC' : this.props.value;
        return (
            <Select
                className={classNames('x-tzselector', this.props.className)}
                placeholder="Select a time zone."
                value={value}
                onChange={this.props.onChange}>
                {Timezones.map(timezone => (
                    <Select.Option key={timezone} value={timezone}>{timezone.replace("_", " ")}</Select.Option>
                ))}
            </Select>
        )
    }

}

export const Timezones = [
    'UTC',
    'America/Adak',
    'America/Anchorage',
    'America/Boise',
    'America/Chicago',
    'America/Denver',
    'America/Detroit',
    'America/Fort_Wayne',
    'America/Indiana/Indianapolis',
    'America/Indiana/Knox',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Tell_City',
    'America/Indiana/Vevay',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indianapolis',
    'America/Juneau',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Los_Angeles',
    'America/Louisville',
    'America/New_York',
    'America/Nome',
    'America/North_Dakota/Beulah',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/Phoenix',
    'Pacific/Honolulu',
    'Pacific/Pago_Pago'
];
