import { ReactElement, useState } from 'react';
import { FormInstance, Input, Select } from 'antd';
import { RecordsLocator } from '@methodset/endpoint-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { FormItem } from 'components/FormItem/FormItem';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';
import update from 'immutability-helper';
import './RecordsLocatorEditor.less';

type ColumnType = "FIELD" | "INDEX";

export type ChangeCallback = (locator: RecordsLocator) => void;

export type RecordsLocatorEditorProps = {
    formRef: React.RefObject<FormInstance>,
    locator: RecordsLocator,
    index: number,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
}

export const RecordsLocatorEditor = (props: RecordsLocatorEditorProps): ReactElement => {

    const isNumber = (value: any): boolean => {
        const integer = parseInt(value);
        return !isNaN(integer);
    }

    const [columnType, setColumnType] = useState<ColumnType>(isNumber(props.locator.col) ? "INDEX" : "FIELD");

    const handleRowChange = (row: number): void => {
        const locator = update(props.locator, {
            row: { $set: row as any }
        });
        props.onChange(locator);
    }

    const handleColumnTypeChange = (columnType: ColumnType): void => {
        setColumnType(columnType);
        const locator = update(props.locator, {
            $unset: ["col"]
        });
        props.onChange(locator);
    }

    const handleColChange = (col: number | string): void => {
        const locator = update(props.locator, {
            col: { $set: col as any }
        });
        props.onChange(locator);
    }

    return (
        <Spacer className="x-recordslocatoreditor">
            <FormItem
                formRef={props.formRef}
                className="x-recordslocatoreditor-form"
                name={`records-locator-row-${props.index}`}
                label="Row Number"
                colon={false}
                rules={[{
                    required: true,
                    message: "Enter a row number."
                }]}
            >
                <IntegerInput
                    key={props.locator.row}
                    className="x-recordslocatoreditor-item"
                    placeholder="Index (0-based)."
                    natural={true}
                    value={props.locator.row}
                    onChange={(value) => handleRowChange(value)}
                />
            </FormItem>
            <FormItem
                formRef={props.formRef}
                className="x-recordslocatoreditor-form"
                name={`records-locator-col-${props.index}`}
                label="Column"
                colon={false}
                rules={[{
                    required: true,
                    message: "Select a type."
                }]}
            >
                <Select
                    key={`column-type-${props.index}`}
                    className="x-recordslocatoreditor-item"
                    defaultValue="FIELD"
                    value={columnType}
                    onChange={(value) => handleColumnTypeChange(value)}
                >
                    <Select.Option key="FIELD" value="FIELD">Field</Select.Option>
                    <Select.Option key="INDEX" value="INDEX">Index</Select.Option>
                </Select>
            </FormItem>
            {columnType === "FIELD" &&
                <FormItem
                    formRef={props.formRef}
                    className="x-recordslocatoreditor-form"
                    name={`records-locator-field-${props.index}`}
                    label="Field"
                    colon={false}
                    rules={[{
                        required: true,
                        message: "Column field."
                    }]}
                >
                    <Input
                        key={`column-field-${props.index}`}
                        className="x-recordslocatoreditor-item"
                        placeholder="Column name."
                        value={props.locator.col as string}
                        onChange={(e) => handleColChange(e.target.value)}
                    />
                </FormItem>
            }
            {columnType === "INDEX" &&
                <FormItem
                    formRef={props.formRef}
                    className="x-recordslocatoreditor-form"
                    name={`records-locator-index-${props.index}`}
                    label="Index"
                    colon={false}
                    rules={[{
                        required: true,
                        message: "Column index."
                    }]}
                >
                    <IntegerInput
                        key={`column-index-${props.index}`}
                        className="x-recordslocatoreditor-item"
                        natural={true}
                        value={props.locator.col as number}
                        onChange={(value) => handleColChange(value)}
                    />
                </FormItem>
            }
        </Spacer>
    )
}
