import { ReferenceParser } from "@methodset/calculator-ts";
import { TextWidgetConfiguration, Widget } from '@methodset/model-client-ts';

export class TextWidgetSync {

    public static parser = (widget: Widget): string[] => {
        const configuration = widget.configuration as TextWidgetConfiguration;
        const refs: string[] = [];
        refs.push(...ReferenceParser.extract(configuration.hideId, true));
        refs.push(...ReferenceParser.extract(configuration.text));
        refs.push(...ReferenceParser.extract(configuration.valueLink?.variableId, true));
        refs.push(...ReferenceParser.extract(configuration.valueLink?.valueId, true));
        return refs;
    }

    public static updater = (widget: Widget, target: string, replacement: string): void => {
        const configuration = widget.configuration as TextWidgetConfiguration;
        configuration.hideId = ReferenceParser.update(configuration.hideId, target, replacement, true);
        configuration.text = ReferenceParser.update(configuration.text, target, replacement);
        if (configuration.valueLink) {
            configuration.valueLink.variableId = ReferenceParser.update(configuration.valueLink.variableId, target, replacement, true);
            configuration.valueLink.valueId = ReferenceParser.update(configuration.valueLink.valueId, target, replacement, true);
        }
    }

}
