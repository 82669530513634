import React, { PureComponent, ReactElement } from 'react';
import { FormInstance, Select } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { Configuration, ConfigurationSpec, RangeDef } from '@methodset/endpoint-client-ts';
import { Model } from '@methodset/model-client-ts';
import { ModelPicker } from './ModelPicker/ModelPicker';
import { ModelVersionPicker } from './ModelVersionPicker/ModelVersionPicker';
import { TextInput } from 'components/TextInput/TextInput';
import { OverridesConfiguration } from './OverridesConfiguration/OverridesConfiguration';
import { ModelLoader } from './ModelLoader/ModelLoader';
import { Calculator, Sheet } from '@methodset/calculator-ts';
import classNames from 'classnames';
import update from 'immutability-helper';
import './RangeSelector.less';

export type ChangeCallback = (rangeDef: RangeDef) => void;

export type RangeSelectorProps = typeof RangeSelector.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    className?: string,
    rangeDef?: RangeDef,
    variables?: ConfigurationSpec[],
    label?: string,
    description?: string,
    onChange: ChangeCallback
}

export type RangeSelectorState = {
    model?: Model,
    calculator?: Calculator
}

export class RangeSelector extends PureComponent<RangeSelectorProps, RangeSelectorState> {

    static defaultProps = {
        rangeDef: {
            configuration: {}
        },
        label: "Range",
        description: "The data range that contains the desired data, i.e., A1:C5."
    }

    constructor(props: RangeSelectorProps) {
        super(props);
        this.state = {}
        this.handleModelChange = this.handleModelChange.bind(this);
        this.handleVersionChange = this.handleVersionChange.bind(this);
        this.handleModelLoaded = this.handleModelLoaded.bind(this);
        this.handleSheetChange = this.handleSheetChange.bind(this);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
    }

    private handleModelChange(modelId: string): void {
        const rangeDef = update(this.props.rangeDef, {
            modelId: { $set: modelId },
            version: { $set: undefined as any },
            sheetId: { $set: undefined as any },
            rangeId: { $set: undefined as any },
            configuration: { $set: {} }
        });
        this.props.onChange(rangeDef);
    }

    private handleVersionChange(version: number): void {
        const rangeDef = update(this.props.rangeDef, {
            version: { $set: version },
            sheetId: { $set: undefined as any },
            rangeId: { $set: undefined as any },
            configuration: { $set: {} }
        });
        this.props.onChange(rangeDef);
    }

    private handleModelLoaded(model: Model): void {
        const calculator = Calculator.deserialize(model.calculator!);
        this.setState({
            model: model,
            calculator: calculator
        });
    }

    private handleSheetChange(sheetId: string): void {
        const rangeRef = update(this.props.rangeDef, {
            sheetId: { $set: sheetId },
            rangeId: { $set: undefined as any }
        });
        this.props.onChange(rangeRef);
    }

    private handleRangeChange(rangeId: string | undefined): void {
        const rangeRef = update(this.props.rangeDef, {
            rangeId: { $set: rangeId as any }
        });
        this.props.onChange(rangeRef);
    }

    private handleConfigurationChange(configuration: Configuration): void {
        const rangeRef = update(this.props.rangeDef, {
            configuration: { $set: configuration }
        });
        this.props.onChange(rangeRef);
    }

    public render(): ReactElement {
        return (
            <div className={classNames('x-rangeselector', this.props.className)}>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Model"
                    name="model"
                    info="The model that contains the desired data."
                    valuePropName="modelId"
                    justification="center"
                    rules={[{
                        required: true,
                        message: `Please select a model.`
                    }]}
                >
                    <ModelPicker
                        modelId={this.props.rangeDef.modelId}
                        onChange={this.handleModelChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Version"
                    name="version"
                    info="The model version."
                    valuePropName="version"
                    rules={[{
                        required: true,
                        message: `Please select a model version.`
                    }]}
                >
                    <ModelVersionPicker
                        modelId={this.props.rangeDef.modelId}
                        version={this.props.rangeDef.version}
                        onChange={this.handleVersionChange}
                    />
                </FormItem>
                {this.props.rangeDef.modelId &&
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        hidden={!!this.state.model}
                    >
                        <ModelLoader
                            modelId={this.props.rangeDef.modelId}
                            modelVersion={this.props.rangeDef.version}
                            onLoaded={this.handleModelLoaded}
                        />
                    </FormItem>
                }
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label="Sheet"
                    name="sheet"
                    info="The sheet that contains the desired data."
                    rules={[{
                        required: true,
                        message: `Please select a model sheet.`
                    }]}
                >
                    <Select
                        placeholder="Select a sheet."
                        value={this.props.rangeDef.sheetId}
                        onChange={this.handleSheetChange}
                    >
                        {this.state.calculator?.sheets.map((sheet: Sheet) => (
                            <Select.Option key={sheet.id} value={sheet.name}>{sheet.name}</Select.Option>
                        ))}
                    </Select>
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label={this.props.label}
                    name="range"
                    info={this.props.description}
                    rules={[{
                        required: true,
                        message: `Please select a data range.`
                    }]}
                >
                    <TextInput
                        placeholder="Enter a cell range."
                        value={this.props.rangeDef.rangeId}
                        onChange={this.handleRangeChange}
                    />
                </FormItem>
                {this.state.model && this.state.calculator && this.props.variables &&
                    <OverridesConfiguration
                        formRef={this.props.formRef}
                        configuration={this.props.rangeDef.configuration}
                        variableSpecs={this.props.variables}
                        variables={this.state.calculator.variables}
                        onChange={this.handleConfigurationChange}
                    />
                }
            </div>
        );
    }

}
