import { useState, createContext, FC, useContext, ReactElement, useRef } from 'react';
import { Calculator, Sheet } from '@methodset/calculator-ts';
import { Application, Model } from '@methodset/model-client-ts';
import { Profile } from '@methodset/entity-client-ts';
import { EventCallback, EventEmitter } from 'utils/EventEmitter';

/**
 * Active components that will not trigger re-renders. This is an object that
 * wraps the active components, but the object itself will not change. If changes
 * in the active cell are desired, attach a callback to the emitter.
 */
export interface ActiveCell {
    id: string;
    sheet: Sheet;
    variableId?: string;
    prev?: ActiveCell
}

interface Active {
    cell?: ActiveCell
}

interface ModelState {
    active: Active,
    model?: Model,
    profile?: Profile,
    calculator?: Calculator,
    application?: Application,
    setFocusedCell: (sheet: Sheet, cellId: string, variableId?: string) => void,
    saveModel: (model: Model) => void,
    saveProfile: (profile: Profile) => void,
    saveCalculator: (calculator: Calculator) => void,
    saveApplication: (application: Application | undefined) => void,
    addCallback: (type: string, callback: EventCallback, sender?: any) => void,
    removeCallback: (type: string, callback: EventCallback) => void,
    sendEvent: (type: string, data: any, sender?: any) => void
}

const defaultState: ModelState = {
    active: {},
    model: undefined,
    profile: undefined,
    calculator: undefined,
    application: undefined,
    setFocusedCell: () => {},
    saveModel: () => {},
    saveProfile: () => {},
    saveCalculator: () => {},
    saveApplication: () => {},
    addCallback: () => {},
    removeCallback: () => {},
    sendEvent: () => {}
}

export const ModelContext = createContext<ModelState>(defaultState);

export const ModelProvider: FC = ({ children }): ReactElement => {

    const emitter = useRef<EventEmitter>(new EventEmitter());

    // No setter so that change of active cell will not trigger updates.
    // Change of active cell is received via an event.
    const [active] = useState(defaultState.active);
    const [model, setModel] = useState(defaultState.model);
    const [profile, setProfile] = useState(defaultState.profile);
    const [calculator, setCalculator] = useState(defaultState.calculator);
    const [application, setApplication] = useState(defaultState.application);

    const setFocusedCell = (sheet: Sheet, cellId: string, variableId?: string) => {
        // Set directly (not through setter) to prevent props update.
        // Changes will be triggered via the emitted event.
        const activeCell = {
            id: cellId,
            sheet: sheet,
            variableId: variableId,
            prev: active.cell
        }
        active.cell = activeCell;
        if (sheet.id !== Sheet.PARAMETERS_SHEET) {
            calculator!.defaultSheet = activeCell.sheet;
        }
        emitter.current.sendEvent("CellFocusChange", activeCell);
    }

    const saveModel = (model: Model): void => {
        setModel(model);
    }

    const saveProfile = (profile: Profile): void => {
        setProfile(profile);
    }

    const saveCalculator = (calculator: Calculator): void => {
        setCalculator(calculator);
    }

    const saveApplication = (application: Application | undefined): void => {
        setApplication(application);
    }

    const addCallback = (type: string, callback: EventCallback): void => {
        emitter.current.addCallback(type, callback);
    }

    const removeCallback = (type: string, callback: EventCallback): void => {
        emitter.current.removeCallback(type, callback);
    }

    const sendEvent = (type: string, data: any): void => {
        emitter.current.sendEvent(type, data);
    }

    return (
        <ModelContext.Provider
            value={{
                active,
                model,
                profile,
                calculator,
                application,
                setFocusedCell,
                saveModel,
                saveProfile,
                saveCalculator,
                saveApplication,
                addCallback,
                removeCallback,
                sendEvent
            }}
        >
            {children}
        </ModelContext.Provider>
    );

};

export const useModelContext = () => useContext(ModelContext)