import { Component as ReactComponent, ReactElement } from 'react';
import { green, red } from '@ant-design/colors';
import { Button, Tag } from 'antd';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { ColumnsType } from 'antd/lib/table';
import { CoreUtils } from 'utils/CoreUtils';
import { AuthenticationHeader, Component, Configuration, ConfigurationSpec, ConverterMap, Flow, FlowType, FlowUtils, ProcessorHeader } from '@methodset/endpoint-client-ts';
import { FlowEditor, State } from './FlowEditor/FlowEditor';
import { ButtonLink } from 'components/ButtonLink/ButtonLink';
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, DownOutlined, EditOutlined, SyncOutlined, UpOutlined } from '@ant-design/icons';
import { Globals } from 'constants/Globals';
import { Spacer } from 'components/Spacer/Spacer';
import { ExecutionHistory, ExecutionState, Inputs, StatusType } from '@methodset/workflow-client-ts';
import { RestUtils } from 'utils/RestUtils';
import workflowService from 'services/WorkflowService';
import update from 'immutability-helper';
import './Flows.less';
import { StateView } from './StateView/StateView';

const CHECK_INTERVAL = 2 * 1000;

const COLOR_MAP = CoreUtils.toColorMap(CoreUtils.enumToKeys(FlowType));

export type ComponentMap = { [key: string]: Component };
export type StateMap = { [key: string]: State };
export type ExecutionMap = { [key: string]: ExecutionState };

export type EditCallback = (isEditing: boolean) => void;
export type ChangeCallback = (transitions: Flow[]) => void;

export type FlowsProps = {
    className?: string,
    workflowId?: string,
    headers: ProcessorHeader[],
    components: Component[],
    flows: Flow[],
    inputs?: Inputs,
    converterMap: ConverterMap,
    configurationSpecs: ConfigurationSpec[],
    authentications: AuthenticationHeader[],
    onEdit?: EditCallback,
    onChange: ChangeCallback
}

export type FlowsState = {
    // Transition being edited.
    flow?: Flow,
    // The index of the edited component.
    index?: number,
    // True when the workflow test is running.
    isRunning: boolean,
    // True when the flow is in test mode.
    testingView: boolean,
    // The execution state inputs/outputs to display.
    executionState: ExecutionState | undefined,
    // Map flow name to execution history.
    executionMap: ExecutionMap,
    // Error message on loading test records.
    errorMessage: string | undefined
    // Message to show during testing.
    testingMessage: string | undefined,
}

export class Flows extends ReactComponent<FlowsProps, FlowsState> {

    // Map of id to components.
    private componentMap: ComponentMap = {};
    // Map of id to flows.
    private stateMap: StateMap = {};
    // Timer to check states during testing.
    private timerId: NodeJS.Timeout | null = null;

    constructor(props: FlowsProps) {
        super(props);
        this.state = {
            isRunning: false,
            testingView: false,
            executionState: undefined,
            executionMap: {},
            errorMessage: undefined,
            testingMessage: undefined
        }
        this.componentMap = this.buildComponentMap(props.components);
        this.stateMap = this.buildStateMap(props.flows, props.components);
        this.handleFlowCreate = this.handleFlowCreate.bind(this);
        this.handleFlowDelete = this.handleFlowDelete.bind(this);
        this.handleFlowEdit = this.handleFlowEdit.bind(this);
        this.handleFlowMoveUp = this.handleFlowMoveUp.bind(this);
        this.handleFlowMoveDown = this.handleFlowMoveDown.bind(this);
        this.handleWorkflowTest = this.handleWorkflowTest.bind(this);
        this.handleEditShow = this.handleEditShow.bind(this);
        this.handleStateShow = this.handleStateShow.bind(this);
        this.handleStateHide = this.handleStateHide.bind(this);
        this.handleEditorDone = this.handleEditorDone.bind(this);
        this.handleEditorCancel = this.handleEditorCancel.bind(this);
    }

    private handleFlowCreate(): void {
        this.setState({ index: -1 });
        if (this.props.onEdit) {
            this.props.onEdit(true);
        }
    }

    private handleFlowDelete(flow: any, index: number): void {
        const flows = update(this.props.flows, {
            $splice: [[index, 1]]
        });

        this.props.onChange(flows);
    }

    private handleFlowEdit(flow: Flow, index: number): void {
        this.setState({
            flow: flow,
            index: index
        });
        if (this.props.onEdit) {
            this.props.onEdit(true);
        }
    }

    private handleFlowMoveUp(flow: Flow, index: number): void {
        const flows = update(this.props.flows, {
            $splice: [[index, 1], [index - 1, 0, flow]]
        });
        this.props.onChange(flows);
    }

    private handleFlowMoveDown(flow: Flow, index: number): void {
        const flows = update(this.props.flows, {
            $splice: [[index, 1], [index + 1, 0, flow]]
        });
        this.props.onChange(flows);
    }

    private handleWorkflowTest(): void {
        this.testWorkflowRequest();
    }

    private handleEditShow(): void {
        this.setState({
            testingView: false,
            errorMessage: undefined
        });
    }

    private handleStateShow(flow: Flow): void {
        const executionState = this.state.executionMap[flow.name];
        if (!executionState) {
            return undefined;
        }
        this.setState({ executionState: executionState });
    }

    private handleStateHide(): void {
        this.setState({ executionState: undefined });
    }

    private handleEditorDone(flow: Flow, index: number): void {
        let flows;
        if (index !== -1) {
            // Updated flow.
            flows = update(this.props.flows, {
                [index]: { $set: flow }
            });
        } else {
            // Created flow.
            flows = update(this.props.flows, {
                $push: [flow]
            });
        }
        this.props.onChange(flows);
        this.setState({
            flow: undefined,
            index: undefined
        });
        if (this.props.onEdit) {
            this.props.onEdit(false);
        }
    }

    private handleEditorCancel(): void {
        this.setState({
            flow: undefined,
            index: undefined
        });
        if (this.props.onEdit) {
            this.props.onEdit(false);
        }
    }

    private testWorkflowRequest(): Promise<any> {
        this.setState({
            isRunning: true,
            testingView: true,
            errorMessage: undefined,
            executionMap: {}
        });
        const request = {
            workflowId: this.props.workflowId
        };
        return workflowService.testWorkflow(request,
            (response: any) => this.testWorkflowResponse(response),
            (error: Error) => this.testWorkflowException(error),
            true
        );
    }

    private testWorkflowResponse(response: any): void {
        const executionId = response.data.executionId;
        // Execution started, start monitoring results.
        this.readExecutionHistoryRequest(executionId);
    }

    private testWorkflowException(error: Error): void {
        const errorMessage = RestUtils.getErrorMessage(error);
        this.setState({
            isRunning: false,
            errorMessage: errorMessage
        });
    }

    private readExecutionHistoryRequest(executionId: string): Promise<any> {
        const request = {
            workflowId: this.props.workflowId,
            executionId: executionId
        };
        return workflowService.readExecutionHistory(request,
            (response: any) => this.readExecutionHistoryResponse(response, executionId),
            (error: Error) => this.readExecutionHistoryException(error),
            true
        );
    }

    private readExecutionHistoryResponse(response: any, executionId: string): void {
        const executionHistory: ExecutionHistory = response.data.executionHistory;
        const states = executionHistory.states;
        const executionMap = states.reduce((map: ExecutionMap, state: ExecutionState) => {
            map[state.flowName] = state;
            return map;
        }, {});
        const testingMessage = this.testingMessage(executionMap);
        this.setState({
            executionMap: executionMap,
            testingMessage: testingMessage
        });
        if (executionHistory.status === StatusType.NONE ||
            executionHistory.status === StatusType.STARTED ||
            executionHistory.status === StatusType.RUNNING) {
            this.timerId = setTimeout(() => this.readExecutionHistoryRequest(executionId), CHECK_INTERVAL);
        } else {
            let errorMessage;
            if (executionHistory.status === StatusType.SUCCEEDED) {
                errorMessage = undefined;
            } else if (executionHistory.status === StatusType.FAILED) {
                errorMessage = executionHistory?.error?.message;
            } else {
                errorMessage = `${CoreUtils.toCapital(executionHistory.status)}.`;
            }
            this.setState({
                isRunning: false,
                errorMessage: errorMessage
            });
            this.timerId = null;
        }
    }

    private readExecutionHistoryException(error: Error): void {
        const errorMessage = RestUtils.getErrorMessage(error);
        this.setState({
            isRunning: false,
            errorMessage: errorMessage
        });
        this.stopTimer();
    };

    private testingMessage(executionMap: ExecutionMap): string | undefined {
        const state = executionMap["Setup"];
        if (!state) {
            return undefined;
        }
        const status = state.status;
        return status === StatusType.RUNNING ? "Setting up test..." : undefined;
    }

    private statusIcon(flowName: string): ReactElement | undefined {
        const state = this.state.executionMap[flowName];
        if (!state) {
            return undefined;
        }
        const status = state.status;
        switch (status) {
            case StatusType.RUNNING:
                return <SyncOutlined spin={true} />;
            case StatusType.SUCCEEDED:
                return <CheckCircleOutlined style={{ color: green[6] }} />;
            case StatusType.FAILED:
                return <CloseCircleOutlined style={{ color: red[6] }} />;
            default:
                return undefined;
        }
    }

    private buildEditColumns(): ColumnsType<any> {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            width: 200,
            ellipsis: true,
            render: (_, flow: Flow, index: number) => {
                return (
                    <ButtonLink
                        onClick={() => this.handleFlowEdit(flow, index)}
                    >
                        {flow.name}
                    </ButtonLink>
                )
            }
        }, {
            key: 'from',
            title: 'From',
            width: 200,
            ellipsis: true,
            render: (_, flow: Flow, index: number) => {
                return (
                    <span>
                        {!flow.prevId ? "Workflow Start" : this.stateMap[flow.prevId].name}
                    </span>
                )
            }
        }, {
            key: 'to',
            title: 'To',
            ellipsis: true,
            render: (flow: Flow) => {
                const nextIds = FlowUtils.nextIds(flow);
                const tos = nextIds.map(nextId => this.stateMap[nextId].name);
                return (
                    <span>
                        {tos.length > 0 ? tos.join(", ") : Globals.EMPTY_FIELD}
                    </span>
                );
            }
        }, {
            key: 'type',
            title: 'Type',
            align: 'center',
            width: 150,
            render: (flow: Flow) => {
                return (
                    <Tag color={COLOR_MAP[flow.type]}>
                        {flow.type}
                    </Tag>
                );
            }
        }];
        return columns;
    }

    private buildTestColumns(): ColumnsType<any> {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            width: 200,
            ellipsis: true,
            render: (_, flow: Flow) => {
                return (
                    <ButtonLink
                        onClick={() => this.handleStateShow(flow)}
                    >
                        {flow.name}
                    </ButtonLink>
                )
            }
        }, {
            key: 'from',
            title: 'From',
            width: 200,
            ellipsis: true,
            render: (_, flow: Flow) => {
                return (
                    <span>
                        {!flow.prevId ? "Workflow Start" : this.stateMap[flow.prevId].name}
                    </span>
                )
            }
        }, {
            key: 'to',
            title: 'To',
            ellipsis: true,
            render: (flow: Flow) => {
                const nextIds = FlowUtils.nextIds(flow);
                const tos = nextIds.map(nextId => this.stateMap[nextId].name);
                return (
                    <span>
                        {tos.length > 0 ? tos.join(", ") : Globals.EMPTY_FIELD}
                    </span>
                );
            }
        }, {
            key: 'type',
            title: 'Type',
            align: 'center',
            width: 150,
            render: (flow: Flow) => {
                return (
                    <Tag color={COLOR_MAP[flow.type]}>
                        {flow.type}
                    </Tag>
                );
            }
        }, {
            key: 'status',
            title: 'Status',
            align: 'center',
            width: 100,
            render: (_, flow: Flow, index: number) => {
                return (
                    <span>
                        {this.statusIcon(flow.name)}
                    </span>
                )
            }
        }];
        return columns;
    }

    private buildComponentMap(components: Component[]): ComponentMap {
        return components.reduce((map: ComponentMap, component: Component) => {
            map[component.id] = component;
            return map;
        }, {} as ComponentMap);
    }

    private buildStateMap(flows: Flow[], components: Component[]): StateMap {
        // Replace the pipe flows with components.
        let stateMap: StateMap = this.buildComponentMap(components);
        stateMap = flows.filter(flow => flow.type !== FlowType.PIPE).reduce((map: StateMap, flow: Flow) => {
            map[flow.id] = flow;
            return map;
        }, stateMap);
        return stateMap;
    }

    private buildData(): any {
        return this.props.flows;
    }

    private stopTimer(): void {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    }

    public shouldComponentUpdate(nextProps: FlowsProps): boolean {
        if (nextProps.components !== this.props.components) {
            this.componentMap = this.buildComponentMap(nextProps.components);
            this.stateMap = this.buildStateMap(nextProps.flows, nextProps.components);
        }
        if (nextProps.flows !== this.props.flows) {
            this.stateMap = this.buildStateMap(nextProps.flows, nextProps.components);
        }
        return true;
    }

    public componentWillUnmount(): void {
        this.stopTimer();
    }

    public render(): ReactElement {
        const actions = !this.state.testingView ? [{
            icon: <EditOutlined />,
            label: "Edit flow",
            callback: this.handleFlowEdit
        }, {
            icon: <DeleteOutlined />,
            label: "Delete flow",
            confirm: "Are you sure you want to delete the flow?",
            callback: this.handleFlowDelete
        }, {
            icon: <UpOutlined />,
            label: "Move up",
            disabled: (flow: Flow, index: number) => {
                return index === 0;
            },
            callback: this.handleFlowMoveUp
        }, {
            icon: <DownOutlined />,
            label: "Move down",
            disabled: (flow: Flow, index: number) => {
                return index === this.props.flows.length - 1;
            },
            callback: this.handleFlowMoveDown
        }] : undefined;
        const columns = this.state.testingView ? this.buildTestColumns() : this.buildEditColumns();
        return (
            <div>
                {CoreUtils.isEmpty(this.state.index) &&
                    <div>
                        <ItemTable
                            className="x-flows"
                            title="Flows"
                            size="small"
                            pagination={false}
                            columns={columns}
                            items={this.buildData()}
                            actions={actions}
                        />
                        <Spacer className="x-flows-add">
                            {!this.state.testingView &&
                                <Button
                                    disabled={this.state.testingView}
                                    onClick={this.handleFlowCreate}
                                >
                                    Add Flow
                                </Button>
                            }
                            {this.state.testingView && !this.state.isRunning &&
                                <Button
                                    onClick={this.handleEditShow}
                                >
                                    Exit Test
                                </Button>
                            }
                            {(!this.state.testingView || this.state.isRunning) &&
                                <Button
                                    onClick={this.handleWorkflowTest}
                                    loading={this.state.isRunning}
                                    disabled={this.props.components.length === 0}
                                >
                                    Test Workflow
                                </Button>
                            }
                            {this.state.testingMessage &&
                                <span>{this.state.testingMessage}</span>
                            }
                            {this.state.errorMessage &&
                                <span className="x-flows-error">{this.state.errorMessage}</span>
                            }
                        </Spacer>
                    </div>
                }
                {!CoreUtils.isEmpty(this.state.index) &&
                    <FlowEditor
                        converterMap={this.props.converterMap}
                        flow={this.state.flow}
                        index={this.state.index!}
                        flows={this.props.flows}
                        components={this.props.components}
                        componentMap={this.componentMap}
                        configurationSpecs={this.props.configurationSpecs}
                        authentications={this.props.authentications}
                        onDone={this.handleEditorDone}
                        onCancel={this.handleEditorCancel}
                    />
                }
                {this.state.executionState &&
                    <StateView
                        state={this.state.executionState}
                        onClose={this.handleStateHide}
                    />
                }
            </div>

        );
    }

}
