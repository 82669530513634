import { PureComponent, ReactElement } from 'react';
import { Cell, FunctionChangeEvent, FunctionChangeType, QueryChangeType, QueryChangeEvent, Sheet, SheetChangeEvent, ElementChangeType, FnCell } from '@methodset/calculator-ts';
import { Button, Empty } from 'antd';
import { ActiveCell, ModelContext } from 'context/ModelContext';
import { RefreshInterval } from './RefreshInterval/RefreshInterval';
import { QueryItem } from './QueryItem/QueryItem';
import { QueryDialog } from './QueryDialog/QueryDialog';
import { ActionType, CellAction } from '../CellAction/CellAction';
import classNames from 'classnames';
import update from 'immutability-helper';
import './Queries.less';
import { NoData } from 'components/NoData/NoData';

type QueryCell = {
    cell: Cell,
    isPrimary: boolean,
    isLoading: boolean
}

export type QueriesProps = {
}

export type QueriesState = {
    showInterval: boolean
    queryCells: QueryCell[],
    editCell?: ActiveCell
}

export class Queries extends PureComponent<QueriesProps, QueriesState> {

    static contextType = ModelContext;

    constructor(props: QueriesProps) {
        super(props);
        this.state = {
            showInterval: false,
            queryCells: []
        };
        this.handleSheetChange = this.handleSheetChange.bind(this);
        this.handleFunctionChange = this.handleFunctionChange.bind(this);
        this.handleQueryAdd = this.handleQueryAdd.bind(this);
        this.handleQueryEdit = this.handleQueryEdit.bind(this);
        this.handleQueryCancel = this.handleQueryCancel.bind(this);
        this.handleQueryUpdate = this.handleQueryUpdate.bind(this);
        this.handleQueryRemove = this.handleQueryRemove.bind(this);
        this.handleQueryChange = this.handleQueryChange.bind(this);
        this.handleRunAllClick = this.handleRunAllClick.bind(this);
        this.handleIntervalChange = this.handleIntervalChange.bind(this);
        this.handleIntervalCancel = this.handleIntervalCancel.bind(this);
        this.handleIntervalShow = this.handleIntervalShow.bind(this);
    }

    private handleSheetChange(event: SheetChangeEvent): void {
        if (event.type === ElementChangeType.ADD) {
            // Get sheet name updates when a new sheet is added.
            event.sheet.addCallback("Change", this.handleSheetChange);
            // Query start and finish events.
            event.sheet.addCallback("QueryChange", this.handleQueryChange);
            // Function add or remove events.
            event.sheet.addCallback("FunctionChange", this.handleFunctionChange);
        } else if (event.type === ElementChangeType.REMOVE) {
            event.sheet.removeCallback("Change", this.handleSheetChange);
            event.sheet.removeCallback("QueryChange", this.handleQueryChange);
            event.sheet.removeCallback("FunctionChange", this.handleFunctionChange);
            // Remove the queries in the sheet being removed.
            this.removeQueries(event.sheet);
            // Sheet removed, may update blocks referencing sheet.
            this.forceUpdate();
        } else if (event.type === ElementChangeType.RENAME) {
            // Sheet name change.
            this.forceUpdate();
        }
    }

    private handleFunctionChange(event: FunctionChangeEvent): void {
        if (event.fn !== "QUERY") {
            return;
        }
        let queryCells;
        if (event.type === FunctionChangeType.ADD) {
            const queryCell = {
                cell: event.cell,
                isPrimary: event.primary,
                isLoading: false
            };
            queryCells = update(this.state.queryCells, {
                $push: [queryCell]
            });
        } else {
            const index = this.state.queryCells.findIndex((query: QueryCell) => query.cell.uid === event.cell.uid);
            if (event.type === FunctionChangeType.REMOVE) {
                queryCells = update(this.state.queryCells, {
                    $splice: [[index, 1]]
                });
            } else if (event.type === FunctionChangeType.UPDATE) {
                queryCells = update(this.state.queryCells, {
                    [index]: {
                        cell: { $set: event.cell }
                    }
                });
            }
        }
        if (queryCells) {
            this.setState({ queryCells: queryCells });
        }
    }

    private handleQueryAdd(): void {
        const editCell = this.context.active.cell;
        if (!editCell) {
            return;
        }
        this.setState({ editCell: editCell });
    }

    private handleQueryEdit(cell: Cell): void {
        const editCell: ActiveCell = {
            id: cell.id,
            sheet: cell.sheet
        }
        this.setState({ editCell: editCell });
    }

    private handleQueryCancel(): void {
        this.setState({ editCell: undefined });
    }

    private handleQueryUpdate(formula: string): void {
        const editCell = this.state.editCell!;
        const sheet = editCell.sheet;
        const cell = sheet.getCell(editCell.id);
        cell.formula = formula;
        this.setState({ editCell: undefined });
    }

    private handleQueryRemove(cell: Cell): void {
        cell.clear();
    }

    private handleQueryChange(event: QueryChangeEvent): void {
        const index = this.state.queryCells.findIndex((query: QueryCell) => query.cell.uid === event.cell.uid);
        if (index === -1) {
            return;
        }
        let queryCells;
        if (event.type === QueryChangeType.START) {
            queryCells = update(this.state.queryCells, {
                [index]: {
                    isLoading: { $set: true }
                }
            });
        } else if (event.type === QueryChangeType.FINISH) {
            queryCells = update(this.state.queryCells, {
                [index]: {
                    isLoading: { $set: false }
                }
            });
        }
        if (queryCells) {
            this.setState({ queryCells: queryCells });
        }
    }

    private handleRunAllClick(): void {
        this.context.calculator!.runQueries();
    }

    private handleIntervalChange(interval: number): void {
        this.context.calculator!.timer.interval = interval;
        this.setState({ showInterval: false });
    }

    private handleIntervalCancel(): void {
        this.setState({ showInterval: false });
    }

    private handleIntervalShow(): void {
        this.setState({ showInterval: true });
    }

    private removeQueries(sheet: Sheet): void {
        const cells = sheet.tracker.cellsWith("QUERY");
        let queryCells;
        for (let cell of cells) {
            const index = this.state.queryCells.findIndex((queryCell: QueryCell) => queryCell.cell.uid === cell.uid);
            queryCells = update(this.state.queryCells, {
                $splice: [[index, 1]]
            });
        }
        if (queryCells) {
            this.setState({ queryCells: queryCells });
        }
    }

    private buildQueries(): ReactElement {
        if (this.state.queryCells.length === 0) {
            return (
                <NoData text="No queries." />
            );
        } else {
            return (
                <div>
                    <div className="x-queries-hdr">
                        <div className="x-queries-sch">
                            <Button type="link" onClick={this.handleIntervalShow}>
                                <span>Refresh:</span>
                            </Button>
                            <span className={
                                classNames({
                                    "x-queries-sch-on": this.context.calculator!.timer.interval > 0
                                }, {
                                    "x-queries-sch-off": this.context.calculator!.timer.interval === 0
                                })}>
                                {this.context.calculator!.timer.interval > 0 ? "ON" : "OFF"}
                            </span>
                        </div>
                        <Button
                            className="x-queries-runall"
                            type="link"
                            onClick={this.handleRunAllClick}
                        >
                            Run All
                        </Button>
                    </div>
                    <div className="x-queries-items">
                        {this.state.queryCells.map((query: QueryCell) =>
                            <QueryItem
                                key={query.cell.uid}
                                cell={query.cell}
                                isPrimary={query.isPrimary}
                                isLoading={query.isLoading}
                                onEdit={this.handleQueryEdit}
                                onRemove={this.handleQueryRemove}
                            />
                        )}
                    </div>
                    {this.state.showInterval &&
                        <RefreshInterval
                            interval={this.context.calculator!.timer.interval}
                            onChange={this.handleIntervalChange}
                            onCancel={this.handleIntervalCancel}
                        />
                    }
                </div>
            );
        }
    }

    public componentDidMount(): void {
        const queryCells: QueryCell[] = [];
        const sheets = this.context.calculator!.sheets;
        // Sheet add or remove events to attach for name changes.
        sheets.addCallback("Change", this.handleSheetChange);
        sheets.forEach((sheet: Sheet) => {
            // Sheet name change events.
            sheet.addCallback("Change", this.handleSheetChange);
            // Query start and finish events.
            sheet.addCallback("QueryChange", this.handleQueryChange);
            // Function add or remove events.
            sheet.addCallback("FunctionChange", this.handleFunctionChange);
            // Store cells with queries.
            const fnCells = sheet.tracker.fnCellsWith("QUERY");
            fnCells.forEach((fnCell: FnCell) => {
                queryCells.push({
                    cell: fnCell.cell,
                    isPrimary: fnCell.primary,
                    isLoading: false
                });
            });
        }, true);
        this.setState({ queryCells: queryCells });
    }

    public componentWillUnmount(): void {
        const sheets = this.context.calculator!.sheets;
        sheets.removeCallback("Change", this.handleSheetChange);
        sheets.forEach((sheet: Sheet) => {
            sheet.removeCallback("Change", this.handleSheetChange);
            sheet.removeCallback("QueryChange", this.handleQueryChange);
            sheet.removeCallback("FunctionChange", this.handleFunctionChange);
        }, true);
    }

    private buildQueryDialog(): ReactElement {
        const editCell = this.state.editCell!;
        const sheet = editCell.sheet;
        const cell = sheet.getCell(editCell.id);
        return (
            <QueryDialog
                cell={cell}
                formula={cell.formula}
                onChange={this.handleQueryUpdate}
                onCancel={this.handleQueryCancel}
            />
        );
    }

    public render(): ReactElement {
        const fn = (cell?: Cell): ActionType => {
            if (!cell) {
                return "add";
            }
            const inBlock = cell.isInBlock();
            const fnCell = cell.sheet.tracker.fnCell(cell, "QUERY");
            if (fnCell) {
                return (inBlock || !fnCell.primary) ? "none" : "edit";
            } else {
                return (inBlock || (cell && cell.isBlockOwner())) ? "none" : "add";
            }
        }
        return (
            <div className="x-queries">
                {this.buildQueries()}
                <CellAction
                    fn={fn}
                    onAdd={this.handleQueryAdd}
                    onEdit={this.handleQueryEdit}
                />
                {this.state.editCell &&
                    this.buildQueryDialog()
                }
            </div>
        )
    }

}
