import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Globals } from 'constants/Globals';
import { DatasetEditor } from './DatasetEditor/DatasetEditor';
import { ItemEditor } from 'containers/Console/ItemEditor/ItemEditor';
import { CompositeQuery } from '@methodset/endpoint-client-ts';
import classNames from 'classnames';
import './DatasetItem.less';

type MatchParams = {
    queryId: string
}

export type DatasetItemState = {
    status: string,
    errorMessage: string | null
    isLoading: boolean,
    isEditing: boolean
}

export type DatasetItemProps = RouteComponentProps<MatchParams> & {
    className?: string
}

export class DatasetItem extends PureComponent<DatasetItemProps, DatasetItemState> {

    private queryRef = React.createRef<DatasetEditor>();

    constructor(props: DatasetItemProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            errorMessage: null,
            isLoading: false,
            isEditing: false
        };
        this.handleEditorSave = this.handleEditorSave.bind(this);
        this.handleEditorCancel = this.handleEditorCancel.bind(this);
        this.handleEditorError = this.handleEditorError.bind(this);
        this.handleQueryEdit = this.handleQueryEdit.bind(this);
        this.handleQuerySaved = this.handleQuerySaved.bind(this);
        this.handleQueryTouch = this.handleQueryTouch.bind(this);
        this.handleQueryError = this.handleQueryError.bind(this);
    }

    private handleEditorSave(values: any): void {
        const callback = (): void => {
            this.setState({
                errorMessage: null,
                isLoading: true
            });
        }
        this.queryRef.current!.saveQuery(callback);
    }

    private handleEditorCancel(): void {
        this.props.history.push(RouteBuilder.CONSOLE_DATASETS);
    }

    private handleEditorError(details: any): void {
        const errorMessage = 'Please fill in all required fields.';
        this.setState({ errorMessage: errorMessage });
    }

    private handleQueryEdit(isEditing: boolean): void {
        this.setState({ isEditing: isEditing });
    }

    private handleQuerySaved(query: CompositeQuery): void {
        this.setState({
            isLoading: false
        });
        this.props.history.push(RouteBuilder.CONSOLE_DATASETS);
    }

    private handleQueryTouch(): void {
        if (this.state.errorMessage) {
            this.setState({ errorMessage: null });
        }
    }

    private handleQueryError(error: Error): void {
        this.setState({
            isLoading: false,
            errorMessage: error.message
        });
    }

    render() {
        const queryId = this.props.match.params.queryId;
        return (
            <ItemEditor
                className={classNames('x-datasetitem', this.props.className)}
                title="Dataset"
                loading={this.state.isLoading}
                disabled={this.state.isEditing}
                onSave={this.handleEditorSave}
                onCancel={this.handleEditorCancel}
                onError={this.handleEditorError}
            >
                <DatasetEditor
                    ref={this.queryRef}
                    queryId={queryId}
                    onEdit={this.handleQueryEdit}
                    onSaved={this.handleQuerySaved}
                    onError={this.handleQueryError}
                    onTouch={this.handleQueryTouch}
                />
                <div className="x-datasetitem-error">{this.state.errorMessage}</div>
            </ItemEditor>
        );
    }

}
