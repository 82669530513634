import { ReferenceParser } from "@methodset/calculator-ts";
import { Applet } from '@methodset/model-client-ts';

export class AppletSync {

    public static parser = (applet: Applet): string[] => {
        const refs: string[] = [];
        refs.push(...ReferenceParser.extract(applet.title));
        return refs;
    }

    public static updater = (applet: Applet, target: string, replacement: string): void => {
        applet.title = ReferenceParser.update(applet.title, target, replacement);
    }

}
