import { ReactElement } from 'react';
import { VersionActivator } from './VersionActivator/VersionActivator';
import { VersionResetter } from './VersionResetter/VersionResetter';
import { VersionReverter } from './VersionReverter/VersionReverter';
import { PackDetails, PackHeader, PackType } from '@methodset/library-client-ts';
import { VersionPackager } from './VersionPackager/VersionPackager';
import './VersionEditor.less';

export type EditorMode = "publish" | "revert" | "reset" | "activate" | "none";
export type PackageCallback = (header: PackHeader) => void;
export type RevertCallback = (header: PackHeader) => void;
export type ResetCallback = () => void;
export type ActivateCallback = (version: number) => void;
export type CancelCallback = () => void;

export type VersionEditorProps = {
    id: string,
    name?: string,
    description?: string,
    details: PackDetails,
    type: PackType,
    mode: EditorMode,
    version: number,
    error?: Error,
    isProcessing?: boolean,
    onPackage?: PackageCallback,
    onRevert?: RevertCallback,
    onReset?: ResetCallback,
    onActivate?: ActivateCallback,
    onCancel: CancelCallback
} & typeof defaultProps;

const defaultProps = {
    isProcessing: false
}

export const VersionEditor = (props: VersionEditorProps): ReactElement => {

    return (
        <>
            {props.mode === "publish" && props.onPackage &&
                <VersionPackager
                    type={props.type}
                    packId={props.id}
                    name={props.name}
                    description={props.description}
                    details={props.details}
                    onPackage={(header) => props.onPackage!(header)}
                    onCancel={() => props.onCancel()}
                />
            }
            {props.mode === "revert" && props.onRevert &&
                <VersionReverter
                    id={props.id}
                    type={props.type}
                    version={props.version}
                    onRevert={(header) => props.onRevert!(header)}
                    onCancel={() => props.onCancel()}
                />
            }
            {props.mode === "reset" && props.onReset &&
                <VersionResetter
                    id={props.id}
                    type={props.type}
                    version={props.version}
                    error={props.error}
                    isProcessing={props.isProcessing}
                    onReset={() => props.onReset!()}
                    onCancel={() => props.onCancel()}
                />
            }
            {props.mode === "activate" && props.onActivate &&
                <VersionActivator
                    id={props.id}
                    type={props.type}
                    version={props.version}
                    error={props.error}
                    isProcessing={props.isProcessing}
                    onActivate={(version) => props.onActivate!(version)}
                    onCancel={() => props.onCancel()}
                />
            }
        </>
    );

}

VersionEditor.defaultProps = defaultProps;
