import { Component, ReactElement } from 'react';
import { Button, Card, Dropdown, Empty, Menu, Row } from 'antd';
import { Applet, AppletPanel, AppletRef, Configuration, MenuItem, Widget, WidgetType } from '@methodset/model-client-ts';
import { WidgetViewer } from 'containers/Components/Widgets/WidgetViewer/WidgetViewer';
import { ViewItem } from 'containers/Console/Models/ModelItem/ModelApplications/ApplicationItem/ModelApplet/AppletEditor/ItemLayout/ItemLayout';
import { Calculator } from '@methodset/calculator-ts';
import { WidgetUtils } from 'utils/WidgetUtils';
import { SyncOutlined } from '@ant-design/icons';
import { StatusType } from 'constants/StatusType';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { RestUtils } from 'utils/RestUtils';
import { AuthenticationHeader, CredentialsType, SourceType } from '@methodset/endpoint-client-ts';
import { Admission } from '@methodset/library-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { AuthenticationItem } from 'containers/Console/Authentications/AuthenticationItem/AuthenticationItem';
import { AppletTitle } from './AppletTitle/AppletTitle';
import { PanelDialog } from './PanelDialog/PanelDialog';
import axios from 'axios';
import modelService from 'services/ModelService';
import './AppletViewer.less';
import { NoData } from 'components/NoData/NoData';

export type LoadCallback = (applet: Applet, calculator: Calculator) => void;
export type ChangeCallback = (configuration: Configuration, appletId: string) => void;
export type AuthenticationCallback = (header: AuthenticationHeader) => void;

export type AppletViewerProps = {
    // The applet title.
    title?: string,
    // Id of parent container.
    instanceId?: string,
    // Info to retrieve the applet. If not set, applet MUST be set.
    appletRef?: AppletRef,
    // The panel to display. Top level should set as applet.
    panel?: AppletPanel,
    // If not set, will get loaded. If not set, appletRef MUST be set.
    applet?: Applet,
    // The menu to display in the applet header.
    menu?: MenuItem[],
    // If not set, will get loaded.
    calculator?: Calculator,
    // Extra to display in the title bar.
    extra?: ReactElement,
    // The applet configuration.
    configuration: Configuration,
    // True if this is an embedded applet.
    isEmbedded?: boolean,
    // Admissions (credential specs) required for the applet.
    admissions?: Admission[],
    // The credentials if available.
    authentications?: AuthenticationHeader[],
    onLoad?: LoadCallback,
    onChange: ChangeCallback,
    onAuthentication?: AuthenticationCallback,
}

export type AppletViewerState = {
    status: StatusType,
    applet?: Applet,
    error?: Error,
    message?: string,
    calculator?: Calculator,
    menuItem?: MenuItem,
    isExecuting: boolean,
    editAdmission?: Admission
}

export class AppletViewer extends Component<AppletViewerProps, AppletViewerState> {

    static defaultProps = {
        isEmbedded: false
    }

    private loadedApplet: boolean = false;

    constructor(props: AppletViewerProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            isExecuting: false,
            applet: props.applet,
            calculator: props.calculator
        }
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleWidgetUpdate = this.handleWidgetUpdate.bind(this);
        this.handleMenuSelect = this.handleMenuSelect.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
        this.handleAuthenticationAdd = this.handleAuthenticationAdd.bind(this);
        this.handleAuthenticationCancel = this.handleAuthenticationCancel.bind(this);
        this.handleExecutionInitiate = this.handleExecutionInitiate.bind(this);
        this.handleExecutionComplete = this.handleExecutionComplete.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private isApplet(): boolean {
        return this.state.applet?.id === this.props.panel?.id;
    }

    private findMissingAdmissions(): Admission[] {
        if (!this.hasMissingAdmissions()) {
            return [];
        }
        const authentications = this.props.authentications!;
        const admissions = this.props.admissions!;
        return admissions.filter(admission => {
            const index = authentications.findIndex(authentication => {
                return authentication.sourceType === admission.sourceType &&
                    authentication.credentialsType === admission.credentialsType
            });
            return index === -1;
        });
    }

    private hasMissingAdmissions(): boolean {
        if (!this.props.authentications || !this.props.admissions || this.props.admissions.length === 0) {
            // If there are no admissions, they cannot be missing. Also if
            // there are no authentications, this could be embedded in another
            // applet. The parent will handle the credentials check.
            return false;
        }
        const authentications = this.props.authentications;
        const admissions = this.props.admissions;
        for (const admission of admissions) {
            const index = authentications.findIndex(authentication => {
                return authentication.sourceType === admission.sourceType &&
                    authentication.credentialsType === admission.credentialsType
            });
            if (index === -1) {
                return true;
            }
        }
        return false;
    }

    private findRootWidgets(): Widget[] {
        const childIds = new Set<string>();
        const widgets = this.activePanel().widgets;
        for (const widget of widgets) {
            const type = widget.configuration.type;
            // Find child widgets of row and columns.
            if (type === WidgetType.ROW || type === WidgetType.COLUMN) {
                const configuration = widget.configuration as any;
                const widgetIds = configuration.widgetIds;
                for (const widgetId of widgetIds) {
                    childIds.add(widgetId);
                }
            }
        }
        const rootWidgets = [];
        for (const widget of widgets) {
            if (!childIds.has(widget.id)) {
                rootWidgets.push(widget);
            }
        }
        return rootWidgets;
    }

    private buildItems(): ViewItem[] {
        const calculator = this.state.calculator!;
        const widgets = this.findRootWidgets();
        const viewItems: ViewItem[] = [];
        const panel = this.activePanel();
        widgets.forEach(widget => {
            // Check if the hide condition is satisfied.
            if (WidgetUtils.isHidden(calculator, widget)) {
                return;
            }
            const viewItem = {
                row: widget.row,
                col: widget.col,
                span: widget.span,
                element: (
                    <WidgetViewer
                        key={widget.id}
                        applet={this.state.applet!}
                        panel={panel}
                        widget={widget}
                        calculator={calculator}
                        configuration={this.props.configuration}
                        showHeader={false}
                        onUpdate={this.handleWidgetUpdate}
                    />
                )
            }
            viewItems.push(viewItem);
        });
        return viewItems;
    }

    private handleWidgetUpdate(configuration: Configuration): void {
        this.props.onChange(configuration, this.state.applet!.id);
    }

    private handleMenuSelect(menuItem: MenuItem): void {
        this.setState({ menuItem: menuItem });
    }

    private handleMenuClose(): void {
        this.setState({ menuItem: undefined });
    }

    private handleAuthenticationAdd(header: AuthenticationHeader): void {
        if (this.props.onAuthentication) {
            this.props.onAuthentication(header);
        }
        if (!this.hasMissingAdmissions() && this.props.onLoad) {
            this.props.onLoad(this.state.applet!, this.state.calculator!);
        }
        this.setState({ editAdmission: undefined });
    }

    private handleAuthenticationCancel(): void {
        this.setState({ editAdmission: undefined });
    }

    private handleExecutionInitiate(): void {
        this.setState({ isExecuting: true });
    }

    private handleExecutionComplete(): void {
        //this.props.calculator.printState(true);
        this.setState({ isExecuting: false });
        this.forceUpdate();
    }

    private handleCredentialsConfigure(admission: Admission): void {
        this.setState({ editAdmission: admission });
    }

    private readAppletItemRequest(appletRef: AppletRef | undefined): Promise<any> {
        if ((this.state.applet && this.state.calculator) || !appletRef) {
            return Promise.resolve();
        }
        const request = {
            appletRef: appletRef
        };
        return modelService.readAppletItem(request,
            (response: any) => this.readAppletItemResponse(response),
            undefined, true
        );
    }

    private readAppletItemResponse(response: any): void {
        this.loadedApplet = true;
        const item = response.data.item;
        const applet = item.applet;
        const calculator = Calculator.deserialize(item.calculator);
        calculator.httpHeaders = RestUtils.getHttpHeaders();
        calculator.context.requestKey = 0;
        calculator.addCallback("ExecutionInitiate", this.handleExecutionInitiate);
        calculator.addCallback("ExecutionComplete", this.handleExecutionComplete);
        this.setState({
            applet: applet,
            calculator: calculator
        });
        // Only execute the calculator when all credentials are configured.
        if (!this.hasMissingAdmissions() && this.props.onLoad) {
            this.props.onLoad(applet, calculator);
        }
    }

    private buildWidgetsView(): ReactElement {
        const items = this.buildItems();
        return (
            <Row className="x-appletviewer-view">
                {items.map(item => item.element)}
            </Row>
        )
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        if (this.props.isEmbedded) {
            return (
                <div>
                    {this.buildSkeleton(isLoading)}
                </div>
            )
        } else {
            return (
                <Card
                    size="small"
                    bordered={true}
                    title={this.props.title}
                    extra={
                        <Spacer>
                            {this.state.message}
                            {this.props.extra}
                        </Spacer>
                    }
                >
                    {this.buildSkeleton(isLoading)}
                </Card>
            )
        }
    }

    private buildSkeleton(isLoading: boolean): ReactElement {
        return (
            <LoadSkeleton
                count={4}
                status={isLoading ? "loading" : "failed"}
                failedMessage={this.state.error ? this.state.error.message : "Failed to load applet."}
                onRetry={this.handleRetryLoad}
            >
                <LoadSkeleton.Input length="fill" />
            </LoadSkeleton>
        )
    }

    private activePanel(): AppletPanel {
        return this.props.panel ?? this.state.applet!;
    }

    private buildAppletView(): ReactElement {
        const admissions = this.findMissingAdmissions();
        const widgets = this.activePanel().widgets;
        const title = (
            <AppletTitle
                applet={this.state.applet!}
                calculator={this.state.calculator!}
                onSelect={this.handleMenuSelect}
            />
        )
        const extra = (
            <Spacer>
                {this.state.isExecuting &&
                    <SyncOutlined spin={true} />
                }
                {this.props.extra}
            </Spacer>
        )
        const view = (
            <>
                {widgets.length === 0 &&
                    <NoData text="No widgets added." />
                }
                {widgets.length > 0 &&
                    <>
                        {admissions.length > 0 &&
                            this.buildConfigurationView(admissions)
                        }
                        {admissions.length === 0 &&
                            this.buildWidgetsView()
                        }
                    </>
                }
            </>
        )
        return (
            <>
                {!this.props.isEmbedded &&
                    <Card
                        className="x-appletviewer-card"
                        size="small"
                        bordered={true}
                        title={title}
                        extra={extra}
                    >
                        {view}
                    </Card>
                }
                {this.props.isEmbedded &&
                    <div>
                        {view}
                    </div>
                }
                {this.isApplet() && this.state.menuItem &&
                    <PanelDialog
                        viewId="dashboarditem"
                        applet={this.state.applet!}
                        title={this.state.menuItem.label}
                        panelId={this.state.menuItem.panelId}
                        calculator={this.state.calculator!}
                        configuration={this.props.configuration}
                        onChange={this.props.onChange}
                        onClose={this.handleMenuClose}
                    />
                }
            </>
        );
    }

    private buildConfigurationView(admissions: Admission[]): ReactElement {
        return (
            <div>
                <div className="x-appletviewer-config">This applet requires credentials that need to be configured:</div>
                <Spacer direction="vertical">
                    {admissions.map(admission => (
                        <Spacer>
                            <Button onClick={() => this.handleCredentialsConfigure(admission)}>Configure</Button>
                            <span>{`${SourceType.name(admission.sourceType)} ${CredentialsType.name(admission.credentialsType)}`}</span>
                        </Spacer>
                    ))}
                </Spacer>
            </div>
        )
    }

    private loadData(): void {
        this.loadApplet(this.props.appletRef);
    }

    private loadApplet(appletRef: AppletRef | undefined): void {
        const requests = [];
        requests.push(this.readAppletItemRequest(appletRef));
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public componentWillUnmount(): void {
        if (this.state.calculator && this.loadedApplet) {
            this.state.calculator.removeCallback("ExecutionInitiate", this.handleExecutionInitiate);
            this.state.calculator.removeCallback("ExecutionComplete", this.handleExecutionComplete);
            // this.state.calculator.close();
        }
    }

    public shouldComponentUpdate(nextProps: AppletViewerProps): boolean {
        if (!this.props.appletRef && nextProps.appletRef) {
            this.loadApplet(nextProps.appletRef);
            return false;
        } else if (this.props.appletRef && nextProps.appletRef && this.props.appletRef.version !== nextProps.appletRef.version) {
            this.loadApplet(nextProps.appletRef);
            return false;
        } else {
            return true;
        }
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === StatusType.LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === StatusType.FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === StatusType.READY) {
            view = this.buildAppletView();
        }
        return (
            <div className="x-appletviewer">
                {view}
                {this.state.editAdmission &&
                    <AuthenticationItem
                        admission={this.state.editAdmission}
                        onChange={this.handleAuthenticationAdd}
                        onCancel={this.handleAuthenticationCancel}
                    />
                }
            </div>
        )
    }

}
