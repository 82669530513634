import { ReactElement } from 'react';
import { CartesianData } from '../ChartWidgetViewer';
import { LineChartWidgetConfiguration, LineType } from '@methodset/model-client-ts';
import { ApexChartUtils } from '../ApexChartsUtils';
import ReactApexChart from 'react-apexcharts';
import classNames from 'classnames';
import './LineChartView.less';

const LARGE_CHART = 15;

export type LineChartProps = {
    className?: string,
    data: CartesianData,
    configuration: LineChartWidgetConfiguration
};

export const LineChartView = (props: LineChartProps): ReactElement => {

    const series = (): ApexAxisChartSeries => {
        return props.data.seriesList;
    }

    const options = (): ApexCharts.ApexOptions => {
        const options = ApexChartUtils.cartesianOptions("line", props.data, props.configuration);
        options.stroke = ApexChartUtils.strokeOptions();
        options.grid = ApexChartUtils.gridOptions();
        options.markers = {
            size: props.configuration.hasMarkers ? 5 : 0,
            shape: "circle"
        }
        const lineType = LineType.option(props.configuration.lineType);
        if (lineType) {
            options.stroke!.curve = lineType as any
        }
        return options;
    }

    return (
        <div className={classNames('x-linechartview', props.className)}>
            <ReactApexChart
                options={options()}
                series={series()}
                type="line"
                height={props.configuration.height}
            />
        </div>
    )
}
