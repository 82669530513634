export class Constants {
    public static readonly DAY_OF_WEEK_ABBREV = [ 'Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa' ];
    public static readonly MONTH_ABBREV = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
    public static readonly TIME_DISPLAY_FORMAT = 'h:mm a'
    public static readonly DATE_DISPLAY_FORMAT = 'YYYY-MM-DD';
    public static readonly DATE_TIME_DISPLAY_FORMAT = 'YYYY-MM-DD h:mm:ss a';
    public static readonly TIME_ISO_FORMAT = 'HH:mm:ss';
    public static readonly DATE_ISO_FORMAT = 'YYYY-MM-DD';
    public static readonly DATE_TIME_ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
    public static readonly DATE_TIME_LOCAL_ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
    public static readonly ELEMENT_SELECTED = 'ELEMENT_SELECTED';
    public static readonly ELEMENT_ACCEPTED = 'ELEMENT_ACCEPTED';
    public static readonly EDITOR_ENABLED = 'EDITOR_ENABLED';

    // XXX REMOVE
    public static readonly CHART_COLORS = [
        '#108ee9',
        '#00a854',
        '#f04134',
        '#f5317f',
        '#f56a00',
        '#7265e6',
        '#ffbf00',
        '#00a2ae',
        '#7ec2f3',
        '#76d0a3',
        '#f79992',
        '#fa90ba',
        '#faaf76',
        '#b3acf2',
        '#ffdd76',
        '#76cdd3',
        '#0c60aa',
        '#007b43',
        '#bd2636',
        '#c11c7b',
        '#b93600',
        '#533eb4',
        '#c17500',
        '#00707f',
        '#d2eafb',
        '#cfefdf',
        '#fcdbd9',
        '#fdd8e7',
        '#fde3cf',
        '#fde3cf',
        '#e4e2fa',
        '#fff3cf',
        '#cfedf0',
        '#073069',
        '#004c32',
        '#880a38',
        '#8c0776',
        '#7a0000',
        '#321580',
        '#802800',
        '#003c4e'
    ];

    // XXX REMOVE
    public static CHART_COLOR(index: number): string {
        return Constants.CHART_COLORS[index % Constants.CHART_COLORS.length];
    }

};
