import { ReactElement } from 'react';
import { Spacer } from 'components/Spacer/Spacer';
import { Interval, UnitType } from '@methodset/model-client-ts';
import { Select } from 'antd';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';
import update from 'immutability-helper';
import './IntervalEditor.less';

export type ChangeCallback = (interval: Interval) => void;

export type IntervalEditorProps = {
    interval?: Interval,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    interval: {
        duration: 1,
        unitType: UnitType.DAY
    }
}

export const IntervalEditor = (props: IntervalEditorProps): ReactElement => {

    const handleDurationChange = (duration: number): void => {
        const interval = update(props.interval, {
            duration: { $set: duration }
        });
        props.onChange(interval);
    }

    const handleUnitChange = (unitType: UnitType): void => {
        const interval = update(props.interval, {
            unitType: { $set: unitType }
        });
        props.onChange(interval);
    }

    return (
        <Spacer className="x-intervaleditor" fill>
            <IntegerInput
                className="x-intervaleditor-value"
                natural={true}
                value={props.interval.duration}
                onChange={handleDurationChange}
            />
            <Select
                className="x-intervaleditor-unit"
                allowClear={true}
                value={props.interval.unitType}
                onChange={handleUnitChange}
            >
                {UnitType.entries().map(([key, value]) =>
                    <Select.Option key={key} value={key}>{value}</Select.Option>
                )}
            </Select>
        </Spacer>
    );

}

IntervalEditor.defaultProps = defaultProps;