import { ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import { FormInstance, Input, Select } from 'antd';
import { FrequencyType, Income } from '@methodset/entity-client-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { IntegerInput } from 'components/IntegerInput/IntegerInput';
import { Date } from '@methodset/commons-shared-ts';
import { DateSelector } from 'components/DateSelector/DateSelector';
import update from 'immutability-helper';
import './IncomeEditor.less';

export type ChangeCallback = (income: Income, index: number) => void;

export type IncomeEditorProps = {
    formRef: React.RefObject<FormInstance>,
    id: string,
    index?: number,
    income?: Income,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    index: 0,
    income: {} as Income
}

export const IncomeEditor = (props: IncomeEditorProps): ReactElement => {

    const handleNameChange = (name: string): void => {
        const income = update(props.income, {
            name: { $set: name }
        });
        props.onChange(income, props.index);
    }

    const handleSourceChange = (source: string): void => {
        const income = update(props.income, {
            source: { $set: source }
        });
        props.onChange(income, props.index);
    }

    const handleAmountChange = (amount: number): void => {
        const income = update(props.income, {
            amount: { $set: amount }
        });
        props.onChange(income, props.index);
    }

    const handleFrequencyChange = (frequency: FrequencyType): void => {
        const income = update(props.income, {
            frequency: { $set: frequency }
        });
        props.onChange(income, props.index);
    }

    const handleStartDateChange = (date: Date | undefined): void => {
        const startDate = date ? date.toIso() : undefined;
        const income = update(props.income, {
            startDate: { $set: startDate as any }
        });
        props.onChange(income, props.index);
    }

    const handleEndDateChange = (date: Date | undefined): void => {
        const endDate = date ? date.toIso() : undefined;
        const income = update(props.income, {
            endDate: { $set: endDate as any }
        });
        props.onChange(income, props.index);
    }

    return (
        <>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Name"
                name={`income-name-${props.id}${props.index}`}
                rules={[{
                    required: true,
                    message: 'Please enter a name.'
                }]}
            >
                <Input
                    placeholder="Income name (i.e., salary)."
                    value={props.income.name}
                    onChange={(e) => handleNameChange(e.target.value)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Source"
                name={`income-source-${props.id}${props.index}`}
                rules={[{
                    required: true,
                    message: 'Please enter a source.'
                }]}
            >
                <Input
                    placeholder="Income source (i.e., company name)."
                    value={props.income.source}
                    onChange={(e) => handleSourceChange(e.target.value)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Amount"
                name={`income-amount-${props.id}${props.index}`}
                info="The amount of income received for the frequency."
                rules={[{
                    required: true,
                    message: "Please enter an amount."
                }]}
            >
                <IntegerInput
                    fill={true}
                    natural={true}
                    placeholder="Enter an amount."
                    value={props.income.amount}
                    onChange={(amount) => handleAmountChange(amount)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Frequency"
                name={`income-frequency-${props.id}${props.index}`}
                info="The frequency of the income."
                rules={[{
                    required: true,
                    message: 'Please enter a frequency.'
                }]}
            >
                <Select
                    placeholder="Select a frequency."
                    value={props.income.frequency}
                    onChange={(frequency) => handleFrequencyChange(frequency)}
                >
                    <Select.Option value={FrequencyType.DAILY}>Daily</Select.Option>
                    <Select.Option value={FrequencyType.WEEKLY}>Weekly</Select.Option>
                    <Select.Option value={FrequencyType.BIWEEKLY}>Bi-Weekly</Select.Option>
                    <Select.Option value={FrequencyType.MONTHLY}>Monthly</Select.Option>
                    <Select.Option value={FrequencyType.QUARTERLY}>Quarterly</Select.Option>
                    <Select.Option value={FrequencyType.BIANNUALLY}>Bi-Annually</Select.Option>
                    <Select.Option value={FrequencyType.ANNUALLY}>Annually</Select.Option>
                </Select>
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Start Date"
                name={`income-start-${props.id}${props.index}`}
                rules={[{
                    required: true,
                    message: 'Please enter the start date.'
                }]}
            >
                <DateSelector
                    value={props.income.startDate ? Date.fromIso(props.income.startDate) : undefined}
                    onChange={(date) => handleStartDateChange(date)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="End Date"
                name={`income-end-${props.id}${props.index}`}
            >
                <DateSelector
                    value={props.income.endDate ? Date.fromIso(props.income.endDate) : undefined}
                    onChange={(date) => handleEndDateChange(date)}
                />
            </FormItem>
        </>
    )
}

IncomeEditor.defaultProps = defaultProps;
