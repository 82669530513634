import { FC, ReactElement, useContext } from 'react';
import { DeleteOutlined, EditOutlined, MoreOutlined, SyncOutlined } from '@ant-design/icons';
import { Cell } from '@methodset/calculator-ts';
import { ModelContext } from 'context/ModelContext';
import { Clickable } from 'components/Clickable/Clickable';
import { MenuButton } from 'components/MenuButton/MenuButton';
import './QueryItem.less';

export type EditCallback = (cell: Cell) => void;
export type RemoveCallback = (cell: Cell) => void;


type QueryItemProps = {
    cell: Cell,
    isPrimary: boolean,
    isLoading: boolean,
    onEdit: EditCallback,
    onRemove: RemoveCallback
}

export const QueryItem: FC<QueryItemProps> = (props: QueryItemProps): ReactElement => {

    const context = useContext(ModelContext);

    const handleRunClick = (): void => {
        context.calculator!.runQuery(props.cell)
    }

    const handleEditClick = (): void => {
        props.onEdit(props.cell);
    }

    const handleRemoveClick = (): void => {
        props.onRemove(props.cell);
    }

    const buildMenu = (): ReactElement => {
        const items = [{
            icon: <SyncOutlined />,
            label: 'Run',
            onSelect: () => handleRunClick()
        }, {
            icon: <EditOutlined />,
            label: 'Edit',
            hidden: () => !props.isPrimary,
            onSelect: () => handleEditClick()
        }, {
            icon: <DeleteOutlined />,
            label: 'Remove',
            onSelect: () => handleRemoveClick()
        }];
        return (
            <MenuButton items={items} icon={buildIcon()} />
        );
    }

    const buildIcon = (): ReactElement => {
        return props.isLoading ? <SyncOutlined spin /> : <MoreOutlined />;
    }

    return (
        <div className="x-queryitem">
            {props.isPrimary &&
                <Clickable onClick={handleEditClick}>
                    <div className="x-queryitem-id">
                        {props.cell.uid}
                    </div>
                </Clickable>
            }
            {!props.isPrimary &&
                <div className="x-queryitem-id">
                    {props.cell.uid}
                </div>
            }
            {buildMenu()}
        </div>
    )

}
