import { FC, ReactElement } from 'react';
import { Cell } from '@methodset/calculator-ts';
import { Button, Dropdown, Menu, Space } from 'antd';
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Clickable } from 'components/Clickable/Clickable';
import './PivotItem.less';

export type EditCallback = (cell: Cell) => void;
export type RemoveCallback = (cell: Cell) => void;

type Props = {
    cell: Cell,
    onEdit: EditCallback,
    onRemove: RemoveCallback
}

export const PivotItem: FC<Props> = (props: Props): ReactElement => {

    const handleEdit = (): void => {
        props.onEdit(props.cell);
    }

    const handleRemove = (): void => {
        props.onRemove(props.cell);
    }

    const buildMenu = (): ReactElement => {
        const menu = [{
            icon: <EditOutlined />,
            label: 'Edit',
            callback: handleEdit
        }, {
            icon: <DeleteOutlined />,
            label: 'Remove',
            callback: handleRemove
        }];
        return (
            <Menu>
                {menu.map((entry, index) => {
                    return (
                        <Menu.Item key={index} onClick={entry.callback}>
                            <Space>
                                <span>{entry.icon}</span>
                                <span>{entry.label}</span>
                            </Space>
                        </Menu.Item>
                    )
                })}
            </Menu>
        );
    }

    return (
        <div className="x-pivotitem">
            <Clickable onClick={handleEdit}>
                <div className="x-pivotitem-id">
                    {props.cell.uid}
                </div>
            </Clickable>
            <Dropdown
                overlay={buildMenu()}
                trigger={['click']}
            >
                <Button icon={<MoreOutlined />} />
            </Dropdown>
        </div>
    )

}
