import React, { PureComponent, ReactElement } from 'react';
import { Form, FormInstance, Modal } from 'antd';
import { AuthenticationHeader, Configuration, ConfigurationSpec } from '@methodset/endpoint-client-ts';
import { Globals } from 'constants/Globals';
import { SyntaxType } from 'components/DateTimeSelector/DateTimeSelector';
import { ConfigurationEditor } from '../ConfigurationEditor/ConfigurationEditor';
import classNames from 'classnames';
import './ConfigurationDialog.less';

export type ChangeCallback = (configuration: Configuration) => void;
export type CancelCallback = () => void;

export type ConfigurationDialogProps = typeof ConfigurationDialog.defaultProps & {
    className?: string,
    title?: string,
    timeSyntax?: SyntaxType,
    configuration?: Configuration,
    configurationSpecs?: ConfigurationSpec[],
    authentications: AuthenticationHeader[],
    optionalLabel?: string,
    onChange: ChangeCallback,
    onCancel: CancelCallback
}

export type ConfigurationDialogState = {
    configuration: Configuration
}

export class ConfigurationDialog extends PureComponent<ConfigurationDialogProps, ConfigurationDialogState> {

    private formRef = React.createRef<FormInstance>();

    static defaultProps = {
        title: "Configuration",
        configuration: {},
        configurationSpecs: [] as ConfigurationSpec[]
    }

    constructor(props: ConfigurationDialogProps) {
        super(props);
        this.state = {
            configuration: props.configuration
        };
        this.handleOkClick = this.handleOkClick.bind(this);
        this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
    }

    private handleOkClick(): void {
        this.formRef.current!.validateFields().then(values => {
            this.props.onChange(this.state.configuration);
        }).catch(e => {
            console.log(e.message);
        });
    }

    private handleConfigurationChange(configuration: Configuration): void {
        this.setState({ configuration: configuration });
    }

    public render(): ReactElement {
        return (
            <Modal
                className={classNames("x-configurationdialog", this.props.className)}
                centered
                title={this.props.title}
                visible={true}
                width={Globals.DIALOG_WIDTH}
                okText="Run"
                onOk={this.handleOkClick}
                onCancel={this.props.onCancel}
            >
                <Form ref={this.formRef}>
                    <ConfigurationEditor
                        formRef={this.formRef}
                        configuration={this.state.configuration}
                        configurationSpecs={this.props.configurationSpecs}
                        authentications={this.props.authentications}
                        timeSyntax={this.props.timeSyntax}
                        optionalLabel={this.props.optionalLabel}
                        showEmpty={true}
                        showExpressions={false}
                        onChange={this.handleConfigurationChange}
                    />
                </Form>
            </Modal>
        );
    }

}
