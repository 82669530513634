import { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Button, Input } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { DeleteOutlined } from '@ant-design/icons';
import { Applet, SwitchOption } from '@methodset/model-client-ts';
import { PanelSelector } from 'containers/Components/Widgets/Selectors/AppletSelector';
import update from 'immutability-helper';
import './SwitchOptionEditor.less';

export type ChangeCallback = (option: SwitchOption, index: number) => void;
export type RemoveCallback = (index: number) => void;

export type SwitchOptionEditorProps = {
    index: number,
    applet: Applet,
    option: SwitchOption,
    onChange: ChangeCallback,
    onRemove: RemoveCallback
}

export class SwitchOptionEditor extends PureComponent<SwitchOptionEditorProps> {

    constructor(props: SwitchOptionEditorProps) {
        super(props);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handlePanelChange = this.handlePanelChange.bind(this);
        this.handleOptionRemove = this.handleOptionRemove.bind(this);
    }

    private handleValueChange(e: ChangeEvent<HTMLInputElement>): void {
        const value = e.target.value;
        const option = update(this.props.option, {
            value: { $set: value }
        });
        this.props.onChange(option, this.props.index);
    }

    private handlePanelChange(panelId: string): void {
        const option = update(this.props.option, {
            panelId: { $set: panelId }
        });
        this.props.onChange(option, this.props.index);
    }

    private handleOptionRemove(): void {
        this.props.onRemove(this.props.index);
    }

    public render(): ReactElement {
        return (
            <Spacer className="x-switchoptioneditor" fill>
                <Input
                    className="x-switchoptioneditor-value"
                    placeholder="Option value."
                    value={this.props.option.value}
                    onChange={this.handleValueChange}
                />
                <PanelSelector
                    className="x-switchoptioneditor-select"
                    applet={this.props.applet}
                    panelId={this.props.option.panelId}
                    onChange={this.handlePanelChange}
                />
                <Button
                    className="x-switchoptioneditor-delete"
                    icon={<DeleteOutlined />}
                    onClick={this.handleOptionRemove}
                >
                </Button>
            </Spacer>
        );
    }

}
