import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Col, Collapse, Input, Row, Switch, FormInstance, Form, Modal } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { SchemaEditor } from './SchemaEditor/SchemaEditor';
import { Globals } from 'constants/Globals';
import { AuthenticationHeader, Component, Configuration, ConfigurationSpec, Field, Flow, InputSpec, IoMap, OutputSpec, ProcessorHeader, Schema, CompositeQuery, Inputs } from '@methodset/endpoint-client-ts';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { Processors } from '../../../Processors/Processors';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { Specs } from 'containers/Console/Specs/Specs';
import update from 'immutability-helper';
import classNames from 'classnames';
import axios from 'axios';
import endpointService from 'services/EndpointService';
import './DatasetEditor.less';

export type EditCallback = (isEditing: boolean) => void;
export type TouchCallback = () => void;
export type SaveCallback = (query: CompositeQuery) => void;
export type ErrorCallback = (error: Error) => void;

export type DatasetEditorProps = typeof DatasetEditor.defaultProps & {
    // Class to style the form.
    className?: string,
    // The id of the query to load and edit.
    queryId?: string,
    // Called when a subsection is being edited.
    onEdit: EditCallback,
    // Called when the query is changed.
    onTouch: TouchCallback,
    // Called when the query is saved.
    onSaved: SaveCallback,
    // Called when there is an error processing the query.
    onError: ErrorCallback
}

export type DatasetEditorState = {
    // Status of loading query.
    status: string,
    // The processor headers.
    processors: ProcessorHeader[],
    // The query being edited.
    query: CompositeQuery,
    // True if user has API publishing enabled.
    useApi: boolean,
    // True to use this users credentials when accessing data.
    useDelegate: boolean,
    // The list of user authentications.
    authentications: AuthenticationHeader[],
}

export class DatasetEditor extends PureComponent<DatasetEditorProps, DatasetEditorState> {

    static defaultProps = {
    }

    private propertiesRef = React.createRef<FormInstance>();
    private schemaRef = React.createRef<FormInstance>();

    // Tells if the user needs to update the schema.
    private isDirty = false;

    constructor(props: DatasetEditorProps) {
        super(props);
        this.state = {
            status: Globals.STATUS_INIT,
            processors: [],
            authentications: [] as AuthenticationHeader[],
            query: {
                id: undefined as any,
                version: undefined as any,
                type: undefined as any,
                name: undefined as any,
                description: undefined as any,
                publisher: undefined as any,
                provider: undefined as any,
                configurationSpecs: [] as ConfigurationSpec[],
                inputSpecs: [] as InputSpec[],
                outputSpecs: [] as OutputSpec[],
                schema: {
                    fields: [] as Field[]
                } as Schema,
                serviceName: undefined as any,
                methodName: undefined as any,
                components: [] as Component[],
                flows: [] as Flow[],
                resultMappings: {} as IoMap,
                testInputs: {} as Inputs
            },
            useApi: false,
            useDelegate: false,
        };
        this.handleSubEdit = this.handleSubEdit.bind(this);
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleUseApiChange = this.handleUseApiChange.bind(this);
        this.handleUseDelegateChange = this.handleUseDelegateChange.bind(this);
        this.handleServiceChange = this.handleServiceChange.bind(this);
        this.handleMethodChange = this.handleMethodChange.bind(this);
        this.handleProviderChange = this.handleProviderChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleVariableSpecsChange = this.handleVariableSpecsChange.bind(this);
        this.handleTestInputsChange = this.handleTestInputsChange.bind(this);
        this.handleComponentsChange = this.handleComponentsChange.bind(this);
        this.handleSchemaChange = this.handleSchemaChange.bind(this);
        this.saveQuery = this.saveQuery.bind(this);
    }

    private handleSubEdit(isEditing: boolean): void {
        this.props.onEdit(isEditing);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>) {
        const name = e.target.value;
        const userQuery = update(this.state.query, {
            name: { $set: name },
        });
        this.setState({ query: userQuery });
        this.props.onTouch();
    }

    private handleDescriptionChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        const description = e.target.value;
        const userQuery = update(this.state.query, {
            description: { $set: description }
        });
        this.setState({ query: userQuery });
        this.props.onTouch();
    }

    private handleUseApiChange(useApi: boolean): void {
        if (useApi) {
            const methodName = CoreUtils.toCodeName(this.state.query.name, "-", true);
            const userQuery = update(this.state.query, {
                methodName: { $set: methodName }
            });
            this.setState({ query: userQuery });
        } else if (this.state.query.name) {
            const query = update(this.state.query, {
                $unset: ["methodName", "serviceName"]
            });
            this.setState({ query: query });
        }
        this.setState({ useApi: useApi });
        this.props.onTouch();
    }

    private handleUseDelegateChange(useDelegate: boolean): void {
        if (useDelegate) {
            const self = this;
            const modal = Modal.confirm({
                title: "Enable Shared Data",
                content: "WARNING: By setting this option, any user can access this dataset, using your credentials if required. " +
                    "Only enable this option when the data is stored in a shared repository that is supposed to be accessed by " +
                    "users of different groups and organizations. When this option is disabled, users will access their own " +
                    "data with their own credentials.",
                cancelText: "Cancel",
                okText: "Ok",
                onCancel: () => {
                    modal.destroy()
                },
                onOk: () => {
                    self.handleEnableDelegate(true)
                    modal.destroy();
                }
            });
        } else {
            this.handleEnableDelegate(false);
        }
    }

    private handleEnableDelegate(useDelegate: boolean): void {
        this.setState({ useDelegate: useDelegate });
        this.props.onTouch();
    }

    private handleServiceChange(e: ChangeEvent<HTMLInputElement>): void {
        let serviceName = e.target.value;
        serviceName = CoreUtils.toCodeName(serviceName);
        const query = update(this.state.query, {
            serviceName: { $set: serviceName }
        });
        this.setState({ query: query });
        this.props.onTouch();
    }

    private handleMethodChange(e: ChangeEvent<HTMLInputElement>): void {
        let methodName = e.target.value;
        methodName = CoreUtils.toCodeName(methodName);
        const query = update(this.state.query, {
            methodName: { $set: methodName }
        });
        this.setState({ query: query });
        this.props.onTouch();
    }

    private handleProviderChange(e: ChangeEvent<HTMLInputElement>): void {
        const provider = e.target.value;
        const query = update(this.state.query, {
            provider: { $set: provider }
        });
        this.setState({ query: query });
        this.props.onTouch();
    }

    private handleComponentsChange(components: Component[]): void {
        const query = update(this.state.query, {
            components: { $set: components }
        });
        this.setState({ query: query });
        this.isDirty = true;
        this.props.onTouch();
    }

    private handleSchemaChange(schema: Schema): void {
        const query = update(this.state.query, {
            schema: { $set: schema }
        });
        this.isDirty = false;
        this.setState({ query: query });
        this.props.onTouch();
    }

    private handleVariableSpecsChange(configurationSpecs: ConfigurationSpec[]): void {
        const query = update(this.state.query, {
            configurationSpecs: { $set: configurationSpecs }
        });
        this.setState({ query: query });
        this.isDirty = true;
        this.props.onTouch();
    }

    private handleTestInputsChange(testInputs: Configuration): void {
        const query = update(this.state.query, {
            testInputs: { $set: testInputs }
        });
        this.setState({ query: query });
        this.props.onTouch();
    }

    public saveQuery(callback: () => void): void {
        // Validate the properties and schema sections. Variables and processors 
        // have already been validated when they were edited (panels only show the
        // results of sub-editors).
        this.propertiesRef.current?.validateFields().then(values => {
            const query = this.state.query;
            if (!query.schema.fields || query.schema.fields.length === 0) {
                this.props.onError(new Error('Please load schema fields.'));
                return;
            }
            if (this.isDirty) {
                // If parts the the query have changed that would affect the
                // result fields, force the user to reload the schema so that
                // the updated values can be saved in the query.
                this.props.onError(new Error('Configuration has changed, please sync schema fields and then save.'));
                return;
            }
            this.schemaRef.current?.validateFields().then(values => {
                if (query.id) {
                    this.updateQueryRequest(query);
                } else {
                    this.createQueryRequest(query);
                }
                callback();
            }).catch(error => {
                this.props.onError(new Error('Please fill in all fields in the schema section.'));
            });
        }).catch(error => {
            this.props.onError(new Error('Please fill in all fields in the properties section.'));
        });
    }

    private readAuthenticationHeadersRequest(): Promise<any> {
        const request = {};
        return endpointService.readAuthenticationHeaders(request,
            (response: any) => this.readAuthenticationHeadersResponse(response),
            undefined, true
        );
    }

    private readAuthenticationHeadersResponse(response: any): any {
        const authentications = response.data.headers;
        this.setState({ authentications: authentications });
    }

    private readProcessorHeadersRequest(): Promise<any> {
        const request = {};
        return endpointService.readProcessorHeaders(request,
            (response: any) => this.readProcessorHeadersResponse(response),
            undefined, true
        );
    }

    private readProcessorHeadersResponse(response: any): void {
        let processors = response.data.headers;
        this.setState({ processors: processors });
    }

    private readQueryRequest(): Promise<any> {
        const queryId = this.props.queryId;
        if (!queryId || queryId === 'create') {
            return Promise.resolve(true);
        }
        const request = {
            queryId: queryId,
            version: 0
        };
        return endpointService.readQuery(request,
            (response: any) => this.readQueryResponse(response),
            undefined, true
        );
    }

    private readQueryResponse(response: any): void {
        const query = response.data.query;
        const useApi = !!query.serviceName && !!query.methodName;
        this.isDirty = false;
        this.setState({
            query: query,
            useApi: useApi,
            useDelegate: !!query.delegateId
        });
    }

    private createQueryRequest(query: CompositeQuery): Promise<any> {
        const request = {
            name: query.name,
            description: query.description,
            provider: query.provider,
            serviceName: this.state.useApi ? query.serviceName : undefined,
            methodName: this.state.useApi ? query.methodName : undefined,
            configurationSpecs: query.configurationSpecs,
            components: query.components,
            schema: query.schema,
            testInputs: query.testInputs,
            useDelegate: this.state.useDelegate
        };
        return endpointService.createQuery(request,
            (response: any) => this.createQueryResponse(response),
            (response: any) => this.saveException(response),
            true
        );
    }

    private createQueryResponse(response: any): void {
        const query = response.data.query;
        this.props.onSaved(query);
    }

    private updateQueryRequest(query: CompositeQuery): Promise<any> {
        const request = {
            queryId: query.id,
            name: query.name,
            description: query.description,
            provider: query.provider,
            serviceName: this.state.useApi ? query.serviceName : undefined,
            methodName: this.state.useApi ? query.methodName : undefined,
            configurationSpecs: query.configurationSpecs,
            components: query.components,
            schema: query.schema,
            testInputs: query.testInputs,
            useDelegate: this.state.useDelegate
        };
        return endpointService.updateQuery(request,
            (response: any) => this.updateQueryResponse(response),
            (response: any) => this.saveException(response),
            true
        );
    }

    private updateQueryResponse(response: any): void {
        const query = response.data.query;
        this.props.onSaved(query);
    }

    private saveException(response: any): void {
        const message = RestUtils.getErrorMessage(response);
        this.props.onError(new Error(message));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSkeleton
                count={4}
                status={isLoading ? "loading" : "failed"}
                failedMessage="Failed to load query."
                onRetry={this.handleRetryLoad}
            >
                <LoadSkeleton.Input length="fill" />
            </LoadSkeleton>
        );
    }

    private buildFormView(): ReactElement {
        return (
            <Collapse defaultActiveKey={["properties"]}>
                <Collapse.Panel key="properties" header="Properties" forceRender={true}>
                    <Form ref={this.propertiesRef}>
                        <Row gutter={[Globals.FORM_GUTTER_COL, Globals.FORM_GUTTER_ROW]}>
                            <Col span={12}>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.propertiesRef}
                                    label="Name"
                                    name="name"
                                    info="The name of the dataset."
                                    rules={[{
                                        required: true,
                                        message: 'Please enter a name.'
                                    }]}
                                >
                                    <Input
                                        placeholder="Dataset name."
                                        value={this.state.query.name}
                                        onChange={this.handleNameChange}
                                    />
                                </FormItem>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.propertiesRef}
                                    label="Description"
                                    name="description"
                                    info="A description of the dataset."
                                    rules={[{
                                        required: false,
                                        message: 'Please enter a description.'
                                    }]}
                                >
                                    <Input.TextArea
                                        placeholder="Dataset description."
                                        rows={3}
                                        value={this.state.query.description}
                                        onChange={this.handleDescriptionChange}
                                    />
                                </FormItem>
                                {/* <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.propertiesRef}
                                    label="Publish API"
                                    name="api"
                                    info="Publishing allows external API access to the dataset."
                                >
                                    <Switch
                                        checked={this.state.useApi}
                                        onChange={this.handleUseApiChange}
                                    />
                                </FormItem> */}
                            </Col>
                            <Col span={12}>
                                {/* <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.propertiesRef}
                                    label="API Service"
                                    name="service"
                                    info="Specifies an API service name for this dataset. Use a value to associate a group 
                                            of related datasets."
                                    hidden={!this.state.useApi}
                                    rules={[{
                                        required: !!this.state.useApi,
                                        message: 'Please enter an API service name.'
                                    }]}
                                >
                                    <Input
                                        placeholder="API service name."
                                        value={this.state.query.serviceName}
                                        onChange={this.handleServiceChange}
                                    />
                                </FormItem>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.propertiesRef}
                                    label="API Method"
                                    name="method"
                                    info="Specifies an API method name for this dataset. Use a value that is meaningful 
                                            to the data fetched from this dataset."
                                    hidden={!this.state.useApi}
                                    rules={[{
                                        required: !!this.state.useApi,
                                        message: 'Please enter an API method name.'
                                    }]}
                                >
                                    <Input
                                        placeholder="API method name."
                                        value={this.state.query.methodName}
                                        onChange={this.handleMethodChange}
                                    />
                                </FormItem> */}
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.propertiesRef}
                                    label="Data Provider"
                                    name="provider"
                                    info="The entity which provides the source data."
                                >
                                    <Input
                                        placeholder="Data provider."
                                        value={this.state.query.provider}
                                        onChange={this.handleProviderChange}
                                    />
                                </FormItem>
                                <FormItem
                                    {...Globals.FORM_LAYOUT}
                                    formRef={this.propertiesRef}
                                    label="Share Data Access"
                                    name="share"
                                    info="If credentials are required, access to this dataset will use your credentials to do so, and not those of the caller. You will be the owner of the centralized data this dataset provides and other users will be able to access it if given permissions."
                                >
                                    <Switch
                                        checked={this.state.useDelegate}
                                        onChange={this.handleUseDelegateChange}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Collapse.Panel>
                <Collapse.Panel key="variables" header="Variables" forceRender={true}>
                    <Specs
                        variableSpecs={this.state.query.configurationSpecs}
                        onEdit={this.handleSubEdit}
                        onChange={this.handleVariableSpecsChange}
                    />
                </Collapse.Panel>
                <Collapse.Panel key="processors" header="Processors" forceRender={true}>
                    <Processors
                        headers={this.state.processors}
                        components={this.state.query.components}
                        variableSpecs={this.state.query.configurationSpecs}
                        configuration={this.state.query.testInputs as Configuration}
                        authentications={this.state.authentications}
                        allowAssignment={false}
                        allowTest={true}
                        serialFlow={true}
                        onEdit={this.handleSubEdit}
                        onUpdate={this.handleTestInputsChange}
                        onChange={this.handleComponentsChange}
                    />
                </Collapse.Panel>
                <Collapse.Panel key="schema" header="Schema" forceRender={true}>
                    <Form ref={this.schemaRef}>
                        <Row>
                            <Col span={24}>
                                <SchemaEditor
                                    formRef={this.schemaRef}
                                    components={this.state.query.components}
                                    configuration={this.state.query.testInputs as Configuration}
                                    configurationSpecs={this.state.query.configurationSpecs}
                                    authentications={this.state.authentications}
                                    schema={this.state.query.schema}
                                    onUpdate={this.handleTestInputsChange}
                                    onChange={this.handleSchemaChange}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Collapse.Panel>
            </Collapse>
        );
    }

    private loadData() {
        const requests = [];
        requests.push(this.readQueryRequest());
        requests.push(this.readProcessorHeadersRequest());
        requests.push(this.readAuthenticationHeadersRequest());
        this.setState({ status: Globals.STATUS_LOADING });
        axios.all(requests).then(axios.spread((r1, r2, r3) => {
            if (RestUtils.isOk(r1, r2, r3)) {
                this.setState({ status: Globals.STATUS_READY });
            } else {
                this.setState({ status: Globals.STATUS_FAILED });
            }
        }));
    }

    public componentDidMount() {
        if (this.state.status !== Globals.STATUS_READY) {
            this.loadData();
        }
    }

    public render() {
        let view;
        if (this.state.status === Globals.STATUS_LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === Globals.STATUS_FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === Globals.STATUS_READY) {
            view = this.buildFormView();
        }
        return (
            <div className={classNames('x-dataseteditor', this.props.className)}>
                {view}
            </div>
        );
    }

}
