import { Boolean, Date, Time } from '@methodset/commons-shared-ts';
import { Cell, ErrorType, FormulaError } from '@methodset/calculator-ts';
import { ClientConfig } from '@methodset/commons-client-ts';
import { Globals } from 'constants/Globals';
import { Constants } from 'components/Constants';
import moment from 'moment';

export type NameFunction = (item: any) => string;
export type VersionType = "latest" | "snapshot" | "release" | "publish" | "package";

export interface ColorMap {
    [key: string]: string;
}

export class CoreUtils {

    private static readonly SEPARATOR_PATTERN = /\s+/g;
    private static readonly CODE_PATTERN = /[^a-zA-Z0-9_-]/g;
    private static readonly VARIABLE_PATTERN = /^[a-zA-Z][a-zA-Z0-9_]*$/;

    private constructor() { }

    public static systemTimeZone(): string {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    /**
     * Generates a unique name by checking the names of the existing items.
     */
    public static createName(base: string, items: any[], fn: NameFunction): string {
        const names: { [key: string]: boolean } = {};
        for (let item of items) {
            const name = fn(item);
            if (name && name.startsWith(base)) {
                names[name] = true;
            }
        }
        let i = 1;
        while (true) {
            const name = `${base} ${i}`;
            if (!names[name]) {
                return name;
            }
            i++;
        }
    }

    public static toUpper(value: any, delimiter?: string, separator?: string): string | undefined {
        if (CoreUtils.isEmpty(value)) {
            return undefined;
        } else if (typeof value !== 'string') {
            value = value.toString();
        }
        value = value.toUpperCase();
        if (delimiter && separator) {
            const regex = new RegExp(delimiter, "g");
            value = value.replace(regex, separator);
        }
        return value;
    }

    public static toLower(value: any, delimiter?: string, separator?: string): string | undefined {
        if (CoreUtils.isEmpty(value)) {
            return undefined;
        } else if (typeof value !== 'string') {
            value = value.toString();
        }
        value = value.toLowerCase();
        if (delimiter && separator) {
            const regex = new RegExp(delimiter, "g");
            value = value.replace(regex, separator);
        }
        return value;
    }

    public static clone(obj: any): any {
        return JSON.parse(JSON.stringify(obj));
    }

    public static equals(o1: any, o2: any): boolean {
        return JSON.stringify(o1) === JSON.stringify(o2);
    }

    public static enumToKeys<T extends { [name: string | number]: any }>(t: T, ...extra: string[]): string[] {
        const keys = Object.keys(t).filter(type => isNaN(Number(type)));
        return extra.length > 0 ? keys.concat(extra) : keys;
        // const keys = Object.entries(t).filter(([key, value]) => {
        //    return typeof value === "string";
        // }).map(([key, value]) => {
        //     return key;
        // });
        // return extra.length > 0 ? keys.concat(extra) : keys;
    }

    public static isEmpty(value: any): boolean {
        return value === undefined || value === null;
    }

    public static stringToList(str?: string): string[] | undefined {
        return str ? str.split(/\s*(?:,|$)\s*/) : undefined;
    }

    public static listToString(items?: string[]): string | undefined {
        return items ? items.join(", ") : undefined;
    }

    public static stringIndex(radix: number, str?: string): number {
        if (!str) {
            return 0;
        }
        let index = 0;
        for (let i = 0; i < str.length; i++) {
            index += str.charCodeAt(i);
        }
        return index % radix;
    }

    /**
     * Gets the value from a cell for display purposes.
     * 
     * @param cell The cell.
     * @param formatted True to use formatted value if available, false otherwise. Defaults to true.
     * @returns The value or error code.
     */
    public static toDisplayValue(cell?: Cell, formatted: boolean = true): string | undefined {
        if (!cell) {
            return undefined;
        }
        let value = cell.value;
        if (FormulaError.isError(value)) {
            value = value.type;
        } else if (formatted && cell.formattedValue) {
            value = cell.formattedValue;
        } else if (Date.isDate(value) || Time.isTime(value)) {
            return value.toIso();
        } else if (Boolean.isBoolean(value)) {
            return value ? "TRUE" : "FALSE";
        }
        return value;
    }

    /**
     * Gets the value to edit from a cell.
     * 
     * @param cell The cell.
     * @returns The value to display in the editor.
     */
    public static toEditValue(cell?: Cell): string | ErrorType | undefined {
        if (!cell) {
            return undefined;
        }
        if (cell.formula) {
            return cell.formula;
        } else if (FormulaError.isError(cell.value)) {
            return cell.value.type;
        } else {
            return cell.value;
        }
    }

    /**
     * Tells if a value is a formula.
     * 
     * @param value The value to check.
     * @returns True if the value is a formula, false otherwise.
     */
    public static isFormula(value: string): boolean {
        return !!value && value.length > 0 && value[0] === "=";
    }

    /**
     * Compares 2 boolean, taking into account nulls.
     */
    public static compareBooleans(a: boolean, b: boolean): number {
        if (!a) {
            return -1;
        } else if (!b) {
            return 1;
        } else {
            return 0;
        }
    }

    /**
     * Compares 2 numbers, taking into account nulls.
     */
    public static compareNumbers(a: number, b: number): number {
        if (!a) {
            return -1;
        } else if (!b) {
            return 1;
        } else {
            return a - b;
        }
    }

    /**
     * Compares 2 strings, taking into account nulls.
     */
    public static compareStrings(a: string, b: string): number {
        if (!a) {
            return -1;
        } else if (!b) {
            return 1;
        } else {
            return a.localeCompare(b, undefined, { sensitivity: 'accent' });
        }
    }

    /**
     * Checks if the components of two arrays are the same.
     */
    public static hasDifference(lhs: any[], rhs: any[]): boolean {
        if (!lhs && !rhs) {
            return false;
        }
        if (!lhs || !rhs) {
            return true;
        }
        if (lhs.length !== rhs.length) {
            return true;
        }
        for (var i = 0; i < lhs.length; i++) {
            if (lhs[i].localeCompare(rhs[i]) !== 0) {
                return true;
            }
        }
        return false;
    }

    /**
     * Tells if the value is a string.
     * 
     * @param value The value to check.
     * @returns True if the value is a string, false otherwise.
     */
    public static isString(value: any): value is string {
        return typeof value === "string";
    }

    /**
     * Tells if the value is a number.
     * 
     * @param value The value to check.
     * @returns True if the value is a number, false otherwise.
     */
    public static isNumber(value: any): value is number {
        return typeof value === "number";
    }

    /**
     * Tells if the value is a boolean.
     * 
     * @param value The value to check.
     * @returns True if the value is a boolean, false otherwise.
     */
    public static isBoolean(value: any): value is boolean {
        return typeof value === "boolean";
    }

    /**
     * Gets the hostname.
     */
    public static hostname(): string {
        const url = new URL(window.location.href);
        return url.hostname;
    }

    /**
     * Gets the API URL to call the service.
     */
    public static apiUrl(service: string, port: number, version: number = 1, path?: string): string {
        const config = ClientConfig.api(service, port);
        const baseUrl = config.getBaseUrl();
        const api = `api/v${version}`;
        path = path ? `/${path}` : '';
        return `${baseUrl}/${service}/${api}${path}`;
        // XXX test connecting to dev/prod but using localhost web server to enable debugging web page
        // return `https://api.dev.methodset.com/${service}/${api}${path}`;
    }

    /**
     * Replaces white spaces with a separator to create a code.
     */
    public static replace(name: string, delimiter: string = "_", replacement: string = " "): string {
        if (!name) {
            return name;
        }
        return name.replace(delimiter, replacement);
    }

    /**
     * Capitalizes the first letter of text.
     */
    public static toCapital(text?: string): string | undefined {
        if (!text) {
            return text;
        }
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }
    
    /**
     * Capitalizes the first letter of all words and lower-cases all other letters split 
     * by the delimiter. Optionally replaces a delimiter with a separator. Excludes is 
     * an optional array that includes string to keep as-is.
     */
    public static toProper(text: string | undefined, delimiter: string = " ", separator: string = " ", excludes?: string[]): string {
        if (!text) {
            return text as string;
        }
        text = text
            .split(delimiter)
            .map((s) => {
                if (excludes) {
                    let contains = false;
                    const regex = new RegExp(excludes.join('|'), 'i');
                    if (regex.test(s)) {
                        contains = true;
                    }
                    return contains ? s : s.charAt(0).toUpperCase() + s.substring(1).toLowerCase();
                } else {
                    return s.charAt(0).toUpperCase() + s.substring(1).toLowerCase();
                }
            }).join(separator);
        return text;
    }

    /**
     * Finds the first object in an associative array.
     */
    public static findFirst(data: any): any {
        for (let prop in data) {
            return data[prop];
        }
    }

    /**
     * Converts a moment time to a string time.
     */
    public static toStringTime(time: any, format = Constants.TIME_DISPLAY_FORMAT): string {
        return !time ? time : time.format(format);
    }

    /**
     * Converts a string time to a moment time.
     */
    public static toMomentTime(time: any, format = Constants.TIME_DISPLAY_FORMAT): any {
        return !time ? time : moment(time, format);
    }

    /**
     * Replaces white spaces with a separator to create a code.
     */
    public static toCodeName(name: string, sep: string = "-", lowercase: boolean = false): string {
        if (!name) {
            return name;
        }
        name = name.replace(this.SEPARATOR_PATTERN, sep);
        name = name.replace(this.CODE_PATTERN, '')
        if (lowercase) {
            name = name.toLowerCase();
        }
        return name;
    }

    /**
     * Tells if a string is a enclosed variable.
     * 
     * @param value The variable, surrounded by ${} if it is a variable.
     * @returns True if the string is an enclosed variable, false otherwise.
     */
    public static isVariable(value: any): boolean {
        return CoreUtils.isString(value) && value.startsWith("${") && value.endsWith("}");
    }

    /**
     * Checks if a string is a valid variable name.
     */
    public static isVariableName(name: string): boolean {
        if (!name) {
            return true;
        }
        return this.VARIABLE_PATTERN.test(name);
    }

    /**
     * Builds a map of colors to use for tags.
     */
    public static toColorMap(types: string[], colors: string[] = Globals.TAG_COLORS): ColorMap {
        const count = colors.length;
        const map: ColorMap = {};
        types.forEach((type, index) => {
            map[type] = colors[index % count];
        });
        return map;
    }

    /**
     * Gets a representation of the update time for a time. If within the current
     * day, return a time, otherwise return a date.
     */
    public static toUpdateTime(time: any): string {
        if (!time) {
            return '---';
        }
        const start = moment().startOf('day');
        const update = moment(time);
        if (start.isBefore(update)) {
            return update.format(Constants.TIME_DISPLAY_FORMAT);
        } else {
            return update.format(Constants.DATE_DISPLAY_FORMAT);
        }
    }

    /**
     * Gets a representation of the time.
     */
    public static toTime(time: any): string {
        if (!time) {
            return '---';
        }
        return moment(time).format(Constants.DATE_TIME_DISPLAY_FORMAT);
    }

    /**
     * Formats a numerical version.
     * 
     * @param version The version number.
     * @returns A formatted version.
     */
    public static toVersion(version: number | undefined, type: VersionType = "snapshot"): string {
        if (CoreUtils.isNumber(version) && version! > 0) {
            return `v${version}`;
        }
        switch (type) {
            case "latest":
                return "Latest";
            case "snapshot":
                return "Snapshot";
            case "release":
                return "Unreleased";
            case "publish":
                return "Unpublished";
            case "package":
                return "Unpackaged";
        }
    }

}

