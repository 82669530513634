import { ReactElement, useEffect, useState } from 'react';
import { PlayCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Spin } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { Label } from 'components/Label/Label';
import { CoreUtils } from 'utils/CoreUtils';
import { AccessType } from '@methodset/commons-shared-ts';
import { AppletDetails, PackHeader } from '@methodset/library-client-ts';
import { AppletState, Dashboard } from '@methodset/dashboard-client-ts';
import { RestUtils } from 'utils/RestUtils';
import { PackInfo } from '../PackInfo/PackInfo';
import libraryService from 'services/LibraryService';
import './PackItem.less';

type InstallStatus = "ready" | "installing";
type InstallType = "install" | "update" | "latest" | "alternate";

export type InstallingCallback = (packHeader: PackHeader) => void;
export type InstalledCallback = (packHeader: PackHeader) => void;
export type ErrorCallback = (error: Error) => void;

export type PackItemProps = {
    index: number,
    dashboard: Dashboard,
    packHeader: PackHeader,
    accessType: AccessType,
    onInstalling: InstallingCallback,
    onInstalled: InstalledCallback,
    onError: ErrorCallback
}

export const PackItem = (props: PackItemProps): ReactElement => {

    const [error, setError] = useState<Error | null>(null);
    const [status, setStatus] = useState<InstallStatus>("ready");
    const [type, setType] = useState<InstallType>("install");

    useEffect(() => {
        const type = findInstallType();
        setType(type);
    });

    const handleInstallClick = () => {
        setStatus("installing");
        installPackRequest();
    }

    const installPackRequest = (): Promise<any> => {
        setError(null);
        props.onInstalling(props.packHeader);
        const request = {
            packId: props.packHeader.id,
            version: props.packHeader.version,
            instanceId: props.dashboard.id,
            accessType: props.accessType
        }
        return libraryService.installPack(request,
            (response: any) => installPackResponse(response),
            (response: any) => installPackException(response),
            true
        );
    }

    const installPackResponse = (response: any): void => {
        const header = response.data.header;
        props.onInstalled(header);
        setStatus("ready");
    }

    const installPackException = (response: any): void => {
        const error = RestUtils.getError(response);
        setError(error);
        setStatus("ready");
        props.onError(error);
    }

    const findAppletState = (): AppletState | undefined => {
        const appletStates = props.dashboard.appletStates;
        const appletDetails = props.packHeader.details as AppletDetails;
        return appletStates.find(appletState => appletState.appletId === appletDetails.applicationId);
    }

    const findInstallType = (): InstallType => {
        const appletState = findAppletState();
        if (appletState) {
            // // TODO: remove if statement when all applet states have pack refs
            // if (!appletState.packInfo) {
            //     return "update";
            // }
            if (appletState.packInfo.packId === props.packHeader.id) {
                if (appletState.packInfo.accessType !== props.packHeader.accessType) {
                    return "alternate";
                } else if (appletState.packInfo.version === props.packHeader.version) {
                    return "latest";
                } else {
                    return "update";
                }
            }
        }
        return "install";

        // for (let i = 0; i < appletStates.length; i++) {
        //     const appletState = appletStates[i];
        //     const appletDetails = props.packHeader.details as AppletDetails;
        //     // TODO: remove if statement when all states have pack refs
        //     if (appletState.appletId === appletDetails.appletId) {
        //         // TODO: remove it statement when all setups have pack refs
        //         if (!appletState.packInfo) {
        //             return "update";
        //         }
        //         if (appletState.packInfo.packId === props.packHeader.id) {
        //             if (appletState.packInfo.accessType !== props.packHeader.accessType) {
        //                 return "alternate";
        //             } else if (appletState.packInfo.version === props.packHeader.version) {
        //                 return "latest";
        //             } else {
        //                 return "update";
        //             }
        //         }
        //     }
        // }
        // return "install";
    }

    return (
        <div className="x-packitem">
            <Row>
                <PackInfo index={props.index} packHeader={props.packHeader} />
            </Row>
            <Row className="x-packitem-details" justify="space-between" align="bottom">
                <Col>
                    <Label label="Version">{CoreUtils.toVersion(props.packHeader.version)}</Label>
                    <Label label="Updated">{CoreUtils.toUpdateTime(props.packHeader.updateTime)}</Label>
                </Col>
                <Col>
                    {type === "latest" &&
                        <div className="x-packitem-install">Installed</div>
                    }
                    {type === "alternate" &&
                        <div className="x-packitem-install">Alternate Catalog</div>
                    }
                    {type !== "latest" && type !== "alternate" && status === "installing" &&
                        <Spacer>
                            <Spin size="small" />
                            <span className="x-packitem-install">Installing...</span>
                        </Spacer>
                    }
                    {type !== "latest" && type !== "alternate" && status !== "installing" &&
                        <Button
                            icon={<PlayCircleOutlined />}
                            onClick={() => handleInstallClick()}
                        >
                            {type === "install" ? "Install" : "Update"}
                        </Button>
                    }
                </Col>
            </Row>
            <Row>
                {!!error &&
                    <div className="x-packitem-error">{error.message}</div>
                }
            </Row>
        </div>
    )
}
