import React, { PureComponent, ReactElement } from 'react';
import { Button, Col, Form, FormInstance, Row } from 'antd';
import { ConfigurationType } from '@methodset/endpoint-client-ts';
import { InputKey, InputLink } from '@methodset/model-client-ts';
import { Globals } from 'constants/Globals';
import { InputKeyEditor } from './InputKeyEditor/InputKeyEditor';
import { ArrowRightOutlined, DeleteOutlined } from '@ant-design/icons';
import { CoreUtils } from 'utils/CoreUtils';
import { Spacer } from 'components/Spacer/Spacer';
import { Justify } from 'components/Justify/Justify';
import { EditorHeader } from 'containers/Console/EditorHeader/EditorHeader';
import { WidgetUtils } from 'utils/WidgetUtils';
import { UsedMap } from '../InputLinks';
import { ItemMap } from 'containers/Console/Dashboards/DashboardItem/DashboardItem';
import update from 'immutability-helper';
import './InputLinkEditor.less';

const COLOR_MAP = CoreUtils.toColorMap(CoreUtils.enumToKeys(ConfigurationType));

export type CancelCallback = () => void;
export type DoneCallback = (inputLink: InputLink, index: number) => void;

export type InputLinkEditorProps = typeof InputLinkEditor.defaultProps & {
    index: number,
    //appletMap: AppletMap,
    itemMap: ItemMap,
    usedVariables: UsedMap;
    inputLink?: InputLink,
    onCancel: CancelCallback,
    onDone: DoneCallback
}

export type InputLinkEditorState = {
    inputLink: InputLink,
    usedVariables: UsedMap,
    error?: Error
}

export class InputLinkEditor extends PureComponent<InputLinkEditorProps, InputLinkEditorState> {

    static defaultProps = {
    }

    private formRef = React.createRef<FormInstance>();

    constructor(props: InputLinkEditorProps) {
        super(props);
        this.state = {
            inputLink: props.inputLink ? props.inputLink : {
                inputKey: {},
                linkedKeys: [{}]
            } as InputLink,
            usedVariables: {}
        }
        this.handleEditDone = this.handleEditDone.bind(this);
        this.handleEditError = this.handleEditError.bind(this);
        this.handleEditCancel = this.handleEditCancel.bind(this);
        this.handleFromLinkChange = this.handleFromLinkChange.bind(this);
        this.handleToLinkChange = this.handleToLinkChange.bind(this);
        this.handleKeyAdd = this.handleKeyAdd.bind(this);
        this.handleKeyRemove = this.handleKeyRemove.bind(this);
    }

    private handleKeyAdd(): void {
        const inputLink = update(this.state.inputLink, {
            linkedKeys: {
                $push: [{} as InputKey]
            }
        });
        this.setState({ inputLink: inputLink });
    }

    private handleKeyRemove(index: number): void {
        const linkedKey = this.state.inputLink.linkedKeys[index];
        const inputLink = update(this.state.inputLink, {
            linkedKeys: {
                $splice: [[index, 1]]
            }
        });
        if (linkedKey) {
            const key = WidgetUtils.toKey(linkedKey.appletId, 0, linkedKey.specKey);
            const usedVariables = update(this.state.usedVariables, {
                $unset: [key]
            });
            this.setState({
                inputLink: inputLink,
                usedVariables: usedVariables
            });
        } else {
            this.setState({ inputLink: inputLink });
        }
    }

    private handleEditDone(): void {
        const inputKey = this.state.inputLink.inputKey;
        if (!inputKey.appletId || !inputKey.specKey) {
            const error = new Error("The linked input source is not complete.");
            this.setState({ error: error });
            return;
        }
        const linkedKeys = this.state.inputLink.linkedKeys;
        for (const linkedKey of linkedKeys) {
            if (!linkedKey.appletId || !linkedKey.specKey) {
                const error = new Error("One or more of the linked input targets is not complete.");
                this.setState({ error: error });
                return;
            }
        }
        this.setState({ error: undefined });
        this.props.onDone(this.state.inputLink, this.props.index);
    }

    private handleEditError(e: any): void {
    }

    private handleEditCancel(): void {
        this.props.onCancel();
    }

    private handleFromLinkChange(inputKey: InputKey): void {
        const inputLink = update(this.state.inputLink, {
            inputKey: { $set: inputKey }
        });
        this.addUsedLink(inputLink);
        this.setState({
            inputLink: inputLink,
            error: undefined
        });
    }

    private handleToLinkChange(inputKey: InputKey, index: number): void {
        const inputLink = update(this.state.inputLink, {
            linkedKeys: {
                [index]: { $set: inputKey }
            }
        });
        this.addUsedLink(inputLink);
        this.setState({
            inputLink: inputLink,
            error: undefined
        });
    }

    private addUsedLink(inputLink: InputLink): void {
        const inputKey = inputLink.inputKey;
        let usedVariables = this.addUsedSpec(this.props.usedVariables, inputKey);
        const linkedKeys = inputLink.linkedKeys;
        for (const linkedKey of linkedKeys) {
            usedVariables = this.addUsedSpec(usedVariables, linkedKey);
        }
        this.setState({ usedVariables: usedVariables });
    }

    private addUsedSpec(usedVariables: UsedMap, inputKey: InputKey): UsedMap {
        if (inputKey.appletId && inputKey.specKey) {
            const key = WidgetUtils.toKey(inputKey.appletId, 0, inputKey.specKey);
            return update(usedVariables, {
                [key]: { $set: true }
            });
        } else {
            return usedVariables;
        }
    }

    public render(): ReactElement {
        return (
            <Form
                ref={this.formRef}
                onFinish={this.handleEditDone}
                onFinishFailed={this.handleEditError}
            >
                <EditorHeader
                    title="Link Editor"
                    onCancel={this.handleEditCancel}
                />
                <Row gutter={[Globals.APPLET_GUTTER_COL, Globals.APPLET_GUTTER_ROW]}>
                    <Col span={12}>
                        <Spacer fill={true}>
                            <InputKeyEditor
                                itemMap={this.props.itemMap}
                                usedVariables={this.state.usedVariables}
                                inputKey={this.state.inputLink.inputKey}
                                onChange={this.handleFromLinkChange}
                            />
                            <ArrowRightOutlined />
                        </Spacer>
                        {this.state.error &&
                            <div className="x-inputlinkeditor-error">{this.state.error.message}</div>
                        }
                    </Col>
                    <Col span={12}>
                        <Spacer size="lg" direction="vertical">
                            {this.state.inputLink.linkedKeys.map((linkedKey, index) => (
                                <Spacer key={index} alignment="top" fill={true}>
                                    <InputKeyEditor
                                        index={index}
                                        itemMap={this.props.itemMap}
                                        usedVariables={this.state.usedVariables}
                                        inputKey={linkedKey}
                                        onChange={this.handleToLinkChange}
                                    />
                                    {this.state.inputLink.linkedKeys.length > 1 &&
                                        <Button
                                            icon={<DeleteOutlined />}
                                            onClick={() => this.handleKeyRemove(index)}
                                        />
                                    }
                                </Spacer>
                            ))}
                            <Justify justification="right">
                                <Button onClick={this.handleKeyAdd}>
                                    Add Key
                                </Button>
                            </Justify>
                        </Spacer>
                    </Col>
                </Row>
            </Form>
        );
    }

}
