import { ReactElement, useState } from 'react';
import { CaretRightOutlined, LoadingOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tag } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { Globals } from 'constants/Globals';
import { Label } from 'components/Label/Label';
import { CoreUtils } from 'utils/CoreUtils';
import { Board, AppletInfo } from '@methodset/model-client-ts';
import { RestUtils } from 'utils/RestUtils';
import modelService from 'services/ModelService';
import './AppletItem.less';

type Status = "installing" | "error";

export type InstallCallback = (board: Board) => void;

export type AppletItemProps = {
    index: number,
    console?: Console,
    status?: Status,
    appletInfo: AppletInfo,
    board?: Board,
    onInstall: InstallCallback
}

export const AppletItem = (props: AppletItemProps): ReactElement => {

    const [status, setStatus] = useState<Status | undefined>(props.status);

    const handleClick = () => {
        createBoardRequest(props.appletInfo);
    }

    const createBoardRequest = (appletInfo: AppletInfo): Promise<any> => {
        setStatus("installing");
        const request = {
            appletId: appletInfo.id,
            modelId: appletInfo.modelId,
            version: appletInfo.version,
        };
        return modelService.createBoard(request,
            (response: any) => createBoardResponse(response),
            (response: any) => createBoardException(response),
            true
        );
    }

    const createBoardResponse = (response: any): void => {
        const board = response.data.board;
        setStatus(undefined);
        props.onInstall(board);
    }

    const createBoardException = (response: any): void => {
        const errorMessage = RestUtils.getErrorMessage(response);
        console.log(errorMessage);
        setStatus("error");
    }

    const hasLatestVersion = props.board && props.board.version === props.appletInfo.version;
    let i = props.index % Globals.TAG_COLORS.length;
    return (
        <div className="x-appletitem">
            <Row>
                <Spacer direction="vertical" size="sm" alignment="top">
                    <div className="x-appletitem-wrap">
                        <div className="x-appletitem-name">{props.appletInfo.name}</div>
                        <div className="x-appletitem-description">{props.appletInfo.description}</div>
                    </div>
                    <div className="x-appletitem-keywords">
                        {props.appletInfo.keywords && props.appletInfo.keywords.length > 0 && props.appletInfo.keywords.map((keyword: string, index: number) => (
                            <Tag key={index} color={Globals.TAG_COLORS[i++ % Globals.TAG_COLORS.length]}>{keyword}</Tag>
                        ))}
                    </div>
                </Spacer>
            </Row>
            <Row className="x-appletitem-details" justify="space-between" align="bottom">
                <Col>
                    <Label label="Category">{CoreUtils.toProper(props.appletInfo.category)}</Label>
                    <Label label="Version">{CoreUtils.toVersion(props.appletInfo.version)}</Label>
                    <Label label="Updated">{CoreUtils.toUpdateTime(props.appletInfo.updateTime)}</Label>
                </Col>
                <Col>
                    {hasLatestVersion &&
                        <div className="x-appletitem-install">Installed</div>
                    }
                    {!hasLatestVersion && status === "installing" &&
                        <Spacer>
                            <LoadingOutlined />
                            <span className="x-appletitem-install">Installing...</span>
                        </Spacer>
                    }
                    {!hasLatestVersion && status !== "installing" &&
                        <Button
                            icon={<PlayCircleOutlined />}
                            onClick={() => handleClick()}
                        >
                            Install
                        </Button>
                    }
                </Col>
            </Row>
        </div>
    )
}
