import { ReactElement, useEffect, useState } from 'react';
import { RestUtils } from 'utils/RestUtils';
import { StatusType } from 'constants/StatusType';
import { AlertState } from '@methodset/dashboard-client-ts';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { Button, Col, Empty, PageHeader, Row } from 'antd';
import { Globals } from 'constants/Globals';
import { Spacer } from 'components/Spacer/Spacer';
import { ApplicationHeader, ApplicationType } from '@methodset/model-client-ts';
import { AlertItem } from './AlertItem/AlertItem';
import { SyncOutlined } from '@ant-design/icons';
import modelService from 'services/ModelService';
import axios from 'axios';
import update from 'immutability-helper';
import './Alerts.less';
import { NoData } from 'components/NoData/NoData';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { Panel } from 'components/Panel/Panel';

export type AlertsProps = {
}

export const Alerts = (props: AlertsProps): ReactElement => {

    const [status, setStatus] = useState<StatusType>(StatusType.INIT);
    const [headers, setHeaders] = useState<ApplicationHeader[]>([]);
    const [alertStates, setAlertStates] = useState<AlertState[]>([]);

    useEffect(() => {
        if (status !== StatusType.READY) {
            loadData();
        }
    }, []);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const handleAlertsRefresh = (): void => {
        loadData();
    }

    const handleAlertsRead = (): void => {
        readApplicationHeadersRequest();
    }

    const readApplicationHeadersRequest = (): Promise<any> => {
        const request = {
            applicationType: ApplicationType.ALERT
        };
        return modelService.readApplicationHeaders(request,
            (response: any) => readApplicationHeadersResponse(response),
            undefined, true
        );
    }

    const readApplicationHeadersResponse = (response: any): void => {
        const headers = response.data.headers;
        setHeaders(headers);
        // TODO: dialog with alerts to select from
    }

    const readAlertStatesRequest = (): Promise<any> => {
        const request = {};
        return modelService.readAlertStates(request,
            (response: any) => readAlertStatesResponse(response),
            undefined, true
        );
    }

    const readAlertStatesResponse = (response: any): void => {
        const alertStates = response.data.alertStates;
        setAlertStates(alertStates);
    }

    const loadData = (): void => {
        const requests = [];
        requests.push(readAlertStatesRequest());
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    const buildAlertsView = (): ReactElement => {
        if (alertStates.length === 0) {
            return (
                <NoData text="No alerts have been set up." />
            )
        } else {
            return (
                <Row className="x-alerts-body" gutter={[Globals.FORM_GUTTER_COL, Globals.FORM_GUTTER_ROW]}>
                    {alertStates.map(alertState => {
                        return (
                            <Col span={12}>
                                <AlertItem
                                    alertState={alertState}
                                />
                            </Col>
                        )
                    })}
                </Row>
            )
        }
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <Row gutter={[Globals.APPLET_GUTTER_COL, Globals.APPLET_GUTTER_ROW]}>
                {[1, 2, 3, 4].map(index => (
                    <Col key={index} span={12}>
                        <Panel>
                            <LoadSkeleton
                                direction="vertical"
                                status={isLoading ? "loading" : "failed"}
                                onRetry={() => handleRetryLoad()}
                            >
                                <LoadSkeleton.Input length="medium" />
                                <LoadSkeleton.Input length="fill" />
                                <LoadSkeleton.Input length="short" />
                                <LoadSkeleton.Input length="short" />
                            </LoadSkeleton>
                        </Panel>
                    </Col>
                ))}
            </Row>
        )
    }

    let view;
    if (status === StatusType.LOADING) {
        view = buildLoadingView(true);
    } else if (status === StatusType.FAILED) {
        view = buildLoadingView(false);
    } else if (status === StatusType.READY) {
        view = buildAlertsView();
    }

    const extra = (
        <Spacer>
            <Button onClick={handleAlertsRead}>Add Alert</Button>
            <Button icon={<SyncOutlined />} onClick={handleAlertsRefresh}></Button>
        </Spacer>
    )

    return (
        <div className="x-alerts">
            <PageHeader
                className="x-alerts-header"
                title="Alerts"
                extra={extra}
                onBack={() => window.history.back()}
            />
            <div className="x-alerts-body">
                {view}
            </div>
        </div>
    );

}
