import { ReactElement } from 'react';
import { NumberInput, NumberInputProps } from 'components/NumberInput/NumberInput';
import { Alignment } from 'components/Spacer/Spacer';
import { Justification } from 'components/FilterInput/FilterInput';
import './IntegerInput.less';

export type IntegerInputProps = Omit<NumberInputProps, "tester" | "converter"> & {
    // True for number to be positive.
    natural?: boolean,
    alignment?: Alignment,
    justification?: Justification,
} & typeof defaultProps;

const defaultProps = {
    natural: false,
    alignment: "middle",
    justification: "left"
}

export const IntegerInput = (props: IntegerInputProps): ReactElement => {

    const tester = (value: string) => props.natural ? /^(0|[1-9][0-9]*)$/.test(value) : /^(-)?(0|[1-9][0-9]*)$/.test(value);
    const converter = (value: string) => parseInt(value);

    const { natural, ...rest } = props;

    return (
        <NumberInput
            {...rest}
            tester={tester}
            converter={converter}
        />
    );

}

IntegerInput.defaultProps = defaultProps;
