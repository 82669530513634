import React, { PureComponent, ReactElement } from 'react';
import { FormInstance } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { Configuration, Query, QueryDef } from '@methodset/endpoint-client-ts';
import { QueryPicker } from './QueryPicker/QueryPicker';
import { QueryVersionPicker } from './QueryVersionPicker/QueryVersionPicker';
import { ConfigurationEditor } from '../ConfigurationEditor';
import { QueryLoader } from './QueryLoader/QueryLoader';
import classNames from 'classnames';
import update from 'immutability-helper';
import './QuerySelector.less';

export type ChangeCallback = (queryDef: QueryDef) => void;

export type QuerySelectorState = {
    query?: Query,
}

export type QuerySelectorProps = typeof QuerySelector.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    className?: string,
    queryDef?: QueryDef,
    label?: string,
    description?: string,
    onChange: ChangeCallback
}

export class QuerySelector extends PureComponent<QuerySelectorProps, QuerySelectorState> {

    static defaultProps = {
        queryDef: {
            configuration: {}
        },
        label: "Dataset",
        description: "The dataset that contains the desired data."
    }

    constructor(props: QuerySelectorProps) {
        super(props);
        this.state = {};
        this.handleQueryLoaded = this.handleQueryLoaded.bind(this);
        this.handleQueryChange = this.handleQueryChange.bind(this);
        this.handleVersionChange = this.handleVersionChange.bind(this);
        this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
    }

    private handleQueryLoaded(query: Query): void {
        this.setState({ query: query });
    }

    private handleQueryChange(queryId: string): void {
        const queryDef = update(this.props.queryDef, {
            queryId: { $set: queryId },
            //version: { $set: Globals.SNAPSHOT_VERSION },
            configuration: { $set: {} }
        });
        this.setState({ query: undefined });
        this.props.onChange(queryDef);
    }

    private handleVersionChange(version: number): void {
        const queryDef = update(this.props.queryDef, {
            version: { $set: version },
            configuration: { $set: {} }
        });
        this.setState({ query: undefined });
        this.props.onChange(queryDef);
    }

    private handleConfigurationChange(configuration: Configuration): void {
        const queryDef = update(this.props.queryDef, {
            configuration: { $set: configuration }
        });
        this.props.onChange(queryDef);
    }

    public render(): ReactElement {
        return (
            <div className={classNames('x-queryselector', this.props.className)}>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label={this.props.label}
                    name="dataset"
                    info={this.props.description}
                    valuePropName="queryId"
                    justification="center"
                    rules={[{
                        required: true,
                        message: `Please select a dataset.`
                    }]}
                >
                    <QueryPicker
                        queryId={this.props.queryDef.queryId}
                        onChange={this.handleQueryChange}
                    />
                </FormItem>
                {this.props.queryDef.queryId &&
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Version"
                        name="version"
                        info="The dataset version."
                        valuePropName="version"
                        rules={[{
                            required: true,
                            message: `Please select a dataset version.`
                        }]}
                    >
                        <QueryVersionPicker
                            queryId={this.props.queryDef.queryId}
                            version={this.props.queryDef.version}
                            onChange={this.handleVersionChange}
                        />
                    </FormItem>
                }
                {/* {this.props.queryRef.id && !CoreUtils.isEmpty(this.props.queryRef.version) && */}
                <QueryLoader
                    formRef={this.props.formRef}
                    visible={!!this.state.query}
                    queryId={this.props.queryDef.queryId}
                    version={this.props.queryDef.version}
                    onLoad={this.handleQueryLoaded}
                />
                {/* } */}
                {this.state.query &&
                    <ConfigurationEditor
                        formRef={this.props.formRef}
                        configurationSpecs={this.state.query.configurationSpecs}
                        configuration={this.props.queryDef.configuration}
                        onChange={this.handleConfigurationChange}
                    />
                }
            </div>
        );
    }

}
