import { ReactElement } from 'react';
import { Divider } from 'antd';
import { DividerWidgetConfiguration } from '@methodset/model-client-ts';
import { CoreUtils } from 'utils/CoreUtils';
import classNames from 'classnames';
import './DividerWidgetViewer.less';

export type DividerWidgetViewerProps = {
    configuration: DividerWidgetConfiguration
};

export const DividerWidgetViewer = (props: DividerWidgetViewerProps): ReactElement => {

    return (
        <Divider
            className={classNames(
                "x-dividerwidgetviewer",
                { [`x-widgetviewer-color-${CoreUtils.toLower(props.configuration.color)}`]: !!props.configuration.color },
            )}
            orientation={props.configuration.justification as any}
            dashed={props.configuration.dashed}
        >
            {props.configuration.title}
        </Divider>
    );
}
