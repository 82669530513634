import { ReactElement, Ref, useRef, useState } from 'react';
import { Form, FormInstance, Modal } from 'antd';
import { CoreUtils } from 'utils/CoreUtils';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { VersionPicker } from '../VersionPicker/VersionPicker';
import { PackHeader, PackType } from '@methodset/library-client-ts';
import { RestUtils } from 'utils/RestUtils';
import libraryService from 'services/LibraryService';
import './VersionReverter.less';

export type RevertCallback = (header: PackHeader) => void;
export type CancelCallback = () => void;

export type VersionReverterProps = {
    id: string,
    type: PackType,
    version: number,
    onRevert: RevertCallback,
    onCancel: CancelCallback
}

export const VersionReverter = (props: VersionReverterProps): ReactElement => {

    const formRef: Ref<FormInstance> = useRef(null);
    const [revertVersion, setRevertVersion] = useState<number>();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>();

    const handleVersionChange = (version: number): void => {
        setRevertVersion(version);
    }

    const handleVersionRevert = (): void => {
        revertVersionRequest(props.id, revertVersion!);
    }

    const revertVersionRequest = (packId: string, version: number): Promise<any> => {
        setError(undefined);
        setIsProcessing(true);
        const request = {
            packId: packId,
            version: version
        };
        return libraryService.revertPack(request,
            (response: any) => revertVersionResponse(response),
            (response: any) => revertVersionException(response),
            true
        );
    }    

    const revertVersionResponse = (response: any): void => {
        const header = response.data.header;
        setError(undefined);
        setIsProcessing(false);
        props.onRevert(header);
    }

    const revertVersionException = (response: any): void => {
        const error = RestUtils.getError(response);
        setError(error);
        setIsProcessing(false);
    }

    return (
        <Modal
            className="x-versionreverter"
            centered
            title="Revert To Previous Version"
            width={Globals.DIALOG_WIDTH}
            visible={true}
            closable={false}
            okText="Revert"
            okButtonProps={{ loading: isProcessing }}
            cancelButtonProps={{disabled: isProcessing }}
            onOk={() => formRef.current?.submit()}
            onCancel={() => props.onCancel()}
        >
            <div>
                Please select a version that you would like to revert back to.
                A new version <span className="x-editversion-version">{CoreUtils.toVersion(props.version + 1)}</span> will
                be published that will become the latest version, but will be the same as the version you select. This allows
                you to undo any changes that were made since the publication of the selected version.
            </div>
            <Form className="x-versionreverter-form" ref={formRef} onFinish={() => handleVersionRevert()}>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={formRef}
                    name="version"
                    valuePropName="version"
                    rules={[{
                        required: true,
                        message: "Please select a version."
                    }]}
                >
                    <VersionPicker
                        id={props.id}
                        type={props.type}
                        version={revertVersion}
                        filter={(version: number, count: number): boolean => version !== 0 && version < count - 1}
                        onChange={(version) => handleVersionChange(version)}
                    />
                </FormItem>
            </Form>
            {error &&
                <div className="x-versionreverter-error">{error.message}</div>
            }
        </Modal>
    );
}
