import { MouseEvent, PureComponent, ReactElement } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { CaretRightOutlined, SyncOutlined } from '@ant-design/icons';
import { Alert, Button, message, Modal } from 'antd';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { Execution, ExecutionHistory, StatusType as ExecutionType, Workflow } from '@methodset/workflow-client-ts';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { ErrorView } from 'containers/Console/Flows/StateView/ErrorView/ErrorView';
import { StatusType } from 'constants/StatusType';
import axios from 'axios';
import update from 'immutability-helper';
import workflowService from 'services/WorkflowService';
import './Executions.less';

interface CachedPage {
    executions: Execution[],
    nextToken: string
}

type MatchParams = {
    workflowId: string
}

export type ExecutionsState = {
    status: StatusType,
    executions: Execution[],
    workflow: Workflow,
    isLoading: boolean,
    pagination: TablePaginationConfig,
    error?: string
}

export type ExecutionsProps = RouteComponentProps<MatchParams> & {
    className?: string
}

export class Executions extends PureComponent<ExecutionsProps, ExecutionsState> {

    private timer?: NodeJS.Timeout;
    private currentPage: number;
    private cachedPages: CachedPage[];
    private workflowId: string;

    constructor(props: ExecutionsProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            executions: [] as Execution[],
            workflow: {} as Workflow,
            isLoading: false,
            pagination: {
                current: 1,
                pageSize: 12,
                showSizeChanger: false
            } as TablePaginationConfig,
            error: undefined
        };
        this.timer = undefined;
        this.currentPage = this.state.pagination.current!;
        this.cachedPages = [];
        this.workflowId = props.match.params.workflowId;
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);
        this.handleExecuteClick = this.handleExecuteClick.bind(this);
        this.handleFailedClick = this.handleFailedClick.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleRefreshClick(): void {
        const pagination = update(this.state.pagination, {
            current: { $set: 1 }
        });
        this.readExecutionsRequest(pagination);
    }

    private handleTableChange(pagination: TablePaginationConfig): void {
        if (pagination.current! > this.currentPage + 1) {
            // Disable skipping pages forward.
            return;
        }
        const index = pagination.current! - 1;
        if (index < this.cachedPages.length) {
            this.setState({
                pagination: pagination,
                executions: this.cachedPages[index].executions
            });
        } else {
            this.readExecutionsRequest(pagination);
        }
    }

    private handleExecuteClick(e: MouseEvent<HTMLButtonElement>): void {
        const executionId = e.currentTarget.id;
        this.rerunExecutionRequest(executionId);
    }

    private handleFailedClick(e: MouseEvent<HTMLAnchorElement>): void {
        const executionId = e.currentTarget.id;
        this.readExecutionHistoryRequest(executionId);
    }

    private hasRunning(executions: Execution[]): boolean {
        return executions.findIndex(execution => execution.statusType === ExecutionType.RUNNING) !== -1;
    }

    private readExecutionHistoryRequest(executionId: string): Promise<any> {
        const request = {
            workflowId: this.workflowId,
            executionId: executionId
        };
        return workflowService.readExecutionHistory(request,
            (response: any) => this.readExecutionHistoryResponse(response),
            undefined, false
        );
    }

    private readExecutionHistoryResponse(response: any): void {
        const history: ExecutionHistory = response.data.executionHistory;
        if (!history.error) {
            return;
        }
        Modal.error({
            className: "x-execution-error",
            title: 'Execution Error',
            okText: 'Close',
            content: (
                <ErrorView 
                    className="x-execution-error-body" 
                    error={history.error} 
                />
            )
        });
    }

    private rerunExecutionRequest(executionId: string): Promise<any> {
        this.setState({ error: undefined });
        const request = {
            workflowId: this.workflowId,
            executionId: executionId
        };
        return workflowService.rerunExecution(request,
            (response: any) => this.rerunExecutionResponse(response),
            (response: any) => this.rerunExecutionException(response),
            true
        );
    }

    private rerunExecutionResponse(response: any): void {
        let extra;
        if (this.state.pagination.current === 1) {
            // If on first page, refresh to get the new execution 
            // since that is where it will appear (latest time).
            this.readExecutionsRequest(this.state.pagination, true);
            extra = '';
        } else {
            extra = ' Please check status on the first page.';
        }
        message.success(`Execution started!${extra}`, 4);
    }

    private rerunExecutionException(response: any): void {
        const error = `Start of execution failed. ${RestUtils.getErrorMessage(response)}`;
        this.setState({ error: error });
    }

    private readWorkflowRequest(): Promise<any> {
        this.setState({ error: undefined });
        const request = {
            workflowId: this.workflowId,
            version: 0
        };
        return workflowService.readWorkflow(request,
            (response: any) => this.readWorkflowResponse(response),
            undefined, true
        );
    }

    private readWorkflowResponse(response: any): void {
        const workflow = response.data.workflow;
        this.setState({ workflow: workflow });
    }

    private readExecutionsRequest(pagination: TablePaginationConfig, quiet: boolean = false): Promise<any> {
        if (!quiet) {
            this.setState({
                error: undefined,
                isLoading: true
            });
        } else {
            this.setState({ error: undefined });
        }
        const index = pagination.current! - 2;
        const nextToken = index < 0 ? undefined : this.cachedPages[index].nextToken;
        const request = {
            workflowId: this.workflowId,
            maxResults: pagination.pageSize,
            nextToken: nextToken
        };
        return workflowService.readExecutions(request,
            (response: any) => this.readExecutionsResponse(response, pagination),
            (response: any) => this.readExecutionsException(response),
            true
        );
    }

    private readExecutionsResponse(response: any, pagination: TablePaginationConfig): void {
        const data = response.data;
        const executions = data.executions;
        if (data.totalExecutions) {
            pagination = update(pagination, {
                total: { $set: data.totalExecutions }
            });
        }
        this.setState({
            executions: executions,
            pagination: pagination,
            isLoading: false
        });
        if (pagination.current === 1) {
            this.cachedPages = [];
        }
        this.currentPage = pagination.current!;
        this.cachedPages[pagination.current! - 1] = {
            executions: executions,
            nextToken: data.nextToken
        };
        if (this.hasRunning(executions)) {
            this.timer = setTimeout(() => this.readExecutionsRequest(pagination, true), 10000);
        } else if (this.timer) {
            clearTimeout(this.timer);
            this.timer = undefined;
        }
    }

    private readExecutionsException(response: any): void {
        this.setState({ isLoading: false });
    }

    private buildColumns(): ColumnsType<any> {
        const columns: ColumnsType<any> = [{
            key: 'id',
            title: 'Execution Id',
            dataIndex: 'name',
            render: (value: string, record: any) => {
                return (
                    <div>
                        {record.statusType !== ExecutionType.FAILED &&
                            <span>{value}</span>
                        }
                        {record.statusType === ExecutionType.FAILED &&
                            <Link
                                id={record.executionId}
                                to="#"
                                onClick={this.handleFailedClick}
                            >
                                {value}
                            </Link>
                        }
                    </div>
                );
            }
        }, {
            key: 'status',
            title: 'Status',
            align: 'center',
            dataIndex: 'statusType',
            render: (value: ExecutionType, record: any) => {
                return (
                    <span>
                        {value === ExecutionType.RUNNING &&
                            <SyncOutlined className="x-executions-spin" spin />
                        }
                        <span
                            className={`x-executions-status-${value.toLowerCase()}`}
                        >
                            {CoreUtils.toCapital(value)}
                        </span>
                    </span>
                );
            }
        }, {
            key: 'start',
            title: 'Start Time',
            align: 'center',
            dataIndex: 'startTime',
            render: (value: number, record: any) => {
                return (
                    <span>{CoreUtils.toTime(value)}</span>
                );
            }
        }, {
            key: 'end',
            title: 'End Time',
            align: 'center',
            dataIndex: 'endTime',
            render: (value: number, record: any) => {
                return (
                    <span>{CoreUtils.toTime(value)}</span>
                );
            }
        }, {
            key: 'retry',
            title: 'Retry',
            align: 'center',
            //dataIndex: 'endTime',
            render: (value: number, record: any) => {
                return (
                    <Button
                        id={record.executionId}
                        disabled={record.statusType !== StatusType.FAILED}
                        icon={<CaretRightOutlined />}
                        onClick={this.handleExecuteClick}
                    >
                        Run
                    </Button>
                );
            }
        }];
        return columns;
    }

    private buildData(): Execution[] {
        return this.state.executions;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readWorkflowRequest());
        requests.push(this.readExecutionsRequest(this.state.pagination));
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const columns = this.buildColumns();
        const data = this.buildData();
        return (
            <div>
                {this.state.error &&
                    <Alert
                        type="error"
                        banner
                        closable
                        showIcon
                        message="Error"
                        description={this.state.error}
                    />
                }
                <ItemTable
                    title="Executions"
                    subtitle={this.state.workflow.name}
                    columns={columns}
                    items={data}
                    extra={
                        <Button
                            icon={<SyncOutlined />}
                            onClick={this.handleRefreshClick}
                        />
                    }
                    loading={this.state.isLoading}
                    status={this.state.status}
                    pagination={this.state.pagination}
                    rowKey="executionId"
                    onChange={this.handleTableChange}
                    onLoad={this.handleRetryLoad}
                />

            </div>
        );
    }

}
