import { ReactElement } from 'react';
import { Globals } from 'constants/Globals';
import { DatePicker, FormInstance, Input, Radio } from 'antd';
import { GenderType, Person, PersonType } from '@methodset/entity-client-ts';
import { Date } from '@methodset/commons-shared-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { Constants } from 'components/Constants';
import { CoreUtils } from 'utils/CoreUtils';
import { DateSelector } from 'components/DateSelector/DateSelector';
import { Spacer } from 'components/Spacer/Spacer';
import moment from 'moment';
import update from 'immutability-helper';
import './PersonEditor.less';

export type ChangeCallback = (person: Person, index: number) => void;

export type PersonEditorProps = {
    formRef: React.RefObject<FormInstance>,
    id: string,
    index?: number,
    type: PersonType,
    person?: Person,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    index: 0,
    person: {} as Person
}

export const PersonEditor = (props: PersonEditorProps): ReactElement => {

    const handleFirstNameChange = (firstName: string): void => {
        const person = update(props.person, {
            type: { $set: props.type },
            firstName: { $set: firstName }
        });
        props.onChange(person, props.index);
    }

    const handleLastNameChange = (lastName: string): void => {
        const person = update(props.person, {
            type: { $set: props.type },
            lastName: { $set: lastName }
        });
        props.onChange(person, props.index);
    }

    const handleDateOfBirthChange = (date: Date | undefined): void => {
        const dateOfBirth = date ? date.toIso() : undefined;
        const person = update(props.person, {
            type: { $set: props.type },
            dateOfBirth: { $set: dateOfBirth as any }
        });
        props.onChange(person, props.index);
    }

    const handleGenderChange = (gender: GenderType): void => {
        const person = update(props.person, {
            type: { $set: props.type },
            gender: { $set: gender }
        });
        props.onChange(person, props.index);
    }

    return (
        <>
            <Spacer fill>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={props.formRef}
                    label="First Name"
                    name={`person-first-${props.id}${props.index}`}
                    rules={[{
                        required: true,
                        message: 'Please enter a first name.'
                    }]}
                >
                    <Input
                        placeholder="First name."
                        value={props.person.firstName}
                        onChange={(e) => handleFirstNameChange(e.target.value)}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={props.formRef}
                    label="Last Name"
                    name={`person-last-${props.id}${props.index}`}
                    rules={[{
                        required: true,
                        message: 'Please enter a last name.'
                    }]}
                >
                    <Input
                        placeholder="Last name."
                        value={props.person.lastName}
                        onChange={(e) => handleLastNameChange(e.target.value)}
                    />
                </FormItem>
            </Spacer>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Date of Birth"
                name={`person-dob-${props.id}${props.index}`}
                rules={[{
                    required: true,
                    message: 'Please enter a date of birth.'
                }]}
            >
                <DateSelector
                    value={props.person.dateOfBirth ? Date.fromIso(props.person.dateOfBirth) : undefined}
                    onChange={(date) => handleDateOfBirthChange(date)}
                />
            </FormItem>
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Gender"
                name={`person-gender-${props.id}${props.index}`}
            >
                <Radio.Group
                    value={props.person.gender}
                    onChange={(e) => handleGenderChange(e.target.value)}
                >
                    {CoreUtils.enumToKeys(GenderType).map(key => (
                        <Radio key={key} value={key}>{CoreUtils.toProper(key.toString())}</Radio>
                    ))}
                </Radio.Group>
            </FormItem>
        </>
    )
}

PersonEditor.defaultProps = defaultProps;
