import { ReactElement } from 'react';
import { AppletStore } from './AppletStore/AppletStore';
import { RouteComponentProps } from 'react-router-dom';
import './Store.less';

export type StoreProps = RouteComponentProps;

export const Store = (props: StoreProps): ReactElement => {

    return (
        <AppletStore {...props} />
    )

}
