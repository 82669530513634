import { ReactElement, Ref, useRef, useState } from 'react';
import { Form, FormInstance, Modal } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { PackType } from '@methodset/library-client-ts';
import { VersionPicker } from '../VersionPicker/VersionPicker';
import './VersionActivator.less';

export type ActivateCallback = (version: number) => void;
export type CancelCallback = () => void;

export type VersionActivatorProps = {
    id: string,
    type: PackType,
    version: number,
    error?: Error,
    isProcessing: boolean,
    onActivate: ActivateCallback,
    onCancel: CancelCallback
}

export const VersionActivator = (props: VersionActivatorProps): ReactElement => {

    const formRef: Ref<FormInstance> = useRef(null);
    const [version, setVersion] = useState<number>();

    const handleVersionChange = (version: number): void => {
        setVersion(version);
    }

    return (
        <Modal
            className="x-versionactivator"
            centered
            title="Activate Version"
            width={Globals.DIALOG_WIDTH}
            visible={true}
            okText="Activate"
            okButtonProps={{ loading: props.isProcessing }}
            onOk={() => formRef.current?.submit()}
            onCancel={() => props.onCancel()}
        >
            <div>
                Please select a version that you would like to use as the current running version.
            </div>
            <Form className="x-versionactivator-form" ref={formRef} onFinish={() => props.onActivate(version!)}>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={formRef}
                    name="version"
                    valuePropName="version"
                    rules={[{
                        required: true,
                        message: "Please select a version."
                    }]}
                >
                    <VersionPicker
                        id={props.id}
                        type={props.type}
                        version={version}
                        onChange={(version) => handleVersionChange(version)}
                    />
                </FormItem>
            </Form>
            {props.error &&
                <div className="x-versionactivator-error">{props.error.message}</div>
            }
        </Modal>
    );
}
