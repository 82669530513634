import { ReactElement } from 'react';
import './Splash.less';

export type SplashProps = {}

export const Splash = (props: SplashProps): ReactElement => {
    return (
        <div className="x-splash">
            SPLASH PAGE
        </div>
    )
}
