import { ReactElement } from 'react';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { Info } from 'components/Info/Info';
import { Alignment, Justification, Spacer } from 'components/Spacer/Spacer';
import classNames from 'classnames';
import './Label.less';

export type Placement = "top" | "left";
export type ExtraLocation = "right" | "under";

export type LabelProps = {
    id?: string,
    className?: string,
    children?: any,
    label: string | ReactElement,
    extra?: string | ReactElement,
    extraLocation?: ExtraLocation,
    alignment?: Alignment,
    justification?: Justification,
    info?: string,
    placement?: Placement,
    location?: TooltipPlacement,
    bold?: boolean,
    colon?: boolean,
    nowrap?: boolean,
    required?: boolean,
    fill?: boolean
} & typeof defaultProps;

const defaultProps = {
    placement: "left",
    location: "topRight",
    extraLocation: "right",
    bold: true,
    colon: true,
    nowrap: true,
    required: false
}

export const Label = (props: LabelProps): ReactElement => {

    const size = props.placement === "left" ? "sm" : "xs";
    const alignment = props.alignment ?? (props.placement === "left" ? "middle" : "top");
    const justification = props.justification ?? (props.placement === "left" ? "left" : "center");
    const direction = props.placement === "top" ? "vertical" : "horizontal";

    return (
        <Spacer
            id={props.id}
            fill={props.fill}
            size={size}
            alignment={alignment}
            justification={justification}
            direction={direction}
            className={classNames("x-label", props.className)}
        >
            <div className={
                classNames("x-label-info", { "x-label-extra-right": !!props.extra && props.extraLocation === "right" })}
            >
                <Info
                    label={props.label}
                    info={props.info}
                    location={props.location}
                    bold={props.bold}
                    colon={props.label !== "" && props.placement === "left" && props.colon}
                    nowrap={props.nowrap}
                    required={props.required}
                />
                <div>
                    {!!props.extra &&
                        <div className="x-label-extra">{props.extra}</div>
                    }
                </div>
            </div>
            {props.children &&
                <>
                    {props.children}
                </>
            }
        </Spacer>
    )
}

Label.defaultProps = defaultProps;
