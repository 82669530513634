import React, { PureComponent, ReactElement } from 'react';
import { Col, FormInstance, Row, Switch } from 'antd';
import { Calculator, RefType } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { TableWidgetConfiguration, WidgetType } from '@methodset/model-client-ts';
import { RefEditor } from 'containers/Components/Widgets/RefEditor/RefEditor';
import update from 'immutability-helper';
import './TableWidgetEditor.less';

export type ChangeCallback = (configuration: TableWidgetConfiguration) => void;

export type TableWidgetEditorProps = typeof TableWidgetEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    defaults: ReactElement,
    configuration?: TableWidgetConfiguration,
    calculator: Calculator,
    onChange: ChangeCallback
}

export class TableWidgetEditor extends PureComponent<TableWidgetEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.TABLE
    } as TableWidgetConfiguration;

    static defaultProps = {
        configuration: TableWidgetEditor.DefaultConfiguration
    }

    constructor(props: TableWidgetEditorProps) {
        super(props);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.handleHeadersToggle = this.handleHeadersToggle.bind(this);
    }

    private handleRangeChange(rangeId: string | undefined): void {
        const configuration = update(this.props.configuration, {
            rangeId: { $set: rangeId as any }
        });
        this.props.onChange(configuration);
    }

    private handleHeadersToggle(hasHeaders: boolean): void {
        const configuration = update(this.props.configuration, {
            hasHeaders: { $set: hasHeaders }
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        if (this.props.configuration === TableWidgetEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        return (
            <Row gutter={Globals.FORM_GUTTER_ROW}>
                <Col span={12}>
                    {this.props.defaults}
                </Col>
                <Col span={12}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        required={true}
                        //noError={true}
                        label="Data Range"
                        name="range"
                        info="The cell range to include in the table."
                    >
                        <RefEditor
                            formRef={this.props.formRef}
                            //required={true}
                            index={0}
                            calculator={this.props.calculator}
                            refTypes={[RefType.RANGE]}
                            refId={this.props.configuration.rangeId}
                            onChange={this.handleRangeChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Includes Header Row"
                        name="header"
                        info="Enable if the first row in the data range contains column headers."
                        valuePropName="checked"
                    >
                        <Switch
                            checked={this.props.configuration.hasHeaders}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            onChange={this.handleHeadersToggle}
                        />
                    </FormItem>
                </Col>
            </Row>
        );
    }

}
