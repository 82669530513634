import ReactGA from 'react-ga';
import { PureComponent } from 'react';
import { Location } from 'history';
import { Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Console } from 'containers/Console/Console';
import { Main } from 'containers/Main/Main';
import { PageNotFound } from 'containers/Components/PageNotFound/PageNotFound';
import { RouteBuilder } from 'utils/RouteBuilder';
import { ModelProvider } from 'context/ModelContext';
import { EntityProvider } from 'context/EntityContext';
import { Globals } from 'constants/Globals';
import { Application } from 'containers/Application/Application';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import './App.less';

// Setup Google Analytics tracking and log initial page.
ReactGA.initialize(Globals.GOOGLE_ANALYTICS_TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

// Track specific pages when the URL changes.
const history = createBrowserHistory();
history.listen((location: Location<any>) => {
    const path = location.pathname;
    if (RouteBuilder.isPageTracked(path)) {
        ReactGA.pageview(path);
    }
});

export class App extends PureComponent {

    render() {
        return (
            <EntityProvider>
                <Router history={history}>
                    <ScrollToTop>
                        <Switch>
                            <Route exact path={RouteBuilder.HOME} component={Main} />
                            <Route path={RouteBuilder.MAIN} component={Main} />
                            <Route path={RouteBuilder.CONSOLE} component={Console} />
                            <Route path={RouteBuilder.APPLICATION} component={Application} />
                            <Route component={PageNotFound} />
                        </Switch>
                    </ScrollToTop>
                </Router>
            </EntityProvider>
        )
    }

}
