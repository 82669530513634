import { ReactElement, useContext, useEffect, useState } from 'react';
import { FormInstance, Input, Select, Timeline } from 'antd';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { LoadSkeleton } from 'components/LoadSkeleton/LoadSkeleton';
import { PackHeader } from '@methodset/library-client-ts';
import { StatusType } from 'constants/StatusType';
import { CoreUtils } from 'utils/CoreUtils';
import { AccessType } from '@methodset/commons-shared-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { Alert } from 'components/Alert/Alert';
import { EntityContext } from 'context/EntityContext';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Spacer } from 'components/Spacer/Spacer';
import { PermissionsHelper } from '@methodset/entity-client-ts';
import libraryService from 'services/LibraryService';
import axios from 'axios';
import './PackPublisher.less';

const colors: Map<AccessType, string> = new Map([
    [AccessType.GROUP, "blue"],
    [AccessType.ORGANIZATION, "green"],
    [AccessType.PUBLIC, "purple"]
]);

export type ChangeCallback = (type: AccessType) => void;

export type PackPublisherProps = {
    formRef: React.RefObject<FormInstance>,
    packHeader: PackHeader,
    onChange: ChangeCallback
}

export const PackPublisher = (props: PackPublisherProps): ReactElement => {

    // Status of loading data.
    const [status, setStatus] = useState<string>(StatusType.INIT);
    // The pack headers for each environment for the pack id.
    const [packHeaders, setPackHeaders] = useState<PackHeader[]>([]);
    // The access type to release to.
    const [accessType, setAccessType] = useState<AccessType | undefined>();

    const context = useContext(EntityContext);

    useEffect(() => {
        if (status !== StatusType.READY) {
            loadData();
        }
    }, []);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const handleToChange = (accessType: AccessType): void => {
        setAccessType(accessType);
        props.onChange(accessType);
    }

    const readPackPublicationsRequest = (): Promise<any> => {
        const request = {
            packId: props.packHeader.id,
            asAccess: true
        }
        return libraryService.readPackPublications(request,
            (response: any) => readPackPublicationsResponse(response),
            undefined, true
        );
    }

    const readPackPublicationsResponse = (response: any): void => {
        const headers = response.data.headers;
        setPackHeaders(headers);
    }

    const findAccessVersion = (type: AccessType | undefined): number | undefined => {
        if (!type) {
            return undefined;
        }
        const header = packHeaders.find(header => header.accessType === type);
        return !!header ? header.version : undefined;
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <LoadSkeleton
                count={3}
                status={isLoading ? "loading" : "failed"}
                onRetry={() => handleRetryLoad()}
            >
                <LoadSkeleton.Checkbox />
                <LoadSkeleton.Input length="fill" />
            </LoadSkeleton>
        );
    }

    const isValid = (): boolean => {
        const sourceVersion = findAccessVersion(AccessType.GROUP);
        const targetVersion = findAccessVersion(accessType);
        if (CoreUtils.isEmpty(sourceVersion) || CoreUtils.isEmpty(targetVersion)) {
            return true;
        }
        return sourceVersion! > targetVersion!;
    }

    const buildPublishView = (): ReactElement => {
        const isInvalid = !isValid();
        const environmentType = context.user?.environmentType;
        const sourceLevel = AccessType.level(props.packHeader.accessType);
        const perms = PermissionsHelper.to(context.permissions);
        const accessTypes = AccessType.array().filter(type => perms.get("access", type));
        return (
            <div>
                <Timeline className="x-packpublisher-timeline">
                    {accessTypes.map(type => (
                        <Timeline.Item
                            key={type}
                            color={colors.get(type)}
                        >
                            <span className="x-packpublisher-env">{CoreUtils.toProper(type)}</span> <span className="x-packpublisher-version">({CoreUtils.toVersion(findAccessVersion(type), "publish")})</span>
                        </Timeline.Item>
                    ))}
                </Timeline>
                <Spacer fill>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={props.formRef}
                        className="x-packpublisher-drop"
                        label="Source"
                        name="source"
                        info="The source access where the package will be taken from."
                        rules={[{
                            required: true,
                            message: "Enter a source."
                        }]}
                    >
                        <Input value={CoreUtils.toProper(props.packHeader.accessType)} onFocus={(e) => { e.target.blur() }} />
                    </FormItem>
                    <ArrowRightOutlined className="x-packpublisher-arrow" />
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={props.formRef}
                        className="x-packpublisher-drop"
                        label="Target"
                        name="target"
                        info="The target access where the package will be published to."
                        rules={[{
                            required: true,
                            message: "Enter a target."
                        }]}
                    >
                        <Select
                            className="x-packpublisher-select"
                            value={accessType}
                            onChange={handleToChange}
                        >
                            {accessTypes.filter(type => AccessType.level(type) > sourceLevel).map(type => (
                                <Select.Option key={type} value={type}>{CoreUtils.toProper(type)}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                </Spacer>
                {accessType &&
                    <>
                        {isInvalid &&
                            <Alert type="warn">
                                {CoreUtils.toProper(accessType)} version {findAccessVersion(AccessType.GROUP)} as already been published.
                            </Alert>
                        }
                        {!isInvalid &&
                            <Alert type="info">
                                Ready to publish {CoreUtils.toLower(environmentType)} version {findAccessVersion(AccessType.GROUP)} to
                                the {CoreUtils.toLower(accessType)}.
                            </Alert>
                        }
                    </>
                }
            </div>
        )
    }

    const loadData = (): void => {
        const requests = [];
        requests.push(readPackPublicationsRequest());
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((r1, r2) => {
            if (RestUtils.isOk(r1, r2)) {
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    let view;
    if (status === StatusType.LOADING) {
        view = buildLoadingView(true);
    } else if (status === StatusType.FAILED) {
        view = buildLoadingView(false);
    } else if (status === StatusType.READY) {
        view = buildPublishView();
    }
    return (
        <div className="x-packpublisher">
            {view}
        </div>
    );

}
