import { ReactElement } from 'react';
import { Select } from 'antd';
import { JustificationType } from '@methodset/model-client-ts';

export type ChangeCallback = (value: JustificationType) => void;

//export type Justification = "left" | "center" | "right";

export type JustificationSelectorProps = {
    value?: JustificationType,
    //defaultValue?: JustificationType,
    onChange: ChangeCallback
};

export const JustificationSelector = (props: JustificationSelectorProps): ReactElement => {
    return (
        <Select
            allowClear={true}
            placeholder="Select a justification."
            value={props.value}
            //value={props.value ? props.value.toUpperCase() as JustificationType : undefined}
            //defaultValue={props.defaultValue ? props.defaultValue : JustificationType.LEFT}
            onChange={(value) => props.onChange(value)}
        >
            <Select.Option key="left" value={JustificationType.LEFT}>Left</Select.Option>
            <Select.Option key="center" value={JustificationType.CENTER}>Center</Select.Option>
            <Select.Option key="right" value={JustificationType.RIGHT}>Right</Select.Option>
        </Select>
    )
}
