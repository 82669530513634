import React, { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Form, FormInstance, Input, Modal, Select } from 'antd';
import { Calculator, RefType } from '@methodset/calculator-ts';
import { CartesianWidgetConfiguration, ChangeListWidgetConfiguration, TableWidgetConfiguration, TextWidgetConfiguration, PolarWidgetConfiguration, ValueWidgetConfiguration, WatchlistWidgetConfiguration, Widget, WidgetConfiguration, WidgetType, DividerWidgetConfiguration, SelectWidgetConfiguration, SwitchWidgetConfiguration, InputWidgetConfiguration, ListWidgetConfiguration, RowWidgetConfiguration, ColumnWidgetConfiguration, Applet, Margin, Width, PanelWidgetConfiguration, InputFlowWidgetConfiguration, InputPanelWidgetConfiguration, AppletPanel, CandleWidgetConfiguration, FilterWidgetConfiguration } from '@methodset/model-client-ts';
import { TableWidgetEditor } from 'containers/Components/Widgets/TableWidgetViewer/TableWidgetEditor/TableWidgetEditor';
import { TextWidgetEditor } from 'containers/Components/Widgets/TextWidgetViewer/TextWidgetEditor/TextWidgetEditor';
import { ValueWidgetEditor } from 'containers/Components/Widgets/ValueWidgetViewer/ValueWidgetEditor/ValueWidgetEditor';
import { CartesianEditor } from 'containers/Components/Widgets/ChartWidgetViewer/CartesianEditor/CartesianEditor';
import { PolarEditor } from 'containers/Components/Widgets/ChartWidgetViewer/PolarEditor/PolarEditor';
import { WatchlistWidgetEditor } from 'containers/Components/Widgets/WatchlistWidgetViewer/WatchlistWidgetEditor/WatchlistWidgetEditor';
import { ChangeListWidgetEditor } from 'containers/Components/Widgets/ChangeListWidgetViewer/ChangeListWidgetEditor/ChangeListWidgetEditor';
import { DividerWidgetEditor } from 'containers/Components/Widgets/DividerWidgetViewer/DividerWidgetEditor/DividerWidgetEditor';
import { FormItem } from 'components/FormItem/FormItem';
import { CoreUtils } from 'utils/CoreUtils';
import { Globals } from 'constants/Globals';
import { SelectWidgetEditor } from 'containers/Components/Widgets/SelectWidgetViewer/SelectWidgetEditor/SelectWidgetEditor';
import { SwitchWidgetEditor } from 'containers/Components/Widgets/SwitchWidgetViewer/SwitchWidgetEditor/SwitchWidgetEditor';
import { InputWidgetEditor } from '../InputWidgetViewer/InputWidgetEditor/InputWidgetEditor';
import { ListWidgetEditor } from '../ListWidgetViewer/ListWidgetEditor/ListWidgetEditor';
import { RowWidgetEditor } from '../RowWidgetViewer/RowWidgetEditor/RowWidgetEditor';
import { ColumnWidgetEditor } from '../ColumnWidgetViewer/ColumnWidgetEditor/ColumnWidgetEditor';
import { MarginEditor } from './MarginEditor/MarginEditor';
import { WidthEditor } from './WidthEditor/WidthEditor';
import { PanelWidgetEditor } from '../PanelWidgetViewer/PanelWidgetEditor/PanelWidgetEditor';
import { InputFlowWidgetEditor } from '../InputFlowWidgetViewer/InputFlowWidgetEditor/InputFlowWidgetEditor';
import { RefEditor } from '../RefEditor/RefEditor';
import { InputPanelWidgetEditor } from '../InputPanelWidgetViewer/InputPanelWidgetEditor/InputPanelWidgetEditor';
import { HeightEditor } from './HeightEditor/HeightEditor';
import { CandleEditor } from '../ChartWidgetViewer/CandleEditor/CandleEditor';
import { FilterWidgetEditor } from '../FilterWidgetViewer/FilterWidgetEditor/FilterWidgetEditor';
import { v4 as uuid } from "uuid";
import update from 'immutability-helper';
import './WidgetEditor.less';

export type ChangeCallback = (widget: Widget) => void;
export type CancelCallback = () => void;

export type WidgetEditorProps = typeof WidgetEditor.defaultProps & {
    applet: Applet,
    panel: AppletPanel,
    widget?: Widget,
    calculator: Calculator,
    onChange: ChangeCallback,
    onCancel: CancelCallback
}

export type WidgetEditorState = {
    type: WidgetType,
    widget: Widget,
    isEditing: boolean
}

export class WidgetEditor extends PureComponent<WidgetEditorProps, WidgetEditorState> {

    static defaultProps = {
    }

    private formRef = React.createRef<FormInstance>();
    private width: number = Globals.DIALOG_WIDTH;

    constructor(props: WidgetEditorProps) {
        super(props);
        this.state = {
            type: props.widget ? props.widget.configuration.type : WidgetType.ROW,
            widget: props.widget ? props.widget : this.defaultWidget(WidgetType.ROW),
            isEditing: false
        };
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleMarginChange = this.handleMarginChange.bind(this);
        this.handleWidthChange = this.handleWidthChange.bind(this);
        this.handleHeightChange = this.handleHeightChange.bind(this);
        this.handleHideChange = this.handleHideChange.bind(this);
        this.handleConfigurationEdit = this.handleConfigurationEdit.bind(this);
        this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
        this.handleEditOk = this.handleEditOk.bind(this);
        this.handleEditSave = this.handleEditSave.bind(this);
        this.handleEditCancel = this.handleEditCancel.bind(this);
    }

    private defaultWidget(type: WidgetType): Widget {
        // Configuration is left empty so that the default can be
        // set by the concrete widget editor.
        return {
            id: uuid(),
            name: this.buildDefaultName(type),
            col: 0,
            row: 0,
            span: Globals.LAYOUT_COLUMNS
        } as Widget;
    }

    private handleTypeChange(type: WidgetType): void {
        const widget = update(this.state.widget, {
            name: { $set: this.buildDefaultName(type) },
            configuration: { $set: undefined as any }
        });
        this.setState({
            type: type,
            widget: widget
        });
    }

    private handleNameChange(e: ChangeEvent<HTMLInputElement>): void {
        const name = e.target.value;
        const widget = update(this.state.widget, {
            name: { $set: name }
        });
        this.setState({ widget: widget });
    }

    private handleMarginChange(margin: Margin): void {
        const widget = update(this.state.widget, {
            configuration: {
                margin: { $set: margin }
            }
        });
        this.setState({ widget: widget });
    }

    private handleWidthChange(width: Width | undefined): void {
        const widget = update(this.state.widget, {
            configuration: {
                width: { $set: width }
            }
        });
        this.setState({ widget: widget });
    }

    private handleHeightChange(height: number | undefined): void {
        const widget = update(this.state.widget, {
            configuration: {
                height: { $set: height }
            }
        });
        this.setState({ widget: widget });
    }

    private handleHideChange(hideId: string | undefined): void {
        const widget = update(this.state.widget, {
            configuration: {
                hideId: { $set: hideId }
            }
        });
        this.setState({ widget: widget });
    }

    private handleConfigurationEdit(isEditing: boolean): void {
        this.setState({ isEditing: isEditing });
    }

    private handleConfigurationChange(configuration: WidgetConfiguration, isEditing?: boolean): void {
        const widget = update(this.state.widget, {
            configuration: { $set: configuration }
        });
        if (CoreUtils.isBoolean(isEditing)) {
            this.setState({
                widget: widget,
                isEditing: isEditing
            });
        } else {
            this.setState({ widget: widget });
        }
    }

    private handleEditOk(): void {
        // Validate the form fields.
        this.formRef.current?.submit();
    }

    private handleEditSave(): void {
        this.props.onChange(this.state.widget)
    }

    private handleEditCancel(): void {
        this.props.onCancel();
    }

    private buildDefaultName(type: WidgetType): string {
        const names: { [key: string]: boolean } = {};
        const base = CoreUtils.toProper(type.toString(), "_", " ");
        for (const widget of this.props.panel.widgets) {
            if (widget.configuration.type === type) {
                names[widget.name] = true;
            }
        }
        let i = 1;
        while (true) {
            const name = `${base} ${i}`;
            if (!names[name]) {
                return name;
            }
            i++;
        }
    }

    private buildEditorView(): ReactElement {
        const type = this.state.type;
        const configuration = this.state.widget!.configuration;
        const calculator = this.props.calculator!;
        switch (type) {
            case WidgetType.AREA_CHART:
            case WidgetType.BAR_CHART:
            case WidgetType.COLUMN_CHART:
            case WidgetType.LINE_CHART:
                this.width = Globals.DIALOG_WIDTH_STACKED * 3;
                return <CartesianEditor
                    formRef={this.formRef}
                    type={type}
                    defaultConfigurations={this.buildDefaultConfigurations()}
                    calculator={calculator}
                    configuration={configuration as CartesianWidgetConfiguration}
                    onEdit={this.handleConfigurationEdit}
                    onChange={this.handleConfigurationChange}
                />
            case WidgetType.PIE_CHART:
            case WidgetType.DONUT_CHART:
                this.width = Globals.DIALOG_WIDTH_STACKED * 2;
                return <PolarEditor
                    formRef={this.formRef}
                    type={type}
                    defaultConfigurations={this.buildDefaultConfigurations()}
                    calculator={calculator}
                    configuration={configuration as PolarWidgetConfiguration}
                    onChange={this.handleConfigurationChange}
                />
            case WidgetType.CANDLESTICK_CHART:
                this.width = Globals.DIALOG_WIDTH_STACKED * 2;
                return <CandleEditor
                    formRef={this.formRef}
                    type={type}
                    defaultConfigurations={this.buildDefaultConfigurations()}
                    calculator={calculator}
                    configuration={configuration as CandleWidgetConfiguration}
                    onChange={this.handleConfigurationChange}
                />
            case WidgetType.TABLE:
                this.width = Globals.DIALOG_WIDTH_STACKED * 2;
                return <TableWidgetEditor
                    formRef={this.formRef}
                    defaults={this.buildDefaultConfigurations()}
                    calculator={calculator}
                    configuration={configuration as TableWidgetConfiguration}
                    onChange={this.handleConfigurationChange}
                />
            case WidgetType.TEXT:
                this.width = Globals.DIALOG_WIDTH_STACKED * 2;
                return <TextWidgetEditor
                    formRef={this.formRef}
                    defaults={this.buildDefaultConfigurations()}
                    calculator={calculator}
                    configuration={this.state.widget!.configuration as TextWidgetConfiguration}
                    onChange={this.handleConfigurationChange}
                />
            case WidgetType.VALUE:
                this.width = Globals.DIALOG_WIDTH_STACKED * 2;
                return <ValueWidgetEditor
                    formRef={this.formRef}
                    defaults={this.buildDefaultConfigurations()}
                    calculator={calculator}
                    configuration={configuration as ValueWidgetConfiguration}
                    onChange={this.handleConfigurationChange}
                />
            case WidgetType.LIST:
                this.width = Globals.DIALOG_WIDTH_STACKED * 3;
                return <ListWidgetEditor
                    formRef={this.formRef}
                    defaults={this.buildDefaultConfigurations()}
                    calculator={calculator}
                    configuration={configuration as ListWidgetConfiguration}
                    onEdit={this.handleConfigurationEdit}
                    onChange={this.handleConfigurationChange}
                />
            case WidgetType.CHANGE_LIST:
                this.width = Globals.DIALOG_WIDTH_STACKED * 2;
                return <ChangeListWidgetEditor
                    formRef={this.formRef}
                    defaults={this.buildDefaultConfigurations()}
                    calculator={calculator}
                    configuration={configuration as ChangeListWidgetConfiguration}
                    onChange={this.handleConfigurationChange}
                />
            case WidgetType.WATCHLIST:
                this.width = Globals.DIALOG_WIDTH_STACKED * 3;
                return <WatchlistWidgetEditor
                    formRef={this.formRef}
                    defaults={this.buildDefaultConfigurations()}
                    configuration={configuration as WatchlistWidgetConfiguration}
                    calculator={calculator}
                    onChange={this.handleConfigurationChange}
                />
            case WidgetType.DIVIDER:
                this.width = Globals.DIALOG_WIDTH_STACKED * 2;
                return <DividerWidgetEditor
                    formRef={this.formRef}
                    defaults={this.buildDefaultConfigurations()}
                    configuration={configuration as DividerWidgetConfiguration}
                    onChange={this.handleConfigurationChange}
                />
            case WidgetType.INPUT:
                this.width = Globals.DIALOG_WIDTH_STACKED * 2;
                return <InputWidgetEditor
                    formRef={this.formRef}
                    defaults={this.buildDefaultConfigurations()}
                    calculator={calculator}
                    configuration={configuration as InputWidgetConfiguration}
                    onChange={this.handleConfigurationChange}
                />
            case WidgetType.INPUT_FLOW:
                this.width = Globals.DIALOG_WIDTH_STACKED * 2;
                return <InputFlowWidgetEditor
                    formRef={this.formRef}
                    defaults={this.buildDefaultConfigurations()}
                    calculator={calculator}
                    configuration={configuration as InputFlowWidgetConfiguration}
                    onChange={this.handleConfigurationChange}
                />
            case WidgetType.INPUT_PANEL:
                this.width = Globals.DIALOG_WIDTH_STACKED * 2;
                return <InputPanelWidgetEditor
                    formRef={this.formRef}
                    defaults={this.buildDefaultConfigurations()}
                    calculator={calculator}
                    configuration={configuration as InputPanelWidgetConfiguration}
                    onChange={this.handleConfigurationChange}
                />
            case WidgetType.FILTER:
                this.width = Globals.DIALOG_WIDTH_STACKED * 2;
                return <FilterWidgetEditor
                    formRef={this.formRef}
                    defaults={this.buildDefaultConfigurations()}
                    calculator={calculator}
                    configuration={configuration as FilterWidgetConfiguration}
                    onEdit={this.handleConfigurationEdit}
                    onChange={this.handleConfigurationChange}
                />
            case WidgetType.PANEL:
                this.width = Globals.DIALOG_WIDTH_STACKED * 2;
                return <PanelWidgetEditor
                    formRef={this.formRef}
                    defaults={this.buildDefaultConfigurations()}
                    applet={this.props.applet}
                    calculator={calculator}
                    configuration={configuration as PanelWidgetConfiguration}
                    onChange={this.handleConfigurationChange}
                />
            case WidgetType.SELECT:
                this.width = Globals.DIALOG_WIDTH_STACKED * 2.4;
                return <SelectWidgetEditor
                    formRef={this.formRef}
                    defaults={this.buildDefaultConfigurations()}
                    configuration={configuration as SelectWidgetConfiguration}
                    calculator={calculator}
                    onChange={this.handleConfigurationChange}
                />
            case WidgetType.SWITCH:
                this.width = Globals.DIALOG_WIDTH_STACKED * 2.4;
                return <SwitchWidgetEditor
                    formRef={this.formRef}
                    defaults={this.buildDefaultConfigurations()}
                    applet={this.props.applet}
                    calculator={calculator}
                    configuration={configuration as SwitchWidgetConfiguration}
                    onChange={this.handleConfigurationChange}
                />
            // case WidgetType.MENU:
            //     this.width = Globals.DIALOG_WIDTH_STACKED * 2;
            //     return <MenuWidgetEditor
            //         formRef={this.formRef}
            //         defaults={this.buildDefaultConfigurations()}
            //         applet={this.props.applet}
            //         calculator={calculator}
            //         configuration={configuration as MenuWidgetConfiguration}
            //         onChange={this.handleConfigurationChange}
            //     />
            case WidgetType.ROW:
                this.width = Globals.DIALOG_WIDTH_STACKED * 2;
                return <RowWidgetEditor
                    formRef={this.formRef}
                    defaults={this.buildDefaultConfigurations()}
                    panel={this.props.panel}
                    widget={this.state.widget}
                    configuration={configuration as RowWidgetConfiguration}
                    calculator={calculator}
                    onChange={this.handleConfigurationChange}
                />
            case WidgetType.COLUMN:
                this.width = Globals.DIALOG_WIDTH_STACKED * 2;
                return <ColumnWidgetEditor
                    formRef={this.formRef}
                    defaults={this.buildDefaultConfigurations()}
                    panel={this.props.panel}
                    widget={this.state.widget}
                    configuration={configuration as ColumnWidgetConfiguration}
                    calculator={calculator}
                    onChange={this.handleConfigurationChange}
                />
            default:
                this.width = Globals.DIALOG_WIDTH_STACKED;
                return <div>Unsupported widget type '{type}'.</div>
        }
    }

    private buildDefaultConfigurations(): ReactElement {
        return (
            <>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Widget Type"
                    name="widget-type"
                    info="The type of the widget."
                    rules={[{
                        required: true,
                        message: 'Please select a widget type.'
                    }]}
                >
                    <Select
                        value={this.state.type}
                        onChange={this.handleTypeChange}
                    >
                        <Select.OptGroup key="layout" label="Layout">
                            <Select.Option key={WidgetType.ROW} value={WidgetType.ROW}>Row</Select.Option>
                            <Select.Option key={WidgetType.COLUMN} value={WidgetType.COLUMN}>Column</Select.Option>
                        </Select.OptGroup>
                        <Select.OptGroup key="input" label="Input">
                            <Select.Option key={WidgetType.INPUT} value={WidgetType.INPUT}>Input</Select.Option>
                            <Select.Option key={WidgetType.INPUT_FLOW} value={WidgetType.INPUT_FLOW}>Input Flow</Select.Option>
                            <Select.Option key={WidgetType.INPUT_PANEL} value={WidgetType.INPUT_PANEL}>Input Panel</Select.Option>
                            <Select.Option key={WidgetType.FILTER} value={WidgetType.FILTER}>Filter</Select.Option>
                        </Select.OptGroup>
                        <Select.OptGroup key="navigation" label="Navigation">
                            <Select.Option key={WidgetType.SELECT} value={WidgetType.SELECT}>Select</Select.Option>
                            <Select.Option key={WidgetType.SWITCH} value={WidgetType.SWITCH}>Switch</Select.Option>
                            {/* <Select.Option key={WidgetType.MENU} value={WidgetType.MENU}>Menu</Select.Option> */}
                        </Select.OptGroup>
                        <Select.OptGroup key="display" label="Display">
                            <Select.Option key={WidgetType.TEXT} value={WidgetType.TEXT}>Text</Select.Option>
                            <Select.Option key={WidgetType.VALUE} value={WidgetType.VALUE}>Value</Select.Option>
                            <Select.Option key={WidgetType.PANEL} value={WidgetType.PANEL}>Panel</Select.Option>
                            <Select.Option key={WidgetType.TABLE} value={WidgetType.TABLE}>Table</Select.Option>
                            <Select.Option key={WidgetType.LIST} value={WidgetType.LIST}>List</Select.Option>
                            <Select.Option key={WidgetType.CHANGE_LIST} value={WidgetType.CHANGE_LIST}>Change List</Select.Option>
                            <Select.Option key={WidgetType.WATCHLIST} value={WidgetType.WATCHLIST}>Watchlist</Select.Option>
                            <Select.Option key={WidgetType.DIVIDER} value={WidgetType.DIVIDER}>Divider</Select.Option>
                        </Select.OptGroup>
                        <Select.OptGroup key="chart" label="Chart">
                            <Select.Option key={WidgetType.AREA_CHART} value={WidgetType.AREA_CHART}>Area Chart</Select.Option>
                            <Select.Option key={WidgetType.BAR_CHART} value={WidgetType.BAR_CHART}>Bar Chart</Select.Option>
                            <Select.Option key={WidgetType.COLUMN_CHART} value={WidgetType.COLUMN_CHART}>Column Chart</Select.Option>
                            <Select.Option key={WidgetType.LINE_CHART} value={WidgetType.LINE_CHART}>Line Chart</Select.Option>
                            <Select.Option key={WidgetType.PIE_CHART} value={WidgetType.PIE_CHART}>Pie Chart</Select.Option>
                            <Select.Option key={WidgetType.DONUT_CHART} value={WidgetType.DONUT_CHART}>Donut Chart</Select.Option>
                            <Select.Option key={WidgetType.CANDLESTICK_CHART} value={WidgetType.CANDLESTICK_CHART}>Candlestick Chart</Select.Option>
                        </Select.OptGroup>
                    </Select>
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Name"
                    name="name"
                    info="The name of the widget."
                    rules={[{
                        required: true,
                        message: 'Please enter a name.'
                    }]}
                >
                    <Input
                        placeholder="Enter a name."
                        value={this.state.widget.name}
                        onChange={this.handleNameChange}
                    />
                </FormItem>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    label="Margin"
                    name="margin"
                    info={"Enter the margins around the widget."}
                >
                    <MarginEditor
                        value={this.state.widget.configuration?.margin}
                        onChange={this.handleMarginChange}
                    />
                </FormItem>
                {this.state.type !== WidgetType.ROW && this.state.type !== WidgetType.COLUMN &&
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Width"
                        name="width"
                        info="Select the width of the widget."
                    >
                        <WidthEditor
                            value={this.state.widget.configuration?.width}
                            onChange={this.handleWidthChange}
                        />
                    </FormItem>
                }
                {(this.state.type === WidgetType.LINE_CHART ||
                    this.state.type === WidgetType.AREA_CHART ||
                    this.state.type === WidgetType.BAR_CHART ||
                    this.state.type === WidgetType.COLUMN_CHART ||
                    this.state.type === WidgetType.FILTER) &&
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.formRef}
                        label="Height"
                        name="height"
                        info="Select the height of the widget."
                    >
                        <HeightEditor
                            value={this.state.widget.configuration?.height}
                            onChange={this.handleHeightChange}
                        />
                    </FormItem>
                }
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.formRef}
                    required={false}
                    label="Hide Condition"
                    name="hide-condition"
                    info="A cell or variable whose boolean value will be checked to determine whether to hide the widget."
                >
                    <RefEditor
                        formRef={this.formRef}
                        required={false}
                        prefix="hide"
                        isVector={true}
                        calculator={this.props.calculator}
                        refTypes={[RefType.VARIABLE, RefType.CELL]}
                        refId={this.state.widget.configuration?.hideId}
                        onChange={this.handleHideChange}
                    />
                </FormItem>
            </>
        );
    }

    public render() {
        const view = this.buildEditorView();
        return (
            <Modal
                centered
                className="x-widgeteditor"
                title="Widget Editor"
                width={this.width}
                onOk={this.handleEditOk}
                onCancel={this.handleEditCancel}
                visible={true}
                okButtonProps={{
                    disabled: this.state.isEditing
                }}
                cancelButtonProps={{
                    disabled: this.state.isEditing
                }}
            >
                <Form
                    ref={this.formRef}
                    onFinish={this.handleEditSave}
                >
                    {view}
                </Form>
            </Modal>
        );
    }

}
