import { ReactElement, Ref, useRef, useState } from 'react';
import { Form, FormInstance, Modal, Row } from 'antd';
import { Globals } from 'constants/Globals';
import { MenuItem, Applet } from '@methodset/model-client-ts';
import { MenuItems } from './MenuItems/MenuItems';
import './MenuEditor.less';
import { FormItem } from 'components/FormItem/FormItem';

export type ChangeCallback = (menu: MenuItem[]) => void;
export type CancelCallback = () => void;

export type MenuEditorProps = {
    applet: Applet,
    menu: MenuItem[],
    onChange: ChangeCallback,
    onCancel: CancelCallback
}

export const MenuEditor = (props: MenuEditorProps): ReactElement => {

    const formRef: Ref<FormInstance> = useRef(null);
    const [menu, setMenu] = useState<MenuItem[]>(props.menu);

    const handleFormFinish = (): void => {
        props.onChange(menu);
    }

    const handleOk = (): void => {
        formRef.current?.submit();
    }

    const handleCancel = (): void => {
        props.onCancel();
    }

    const handleMenuChange = (menu: MenuItem[]): void => {
        setMenu(menu);
    }

    return (
        <Modal
            centered
            className="x-menueditor"
            title="Menu Editor"
            width={Globals.DIALOG_WIDTH_STACKED * 1.5}
            onOk={handleOk}
            onCancel={handleCancel}
            visible={true}
        >
            <Form ref={formRef} onFinish={handleFormFinish}>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={formRef}
                    label="Menu Items"
                    name="items"
                    info="The items that make up the menu."
                    valuePropName="menu"
                    rules={[{
                        validator: () => {
                            for (const item of menu) {
                                if (!item || !item.label || !item.panelId) {
                                    return Promise.reject(`Please fill all menu items.`);
                                }
                            }
                            return Promise.resolve();
                        }
                    }]}
                >
                    <MenuItems
                        applet={props.applet}
                        menu={menu}
                        onChange={handleMenuChange}
                    />
                </FormItem>
            </Form>
        </Modal>
    )

}


