import { ReactElement } from 'react';
import { AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined } from '@ant-design/icons';
import { AlignmentType } from '@methodset/model-client-ts';
import { Button, Dropdown, Menu } from 'antd';
import { CoreUtils } from 'utils/CoreUtils';
import './AlignmentStyle.less';

export type ChangeCallback = (alignment: AlignmentType) => void;

export type AlignmentStyleProps = {
    alignment?: AlignmentType,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    alignment: AlignmentType.TOP
}

export const AlignmentStyle = (props: AlignmentStyleProps): ReactElement => {

    const handleSelect = (info: any) => {
        props.onChange(info.key);
    }

    const menu = (
        <Menu onClick={handleSelect}>
            {Object.entries(AlignmentType).map(([key, value]) =>
                <Menu.Item key={key}>
                    {CoreUtils.toCapital(value)}
                </Menu.Item>
            )}
        </Menu>
    );

    const iconView = (): ReactElement | undefined => {
        switch (props.alignment) {
            case AlignmentType.TOP:
                return <AlignLeftOutlined rotate={90} />
            case AlignmentType.MIDDLE:
                return <AlignCenterOutlined rotate={90} />
            case AlignmentType.BOTTOM:
                return <AlignRightOutlined rotate={90} />
            default:
                return undefined;
        }
    }

    return (
        <div className="x-alignmentstyle">
            <Dropdown
                overlay={menu}
                trigger={['click']}
            >
                <Button>
                    {iconView()}
                </Button>
            </Dropdown>
        </div>

    )
}

AlignmentStyle.defaultProps = defaultProps;
