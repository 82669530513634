import { ReactElement } from 'react';
import { Col, Row } from 'antd';
import { Calculator } from '@methodset/calculator-ts';
import { Applet, AppletPanel, Configuration, RowWidgetConfiguration, Widget, WidgetType } from '@methodset/model-client-ts';
import { ConfigurationCallback, WidgetViewer } from '../WidgetViewer/WidgetViewer';
import { Globals } from 'constants/Globals';
import { ItemMenu, WidgetCallback } from 'containers/Console/Models/ModelItem/ModelApplications/ApplicationItem/ModelApplet/AppletEditor/ItemMenu/ItemMenu';
import { CoreUtils } from 'utils/CoreUtils';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import update from 'immutability-helper';
import './RowWidgetViewer.less';

export type RowWidgetViewerProps = {
    applet: Applet,
    panel: AppletPanel,
    widget: Widget,
    calculator: Calculator,
    extra?: ReactElement,
    configuration: RowWidgetConfiguration,
    // model: Model,
    // modelSetup?: ModelSetup,
    // modelId: string,
    // version?: number
    appletConfiguration: Configuration,
    //variableSpecs?: VariableSpec[],
    showHeader: boolean,
    onEdit?: WidgetCallback,
    onRemove?: WidgetCallback,
    onChange?: WidgetCallback,
    onUpdate: ConfigurationCallback
};

export const RowWidgetViewer = (props: RowWidgetViewerProps): ReactElement => {

    const findWidget = (widgetId: string): Widget | undefined => {
        return props.panel.widgets.find(widget => widget.id === widgetId);
    }

    const findIndex = (widgetId: string): number => {
        return props.configuration.widgetIds.findIndex(id => id === widgetId);
    }

    const buildView = (index: number, widget: Widget, menu: ReactElement | undefined): ReactElement => {
        return (
            <WidgetViewer
                key={`${widget.id}-${index}`}
                //model={props.model}
                applet={props.applet}
                panel={props.panel}
                widget={widget}
                calculator={props.calculator}
                extra={menu}
                //modelSetup={props.modelSetup}
                // modelId={props.modelId}
                // version={props.version}
                configuration={props.appletConfiguration}
                //variableSpecs={props.variableSpecs}
                showHeader={props.showHeader}
                onEdit={props.onEdit}
                onRemove={props.onRemove}
                onChange={props.onChange}
                onUpdate={props.onUpdate}
            />
        )
    }

    const handleMoveLeft = (widget: Widget): void => {
        const index = findIndex(widget.id);
        if (index === -1) {
            return;
        }
        const configuration = update(props.configuration, {
            widgetIds: {
                $splice: [[index, 1], [index - 1, 0, widget.id]]
            }
        });
        widget = update(props.widget, {
            configuration: { $set: configuration }
        });
        props.onChange!(widget);
    }

    const handleMoveRight = (widget: Widget): void => {
        const index = findIndex(widget.id);
        if (index === -1) {
            return;
        }
        const configuration = update(props.configuration, {
            widgetIds: {
                $splice: [[index, 1], [index + 1, 0, widget.id]]
            }
        });
        widget = update(props.widget, {
            configuration: { $set: configuration }
        });
        props.onChange!(widget);
    }

    const toSpan = (width: string | number | undefined): number | undefined => {
        if (CoreUtils.isNumber(width)) {
            return width * Globals.LAYOUT_SCALE;
        } else {
            return undefined;
        }
    }

    const gap = Globals.spacing(props.configuration.gap);
    const alignment = CoreUtils.toLower(props.configuration.alignment, "_", "-") as any;
    const justification = CoreUtils.toLower(props.configuration.justification, "_", "-") as any;

    return (
        <Row gutter={[gap, gap]} justify={justification} align={alignment} wrap={false}>
            {props.configuration.widgetIds.map((widgetId, index) => {
                const widget = findWidget(widgetId);
                if (!widget) {
                    return undefined;
                } else {
                    let menu;
                    if (props.showHeader) {
                        const extra = [{
                            icon: <ArrowLeftOutlined />,
                            label: "Move left",
                            disabled: () => index === 0,
                            onSelect: handleMoveLeft
                        }, {
                            icon: <ArrowRightOutlined />,
                            label: "Move right",
                            disabled: () => index === props.configuration.widgetIds.length - 1,
                            onSelect: handleMoveRight
                        }];
                        menu = (
                            <ItemMenu
                                widget={widget}
                                extra={extra}
                                onEdit={props.onEdit!}
                                onRemove={props.onRemove!}
                            />
                        )
                    }
                    if (widget.configuration.type === WidgetType.COLUMN || widget.configuration.type === WidgetType.ROW) {
                        return buildView(index, widget, menu);
                    } else {
                        return (
                            <Col
                                className={classNames({ "x-rowwidgetviewer-fill": widget.configuration.width === "FILL" })}
                                key={`column-${index}`}
                                span={toSpan(widget.configuration.width)}
                            >
                                {buildView(index, widget, menu)}
                            </Col>
                        )
                    }
                }
            })}
        </Row>
    );

}
