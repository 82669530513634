import { PureComponent, ReactElement } from 'react';
import { Button, Tag } from 'antd';
import { Applet } from '@methodset/model-client-ts';
import { InputLinkEditor } from './InputLinkEditor/InputLinkEditor';
import { InputLink } from '@methodset/dashboard-client-ts';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { ButtonLink } from 'components/ButtonLink/ButtonLink';
import { CoreUtils } from 'utils/CoreUtils';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { WidgetUtils } from 'utils/WidgetUtils';
import { ItemMap, VariableMap } from '../../../DashboardItem';
import { DataType, Variable } from '@methodset/calculator-ts';
import update from 'immutability-helper';
import './InputLinks.less';

const COLOR_MAP = CoreUtils.toColorMap(CoreUtils.enumToKeys(DataType));

// Map of applet/key to true/false if in use.
export type UsedMap = { [key: string]: boolean };

export type EditCallback = (isEditing: boolean) => void;
export type ChangeCallback = (inputLinks: InputLink[]) => void;

export type InputLinksProps = typeof InputLinks.defaultProps & {
    inputLinks?: InputLink[],
    variableMap: VariableMap,
    itemMap: ItemMap,
    onEdit: EditCallback,
    onChange: ChangeCallback
}

export type InputLinksState = {
    // The index of the edited link.
    index?: number,
    // Configuration spec being edited.
    inputLink?: InputLink,
}

export class InputLinks extends PureComponent<InputLinksProps, InputLinksState> {

    static defaultProps = {
        inputLinks: [] as InputLink[]
    }

    private usedVariables: UsedMap;

    constructor(props: InputLinksProps) {
        super(props);
        this.state = {}
        this.usedVariables = this.buildUsedVariables(props.inputLinks);
        this.handleLinkAdd = this.handleLinkAdd.bind(this);
        this.handleLinkRemove = this.handleLinkRemove.bind(this);
        this.handleLinkEdit = this.handleLinkEdit.bind(this);
        this.handleEditorDone = this.handleEditorDone.bind(this);
        this.handleEditorCancel = this.handleEditorCancel.bind(this);
    }

    private buildUsedVariables(inputLinks: InputLink[]): UsedMap {
        const usedVariables: UsedMap = {};
        for (const inputLink of inputLinks) {
            const inputKey = inputLink.inputKey;
            const key = WidgetUtils.toKey(inputKey.appletId, 0, inputKey.specKey);
            usedVariables[key] = true;
            const linkedKeys = inputLink.linkedKeys;
            for (const linkedKey of linkedKeys) {
                const key = WidgetUtils.toKey(linkedKey.appletId, 0, linkedKey.specKey);
                usedVariables[key] = true;
            }
        }
        return usedVariables;
    }

    private handleLinkEdit(inputLink: InputLink, index: number): void {
        this.setState({
            inputLink: inputLink,
            index: index
        });
        if (this.props.onEdit) {
            this.props.onEdit(true);
        }
    }

    private handleLinkAdd(): void {
        this.setState({ index: -1 });
        if (this.props.onEdit) {
            this.props.onEdit(true);
        }
    }

    private handleLinkRemove(index: number): void {
        const inputLinks = update(this.props.inputLinks, {
            $splice: [[index, 1]]
        });
        this.usedVariables = this.buildUsedVariables(inputLinks);
        this.props.onChange(inputLinks);
    }

    private handleEditorDone(inputLink: InputLink, index: number): void {
        let inputLinks;
        if (index !== -1) {
            inputLinks = update(this.props.inputLinks, {
                [index]: { $set: inputLink }
            });
        } else {
            inputLinks = update(this.props.inputLinks, {
                $push: [inputLink]
            });
        }
        this.usedVariables = this.buildUsedVariables(inputLinks);
        this.props.onChange(inputLinks);
        this.setState({
            inputLink: undefined,
            index: undefined
        });
        if (this.props.onEdit) {
            this.props.onEdit(false);
        }
    }

    private handleEditorCancel(): void {
        this.setState({
            inputLink: undefined,
            index: undefined
        });
        if (this.props.onEdit) {
            this.props.onEdit(false);
        }
    }

    private findApplet(inputLink: InputLink): Applet {
        const inputKey = inputLink.inputKey;
        const item = this.props.itemMap[inputKey.appletId];
        return item.applet;
    }

    private findVariable(inputLink: InputLink): Variable {
        const inputKey = inputLink.inputKey;
        const key = WidgetUtils.toKey(inputKey.appletId, 0, inputKey.specKey);
        return this.props.variableMap[key];
    }

    private buildColumns(): ColumnsType<any> {
        const columns: ColumnsType<any> = [{
            key: 'applet',
            title: 'Applet',
            width: 300,
            render: (_, inputLink: InputLink, index: number) => {
                return (
                    <ButtonLink
                        onClick={() => this.handleLinkEdit(inputLink, index)}
                    >
                        {this.findApplet(inputLink).name}
                    </ButtonLink>
                )
            },
        }, {
            key: 'input',
            title: 'Input',
            width: 300,
            render: (inputLink: InputLink) => {
                return (
                    <span>{this.findVariable(inputLink).name}</span>
                );
            },
        }, {
            key: 'type',
            title: 'Type',
            align: 'center',
            width: 150,
            render: (inputLink: InputLink) => {
                let type = this.findVariable(inputLink).type;
                if (!type) {
                    type = DataType.TEXT;
                }
                return (
                    <Tag color={COLOR_MAP[type]}>
                        {type}
                    </Tag>
                );
            },
        }, {
            key: 'links',
            title: 'Links',
            align: 'center',
            width: 150,
            render: (inputLink: InputLink) => {
                return (
                    <span>{inputLink.linkedKeys.length}</span>
                );
            },
        }];
        return columns;
    }

    private buildData(): any {
        return this.props.inputLinks;
    }

    public render(): ReactElement {
        const actions = [{
            icon: <EditOutlined />,
            label: `Edit link`,
            callback: this.handleLinkEdit
        }, {
            icon: <DeleteOutlined />,
            label: `Delete link`,
            confirm: `Are you sure you want to remove the link?`,
            callback: this.handleLinkRemove
        }];
        return (
            <>
                {CoreUtils.isEmpty(this.state.index) &&
                    <div>
                        <ItemTable
                            className="x-inputlinks"
                            size="small"
                            pagination={false}
                            columns={this.buildColumns()}
                            items={this.buildData()}
                            rowKey={(inputLink: any) => {
                                return `${inputLink.inputKey.modelId}-${inputLink.inputKey.specKey}`;
                            }}
                            actions={actions}
                        />
                        <div className="x-inputlinks-add">
                            <Button onClick={this.handleLinkAdd}>
                                Add Link
                            </Button>
                        </div>
                    </div>
                }
                {!CoreUtils.isEmpty(this.state.index) &&
                    <InputLinkEditor
                        itemMap={this.props.itemMap}
                        usedVariables={this.usedVariables}
                        index={this.state.index!}
                        inputLink={this.state.inputLink}
                        onCancel={this.handleEditorCancel}
                        onDone={this.handleEditorDone}
                    />
                }
            </>
        );
    }

}
