import { ReactElement, useEffect, useState } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { SyncOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RouteBuilder } from 'utils/RouteBuilder';
import { ColumnsType } from 'antd/lib/table';
import { RestUtils } from 'utils/RestUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { StatusType } from 'constants/StatusType';
import { NotificationHeader } from '@methodset/dashboard-client-ts';
import modelService from 'services/ModelService';
import axios from 'axios';
import './Notifications.less';

export type NotificationsProps = RouteComponentProps & {
}

export const Notifications = (props: NotificationsProps): ReactElement => {

    const history = useHistory();
    const [status, setStatus] = useState<StatusType>(StatusType.INIT);
    const [headers, setHeaders] = useState<NotificationHeader[]>([]);

    useEffect(() => {
        if (status !== StatusType.READY) {
            loadData();
        }
    }, []);

    const handleRetryLoad = (): void => {
        loadData();
    }

    const handleRefreshClick = (): void => {
        loadData();
    }

    const handleAlertsEdit = (): void => {
        history.push(RouteBuilder.CONSOLE_ALERTS);
    }

    const readNotificationHeadersRequest = (): Promise<any> => {
        const request = {};
        return modelService.readNotificationHeaders(request,
            (response: any) => readNotificationHeadersResponse(response),
            undefined, true
        );
    }

    const readNotificationHeadersResponse = (response: any): void => {
        const headers = response.data.headers;
        setHeaders(headers);
    }

    const buildColumns = (): ColumnsType<any> => {
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            width: Globals.TABLE_WIDTH_LARGE,
            render: (record) => {
                return <Link to={RouteBuilder.api(record.id)}>{record.name}</Link>
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'utime',
            title: 'Last Updated',
            width: Globals.TABLE_WIDTH_MEDIUM,
            align: 'center',
            render: (record) => {
                return (
                    <span>
                        {CoreUtils.toUpdateTime(record.updateTime)}
                    </span>
                );
            },
            sorter: (a, b) => CoreUtils.compareNumbers(a.updateTime, b.updateTime),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    const buildData = (): any => {
        return headers;
    }

    const loadData = () => {
        const requests = [];
        requests.push(readNotificationHeadersRequest());
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    const columns = buildColumns();
    const data = buildData();

    return (
        <ItemTable
            className="x-notifications"
            title="Notifications"
            status={status}
            columns={columns}
            items={data}
            extra={
                <Space>
                    <Button onClick={handleAlertsEdit}>Alerts</Button>
                    <Button icon={<SyncOutlined />} onClick={handleRefreshClick}></Button>
                </Space>
            }
            onLoad={handleRetryLoad}
        />
    );

}
