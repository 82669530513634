import { ReactElement } from 'react';
import { Button, Modal } from 'antd';
import { Globals } from 'constants/Globals';
import { Applet, Configuration, AppletPanel } from '@methodset/model-client-ts';
import { AppletViewer } from '../AppletViewer';
import { Calculator } from '@methodset/calculator-ts';
import './PanelDialog.less';

const DEFAULT_WIDTH = Globals.DIALOG_WIDTH_STACKED * 2;

export type ChangeCallback = (configuration: Configuration, appletId: string) => void;
export type CloseCallback = () => void;

export type PanelViewerProps = {
    // The DOM element id that will be used to determine the dialog
    // width. The element width represents the maximum width. The
    // actual dialog width is calculated by using the panel span
    // to determine the percent of the full width.
    viewId?: string,
    // The main applet.
    applet: Applet,
    // The title of the dialog.
    title: string,
    // The panel to display.
    panelId: string,
    // The underlying model calculator.
    calculator: Calculator,
    // The applet configuration.
    configuration: Configuration,
    onChange: ChangeCallback,
    onClose: CloseCallback
}

export const PanelDialog = (props: PanelViewerProps): ReactElement => {

    const width = (panel: AppletPanel | undefined): number => {
        if (!props.viewId) {
            return DEFAULT_WIDTH;
        }
        const el = document.getElementById(props.viewId);
        return el && panel?.span ? (panel.span * el.clientWidth) / Globals.LAYOUT_COLUMNS : DEFAULT_WIDTH;
    }

    const panel = props.applet.panels.find(panel => panel.id === props.panelId);

    return (
        <Modal
            centered
            className="x-paneldialog"
            title={props.title}
            width={width(panel)}
            visible={true}
            onCancel={props.onClose}
            footer={(
                <Button type="primary" onClick={props.onClose}>Close</Button>
            )}
        >
            <AppletViewer
                key={props.applet.id}
                applet={props.applet}
                panel={panel}
                calculator={props.calculator}
                configuration={props.configuration}
                isEmbedded={true}
                onChange={props.onChange}
            />
        </Modal>
    )

}
