import { PureComponent, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Empty, message, Modal } from 'antd';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner'
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { ChangeType, DashboardHeading } from './DashboardHeading/DashboardHeading';
import { Applet, Configuration } from '@methodset/model-client-ts';
import { AppletState, Dashboard, InputKey, InputLink, PackInfo } from '@methodset/dashboard-client-ts';
import { Calculator, Variable } from '@methodset/calculator-ts';
import { CloudSyncOutlined, DeleteOutlined, DownloadOutlined, DragOutlined, ExperimentOutlined, InfoCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { ItemSpec, MenuButton } from 'components/MenuButton/MenuButton';
import { RouteBuilder } from 'utils/RouteBuilder';
import { Location } from 'history';
import { Spacer } from 'components/Spacer/Spacer';
import { WidgetUtils } from 'utils/WidgetUtils';
import { CoreUtils } from 'utils/CoreUtils';
import { Item, ItemLayout, ViewItem } from 'containers/Console/Models/ModelItem/ModelApplications/ApplicationItem/ModelApplet/AppletEditor/ItemLayout/ItemLayout';
import { AppletViewer } from './AppletViewer/AppletViewer';
import { ItemPosition } from 'containers/Console/Models/ModelItem/ModelApplications/ApplicationItem/ModelApplet/AppletEditor/ItemPosition/ItemPosition';
import { AppletPacks } from './AppletPacks/AppletPacks';
import { AppletDetails, ApplicationDetails, PackHeader } from '@methodset/library-client-ts';
import { AppletInfo } from './AppletInfo/AppletInfo';
import { StatusType } from 'constants/StatusType';
import { AuthenticationHeader } from '@methodset/endpoint-client-ts';
import { EnvironmentType } from '@methodset/commons-shared-ts';
import { EntityContext } from 'context/EntityContext';
import { AutoUpdater } from './AutoUpdater/AutoUpdater';
import axios from 'axios';
import endpointService from 'services/EndpointService';
import libraryService from 'services/LibraryService';
import modelService from 'services/ModelService';
import update from 'immutability-helper';
import './DashboardItem.less';
import { NoData } from 'components/NoData/NoData';

// Info for item placed on the dashboard.
export interface AppletInfo {
    applet: Applet;
    calculator: Calculator;
}
// Map of applet to item (info).
export type ItemMap = { [key: string]: AppletInfo };
// Map of applet/key to variable.
export type VariableMap = { [key: string]: Variable };
// Map of applet/key to all model/version/spec in group.
export type LinkMap = { [key: string]: string[] };

// The state of exiting. Since the model may need to be saved
// before exiting, the state must be tracked to allow the save
// to complete before exiting.
type ExitState = "init" | "failed" | "started" | "finished";

// Types of modals that can be displayed.
type ModalType = "none" | "packs" | "position" | "auto" | "about";

type MatchParams = {
    dashboardId: string
}

export type DashboardItemProps = RouteComponentProps<MatchParams> & {
    className?: string
}

export type DashboardItemState = {
    // Data load status.
    status: StatusType,
    // The dashboard.
    dashboard?: Dashboard,
    // Type of modal to display.
    modalType: ModalType,
    // A status message to display.
    message?: string,
    // Applet setup used when editing applet position.
    appletState?: AppletState,
    // The columns of applet setups used for applet layout.
    columnSetups: AppletState[][],
    // Authentication headers for credentials check.
    // TODO: load once and use across all dashboards?
    authentications: AuthenticationHeader[],
    // The maximum row number in the dashboard.
    maxRow: number,
    // True when dashboard is saving to storage.
    isSaving: boolean,
    // Set when a pack info is to be displayed.
    packInfo?: PackInfo,
    // The state of exiting the dashboard page.
    exitState: ExitState
}

// The number of columns in the dashboard grid layout.
const NUMBER_COLUMNS = Globals.LAYOUT_COLUMNS;
// The number of rows in the dashboard grid layout.
const NUMBER_ROWS = Globals.LAYOUT_ROWS;
// Auto-save interval in seconds.
const SAVE_INTERVAL = 60 * 1000;

export class DashboardItem extends PureComponent<DashboardItemProps, DashboardItemState> {

    static contextType = EntityContext;

    // The timer for auto-saves.
    private timerId: NodeJS.Timeout | null = null;
    // The target URL when checking for save on exit.
    private nextPathname: string | null = null;
    // The function to stop URL monitoring.
    private unblock: any;
    // True if changes need to be saved, false otherwise.
    private isDirty: boolean = false;
    // Map of specs with link changes.
    private variableMap: VariableMap = {};
    // All permutations of links.
    private linkMap: LinkMap = {};
    // Map of applet items.
    private itemMap: ItemMap = {};

    constructor(props: DashboardItemProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            modalType: "none",
            message: undefined,
            appletState: undefined,
            columnSetups: this.emptyColumnSetups(),
            authentications: [],
            maxRow: 0,
            isSaving: false,
            packInfo: undefined,
            exitState: "init"
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleAppletLoad = this.handleAppletLoad.bind(this);
        this.handleConfigurationChange = this.handleConfigurationChange.bind(this);
        this.handleAuthenticationAdd = this.handleAuthenticationAdd.bind(this);
        this.handleAppletInstall = this.handleAppletInstall.bind(this);
        this.handleAppletsShow = this.handleAppletsShow.bind(this);
        this.handleAppletsClose = this.handleAppletsClose.bind(this);
        this.handleAppletRefresh = this.handleAppletRefresh.bind(this);
        this.handleAppletsRefresh = this.handleAppletsRefresh.bind(this);
        this.handleAppletUninstall = this.handleAppletUninstall.bind(this);
        this.handleSnapshotSelect = this.handleSnapshotSelect.bind(this);
        this.handlePositionEdit = this.handlePositionEdit.bind(this);
        this.handlePositionCancel = this.handlePositionCancel.bind(this);
        this.handlePositionChange = this.handlePositionChange.bind(this);
        this.handleAutoEdit = this.handleAutoEdit.bind(this);
        this.handleAutoChange = this.handleAutoChange.bind(this);
        this.handleAutoCancel = this.handleAutoCancel.bind(this);
        this.handleUpdateCheck = this.handleUpdateCheck.bind(this);
        this.handleDashboardSave = this.handleDashboardSave.bind(this);
        this.handleDashboardChange = this.handleDashboardChange.bind(this);
        this.handleAboutClose = this.handleAboutClose.bind(this);
        this.handleExitCheck = this.handleExitCheck.bind(this);
        this.handleExitWithSave = this.handleExitWithSave.bind(this);
        this.handleExitNoSave = this.handleExitNoSave.bind(this);
        this.handleExitCancel = this.handleExitCancel.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleAppletLoad(applet: Applet, calculator: Calculator): void {
        let dashboard = this.state.dashboard!;
        const index = dashboard.appletStates.findIndex(state => state.appletId === applet.id);
        if (index === -1) {
            // Sanity check.
            return;
        }
        const appletState = dashboard.appletStates[index];
        const configuration = appletState.configuration;

        dashboard = this.setupMaps(dashboard, applet, calculator);
        this.executeCalculator(calculator, configuration);
        this.setState({ dashboard: dashboard });

        if (appletState.autoUpdate) {
            this.readPackHeaderRequest(appletState.packInfo);
        }
    }

    private handleConfigurationChange(configuration: Configuration, appletId: string): void {
        if (!this.state.dashboard) {
            return;
        }
        this.updateLinkedInputs(configuration, appletId);
    }

    private handleAuthenticationAdd(header: AuthenticationHeader): void {
        const authentications = update(this.state.authentications, {
            $push: [header]
        });
        this.setState({ authentications: authentications });
    }

    private handleAppletInstall(packHeader: PackHeader): void {
        const dashboard = this.state.dashboard!;
        this.addApplet(packHeader, dashboard);
    }

    private handleAppletsShow(): void {
        this.setState({ modalType: "packs" });
    }

    private handleAppletsClose(): void {
        this.setState({ modalType: "none" });
    }

    private handlePositionEdit(appletState: AppletState): void {
        this.setState({
            modalType: "position",
            appletState: appletState
        });
    }

    private handlePositionChange(item: Item): void {
        if (!this.state.dashboard) {
            return;
        }
        const appletState = this.state.appletState!;
        const appletStates = this.state.dashboard.appletStates;
        const index = appletStates.findIndex(state => state.appletId === appletState.appletId);
        if (index === -1) {
            return;
        }
        const dashboard = update(this.state.dashboard, {
            appletStates: {
                [index]: {
                    row: { $set: item.row },
                    col: { $set: item.col },
                    span: { $set: item.span }
                }
            }
        });
        this.setupColumns(dashboard);
        this.setState({
            modalType: "none",
            appletState: undefined,
            dashboard: dashboard
        });
        this.isDirty = true;
    }

    private handlePositionCancel(): void {
        this.setState({
            modalType: "none",
            appletState: undefined
        });
    }

    private handleAutoEdit(appletState: AppletState): void {
        this.setState({
            modalType: "auto",
            appletState: appletState
        });
    }

    private handleSnapshotSelect(appletState: AppletState): void {
        if (!this.state.dashboard) {
            return;
        }
        const appletStates = this.state.dashboard.appletStates;
        const index = appletStates.findIndex(state => state.appletId === appletState.appletId);
        if (index === -1) {
            return;
        }
        const dashboard = update(this.state.dashboard, {
            appletStates: {
                [index]: {
                    packInfo: {
                        version: { $set: 0 }
                    }
                }
            }
        });
        this.setState({ dashboard: dashboard });
        this.isDirty = true;
    }

    private handleAutoChange(appletState: AppletState): void {
        if (!this.state.dashboard) {
            return;
        }
        const appletStates = this.state.dashboard.appletStates;
        const index = appletStates.findIndex(state => state.appletId === appletState.appletId);
        if (index === -1) {
            return;
        }
        const dashboard = update(this.state.dashboard, {
            appletStates: {
                [index]: { $set: appletState }
            }
        });
        this.setState({
            modalType: "none",
            appletState: undefined,
            dashboard: dashboard
        });
        this.isDirty = true;
    }

    private handleAutoCancel(): void {
        this.setState({
            modalType: "none",
            appletState: undefined
        });
    }

    private handleUpdateCheck(appletState: AppletState): void {
        const message = "Checking...";
        this.readPackHeaderRequest(appletState.packInfo);
        this.setState({ message: message });
    }

    private handleDashboardSave(): void {
        this.updateDashboardRequest(false);
    }

    private handleDashboardChange(value: InputLink[] | Dashboard, type: ChangeType): void {
        if (type === ChangeType.LINKS) {
            const dashboard = this.state.dashboard!;
            const inputLinks = value as InputLink[];
            this.updateLinks(dashboard, inputLinks);
        } else if (type === ChangeType.PROPERTIES) {
            const dashboard = value as Dashboard;
            this.addProperties(dashboard);
        }
        this.isDirty = true;
    }

    private handleAppletRefresh(appletState: AppletState): void {
        const calculator = this.findCalculator(appletState.appletId);
        if (calculator) {
            calculator.runQueries();
        }
    }

    private handleAppletsRefresh(): void {
        const items = Object.values(this.itemMap);
        for (const item of items) {
            const calculator = item.calculator;
            calculator.runQueries();
        }
    }

    private handleAppletUninstall(appletState: AppletState): void {
        this.uninstallPackRequest(appletState, false);
    }

    private handleAboutShow(appletState: AppletState): void {
        const packInfo = appletState.packInfo;
        this.setState({
            modalType: "about",
            packInfo: packInfo
        });
    }

    private handleAboutClose(): void {
        this.setState({
            modalType: "none",
            packInfo: undefined
        });
    }

    private handleExitCheck(location: Location<any>): false | void {
        if (this.state.exitState === "finished") {
            return;
        }
        // Check if navigating away from the dashboard item editor.
        // If so, save the dashboard.
        const dashboardId = this.props.match.params.dashboardId;
        if (location.pathname !== RouteBuilder.dashboard(dashboardId)) {
            if (!this.isDirty) {
                // Nothing needs to be saved.
                return;
            }
            // Store URL to navigate to after save.
            this.nextPathname = `${location.pathname}${location.search}`;
            if (this.state.dashboard!.autoSave) {
                // Auto-save enabled, save dashboard.
                this.updateDashboardRequest(false, true);
            } else {
                // Ask the user if they want to save the dashboard.
                this.setState({ exitState: "started" });
            }
            return false;
        } else {
            // Internal page URL change, not navigating away.
            return;
        }
    }

    private handleExitWithSave(): void {
        this.updateDashboardRequest(false, true);
    }

    private handleExitNoSave(): void {
        this.setState({ exitState: "finished" }, () => {
            this.props.history.push(this.nextPathname!);
        });
    }

    private handleExitCancel(): void {
        this.setState({ exitState: "init" });
    }

    private uninstallPackRequest(appletState: AppletState, isUpdate: boolean, version?: number): Promise<any> {
        const packInfo = appletState.packInfo;
        const request = {
            packId: packInfo.packId,
            version: !CoreUtils.isEmpty(version) ? version : packInfo.version,
            instanceId: this.state.dashboard!.id,
            accessType: packInfo.accessType
        }
        return libraryService.uninstallPack(request,
            (response: any) => this.uninstallPackResponse(response, appletState, isUpdate),
            undefined, isUpdate
        );
    }

    private uninstallPackResponse(response: any, appletState: AppletState, isUpdate: boolean): void {
        const appletId = appletState.appletId;
        let dashboard;
        if (isUpdate) {
            // A pack was uninstalled due to a version update.
            dashboard = this.updateApplet(appletId);
        } else {
            // A pack was uninstalled due to removal from the dashboard.
            dashboard = this.removeApplet(appletId);
            this.setupColumns(dashboard);
        }
        this.setState({ dashboard: dashboard });
        this.isDirty = true;
    }

    private updateLinkedInputs(configuration: Configuration, appletId: string): void {
        let dashboard = this.state.dashboard!
        const index = dashboard.appletStates.findIndex(state => state.appletId === appletId);
        if (index === -1) {
            return;
        }
        dashboard = update(dashboard, {
            appletStates: {
                [index]: {
                    configuration: { $set: configuration }
                }
            }
        });
        // Do not refresh the model that is part of the configuration. It gets
        // updated when the input value is set.
        // Update all other configurations that have links from it.
        const appletStates = dashboard.appletStates;
        const entries = Object.entries(configuration);
        for (const [id, value] of entries) {
            // Check if the spec has any links.
            const links = this.findLinks(appletId, id);
            if (links) {
                for (const link of links) {
                    const [targetAppletId, _, targetSpecKey] = WidgetUtils.parseKey(link);
                    const index = appletStates.findIndex(state => state.appletId === targetAppletId);
                    if (index !== -1) {
                        const targetConfiguration = appletStates[index].configuration;
                        targetConfiguration[targetSpecKey!] = configuration[id];
                        dashboard = update(dashboard, {
                            appletStates: {
                                [index]: {
                                    configuration: { $set: targetConfiguration }
                                }
                            }
                        });
                        const appletState = this.findAppletState(dashboard, targetAppletId);
                        if (appletState) {
                            if (!appletStates.includes(appletState)) {
                                appletStates.push(appletState);
                            }
                            this.refreshModel(appletState);
                        }
                    }
                }
            }
        }
        this.setState({ dashboard: dashboard });
        this.isDirty = true;
    }

    private removeApplet(appletId: string): Dashboard {
        const calculator = this.findCalculator(appletId);
        if (calculator) {
            calculator.close();
        }
        let dashboard = this.state.dashboard!;
        this.removeFromVariableMap(appletId);
        dashboard = this.removeAppletState(dashboard, appletId);
        this.buildLinkMap(dashboard.inputLinks);
        this.removeFromItemMap(appletId);
        return dashboard;
    }

    private updateApplet(appletId: string): Dashboard {
        let dashboard = this.state.dashboard!;
        const appletStates = dashboard.appletStates;
        let index = appletStates.findIndex(state => state.appletId === appletId);
        if (index === -1) {
            return dashboard;
        }
        // Add the current version to the version history to mark it as up-to-date.
        const appletState = appletStates[index];
        const version = appletState.packInfo.version;
        dashboard = update(dashboard, {
            appletStates: {
                [index]: {
                    versionHistory: { $push: [version] }
                }
            }
        });
        return dashboard;
    }

    private updateLinks(dashboard: Dashboard, inputLinks: InputLink[]): void {
        dashboard = this.setupInputLinks(dashboard, inputLinks);
        this.buildLinkMap(inputLinks);
        dashboard = update(dashboard, {
            inputLinks: { $set: inputLinks }
        });
        this.setState({ dashboard: dashboard });
        this.refreshModels(dashboard);
    }

    private addProperties(dashboard: Dashboard): void {
        this.setState({ dashboard: dashboard });
    }

    private emptyColumnSetups(): AppletState[][] {
        let size = NUMBER_COLUMNS;
        const columnSetups = [];
        while (size--) {
            columnSetups.push([]);
        }
        return columnSetups;
    }

    private startAutoSave(): void {
        if (this.state.dashboard?.autoSave && !this.timerId) {
            this.timerId = setTimeout(() => this.updateDashboardRequest(), SAVE_INTERVAL);
        }
    }

    private stopAutoSave(): void {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    }

    private startMonitorExit(): void {
        if (!this.unblock) {
            this.unblock = this.props.history.block(this.handleExitCheck);
        }
    }

    private stopMonitorExit(): void {
        if (this.unblock) {
            this.unblock();
            this.unblock = undefined;
        }
    }

    private setupColumns(dashboard: Dashboard): void {
        // Add the applet refs into their respective columns.
        const columnSetups = this.emptyColumnSetups();
        const appletStates = dashboard.appletStates;
        for (const appletState of appletStates) {
            // Sanitize data.
            if (CoreUtils.isEmpty(appletState.col)) {
                appletState.col = 0;
            } else if (appletState.col > NUMBER_COLUMNS - 1) {
                appletState.col = NUMBER_COLUMNS - 1;
            }
            if (CoreUtils.isEmpty(appletState.row)) {
                appletState.row = 0;
            } else if (appletState.row > NUMBER_ROWS - 1) {
                appletState.col = NUMBER_ROWS - 1;
            }
            if (CoreUtils.isEmpty(appletState.span)) {
                appletState.span = 4;
            } else if (appletState.span > columnSetups.length) {
                appletState.span = columnSetups.length;
            }
            const col = appletState.col;
            columnSetups[col].push(appletState);
        }
        // Sort the columns by index.
        for (const columnRef of columnSetups) {
            columnRef.sort((a, b) => a.row - b.row);
        }
        // Shift refs that have the same coordinate.
        for (const columnRef of columnSetups) {
            this.shiftIdenticalLocations(columnRef);
        }
        // Find the maximum row index containing a ref in all the rows.
        let maxRow = 0;
        for (const columnRef of columnSetups) {
            for (const appletRef of columnRef) {
                if (appletRef.row > maxRow) {
                    maxRow = appletRef.row;
                }
            }
        }
        this.setState({
            columnSetups: columnSetups,
            maxRow: maxRow
        });
    }

    private shiftIdenticalLocations(columnSetups: AppletState[]): void {
        if (columnSetups.length === 0) {
            return;
        }
        let prev = columnSetups[0].row;
        for (let i = 1; i < columnSetups.length; i++) {
            if (columnSetups[i].row <= prev) {
                columnSetups[i].row = prev + 1;
            }
            prev = columnSetups[i].row;
        }
    }

    private refreshModels(dashboard: Dashboard): void {
        const setups = dashboard.appletStates;
        for (const setup of setups) {
            this.refreshModel(setup);
        }
    }

    private refreshModel(appletState: AppletState): void {
        const calculator = this.findCalculator(appletState.appletId);
        if (calculator) {
            const configuration = appletState.configuration;
            this.executeCalculator(calculator, configuration);
        }
    }

    private executeCalculator(calculator: Calculator, configuration: Configuration | undefined): void {
        // Suspend calculator updates while setting parameters.
        calculator.suspend();
        if (configuration) {
            this.overrideVariables(configuration, calculator);
        }
        // Unsuspend and execute with the new parameter values.
        calculator.execute();
    }

    private overrideVariables(configuration: Configuration, calculator: Calculator): void {
        const variables = calculator.variables;
        for (const [key, value] of Object.entries(configuration)) {
            const variable = variables.get(key, false);
            if (variable && variable.cell) {
                const cell = variable.cell;
                // Sanity check, cannot overwrite formula.
                if (!CoreUtils.isFormula(value)) {
                    cell.value = value;
                }
            }
        }
    }

    private initDashboardRequest(): Promise<any> {
        const dashboardId = this.props.match.params.dashboardId;
        if (dashboardId === "create") {
            return this.createDashboardRequest();
        } else {
            return this.readDashboardRequest(dashboardId);
        }
    }

    private createDashboardRequest(): Promise<any> {
        const request = {
            name: "New Dashboard"
        };
        return modelService.createDashboard(request,
            (response: any) => this.createDashboardResponse(response),
            undefined, true
        );
    }

    private createDashboardResponse(response: any): void {
        const dashboard = response.data.dashboard;
        this.setState({ dashboard: dashboard });
        // Change the URL to include the new dashboard id.
        this.props.history.push(RouteBuilder.dashboard(dashboard.id));
    }

    private updateDashboardRequest(isTimer: boolean = true, isExiting: boolean = false): Promise<any> {
        if (this.state.isSaving) {
            return Promise.resolve();
        }
        if (!this.isDirty && !isExiting && isTimer) {
            // Auto-save and no changes, reset the timer.
            //console.log("Not dirty, restarting timer.");
            this.stopAutoSave();
            this.startAutoSave();
            return Promise.resolve();
        }
        // Stop the auto-save if enabled.
        this.stopAutoSave();
        this.setState({ isSaving: true });
        const dashboard = this.state.dashboard!;
        const request = {
            dashboardId: dashboard.id,
            name: dashboard.name,
            description: dashboard.description,
            autoSave: dashboard.autoSave,
            inputLinks: dashboard.inputLinks,
            appletStates: dashboard.appletStates
        };
        return modelService.updateDashboard(request,
            (response: any) => this.updateDashboardResponse(response, isExiting),
            (error: Error) => this.updateDashboardException(error, isExiting),
            true
        );
    }

    private updateDashboardResponse(response: any, isExiting: boolean): void {
        if (!isExiting) {
            let dashboard = response.data.dashboard;
            // Check if any applet versions were updated since the last
            // save. For each one, uninstall the previous pack version.
            this.isDirty = this.uninstallOldVersions(dashboard);
            this.setState({
                dashboard: dashboard,
                isSaving: false
            });
            // Restart the auto-save if enabled.
            this.startAutoSave();
        } else {
            // Save is complete, now exit.
            this.setState({ exitState: "finished" });
            this.props.history.push(this.nextPathname!);
        }
    }

    private uninstallOldVersions(dashboard: Dashboard): boolean {
        let isDirty = false;
        const appletStates = dashboard.appletStates;
        for (let i = 0; i < appletStates.length; i++) {
            const appletState = appletStates[i];
            const history = appletState.versionHistory ?? [];
            const version = appletState.packInfo.version;
            const latest = Math.max(...history);
            if (latest !== 0 && version > latest) {
                // Once the pack is uninstalled, add the version
                // to the history.
                this.uninstallPackRequest(appletState, true, latest);
            }
        }
        return isDirty;
    }

    private updateDashboardException(error: Error, isExiting: boolean): void {
        console.log(`Error saving dashboard: ${RestUtils.getErrorMessage(error)}`);
        if (isExiting) {
            if (this.state.dashboard?.autoSave) {
                // Auto-save failed, need to give the user a way to exit.
                // They can choose to try to save again or exit without saving.
                this.setState({ exitState: "failed" });
            } else {
                // Let the user try again.
                this.setState({ exitState: "init" });
            }
        }
        this.setState({ isSaving: false });
        this.startAutoSave();
        message.error("Error saving dashboard.");
    }

    private readDashboardRequest(dashboardId: string): Promise<any> {
        const request = {
            dashboardId: dashboardId,
        };
        return modelService.readDashboard(request,
            (response: any) => this.readDashboardResponse(response),
            undefined, true
        );
    }

    private readDashboardResponse(response: any): void {
        const dashboard = response.data.dashboard;
        this.setupColumns(dashboard);
        this.setState({ dashboard: dashboard });
    }

    private readAuthenticationHeadersRequest(): Promise<any> {
        const request = {
            //credentialsTypes: [CredentialsType.API, CredentialsType.FTP, CredentialsType.WEB]
        };
        return endpointService.readAuthenticationHeaders(request,
            (response: any) => this.readAuthenticationHeadersResponse(response),
            undefined, true
        );
    }

    private readAuthenticationHeadersResponse(response: any): void {
        const headers = response.data.headers;
        this.setState({ authentications: headers });
    }

    private addApplet(packHeader: PackHeader, dashboard: Dashboard): void {
        const details = packHeader.details as ApplicationDetails;
        const applicationId = details.applicationId;
        const item = this.findItem(applicationId);
        if (!item) {
            // Add a new applet to the dashboard.
            dashboard = this.addAppletState(dashboard, packHeader);
        } else {
            // Update an exiting applet to a new version.
            dashboard = this.updateAppletState(dashboard, packHeader);
        }
        this.setupColumns(dashboard);
        this.setState({ dashboard: dashboard });
        this.isDirty = true;
    }

    private setupMaps(dashboard: Dashboard, applet: Applet, calculator: Calculator): Dashboard {
        this.addToItemMap(applet, calculator);
        this.addToVariableMap(dashboard, applet.id);
        dashboard = this.setupInputLinks(dashboard, dashboard.inputLinks);
        this.buildLinkMap(dashboard.inputLinks);
        return dashboard;
    }

    private addToItemMap(applet: Applet, calculator: Calculator): void {
        this.itemMap[applet.id] = {
            applet: applet,
            calculator: calculator
        }
    }

    private addToVariableMap(dashboard: Dashboard, appletId: string): void {
        const appletState = dashboard.appletStates.find(appletState => appletState.appletId === appletId);
        if (!appletState) {
            return;
        }
        const item = this.itemMap[appletId];
        // Setup the map of all variables.
        // TODO: removed when all model setups have been removed
        if (!appletState.configuration) {
            appletState.configuration = {};
        }
        const applet = item.applet;
        const calculator = item.calculator;
        const variables = calculator.variables;
        variables.forEach(variable => {
            const key = WidgetUtils.toKey(applet.id, 0, variable.id);
            this.variableMap[key] = variable;
        });
    }

    private removeFromVariableMap(appletId: string): void {
        const calculator = this.findCalculator(appletId);
        if (!calculator) {
            return;
        }
        const variables = calculator.variables;
        variables.forEach(variable => {
            const key = WidgetUtils.toKey(appletId, 0, variable.id);
            delete this.variableMap[key];
        });
    }

    private buildLinkMap(inputLinks: InputLink[]): void {
        this.linkMap = {};
        for (const inputLink of inputLinks) {
            let inputKey = inputLink.inputKey;
            let linkedKeys = inputLink.linkedKeys;
            this.addLinkPermutation(inputKey, linkedKeys);
            for (let i = 0; i < linkedKeys.length; i++) {
                let swap = linkedKeys[i];
                linkedKeys[i] = inputKey;
                inputKey = swap;
                this.addLinkPermutation(inputKey, linkedKeys);
                swap = linkedKeys[i];
                linkedKeys[i] = inputKey;
                inputKey = swap;
            }
        }
    }

    private addLinkPermutation(inputKey: InputKey, linkedKeys: InputKey[]): void {
        const links = [];
        const key = WidgetUtils.toKey(inputKey.appletId, 0, inputKey.specKey);
        for (const linkedKey of linkedKeys) {
            const link = WidgetUtils.toKey(linkedKey.appletId, 0, linkedKey.specKey);
            links.push(link);
        }
        this.linkMap[key] = links;
    }

    private removeFromItemMap(appletId: string): void {
        delete this.itemMap[appletId];
    }

    private updateAppletState(dashboard: Dashboard, packHeader: PackHeader): Dashboard {
        const details = packHeader.details as AppletDetails;
        const modelId = details.modelId;
        const version = details.version;
        const applicationId = details.applicationId;
        const appletStates = dashboard.appletStates;
        const index = appletStates.findIndex(state => state.appletId === applicationId);
        if (index === -1) {
            return dashboard;
        }
        // TODO: remove if statement when all setups have packInfos
        let appletState = appletStates[index];
        dashboard = update(dashboard, {
            appletStates: {
                [index]: {
                    modelId: { $set: modelId },
                    version: { $set: version },
                    packInfo: {
                        version: { $set: packHeader.version },
                        name: { $set: packHeader.name },
                        publisher: { $set: packHeader.publisher },
                        admissions: { $set: packHeader.admissions },
                        accessType: { $set: packHeader.accessType },
                        installTime: { $set: Date.now() }
                    }
                }
            }
        });
        //
        appletState = dashboard.appletStates[index];
        dashboard = update(dashboard, {
            appletStates: {
                [index]: { $set: appletState }
            }
        });
        return dashboard;
    }

    private addAppletState(dashboard: Dashboard, packHeader: PackHeader): Dashboard {
        const details = packHeader.details as AppletDetails;
        const modelId = details.modelId;
        const version = details.version;
        const applicationId = details.applicationId;
        let appletState = this.findAppletState(dashboard, applicationId);
        if (!appletState) {
            // Find the smallest row/col to pack the dashboard
            const [row, col] = this.findOpenPosition(details.span);
            appletState = {
                modelId: modelId,
                version: version,
                appletId: applicationId,
                packInfo: {
                    packId: packHeader.id,
                    version: packHeader.version,
                    name: packHeader.name,
                    publisher: packHeader.publisher,
                    admissions: packHeader.admissions,
                    accessType: packHeader.accessType,
                    installTime: Date.now()
                },
                col: col,
                row: row,
                // Fill in the span when applet is downloaded.
                span: details.span,
                configuration: {},
                // An empty configuration means that the applet is being 
                // setup. Values will be synced when the applet is loaded.
                //configuration: undefined as any,
                autoUpdate: true,
                versionHistory: [packHeader.version]
            }
            // Trigger a new applet viewer to load the applet.
            dashboard = update(dashboard, {
                appletStates: {
                    $push: [appletState]
                }
            });
        }
        return dashboard;
    }

    private findOpenPosition(span: number): [number, number] {
        let row = 0;
        let col = 0;
        let anchor = 0;
        let isEnd;
        const columnSetups = this.state.columnSetups;
        do {
            isEnd = true;
            while (col < NUMBER_COLUMNS) {
                const columnSetup = columnSetups[col];
                const appletState = columnSetup[row];
                if (appletState) {
                    col += appletState.span;
                    anchor = col;
                    isEnd = false;
                } else {
                    col++;
                    if (span <= col - anchor) {
                        return [row, anchor];
                    }
                }
            }
            row++;
            col = 0;
            anchor = 0;
        } while (!isEnd);
        return [row - 1, 0];
    }

    private removeAppletState(dashboard: Dashboard, appletId: string): Dashboard {
        let appletStates = dashboard.appletStates;
        // Remove the widget from the dashboard.
        let index = appletStates.findIndex(state => state.appletId === appletId);
        if (index === -1) {
            return dashboard;
        }
        dashboard = update(dashboard, {
            appletStates: {
                $splice: [[index, 1]]
            }
        });
        return dashboard;
    }

    private findItem(appletId: string): AppletInfo {
        return this.itemMap[appletId];
    }

    private findApplet(appletId: string): Applet {
        const item = this.findItem(appletId);
        return item.applet;
    }

    private findCalculator(appletId: string): Calculator | undefined {
        const item = this.findItem(appletId);
        return item ? item.calculator : undefined;
    }

    private findLinks(appletId: string, specKey: string): string[] {
        const key = WidgetUtils.toKey(appletId, 0, specKey);
        return this.linkMap[key];
    }

    private findAppletState(dashboard: Dashboard, appletId: string): AppletState | undefined {
        const appletStates = dashboard.appletStates;
        return appletStates.find(setup => setup.appletId === appletId);
    }

    private findAppletStateIndex(dashboard: Dashboard, appletId: string): number {
        return dashboard.appletStates.findIndex(state => state.appletId === appletId);
    }

    private setupInputLinks(dashboard: Dashboard, inputLinks: InputLink[]): Dashboard {
        dashboard = this.copyLinkedVariables(dashboard, inputLinks);
        return dashboard;
    }

    private findVariable(inputKey: InputKey): Variable {
        const key = WidgetUtils.toKey(inputKey.appletId, 0, inputKey.specKey);
        return this.variableMap[key];
    }

    private copyLinkedVariables(dashboard: Dashboard, inputLinks: InputLink[]): Dashboard {
        for (const inputLink of inputLinks) {
            const inputKey = inputLink.inputKey;
            const appletState = this.findAppletState(dashboard, inputKey.appletId);
            const sourceConfiguration = appletState?.configuration;
            const sourceKey = inputKey.specKey;
            // Find the specs that will be linked from the source.
            // Those specs need to be removed so that the use is
            // not able to set them anymore. Values will come from
            // the "from" link.
            const linkedKeys = inputLink.linkedKeys;
            for (const linkedKey of linkedKeys) {
                let index = dashboard.appletStates.findIndex(state => state.appletId === linkedKey.appletId);
                if (index === -1) {
                    continue;
                }
                const appletState = dashboard.appletStates[index];
                const targetConfiguration = appletState?.configuration;
                const targetKey = linkedKey.specKey;
                if (!sourceConfiguration || !targetConfiguration) {
                    continue;
                }
                // Copy the configuration value.
                dashboard = update(dashboard, {
                    appletStates: {
                        [index]: {
                            configuration: {
                                [targetKey]: { $set: sourceConfiguration[sourceKey] }
                            }
                        }
                    }
                });
            }
        }
        return dashboard;
    }

    private readPackHeaderRequest(packInfo: PackInfo): Promise<any> {
        const request = {
            packId: packInfo.packId,
            accessType: packInfo.accessType
        }
        return libraryService.readPackHeader(request,
            (response: any) => this.readPackHeaderResponse(response, packInfo),
            undefined, true
        );
    }

    private readPackHeaderResponse(response: any, packInfo: PackInfo): void {
        const header = response.data.header;
        if (packInfo && header && header.version > packInfo.version) {
            this.installPackRequest(header);
        } else {
            this.setState({ message: undefined });
        }
    }

    private installPackRequest(packHeader: PackHeader): Promise<any> {
        const message = `Installing v${packHeader.version}...`;
        this.setState({ message: message });
        const request = {
            packId: packHeader.id,
            version: packHeader.version,
            instanceId: this.state.dashboard!.id,
            accessType: packHeader.accessType
        }
        return libraryService.installPack(request,
            (response: any) => this.installPackResponse(response),
            (response: any) => this.installPackException(response),
            true
        );
    }

    private installPackResponse(response: any): void {
        const header = response.data.header;
        const details = header.details as AppletDetails;
        const appletStates = this.state.dashboard!.appletStates;
        const index = appletStates.findIndex(state => state.appletId === details.applicationId);
        if (index === -1) {
            return;
        }
        const dashboard = update(this.state.dashboard, {
            appletStates: {
                [index]: {
                    packInfo: {
                        version: { $set: header.version },
                        name: { $set: header.name },
                        publisher: { $set: header.publisher },
                        admissions: { $set: header.admissions },
                        accessType: { $set: header.accessType },
                        installTime: { $set: Date.now() }
                    },
                    version: { $set: details.version },
                    span: { $set: details.span }
                }
            }
        });
        this.setState({
            dashboard: dashboard,
            message: undefined
        });
    }

    private installPackException(response: any): void {
        // If update to new pack version fails, skip and try on next open of applet.
        const error = RestUtils.getError(response);
        console.error(error.message);
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.initDashboardRequest());
        requests.push(this.readAuthenticationHeadersRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1, r2) => {
            if (RestUtils.isOk(r1, r2)) {
                // Start monitoring URL changes to watch for exit.
                this.startMonitorExit();
                this.startAutoSave();
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    private buildLoadingView(isLoading: boolean): ReactElement {
        return (
            <LoadSpinner
                className="x-dashboarditem-loading"
                loadingMessage="Loading dashboard..."
                failedMessage="Failed to load dashboard."
                status={isLoading ? "loading" : "failed"}
                onRetry={this.handleRetryLoad}
            />
        )
    }

    private buildAppletView(appletState: AppletState): ReactElement {
        const environmentType = this.context.user?.environmentType;
        const items: ItemSpec[] = [{
            icon: <SyncOutlined />,
            label: "Refresh",
            onSelect: (appletState: AppletState) => this.handleAppletRefresh(appletState)
        }, {
            icon: <DownloadOutlined />,
            label: "Update",
            onSelect: (appletState: AppletState) => this.handleUpdateCheck(appletState)
        }, {
            icon: <DragOutlined />,
            label: "Position...",
            onSelect: (appletState: AppletState) => this.handlePositionEdit(appletState)
        }, {
            icon: <CloudSyncOutlined />,
            label: "Auto Update...",
            onSelect: (appletState: AppletState) => this.handleAutoEdit(appletState)
        }, {
            icon: <DeleteOutlined />,
            label: "Uninstall",
            confirm: "Are you sure you want to uninstall the applet?",
            onSelect: (appletState: AppletState) => this.handleAppletUninstall(appletState)
        }, {
            icon: <ExperimentOutlined />,
            label: "Development",
            children: [{
                label: "Use snapshot",
                disabled: (appletState: AppletState) => appletState.packInfo.version === 0,
                onSelect: (appletState: AppletState) => this.handleSnapshotSelect(appletState)
            }],
            hidden: (appletState: AppletState) => environmentType !== EnvironmentType.DEVELOPMENT
        }, {
            icon: <InfoCircleOutlined />,
            label: "About...",
            onSelect: (appletState: AppletState) => this.handleAboutShow(appletState)
        }];
        const extra = (
            <Spacer>
                <span>{this.state.message}</span>
                <MenuButton
                    items={items}
                    data={appletState}
                    size={Globals.APPLET_MENU_SIZE}
                    shape={Globals.APPLET_MENU_SHAPE}
                />
            </Spacer>
        );
        const appletRef = {
            appletId: appletState.appletId,
            modelId: appletState.modelId,
            version: appletState.version
        }
        return (
            <AppletViewer
                key={appletState.appletId}
                instanceId={this.state.dashboard!.id}
                appletRef={appletRef}
                extra={extra}
                configuration={appletState.configuration}
                admissions={appletState.packInfo?.admissions}
                authentications={this.state.authentications}
                onLoad={this.handleAppletLoad}
                onChange={this.handleConfigurationChange}
                onAuthentication={this.handleAuthenticationAdd}
            />
        )
    }

    private buildViewItems(appletStates: AppletState[]): ViewItem[] {
        return appletStates.map(appletState => this.buildViewItem(appletState));
    }

    private buildViewItem(appletState: AppletState): ViewItem {
        const element = this.buildAppletView(appletState);
        const viewItem = {
            row: appletState.row,
            col: appletState.col,
            span: appletState.span,
            element: element
        }
        return viewItem;
    }

    private buildDashboardView(): ReactElement {
        const appletStates = this.state.dashboard?.appletStates;
        return (
            <>
                <DashboardHeading
                    dashboard={this.state.dashboard!}
                    variableMap={this.variableMap}
                    itemMap={this.itemMap}
                    isSaving={this.state.isSaving}
                    onAdd={this.handleAppletsShow}
                    onSave={this.handleDashboardSave}
                    onRefresh={this.handleAppletsRefresh}
                    onChange={this.handleDashboardChange}
                />
                <div className="x-dashboarditem-applets">
                    {(!appletStates || appletStates.length === 0) &&
                        <NoData 
                            className="x-dashboarditem-empty"
                            text="No applets."
                        />
                    }
                    {appletStates && appletStates.length > 0 &&
                        <ItemLayout items={this.buildViewItems(appletStates)} />
                    }
                </div>
                {this.state.modalType === "packs" &&
                    <AppletPacks
                        dashboard={this.state.dashboard!}
                        onInstall={this.handleAppletInstall}
                        onClose={this.handleAppletsClose}
                    />
                }
                {this.state.modalType === "auto" &&
                    <AutoUpdater
                        appletState={this.state.appletState!}
                        onChange={this.handleAutoChange}
                        onCancel={this.handleAutoCancel}
                    />
                }
                {this.state.modalType === "position" &&
                    <ItemPosition
                        type="applet"
                        rows={Math.max(Globals.LAYOUT_ROWS, this.state.maxRow + 1)}
                        cols={Globals.LAYOUT_COLUMNS}
                        items={this.state.dashboard!.appletStates}
                        item={this.state.appletState!}
                        onChange={this.handlePositionChange}
                        onCancel={this.handlePositionCancel}
                    />
                }
                {this.state.modalType === "about" &&
                    <AppletInfo
                        packInfo={this.state.packInfo!}
                        onClose={this.handleAboutClose}
                    />
                }
                {(this.state.exitState === "failed" || this.state.exitState === "started") &&
                    <Modal
                        centered
                        title="Save Dashboard"
                        visible={true}
                        width={Globals.DIALOG_WIDTH}
                        onCancel={this.handleExitCancel}
                        footer={(
                            <>
                                <Button onClick={this.handleExitNoSave}>No</Button>
                                <Button type="primary" onClick={this.handleExitWithSave}>Yes</Button>
                            </>
                        )}
                    >
                        <span>
                            Do you want to save the dashboard before exiting?
                        </span>
                    </Modal>
                }
            </>
        )
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public componentWillUnmount(): void {
        // Stop auto-save.
        this.stopAutoSave();
        // Close calculators.
        const items = Object.values(this.itemMap);
        for (const item of items) {
            const calculator = item.calculator;
            calculator.close();
        }
        // Stop monitoring URL changes.
        this.stopMonitorExit();
    }

    public render(): ReactElement {
        let view;
        if (this.state.status === StatusType.LOADING) {
            view = this.buildLoadingView(true);
        } else if (this.state.status === StatusType.FAILED) {
            view = this.buildLoadingView(false);
        } else if (this.state.status === StatusType.READY) {
            view = this.buildDashboardView();
        }
        return (
            <div id="dashboarditem" className="x-dashboarditem">
                {view}
            </div>
        )
    }

}
