import { PolarData } from '../ChartWidgetViewer';
import { DonutChartWidgetConfiguration } from '@methodset/model-client-ts';
import { ApexChartUtils } from '../ApexChartsUtils';
import ReactApexChart from 'react-apexcharts';
import classNames from 'classnames';
import './DonutChartView.less';

export type DonutChartViewProps = {
    className?: string,
    data: PolarData,
    configuration: DonutChartWidgetConfiguration
};

export const DonutChartView = (props: DonutChartViewProps) => {

    const series = (): number[] => {
        const series = props.data.series;
        return series.data.map(entry => entry.y);
    }

    const options = (): ApexCharts.ApexOptions => {
        const options = ApexChartUtils.polarOptions("donut", props.data, props.configuration);
        return options;
    }

    return (
        <div className={classNames('x-donutview', props.className)}>
            <ReactApexChart
                options={options()}
                series={series()}
                type="donut"
                height={props.configuration.height}
            />
        </div>
    )
}
