import React, { Component, ReactElement } from 'react';
import { DatePicker, FormInstance, TimePicker } from 'antd';
import { DaysOfWeekSelector } from 'components/DaysOfWeekSelector/DaysOfWeekSelector';
import { DaysOfMonthSelector } from 'components/DaysOfMonthSelector/DaysOfMonthSelector';
import { FormItem } from 'components/FormItem/FormItem';
import { MonthPeriod, MonthsSelector } from 'components/MonthsSelector/MonthsSelector';
import { TimezoneSelector } from 'components/TimezoneSelector/TimezoneSelector';
import { Constants } from 'components/Constants';
import { Cron, FrequencyType, TimePoint } from '@methodset/schedule-client-ts';
import { CoreUtils } from 'utils/CoreUtils';
import { ExcludeField } from '../ScheduleEditor';
import moment from 'moment';
import update from 'immutability-helper';
import './PointEditor.less';

export type PointPeriod = FrequencyType.YEAR | FrequencyType.BIANNUAL | FrequencyType.QUARTER | FrequencyType.MONTH | FrequencyType.DAY;
export type ChangeCallback = (cron: Cron) => void;

export type PointEditorProps = typeof PointEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    cron?: Cron,
    layout: any,
    period: PointPeriod,
    excludes?: ExcludeField[],
    onChange: ChangeCallback
}

export class PointEditor extends Component<PointEditorProps> {

    static defaultProps = {
        cron: {
            //frequencyType: FrequencyType.DAY,
            frequencyType: undefined as any,
            months: [] as number[],
            daysOfMonth: [] as number[],
            daysOfWeek: [] as number[],
            timePoint: {
                time: undefined as any,
            } as TimePoint,
            timeZone: CoreUtils.systemTimeZone()
        } as Cron,
        excludes: [] as ExcludeField[]
    }

    constructor(props: PointEditorProps) {
        super(props);
        this.handleMonthsChange = this.handleMonthsChange.bind(this);
        this.handleDaysOfMonthChange = this.handleDaysOfMonthChange.bind(this);
        this.handleDaysOfWeekChange = this.handleDaysOfWeekChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
        this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
        this.handleTimeZoneChange = this.handleTimeZoneChange.bind(this);
    }

    private handleMonthsChange(months: number[]): void {
        const cron = update(this.props.cron, {
            months: { $set: months }
        });
        this.props.onChange(cron);
    }

    private handleDaysOfMonthChange(daysOfMonth: number[]): void {
        const cron = update(this.props.cron, {
            daysOfMonth: { $set: daysOfMonth }
        });
        this.props.onChange(cron);
    }

    private handleDaysOfWeekChange(daysOfWeek: number[]): void {
        const cron = update(this.props.cron, {
            daysOfWeek: { $set: daysOfWeek }
        });
        this.props.onChange(cron);
    }

    private handleTimeChange(time: moment.Moment | null): void {
        const cron = update(this.props.cron, {
            timePoint: {
                time: { $set: CoreUtils.toStringTime(time) }
            }
        });
        this.props.onChange(cron);
    }

    private handleStartTimeChange(startDate: moment.Moment | null): void {
        const cron = update(this.props.cron, {
            startDate: { $set: CoreUtils.toStringTime(startDate, Constants.DATE_TIME_LOCAL_ISO_FORMAT) }
        });
        this.props.onChange(cron);
    }

    private handleEndTimeChange(endDate: moment.Moment | null): void {
        const cron = update(this.props.cron, {
            endDate: { $set: CoreUtils.toStringTime(endDate, Constants.DATE_TIME_LOCAL_ISO_FORMAT) }
        });
        this.props.onChange(cron);
    }

    private handleTimeZoneChange(timeZone: string): void {
        const cron = update(this.props.cron, {
            timeZone: { $set: timeZone }
        });
        this.props.onChange(cron);
    }

    private hasMonths(): boolean {
        return this.props.period === FrequencyType.YEAR ||
            this.props.period === FrequencyType.BIANNUAL ||
            this.props.period === FrequencyType.QUARTER;
    }

    private hasDaysOfMonth(): boolean {
        return this.props.period === FrequencyType.YEAR ||
            this.props.period === FrequencyType.BIANNUAL ||
            this.props.period === FrequencyType.QUARTER ||
            this.props.period === FrequencyType.MONTH;
    }

    private hasDaysOfWeek(): boolean {
        return this.props.period === FrequencyType.DAY;
    }

    public componentDidMount(): void {
        if (this.props.cron === PointEditor.defaultProps.cron) {
            // Update if cron set with defaults.
            this.props.onChange(this.props.cron);
        }
    }

    public shouldComponentUpdate(nextProps: PointEditorProps): boolean {
        if (nextProps.period !== this.props.cron.frequencyType) {
            // Set default if frequency changes externally.
            let cron = PointEditor.defaultProps.cron;
            cron = update(cron, {
                frequencyType: { $set: nextProps.period }
            });
            if (cron.frequencyType !== FrequencyType.DAY) {
                cron.daysOfMonth!.push(1);
            }
            this.props.onChange(cron);
        }
        return true;
    }

    public render(): ReactElement {
        return (
            <div className="x-pointeditor">
                {this.hasMonths() &&
                    <FormItem
                        {...this.props.layout}
                        formRef={this.props.formRef}
                        label="Months"
                        name="months"
                        info="One or more months."
                        rules={[{
                            required: true,
                            message: 'Please enter one or more months.'
                        }]}
                    >
                        <MonthsSelector
                            period={this.props.period as MonthPeriod}
                            value={this.props.cron.months}
                            onChange={this.handleMonthsChange}
                        />
                    </FormItem>
                }
                {this.hasDaysOfMonth() &&
                    <FormItem
                        {...this.props.layout}
                        formRef={this.props.formRef}
                        label="Day of Month"
                        name="daysofmonth"
                        info="The days of the month."
                        rules={[{
                            required: true,
                            message: 'Please enter a day of the month.'
                        }]}
                    >
                        <DaysOfMonthSelector
                            value={this.props.cron.daysOfMonth}
                            onChange={this.handleDaysOfMonthChange}
                        />
                    </FormItem>
                }
                {this.hasDaysOfWeek() &&
                    <FormItem
                        {...this.props.layout}
                        formRef={this.props.formRef}
                        label="Days of Week"
                        name="daysofweek"
                        info="The days of the week."
                        rules={[{
                            required: true,
                            message: 'Please enter the days of week.'
                        }]}
                    >
                        <DaysOfWeekSelector
                            value={this.props.cron.daysOfWeek}
                            onChange={this.handleDaysOfWeekChange}
                        />
                    </FormItem>
                }
                <FormItem
                    {...this.props.layout}
                    formRef={this.props.formRef}
                    label="Time of Day"
                    name="time"
                    info="The time of day."
                    rules={[{
                        required: true,
                        message: 'Please enter a time of day.'
                    }]}
                >
                    <TimePicker
                        className="x-pointeditor-time"
                        use12Hours
                        format={Constants.TIME_DISPLAY_FORMAT}
                        value={CoreUtils.toMomentTime(this.props.cron.timePoint!.time)}
                        onChange={this.handleTimeChange}
                    />
                </FormItem>
                {!this.props.excludes.includes("time_zone") &&
                    <FormItem
                        {...this.props.layout}
                        formRef={this.props.formRef}
                        label="Time Zone"
                        name="timezone"
                        info="The time zone."
                        rules={[{
                            required: true,
                            message: 'Please enter a time zone.'
                        }]}
                    >
                        <TimezoneSelector
                            value={this.props.cron.timeZone}
                            onChange={this.handleTimeZoneChange}
                        />
                    </FormItem>
                }
                {!this.props.excludes.includes("start_time") &&
                    <FormItem
                        {...this.props.layout}
                        formRef={this.props.formRef}
                        label="Start Date"
                        name="startdate"
                        info="The date when the schedule will start. Leave empty to start immediately."
                    >
                        <DatePicker
                            className="x-pointeditor-time"
                            use12Hours
                            showTime
                            placeholder="Select a date."
                            format={Constants.DATE_TIME_DISPLAY_FORMAT}
                            value={CoreUtils.toMomentTime(this.props.cron.startDate, Constants.DATE_TIME_LOCAL_ISO_FORMAT)}
                            onChange={this.handleStartTimeChange}
                        />
                    </FormItem>
                }
                {!this.props.excludes.includes("end_time") &&
                    <FormItem
                        {...this.props.layout}
                        formRef={this.props.formRef}
                        label="End Date"
                        name="enddate"
                        info="The date when the schedule will end. Leave empty to run forever."
                    >
                        <DatePicker
                            className="x-pointeditor-time"
                            use12Hours
                            showTime
                            placeholder="Select a date."
                            format={Constants.DATE_TIME_DISPLAY_FORMAT}
                            value={CoreUtils.toMomentTime(this.props.cron.endDate, Constants.DATE_TIME_LOCAL_ISO_FORMAT)}
                            onChange={this.handleEndTimeChange}
                        />
                    </FormItem>
                }
            </div>
        );
    }

}
