import { PureComponent, ReactElement } from 'react';
import { BookOutlined, DeleteOutlined, EditOutlined, SyncOutlined } from '@ant-design/icons';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Tag } from 'antd';
import { Globals } from 'constants/Globals';
import { ItemTable } from 'containers/Console/ItemTable/ItemTable';
import { RouteBuilder } from 'utils/RouteBuilder';
import { ColumnsType } from 'antd/lib/table';
import { CoreUtils } from 'utils/CoreUtils';
import { RestUtils } from 'utils/RestUtils';
import { ApplicationHeader, ApplicationType } from '@methodset/model-client-ts';
import { Spacer } from 'components/Spacer/Spacer';
import { EditorMode, VersionEditor } from '../VersionEditor/VersionEditor';
import { AppletDetails, ApplicationDetails, PackDetails, PackHeader, PackType } from '@methodset/library-client-ts';
import { StatusType } from 'constants/StatusType';
import { IdUtils } from '@methodset/commons-shared-ts';
import axios from 'axios';
import update from 'immutability-helper';
import modelService from 'services/ModelService';
import './Applications.less';

interface ProcessData {
    mode: EditorMode,
    header?: ApplicationHeader,
    error?: Error,
    isProcessing?: boolean
}

export type ApplicationsProps = RouteComponentProps & {
    className?: string
}

export type ApplicationsState = {
    status: StatusType,
    headers: ApplicationHeader[],
    process: ProcessData
}

export class Applications extends PureComponent<ApplicationsProps, ApplicationsState> {

    private colorMap = CoreUtils.toColorMap(CoreUtils.enumToKeys(ApplicationType));

    constructor(props: ApplicationsProps) {
        super(props);
        this.state = {
            status: StatusType.INIT,
            headers: [],
            process: {
                mode: "none",
                isProcessing: false
            }
        };
        this.handleRetryLoad = this.handleRetryLoad.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleApplicationEdit = this.handleApplicationEdit.bind(this);
        this.handleApplicationDelete = this.handleApplicationDelete.bind(this);
        this.handleShowPackage = this.handleShowPackage.bind(this);
        this.handleVersionPackage = this.handleVersionPackage.bind(this);
        this.handleVersionCancel = this.handleVersionCancel.bind(this);
    }

    private handleRetryLoad(): void {
        this.loadData();
    }

    private handleRefreshClick(): void {
        this.loadData();
    }

    private handleApplicationEdit(header: ApplicationHeader): void {
        const url = RouteBuilder.application(header.modelId, header);
        this.props.history.push(url);
    }

    private handleApplicationDelete(header: ApplicationHeader): void {
        this.deleteApplicationRequest(header);
    }

    private handleShowPackage(header: ApplicationHeader): void {
        const process = update(this.state.process, {
            mode: { $set: "publish" },
            header: { $set: header }
        });
        this.setState({ process: process });
    }

    private handleVersionPackage(header: PackHeader): void {
        const response = {
            data: {
                header: header
            }
        }
        this.versionResponse(response);
    }

    private handleVersionCancel(): void {
        const process = update(this.state.process, {
            mode: { $set: "none" },
            header: { $set: undefined },
            isProcessing: { $set: false }
        });
        this.setState({ process: process });
    }

    private deleteApplicationRequest(application: ApplicationHeader): Promise<any> {
        const request = {
            applicationType: application.type,
            applicationId: application.id
        };
        return modelService.deleteApplication(request,
            (response: any) => this.deleteApplicationResponse(response)
        );
    }

    private deleteApplicationResponse(response: any): void {
        const applicationId = response.data.applicationId;
        const index = this.state.headers.findIndex(model => model.id === applicationId);
        if (index !== -1) {
            this.setState({
                headers: update(this.state.headers, {
                    $splice: [[index, 1]]
                })
            });
        }
    }

    private versionResponse(response: any): void {
        const header = response.data.header;
        const details = header.details as ApplicationDetails;
        const applicationId = details.applicationId;
        const index = this.state.headers.findIndex(application => application.id === applicationId);
        this.setState({
            headers: update(this.state.headers, {
                [index]: {
                    version: { $set: header.version },
                    updateTime: { $set: header.updateTime }
                }
            })
        });
        const process = update(this.state.process, {
            mode: { $set: "none" },
            header: { $set: undefined },
            isProcessing: { $set: false }
        });
        this.setState({ process: process });
    }

    private readApplicationHeadersRequest(): Promise<any> {
        const request = {};
        return modelService.readApplicationHeaders(request,
            (response: any) => this.readApplicationHeadersResponse(response),
            undefined, true
        );
    }

    private readApplicationHeadersResponse(response: any): void {
        const headers = response.data.headers;
        this.setState({ headers: headers });
    }

    private buildColumns(): ColumnsType<any> {
        let i = 0;
        const columns: ColumnsType<any> = [{
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            ellipsis: true,
            width: Globals.TABLE_WIDTH_LARGE,
            render: (value: string, header: ApplicationHeader) => (
                <Link to={RouteBuilder.application(header.modelId, header)}>{header.name}</Link>
            ),
            sorter: (a: any, b: any) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'description',
            title: 'Description',
            ellipsis: true,
            dataIndex: 'description',
            render: (value: string, header: ApplicationHeader) => {
                return (
                    <span>{header.description ? header.description : Globals.EMPTY_FIELD}</span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareStrings(a.name, b.name),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend'
        }, {
            key: 'type',
            title: 'Type',
            align: 'center',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (value: string, header: ApplicationHeader) => {
                return (
                    <Tag color={this.colorMap[header.type]}>{CoreUtils.toProper(header.type)}</Tag>
                );
            },
            sorter: (a, b) => CoreUtils.compareStrings(a.type, b.type),
            sortDirections: ['ascend', 'descend']
        }, {
            key: 'version',
            title: 'Latest Version',
            align: 'center',
            dataIndex: 'version',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (value: boolean, header: ApplicationHeader) => {
                return (
                    <span>
                        {CoreUtils.toVersion(header.version, "package")}
                    </span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareNumbers(a.version, b.version),
            sortDirections: ['descend', 'ascend']
        }, {
            key: 'utime',
            title: 'Last Updated',
            align: 'center',
            dataIndex: 'updateTime',
            width: Globals.TABLE_WIDTH_MEDIUM,
            render: (value: any, header: ApplicationHeader) => {
                return (
                    <span>
                        {CoreUtils.toUpdateTime(header.updateTime)}
                    </span>
                );
            },
            sorter: (a: any, b: any) => CoreUtils.compareStrings(a.updateTime, b.updateTime),
            sortDirections: ['descend', 'ascend']
        }];
        return columns;
    }

    private loadData(): void {
        const requests = [];
        requests.push(this.readApplicationHeadersRequest());
        this.setState({ status: StatusType.LOADING });
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                this.setState({ status: StatusType.READY });
            } else {
                this.setState({ status: StatusType.FAILED });
            }
        }));
    }

    private buildDetails(): PackDetails {
        const header = this.state.process.header!;
        const packDetails: any = {
            type: this.toPackType(header.type)!,
            applicationId: header.id,
            modelId: header.modelId,
            version: 0, // snapshot version
            categoryType: header.categoryType
        }
        // Add any app-specific detail fields.
        const details = header.details as any;
        if (details) {
            for (const [key, value] of Object.entries(details)) {
                packDetails[key] = value;
            }
        }
        return packDetails as PackDetails;
    }

    private toPackType(applicationType: ApplicationType | undefined): PackType | null {
        if (!applicationType) {
            return null;
        }
        switch (applicationType) {
            case ApplicationType.APPLET:
                return PackType.APPLET;
            case ApplicationType.ALERT:
                return PackType.ALERT;
            default:
                return null;
        }
    }

    public componentDidMount(): void {
        if (this.state.status !== StatusType.READY) {
            this.loadData();
        }
    }

    public render(): ReactElement {
        const actions = [{
            icon: <EditOutlined />,
            label: (header: ApplicationHeader) => `Edit ${header.type.toLowerCase()}`,
            callback: this.handleApplicationEdit
        }, {
            icon: <BookOutlined />,
            label: "Package version...",
            callback: this.handleShowPackage
        }, {
            icon: <DeleteOutlined />,
            label: (header: ApplicationHeader) => `Delete ${header.type.toLowerCase()}`,
            confirm: (header: ApplicationHeader) => `Are you sure you want to delete the ${header.type.toLowerCase()}?`,
            callback: this.handleApplicationDelete
        }];
        const columns = this.buildColumns();
        const data = this.state.headers;
        const process = this.state.process;
        const type = this.toPackType(process.header?.type);
        return (
            <>
                <ItemTable
                    title="Applications"
                    status={this.state.status}
                    columns={columns}
                    items={data}
                    extra={
                        <Spacer>
                            <Button icon={<SyncOutlined />} onClick={this.handleRefreshClick}></Button>
                        </Spacer>
                    }
                    actions={actions}
                    onLoad={this.handleRetryLoad}
                />
                {process.mode !== "none" && process.header && type &&
                    <VersionEditor
                        id={IdUtils.ensureAccessId(process.header.id)}
                        type={type}
                        name={process.header.name}
                        description={process.header.description}
                        details={this.buildDetails()}
                        mode={process.mode}
                        version={0}
                        error={process.error}
                        isProcessing={process.isProcessing}
                        onPackage={this.handleVersionPackage}
                        onCancel={this.handleVersionCancel}
                    />
                }
            </>
        );
    }

}
