import React, { PureComponent, ReactElement } from 'react';
import { Col, FormInstance, Row, Select } from 'antd';
import { Calculator } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { AppletPanel, ColumnWidgetConfiguration, JustificationType, SpacingType, Widget, WidgetType } from '@methodset/model-client-ts';
import { WidgetSelectors } from 'containers/Components/Widgets/RowWidgetViewer/RowWidgetEditor/WidgetSelectors/WidgetSelectors';
import { SpacingSelector } from 'containers/Components/Widgets/Selectors/SpacingSelector';
import { CoreUtils } from 'utils/CoreUtils';
import update from 'immutability-helper';
import './ColumnWidgetEditor.less';

export type ChangeCallback = (configuration: ColumnWidgetConfiguration) => void;

export type ColumnWidgetEditorProps = typeof ColumnWidgetEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    defaults: ReactElement,
    panel: AppletPanel,
    widget?: Widget,
    configuration?: ColumnWidgetConfiguration,
    calculator: Calculator,
    onChange: ChangeCallback
}

export class ColumnWidgetEditor extends PureComponent<ColumnWidgetEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.COLUMN,
        span: Globals.LAYOUT_COLUMNS,
        offset: 0,
        justification: JustificationType.LEFT,
        gap: SpacingType.MED,
        widgetIds: []
    } as ColumnWidgetConfiguration;

    static defaultProps = {
        configuration: ColumnWidgetEditor.DefaultConfiguration
    }

    constructor(props: ColumnWidgetEditorProps) {
        super(props);
        this.handleSpanChange = this.handleSpanChange.bind(this);
        this.handleOffsetChange = this.handleOffsetChange.bind(this);
        this.handleJustificationChange = this.handleJustificationChange.bind(this);
        this.handleGapChange = this.handleGapChange.bind(this);
        this.handleWidgetsChange = this.handleWidgetsChange.bind(this);
    }

    private handleSpanChange(span: number): void {
        const configuration = update(this.props.configuration, {
            span: { $set: span }
        });
        this.props.onChange(configuration);
    }

    private handleOffsetChange(offset: number): void {
        const configuration = update(this.props.configuration, {
            offset: { $set: offset }
        });
        this.props.onChange(configuration);
    }

    private handleJustificationChange(justification: JustificationType): void {
        const configuration = update(this.props.configuration, {
            justification: { $set: justification }
        });
        this.props.onChange(configuration);
    }

    private handleGapChange(gap: SpacingType): void {
        const configuration = update(this.props.configuration, {
            gap: { $set: gap }
        });
        this.props.onChange(configuration);
    }

    private handleWidgetsChange(widgetIds: string[]): void {
        const configuration = update(this.props.configuration, {
            widgetIds: { $set: widgetIds }
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        if (this.props.configuration === ColumnWidgetEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        return (
            <Row gutter={Globals.FORM_GUTTER_ROW}>
                <Col span={12}>
                    {this.props.defaults}
                </Col>
                <Col span={12}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Span"
                        name="span"
                        info={`The number of columns should span (1-${Globals.LAYOUT_COLUMNS}).`}
                        rules={[{
                            required: true,
                            message: 'Please select a column span.'
                        }]}
                    >
                        <Select
                            value={this.props.configuration.span}
                            onChange={this.handleSpanChange}
                        >
                            {Array.from(Array(Globals.LAYOUT_COLUMNS).keys()).map(index => (
                                <Select.Option key={index} value={index + 1}>{index + 1}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Offset"
                        name="offset"
                        info={`The number of columns to offset from the left (1-${Globals.LAYOUT_COLUMNS}).`}
                        rules={[{
                            required: true,
                            message: 'Please select a column offset.'
                        }]}
                    >
                        <Select
                            value={this.props.configuration.offset}
                            onChange={this.handleOffsetChange}
                        >
                            {Array.from(Array(Globals.LAYOUT_COLUMNS).keys()).map(index => (
                                <Select.Option key={index} value={index}>{index}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Justification"
                        name="type"
                        info={"The justification of the items in the column."}
                    >
                        <Select
                            placeholder="Select a justification."
                            allowClear={true}
                            value={this.props.configuration.justification}
                            onChange={this.handleJustificationChange}
                        >
                            {Object.entries(JustificationType).map(([key, value]) =>
                                <Select.Option value={value}>{CoreUtils.toProper(key)}</Select.Option>
                            )}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Gap"
                        name="gap"
                        info={"The spacing between the column items."}
                    >
                        <SpacingSelector
                            value={this.props.configuration.gap}
                            onChange={(gap) => this.handleGapChange(gap)}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Widgets"
                        name="widgets"
                        info="The widgets to include in the column."
                        valuePropName="widgetIds"
                        rules={[{
                            validator: () => {
                                for (const widgetId of this.props.configuration.widgetIds) {
                                    if (!widgetId) {
                                        return Promise.reject(`Please fill all widgets.`);
                                    }
                                }
                                return Promise.resolve();
                            }
                        }]}
                    >
                        <WidgetSelectors
                            panel={this.props.panel}
                            widget={this.props.widget}
                            widgetIds={this.props.configuration.widgetIds}
                            onChange={this.handleWidgetsChange}
                        />
                    </FormItem>
                </Col>
            </Row>
        );
    }

}
