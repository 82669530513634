import React, { PureComponent, ReactElement } from 'react';
import { FormInstance } from 'antd';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { WorkflowPicker } from './WorkflowPicker/WorkflowPicker';
import classNames from 'classnames';
import './WorkflowSelector.less';

export type ChangeCallback = (workflowId: string) => void;

export type WorkflowSelectorProps = typeof WorkflowSelector.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    className?: string,
    workflowId?: string,
    label?: string,
    description?: string,
    onChange: ChangeCallback
}

export class WorkflowSelector extends PureComponent<WorkflowSelectorProps> {

    static defaultProps = {
        label: "Workflow Source",
        description: "The workflow that generates the source data."
    }

    constructor(props: WorkflowSelectorProps) {
        super(props);
        this.state = {};
        this.handleWorkflowChange = this.handleWorkflowChange.bind(this);
    }

    private handleWorkflowChange(workflowId: string): void {
        this.props.onChange(workflowId);
    }

    public render(): ReactElement {
        return (
            <div className={classNames('x-workflowselector', this.props.className)}>
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={this.props.formRef}
                    label={this.props.label}
                    name="workflow"
                    info={this.props.description}
                    valuePropName="workflowId"
                    justification="center"
                    rules={[{
                        required: true,
                        message: `Please select a workflow.`
                    }]}
                >
                    <WorkflowPicker
                        workflowId={this.props.workflowId}
                        onChange={this.handleWorkflowChange}
                    />
                </FormItem>
            </div>
        );
    }

}
