import { ReactElement, useEffect, useState } from 'react';
import { FormInstance } from 'antd';
import { Globals } from 'constants/Globals';
import { RestUtils } from 'utils/RestUtils';
import { StatusType } from 'constants/StatusType';
import { Query, QueryHeader } from '@methodset/endpoint-client-ts';
import { FormItem } from 'components/FormItem/FormItem';
import { QueryVersionPicker } from 'containers/Console/ConfigurationEditor/QuerySelector/QueryVersionPicker/QueryVersionPicker';
import { LoadSpinner } from 'components/LoadSpinner/LoadSpinner';
import { QueryList } from './QueryList/QueryList';
import endpointService from 'services/EndpointService';
import axios from 'axios';
import './QuerySelector.less';

export type SelectCallback = (queryId: string | undefined, version: number | undefined) => void;
export type LoadCallback = (query: Query) => void;

export type QuerySelectorProps = {
    formRef: React.RefObject<FormInstance>,
    queryId?: string,
    version?: number,
    columns?: number,
    hideId?: boolean,
    onSelect: SelectCallback,
    onLoad: LoadCallback
}

export const QuerySelector = (props: QuerySelectorProps): ReactElement => {

    const [status, setStatus] = useState<string>(StatusType.INIT);

    useEffect(() => {
        loadData(props.queryId, props.version);
    }, []);

    const handleRetryLoad = (): void => {
        loadData(props.queryId, props.version);
    }

    const handleHeaderSelect = (header: QueryHeader): void => {
        props.onSelect(header.id, header.version);
        loadData(header.id, header.version);
    }

    const handleVersionChange = (version: number): void => {
        props.onSelect(props.queryId, version);
        loadData(props.queryId, version);
    }

    const readQueryRequest = (queryId: string | undefined, version: number | undefined): Promise<any> => {
        if (!queryId) {
            return Promise.resolve();
        }
        const request = {
            queryId: queryId,
            version: version
        };
        return endpointService.readQueryProcessor(request,
            (response: any) => readQueryResponse(response),
            undefined, true
        );
    }

    const readQueryResponse = (response: any): void => {
        const query = response.data.query;
        props.onLoad(query);
    }

    const buildLoadingView = (isLoading: boolean): ReactElement => {
        return (
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
            >
                <LoadSpinner
                    className="x-queryselector-loading"
                    status={isLoading ? "loading" : "failed"}
                    loadingMessage="Loading query..."
                    failedMessage="Failed to load query."
                    onRetry={handleRetryLoad}
                />
            </FormItem>
        );
    }

    const loadData = (queryId: string | undefined, version: number | undefined): void => {
        const requests = [];
        requests.push(readQueryRequest(queryId, version));
        setStatus(StatusType.LOADING);
        axios.all(requests).then(axios.spread((r1) => {
            if (RestUtils.isOk(r1)) {
                setStatus(StatusType.READY);
            } else {
                setStatus(StatusType.FAILED);
            }
        }));
    }

    let loadingView;
    if (status === StatusType.LOADING) {
        loadingView = buildLoadingView(true);
    } else if (status === StatusType.FAILED) {
        loadingView = buildLoadingView(false);
    } else {
        loadingView = <></>;
    }

    return (
        <div className="x-queryselector">
            {!props.hideId &&
                <FormItem
                    {...Globals.FORM_LAYOUT}
                    formRef={props.formRef}
                    label="Dataset"
                    name="dataset"
                    info="The dataset to query."
                    valuePropName="queryId"
                    rules={[{
                        required: true,
                        message: 'Please select a dataset.'
                    }]}
                >
                    <QueryList
                        formRef={props.formRef}
                        columns={props.columns}
                        queryId={props.queryId}
                        onSelect={handleHeaderSelect}
                    />
                </FormItem>
            }
            <FormItem
                {...Globals.FORM_LAYOUT}
                formRef={props.formRef}
                label="Version"
                name="version"
                info="The dataset version."
                valuePropName="version"
                rules={[{
                    required: true,
                    message: `Please select a dataset version.`
                }]}
            >
                <QueryVersionPicker
                    queryId={props.queryId}
                    version={props.version}
                    onChange={handleVersionChange}
                />
            </FormItem>
            {loadingView}
        </div>
    );

}
