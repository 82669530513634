import { ReactElement } from 'react';
import { CandleData } from '../ChartWidgetViewer';
import { CandlestickChartWidgetConfiguration } from '@methodset/model-client-ts';
import { ApexChartUtils } from '../ApexChartsUtils';
import ReactApexChart from 'react-apexcharts';
import classNames from 'classnames';
import './CandlestickChartView.less';

export type CandlestickChartViewProps = {
    className?: string,
    data: CandleData,
    configuration: CandlestickChartWidgetConfiguration
};

export const CandlestickChartView = (props: CandlestickChartViewProps): ReactElement => {

    const series = (): ApexAxisChartSeries => {
        return [props.data.series];
    }

    const options = (): ApexCharts.ApexOptions => {
        return ApexChartUtils.candleOptions("candlestick", props.data, props.configuration);
    }

    return (
        <div className={classNames('x-candlestickchartview', props.className)}>
            <ReactApexChart
                options={options()}
                series={series()}
                type="candlestick"
                height={props.configuration.height}
            />
        </div>
    )
}
