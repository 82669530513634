import { ReactElement, Ref, useRef, useState } from 'react';
import { FormInstance } from 'antd';
import { Address } from '@methodset/entity-client-ts';
import { ListEditor } from '../ListEditor/ListEditor';
import { AddressEditor } from '../AddressEditor/AddressEditor';
import './RealEstateEditor.less';

export type ChangeCallback = (addresses: Address[]) => void;

export type RealEstateEditorProps = {
    id: string,
    addresses?: Address[],
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    addresses: [] as Address[]
}

export const RealEstateEditor = (props: RealEstateEditorProps): ReactElement => {

    const [editAddress, setEditAddress] = useState<Address | undefined>();

    const formRef: Ref<FormInstance> = useRef(null);

    const handleAddressChange = (address: Address): void => {
        setEditAddress(address);
    }

    const handleAddressEdit = (address: Address): void => {
        setEditAddress(address);
    }

    const handleAddressesSave = (addresses: Address[]): void => {
        setEditAddress(undefined);
        props.onChange(addresses);
    }

    const renderItem = (address: Address): ReactElement => {
        return (
            <div className="x-realestateeditor-item">
                <div>{address.street1}</div>
                <div>{address.city}, {address.state} {address.zipCode}</div>
            </div>
        )
    }

    return (
        <ListEditor
            ref={formRef}
            id="real-estate"
            name="Address"
            items={props.addresses}
            editItem={editAddress}
            renderItem={renderItem}
            onEdit={(address) => handleAddressEdit(address)}
            onSave={(addresses) => handleAddressesSave(addresses)}
        >
            <AddressEditor
                formRef={formRef}
                id={props.id}
                address={editAddress}
                onChange={(address) => handleAddressChange(address)}
            />
        </ListEditor>
    )

}

RealEstateEditor.defaultProps = defaultProps;
