import { ReactElement } from 'react';
import { Divider, FormInstance } from 'antd';
import { Income, Person, PersonType, Spouse } from '@methodset/entity-client-ts';
import { PersonEditor } from '../PersonEditor/PersonEditor';
import { IncomesEditor } from '../IncomesEditor/IncomesEditor';
import update from 'immutability-helper';
import './SpouseEditor.less';

export type ChangeCallback = (spouse: Spouse) => void;

export type SpouseEditorProps = {
    formRef: React.RefObject<FormInstance>,
    id: string,
    spouse?: Spouse,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    spouse: {} as Spouse
}

export const SpouseEditor = (props: SpouseEditorProps): ReactElement => {

    const handlePersonChange = (person: Person): void => {
        const spouse = update(props.spouse, {
            $merge: person
        });
        props.onChange(spouse);
    }

    const handleIncomesChange = (incomes: Income[]): void => {
        const spouse = update(props.spouse, {
            incomes: { $set: incomes }
        });
        props.onChange(spouse);
    }

    return (
        <>
            <PersonEditor
                formRef={props.formRef}
                id={props.id}
                type={PersonType.SPOUSE}
                person={props.spouse}
                onChange={handlePersonChange}
            />
            <Divider className="x-spouseeditor-divider" />
            <IncomesEditor
                id={props.id}
                incomes={props.spouse.incomes}
                onChange={handleIncomesChange}
            />
        </>
    )
}

SpouseEditor.defaultProps = defaultProps;
