import React, { ReactElement, useState } from 'react';
import { AuthenticationHeader, Configuration, ConfigurationSpec, ConfigurationType, ConverterMap, Flow, FlowType, IoMap, Link, ListLoop, Loop, LoopFlow, LoopType, Processor, RangeLoop, RecordsLoop } from '@methodset/endpoint-client-ts';
import { Button, Col, Dropdown, FormInstance, Menu, Row, Select } from 'antd';
import { FormItem } from 'components/FormItem/FormItem';
import { Globals } from 'constants/Globals';
import { ChangeCallback, OutputComponents, State } from '../FlowEditor';
import { IoMapEditor } from '../IoMapEditor/IoMapEditor';
import { ProcessorLoader } from 'containers/Console/Processors/ProcessorLoader/ProcessorLoader';
import { ComponentMap } from '../../Flows';
import update from 'immutability-helper';
import './LoopEditor.less';
import { ListLoopEditor } from './ListLoopEditor/ListLoopEditor';
import { VariableSelector } from 'containers/Components/Widgets/Selectors/VariableSelector';
import { RangeLoopEditor } from './RangeLoopEditor/RangeLoopEditor';
import { RecordsLoopEditor } from './RecordsLoopEditor/RecordsLoopEditor';

//export type ChangeCallback = (loop: Loop) => void;

export type LoopEditorProps = {
    formRef: React.RefObject<FormInstance>,
    index: number,
    // Ids used as prevs.
    prevIds: string[],
    // Ids used as nexts.
    nextIds: string[],
    parentFlow?: Flow,
    flow: LoopFlow,
    states: State[],
    outputComponents: OutputComponents,
    componentMap: ComponentMap,
    configurationSpecs: ConfigurationSpec[],
    converterMap: ConverterMap,
    authentications: AuthenticationHeader[],
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
}

const newFlow = (): LoopFlow => {
    return {
        type: FlowType.LOOP,
        prevId: undefined,
        startLink: {
            ioMap: {} as IoMap
        } as Link,
        loop: {
            //type: LoopType.LIST
        } as Loop
    } as LoopFlow;
}

export const LoopEditor = (props: LoopEditorProps): ReactElement => {

    const [processor, setProcessor] = useState<Processor | null>(null);

    const handleStartChange = (componentId: string): void => {
        const flow = update(props.flow, {
            startLink: {
                id: { $set: componentId },
                ioMap: { $set: {} }
            }
        });
        props.onChange(flow);
        setProcessor(null);
    }

    const handleStartLinkChange = (link: Link): void => {
        const flow = update(props.flow, {
            startLink: { $set: link }
        });
        props.onChange(flow);
    }

    const handleTypeChange = (type: LoopType): void => {
        const flow = update(props.flow, {
            loop: {
                type: { $set: type }
            }
        });
        props.onChange(flow);
    }

    const handleVariableChange = (variable: string): void => {
        const flow = update(props.flow, {
            loop: {
                variable: { $set: variable }
            }
        });
        props.onChange(flow);
    }

    const handleLoopChange = (loop: Loop): void => {
        const flow = update(props.flow, {
            loop: { $set: loop }
        });
        props.onChange(flow);
    }

    const handleProcessorLoaded = (processor: Processor): void => {
        setProcessor(processor);
    }

    // Get the component for the starting processor.
    const component = props.componentMap[props.flow.startLink.id];

    return (
        <div className="x-loopeditor">
            <Row gutter={Globals.FORM_GUTTER_COL}>
                <Col span={12}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={props.formRef}
                        label="Iterator"
                        name="iterator"
                        info="The processor or flow to iterate over before moving to the next step."
                        rules={[{
                            required: true,
                            message: "Please select a processor or flow to iterate over."
                        }]}
                    >
                        <Select
                            placeholder="Select a processor or flow."
                            value={props.flow.startLink?.id}
                            onChange={(value) => handleStartChange(value)}
                        >
                            {props.states.map(state => (
                                <Select.Option
                                    key={state.id}
                                    value={state.id}
                                    disabled={props.nextIds.includes(state.id) || props.flow.id === state.id}
                                >
                                    {state.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    {props.flow.startLink.id &&
                        <FormItem
                            {...Globals.FORM_LAYOUT}
                            formRef={props.formRef}
                            hidden={!!processor}
                        >
                            <ProcessorLoader
                                processorId={component?.processor.id}
                                processorVersion={component?.processor.version}
                                onLoaded={handleProcessorLoaded}
                            />
                        </FormItem>
                    }
                </Col>
                <Col span={12}>
                    {!!props.flow.prevId && !!props.flow.startLink.id &&
                        <IoMapEditor
                            formRef={props.formRef}
                            visible={true}
                            parentFlow={props.parentFlow}
                            link={props.flow.startLink}
                            componentMap={props.componentMap}
                            converterMap={props.converterMap}
                            outputComponents={props.outputComponents}
                            onChange={(link) => handleStartLinkChange(link)}
                        />
                    }
                </Col>
                <Col span={12}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={props.formRef}
                        label="Variable"
                        name="variable"
                        info="The variable to update on each iteration of the loop."
                        rules={[{
                            required: true,
                            message: 'Please select a variable.'
                        }, {
                            validator: (rule: any, name: string) => {
                                const spec = props.configurationSpecs.find(spec => spec.key === props.flow.loop.variable);
                                if (spec && props.flow.loop.type === LoopType.OPTION && spec.type !== ConfigurationType.OPTION) {
                                    return Promise.reject(`Variable '${spec.name}' is not of type OPTION.`);
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        }]}
                    >
                        <Select
                            placeholder="Select a loop type."
                            allowClear={true}
                            value={props.flow.loop.variable}
                            onChange={handleVariableChange}
                        >
                            {props.configurationSpecs.map(spec => (
                                <Select.Option value={spec.key}>{spec.name}</Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={props.formRef}
                        label="Loop Type"
                        info="The source of the loop variable."
                        name="type"
                        rules={[{
                            required: true,
                            message: 'Please select a loop type.'
                        }]}
                    >
                        <Select
                            placeholder="Select a loop type."
                            allowClear={true}
                            value={props.flow.loop.type}
                            onChange={handleTypeChange}
                        >
                            <Select.Option value={LoopType.LIST}>List</Select.Option>
                            <Select.Option value={LoopType.OPTION}>Option</Select.Option>
                            <Select.Option value={LoopType.RANGE}>Range</Select.Option>
                            <Select.Option value={LoopType.RECORDS}>Records</Select.Option>
                        </Select>
                    </FormItem>
                    {props.flow.loop.type === LoopType.LIST &&
                        <ListLoopEditor
                            formRef={props.formRef}
                            loop={props.flow.loop as ListLoop}
                            onChange={handleLoopChange}
                        />
                    }
                    {props.flow.loop.type === LoopType.OPTION &&
                        <></>
                    }
                    {props.flow.loop.type === LoopType.RANGE &&
                        <RangeLoopEditor
                            formRef={props.formRef}
                            loop={props.flow.loop as RangeLoop}
                            onChange={handleLoopChange}
                        />
                    }
                    {props.flow.loop.type === LoopType.RECORDS &&
                        <RecordsLoopEditor
                            formRef={props.formRef}
                            loop={props.flow.loop as RecordsLoop}
                            onChange={handleLoopChange}
                        />
                    }
                </Col>
            </Row>
        </div>
    )
}

LoopEditor.defaultProps = defaultProps;
LoopEditor.newFlow = newFlow;
