import React, { PureComponent, ReactElement } from 'react';
import { Col, FormInstance, Row } from 'antd';
import { Calculator } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { SwitchWidgetConfiguration, SwitchOption, WidgetType, Applet } from '@methodset/model-client-ts';
import { VariableSelector } from 'containers/Components/Widgets/Selectors/VariableSelector';
import { SwitchOptions } from './SwitchOptions/SwitchOptions';
import update from 'immutability-helper';
import './SwitchWidgetEditor.less';

export type ChangeCallback = (configuration: SwitchWidgetConfiguration) => void;

export type SwitchWidgetEditorProps = typeof SwitchWidgetEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    defaults: ReactElement,
    applet: Applet,
    calculator: Calculator,
    configuration?: SwitchWidgetConfiguration,
    onChange: ChangeCallback
}

export class SwitchWidgetEditor extends PureComponent<SwitchWidgetEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.SWITCH,
        variableId: undefined as any,
        options: []
    } as SwitchWidgetConfiguration;

    static defaultProps = {
        configuration: SwitchWidgetEditor.DefaultConfiguration
    }

    constructor(props: SwitchWidgetEditorProps) {
        super(props);
        this.handleVariableChange = this.handleVariableChange.bind(this);
        this.handleOptionsChange = this.handleOptionsChange.bind(this);
    }

    private handleVariableChange(variableId: string): void {
        const configuration = update(this.props.configuration, {
            variableId: { $set: variableId }
        });
        this.props.onChange(configuration);
    }

    private handleOptionsChange(options: SwitchOption[]): void {
        const configuration = update(this.props.configuration, {
            options: { $set: options }
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        if (this.props.configuration === SwitchWidgetEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        return (
            <Row gutter={Globals.FORM_GUTTER_ROW}>
                <Col span={12}>
                    {this.props.defaults}
                </Col>
                <Col span={12}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Variable"
                        name="variable"
                        info="The variable to change when a selection is chosen."
                        valuePropName="variableId"
                        rules={[{
                            required: true,
                            message: 'Please select a variable.'
                        }]}
                    >
                        <VariableSelector
                            calculator={this.props.calculator}
                            variableId={this.props.configuration.variableId}
                            onChange={this.handleVariableChange}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Options"
                        name="options"
                        info="The options that map a value to a widget."
                        valuePropName="options"
                        rules={[{
                            required: true,
                            message: ''
                        }, {
                            validator: (rule: any, options: SwitchOption[]) => {
                                for (let i = 0; i < options.length; i++) {
                                    const option = options[i];
                                    if (!option.value || !option.panelId) {
                                        return Promise.reject('Please enter all option fields.');
                                    }
                                }
                                return Promise.resolve();
                            }
                        }]}
                    >
                        <SwitchOptions
                            applet={this.props.applet}
                            options={this.props.configuration.options}
                            onChange={this.handleOptionsChange}
                        />
                    </FormItem>
                </Col>
            </Row>
        );
    }

}
