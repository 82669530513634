import { Component, ReactElement } from 'react';
import { Col, Row } from 'antd';
import { Globals } from 'constants/Globals';
import { CoreUtils } from 'utils/CoreUtils';
import './ItemLayout.less';

// An item that will be layed out.
export interface Item {
    col: number;
    row: number;
    span: number
}

export interface ViewItem extends Item {
    element: ReactElement;
}

// A column definition. Each column is defined by the first
// widget ref (parent). Its width is determined by its starting 
// column index and the next widget ref in the same row.
interface ColDef {
    item: ViewItem;
    min: number;
    max: number;
}

// A value to use, combined with the version, to assign to component keys.
interface Key {
    value: number;
}

export type ItemLayoutProps = {
    className?: string,
    // The items to layout.
    items: ViewItem[],
}

// Track the next item in a column that needs processing.
interface RowIndex {
    index: number;
    column: number;
    item?: ViewItem;
}

type ViewItemMap = Map<number, ViewItem[]>;

export class ItemLayout extends Component<ItemLayoutProps> {

    private rowMap: ViewItemMap;
    private columnMap: ViewItemMap;

    constructor(props: ItemLayoutProps) {
        super(props);
        this.sanitizeItems(props.items);
        this.rowMap = this.buildRowMap(props.items);
        this.columnMap = this.buildColumnMap(props.items);
    }

    private buildRowIndices(columnMap: ViewItemMap): RowIndex[] {
        const rowIndices: RowIndex[] = [];
        for (let c = 0; c < Globals.LAYOUT_COLUMNS; c++) {
            rowIndices.push({
                index: -1,
                column: c
            });
        }
        columnMap.forEach((value, key) => {
            rowIndices[key].index = 0
        });
        return rowIndices;
    }

    private buildRowMap(items: ViewItem[]): ViewItemMap {
        const rowMap = new Map<number, ViewItem[]>();
        for (const item of items) {
            let row = rowMap.get(item.row);
            if (!row) {
                row = [];
                rowMap.set(item.row, row);
            }
            row.push(item);
        }
        this.sortRows(rowMap);
        return rowMap;
    }

    private sortRows(rowMap: ViewItemMap): void {
        rowMap.forEach((row) => {
            // Sort the columns by index.
            row.sort((a, b) => a.col - b.col);
        });
    }

    private buildColumnMap(items: ViewItem[]): ViewItemMap {
        const columnMap = new Map<number, ViewItem[]>();
        for (const item of items) {
            let column = columnMap.get(item.col);
            if (!column) {
                column = [];
                columnMap.set(item.col, column);
            }
            column.push(item);
        }
        this.sortColumns(columnMap);
        return columnMap;
    }

    private sortColumns(columnMap: ViewItemMap): void {
        columnMap.forEach((column) => {
            // Sort the columns by index.
            column.sort((a, b) => a.row - b.row);
        });
    }

    private sanitizeItems(items: ViewItem[]): void {
        for (const item of items) {
            if (CoreUtils.isEmpty(item.col)) {
                item.col = 0;
            } else if (item.col > Globals.LAYOUT_COLUMNS - 1) {
                item.col = Globals.LAYOUT_COLUMNS - 1;
            }
            if (CoreUtils.isEmpty(item.row)) {
                item.row = 0;
            } else if (item.row > Globals.LAYOUT_ROWS - 1) {
                item.col = Globals.LAYOUT_ROWS - 1;
            }
            if (CoreUtils.isEmpty(item.span)) {
                item.span = Globals.LAYOUT_COLUMNS;
            } else if (item.col + item.span > Globals.LAYOUT_COLUMNS) {
                item.span -= (item.col + item.span) - Globals.LAYOUT_COLUMNS;
            }
        }
    }

    private sortIndices(rowIndices: RowIndex[], min: number, max: number): RowIndex[] {
        // Collect the items that are in the range.
        const indices = [];
        for (let c = 0; c < rowIndices.length; c++) {
            const rowIndex = rowIndices[c].index;
            if (rowIndex === -1) {
                // Nothing left in the column.
                continue;
            }
            const col = rowIndices[c].column;
            if (col >= min && col <= max) {
                const items = this.columnMap.get(c);
                rowIndices[c].item = items![rowIndex];
                indices.push(rowIndices[c]);
            }
        }
        // Sort in ascending order by the row, i.e., which columns
        // need to be processed first because they have an item at
        // a lower row index.
        indices.sort((a, b) => {
            return a.item!.row - b.item!.row;
        });
        return indices;
    }

    private buildColDefs(rowIndices: RowIndex[], start: number, min: number, max: number): ColDef[] {
        // Find the columns that are to be added to the next row.
        const colDefs: ColDef[] = [];
        const indices = this.sortIndices(rowIndices, min, max);
        // Loop over columns in the range in order of increasing row index.
        for (let i = 0; i < indices.length; i++) {
            const c = indices[i].column;
            const viewItem = indices[i].item!;
            // Check if it fits into the current range.
            if (viewItem.col + viewItem.span - 1 <= max) {
                // Iterate over row gap to see if any rows from columns to left encroach.
                let isOverlap = false;
                // If this is the start of a new parent column (-1), set the start row
                // from which to check for column encroachment to the smallest row index
                // in the column range. 
                if (start === -1) {
                    start = viewItem.row;
                }
                // Check if item is not blocked by an item in another column.
                for (let r = start; r < viewItem.row; r++) {
                    const rowItems = this.rowMap.get(r);
                    if (!rowItems) {
                        // No items in this row.
                        continue;
                    }
                    // Check each item in the row to see if it overlaps this column item.
                    for (let j = 0; j < rowItems.length; j++) {
                        const rowItem = rowItems[j];
                        const viewStart = viewItem.col;
                        const viewEnd = viewItem.col + viewItem.span - 1;
                        const rowStart = rowItem.col;
                        const rowEnd = rowItem.col + rowItem.span - 1;
                        if ((viewStart >= rowStart && viewStart <= rowEnd) || (viewEnd >= rowStart && viewEnd <= rowEnd)) {
                            // A row item overlaps the current column.
                            isOverlap = true;
                            break;
                        }
                    }
                    if (isOverlap) {
                        break;
                    }
                }
                if (!isOverlap) {
                    // Found a view item to be added to the layout.
                    const colDef = {
                        item: viewItem,
                        min: c,
                        max: c + viewItem.span - 1
                    }
                    colDefs.push(colDef);
                }
            } else {
                // Item does not fit, anything after will not fit either.
                break;
            }
        }

        // Sort the column defs in column order.
        colDefs.sort((a, b) => a.min - b.min);
        // Extend the column max to any space up to the next column.
        let prevDef;
        for (let i = 0; i < colDefs.length; i++) {
            if (prevDef) {
                prevDef.max = colDefs[i].min - 1;
            }
            prevDef = colDefs[i];
        }
        if (prevDef) {
            prevDef.max = max;
        }
        return colDefs;
    }

    private layoutItems(key: Key, rowIndices: RowIndex[], start: number, min: number, max: number, shift: boolean = true): ReactElement[] {
        const rows: ReactElement[] = [];
        const width = max - min + 1;
        // Loop rows until no widgets left.
        let addedRow;
        do {
            addedRow = false;
            // Find the columns that are in the current row.
            const colDefs = this.buildColDefs(rowIndices, start, min, max);
            if (colDefs.length === 1) {
                if ((colDefs[0].min + colDefs[0].item.span - 1) <= max) {
                    // Widget span fits into the column, add as a row.
                    const item = colDefs[0].item;
                    // Calculate offset and span as a percent of the parent column width.
                    const span = item.span;
                    const offset = shift ? colDefs[0].min - min : 0;
                    const style = this.columnStyle(span, offset, width);
                    const row = (
                        <Row key={key.value++} style={{ marginBottom: Globals.APPLET_GUTTER_ROW }}>
                            <Col key={key.value++} style={style}>
                                {item.element}
                            </Col>
                        </Row>
                    );
                    rows.push(row);
                    addedRow = true;
                    shift = true;
                    // Update the row index for the column (next item to process in the column).
                    const rowIndex = rowIndices[item.col].index;
                    const columnItems = this.columnMap.get(item.col);
                    // Set the index to -1 when there are no more items to process in the column.
                    rowIndices[item.col].index = rowIndex < columnItems!.length - 1 ? rowIndex + 1 : -1;
                    // Set the start row range for the next iteration in the column.
                    // Iterate inside the column to add items to teh current column.
                    start = item.row + 1;
                } else {
                    // Widget span does not fit into the column, create a new row in the parent.
                    break;
                }
            } else if (colDefs.length > 1) {
                // More than one widget in row, create columns and process refs in each column.
                let isFirst = true;
                const row = (
                    <Row key={key.value++} gutter={[Globals.APPLET_GUTTER_COL, Globals.APPLET_GUTTER_ROW]}>
                        {colDefs.map(colDef => {
                            // Calculate offset and span as a percent of the parent column width.
                            const span = colDef.max - colDef.min + 1;
                            // The first column may need an offset if the ref starts in a column location > 0.
                            // After the first column is inserted, all others will be shifted due to the 
                            // width of the columns before and do not require an explicit offset. The child 
                            // columns should not be offset since it has been done here in the parent column.
                            const offset = isFirst ? colDef.min - min : 0;
                            const style = this.columnStyle(span, offset, width);
                            // Build nested rows and columns that are part of the parent column.
                            const layout = this.layoutItems(key, rowIndices, start, colDef.min, colDef.max, false);
                            if (layout.length > 0) {
                                // After the first column, subsequent columns are shifted by the previous column's 
                                // span and do not require offsets.
                                isFirst = false;
                                return (
                                    <Col key={key.value++} style={style}>
                                        {layout}
                                    </Col>
                                );
                            } else {
                                // No layout returned (span too large), skip column and create in the parent.
                                return (
                                    <></>
                                );
                            }
                        })}
                    </Row>
                );
                rows.push(row);
                addedRow = true;
                // Go to next row, need to calculate offsets in first column.
                shift = true;
                start = -1;
            }
            // Continue while there are more rows to process (there may be empty rows).
        } while (!this.isFinished(rowIndices) && addedRow);
        return rows;
    }

    private isFinished(rowIndices: RowIndex[]): boolean {
        for (let i = 0; i < rowIndices.length; i++) {
            if (rowIndices[i].index !== -1) {
                return false;
            }
        }
        return true;
    }

    private columnStyle(span: number, offset: number, width: number): any {
        const spanPct = `${(span / width) * 100.0}%`;
        const offsetPct = `${(offset / width) * 100.0}%`;
        const style = {
            display: "block",
            flex: `0 0 ${spanPct}`,
            maxWidth: spanPct,
            marginLeft: offsetPct
        }
        return style;
    }

    private buildLayoutView(): ReactElement | ReactElement[] {
        if (this.props.items.length === 0) {
            return <></>;
        }
        const key: Key = {
            value: 1
        }
        // Indices mark the current row index being processed for a given column.
        const rowIndices = this.buildRowIndices(this.columnMap);
        // Start building first row which spans all columns.
        return this.layoutItems(key, rowIndices, -1, 0, Globals.LAYOUT_COLUMNS - 1);
    };

    public shouldComponentUpdate(nextProps: Readonly<ItemLayoutProps>): boolean {
        if (this.props.items !== nextProps.items) {
            this.sanitizeItems(nextProps.items);
            this.rowMap = this.buildRowMap(nextProps.items);
            this.columnMap = this.buildColumnMap(nextProps.items);
            return true;
        } else {
            return false;
        }
    }

    public render(): ReactElement {
        return (
            <div className="x-itemlayout">
                {this.buildLayoutView()}
            </div>
        )
    }

}
