import { ChangeEvent, PureComponent, ReactElement } from 'react';
import { Button, Input } from 'antd';
import { Spacer } from 'components/Spacer/Spacer';
import { DeleteOutlined } from '@ant-design/icons';
import { SelectOption } from '@methodset/model-client-ts';
import update from 'immutability-helper';
import './SelectOptionEditor.less';

export type ChangeCallback = (option: SelectOption, index: number) => void;
export type RemoveCallback = (index: number) => void;

export type SelectOptionEditorProps = {
    index: number,
    option: SelectOption,
    onChange: ChangeCallback,
    onRemove: RemoveCallback
}

export class SelectOptionEditor extends PureComponent<SelectOptionEditorProps> {

    constructor(props: SelectOptionEditorProps) {
        super(props);
        this.handleLabelChange = this.handleLabelChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleOptionRemove = this.handleOptionRemove.bind(this);
    }

    private handleLabelChange(e: ChangeEvent<HTMLInputElement>): void {
        const label = e.target.value;
        const option = update(this.props.option, {
            label: { $set: label }
        });
        this.props.onChange(option, this.props.index);
    }

    private handleValueChange(e: ChangeEvent<HTMLInputElement>): void {
        const value = e.target.value;
        const option = update(this.props.option, {
            value: { $set: value }
        });
        this.props.onChange(option, this.props.index);
    }

    private handleOptionRemove(): void {
        this.props.onRemove(this.props.index);
    }

    public render(): ReactElement {
        return (
            <Spacer className="x-selectoptioneditor" fill>
                <Input
                    placeholder="Option label."
                    value={this.props.option.label}
                    onChange={this.handleLabelChange}
                />
                <Input
                    placeholder="Option value."
                    value={this.props.option.value}
                    onChange={this.handleValueChange}
                />
                <Button
                    className="x-selectoptioneditor-delete"
                    icon={<DeleteOutlined />}
                    onClick={this.handleOptionRemove}
                >
                </Button>
            </Spacer>
        );
    }

}
