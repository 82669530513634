import { ReactElement } from 'react';
import { Margin, SpacingType } from '@methodset/model-client-ts';
import { SpacingSelector } from 'containers/Components/Widgets/Selectors/SpacingSelector';
import update from 'immutability-helper';
import './MarginEditor.less';
import { Col, Row } from 'antd';

export type ChangeCallback = (value: Margin) => void;

export type MarginEditorProps = {
    value?: Margin,
    onChange: ChangeCallback
} & typeof defaultProps;

const defaultProps = {
    value: [SpacingType.NONE, SpacingType.NONE, SpacingType.NONE, SpacingType.NONE]
}

export const MarginEditor = (props: MarginEditorProps): ReactElement => {

    const handleChange = (spacing: SpacingType, index: number): void => {
        if (!spacing) {
            spacing = SpacingType.NONE
        }
        const margin = update(props.value, {
            [index]: { $set: spacing }
        });
        props.onChange(margin);
    }

    return (
        <Row gutter={[0, 8]}>
            <Row className="x-margineditor-row" justify="center">
                <Col>
                    <SpacingSelector
                        className="x-margineditor-selector"
                        value={props.value[0]}
                        onChange={(spacing) => handleChange(spacing, 0)}
                    />
                </Col>
            </Row>
            <Row className="x-margineditor-row" gutter={10} justify="center">
                <Col>
                    <SpacingSelector
                        className="x-margineditor-selector"
                        value={props.value[1]}
                        onChange={(spacing) => handleChange(spacing, 3)}
                    />
                </Col>
                <Col>
                    <SpacingSelector
                        className="x-margineditor-selector"
                        value={props.value[3]}
                        onChange={(spacing) => handleChange(spacing, 1)}
                    />
                </Col>
            </Row>
            <Row className="x-margineditor-row" justify="center">
                <Col>
                    <SpacingSelector
                        className="x-margineditor-selector"
                        value={props.value[2]}
                        onChange={(spacing) => handleChange(spacing, 2)}
                    />
                </Col>
            </Row>
        </Row>
    )
}

MarginEditor.defaultProps = defaultProps;
