import React, { PureComponent, ReactElement } from 'react';
import { Col, FormInstance, Row, Select } from 'antd';
import { Calculator } from '@methodset/calculator-ts';
import { Globals } from 'constants/Globals';
import { FormItem } from 'components/FormItem/FormItem';
import { AppletPanel, RowAlignType, RowJustifyType, RowWidgetConfiguration, SpacingType, Widget, WidgetType } from '@methodset/model-client-ts';
import { WidgetSelectors } from './WidgetSelectors/WidgetSelectors';
import { CoreUtils } from 'utils/CoreUtils';
import { SpacingSelector } from 'containers/Components/Widgets/Selectors/SpacingSelector';
import update from 'immutability-helper';
import './RowWidgetEditor.less';

export type ChangeCallback = (configuration: RowWidgetConfiguration) => void;

export type RowWidgetEditorProps = typeof RowWidgetEditor.defaultProps & {
    formRef: React.RefObject<FormInstance>,
    defaults: ReactElement,
    panel: AppletPanel,
    widget?: Widget,
    configuration?: RowWidgetConfiguration,
    calculator: Calculator,
    onChange: ChangeCallback
}

export class RowWidgetEditor extends PureComponent<RowWidgetEditorProps> {

    static DefaultConfiguration = {
        type: WidgetType.ROW,
        alignment: RowAlignType.TOP,
        justification: RowJustifyType.START,
        gap: SpacingType.MED,
        widgetIds: []
    } as RowWidgetConfiguration;

    static defaultProps = {
        configuration: RowWidgetEditor.DefaultConfiguration
    }

    constructor(props: RowWidgetEditorProps) {
        super(props);
        this.handleAlignChange = this.handleAlignChange.bind(this);
        this.handleJustifyChange = this.handleJustifyChange.bind(this);
        this.handleGapChange = this.handleGapChange.bind(this);
        this.handleWidgetsChange = this.handleWidgetsChange.bind(this);
    }

    private handleAlignChange(align: string): void {
        const configuration = update(this.props.configuration, {
            alignment: { $set: align as any }
        });
        this.props.onChange(configuration);
    }

    private handleJustifyChange(justify: string): void {
        const configuration = update(this.props.configuration, {
            justification: { $set: justify as any }
        });
        this.props.onChange(configuration);
    }

    private handleGapChange(gap: SpacingType): void {
        const configuration = update(this.props.configuration, {
            gap: { $set: gap }
        });
        this.props.onChange(configuration);
    }

    private handleWidgetsChange(widgetIds: string[]): void {
        const configuration = update(this.props.configuration, {
            widgetIds: { $set: widgetIds }
        });
        this.props.onChange(configuration);
    }

    public componentDidMount(): void {
        if (this.props.configuration === RowWidgetEditor.DefaultConfiguration) {
            this.props.onChange(this.props.configuration);
        }
    }

    public render(): ReactElement {
        return (
            <Row gutter={Globals.FORM_GUTTER_ROW}>
                <Col span={12}>
                    {this.props.defaults}
                </Col>
                <Col span={12}>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Alignment"
                        name="type"
                        info={"The alignment of the items in the row."}
                    >
                        <Select
                            placeholder="Select an alignment."
                            allowClear={true}
                            value={this.props.configuration.alignment}
                            onChange={this.handleAlignChange}
                        >
                            {Object.entries(RowAlignType).map(([key, value]) =>
                                <Select.Option key={key} value={key}>{CoreUtils.toProper(value, "_")}</Select.Option>
                            )}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Justification"
                        name="type"
                        info={"The justification of the items in the row."}
                    >
                        <Select
                            placeholder="Select a justification."
                            allowClear={true}
                            value={this.props.configuration.justification}
                            onChange={this.handleJustifyChange}
                        >
                            {Object.entries(RowJustifyType).map(([key, value]) =>
                                <Select.Option key={key} value={key}>{CoreUtils.toProper(value, "_")}</Select.Option>
                            )}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Gap"
                        name="gap"
                        info={"The spacing between the column items."}
                    >
                        <SpacingSelector
                            value={this.props.configuration.gap}
                            onChange={(gap) => this.handleGapChange(gap)}
                        />
                    </FormItem>
                    <FormItem
                        {...Globals.FORM_LAYOUT}
                        formRef={this.props.formRef}
                        label="Widgets"
                        name="widgets"
                        info="The widgets to include in the row."
                        valuePropName="widgetIds"
                        rules={[{
                            validator: () => {
                                for (const widgetId of this.props.configuration.widgetIds) {
                                    if (!widgetId) {
                                        return Promise.reject(`Please fill all widgets.`);
                                    }
                                }
                                return Promise.resolve();
                            }
                        }]}
                    >
                        <WidgetSelectors
                            panel={this.props.panel}
                            widget={this.props.widget}
                            widgetIds={this.props.configuration?.widgetIds}
                            onChange={this.handleWidgetsChange}
                        />
                    </FormItem>
                </Col>
            </Row>
        );
    }

}
